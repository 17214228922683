.footer-preview {
    display: block;
    background-color: var(--bg-color);
}

.footer-preview .footer-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px 11px;
    border-top: 1px solid var(--codgray);
    width: calc(100% - 500px);
    margin: 0 auto;
}

.footer-preview .footer-content .footer-link,
.footer-preview .footer-content .footerimg {
    display: flex;
    align-items: center;
}

.footer-preview .footer-content .footer-link li {
    list-style: none;
}

.footer-content .divider {
    background-color: var(--lightfont);
    height: 100%;
    min-height: 1.2rem;
    width: 1px;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}

.footer-content .copyright p,
.footer-content .footer-menu {
    color: var(--lightfont);
    font-size: 14px;
    line-height: 24px;
}

.footer-content .footerimg img {
    margin-right: 7px;
    border-radius: 50%;
    height: 36px;
    width: 36px;
}


@media (max-width: 1024px) {
    .footer-preview .footer-content {
        width: 100%;
    }
}

@media (max-width: 767px) {

    .footer-preview ul a,
    .copyright p,
    .copyright p a {
        color: var(--white);
    }
}
@media (max-width: 420px) {

    .footer-content .divider{
        display: none;
      }
      .footer-preview .footer-content{
        flex-direction: column;
        padding: 18px 6px;
      }
      .footer-content .copyright p, 
      .footer-content .footer-menu {
        font-size: 12px;
      }
}