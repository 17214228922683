.container {
  max-width: 100%;
  padding-left: 36px;
  padding-right: 36px;
}
.dashboard-section {
  padding: 20px;
}
.dashboard-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}
.dashboard-top-left {
  margin-bottom: 28px;
}
.dashboard-top-text {
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  color: #111928;
  margin: 0;
}
.dashboard-top-subtext {
  color: #425466;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  margin-top: 5px;
}
.dashboard-top-per {
  background-color: #FBFBFB;
  border: 1px solid #EAECF0;
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 8px;
  font-size: 18px;
  color: #425466;
  cursor: pointer;
  margin-right: 10px;
}
.dashboard-top-button {
  display: flex;
  align-items: center;
}
.dashboard-top-date {
  background-color: #FBFBFB;
  border: 1px solid #EAECF0;
  padding: 9px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #344054;
}
.dashboard-top-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 28px;
}
.dashboard-top-wrapper .dashboard-top-left {
  margin-bottom: 0;
}
.dashboard-box-wrapp {
  display: flex;
  flex-wrap: wrap;
  gap: 0 20px;
  max-width: 1240px;
}
.dashboard-box,
.dashboard-item-box {
  display: flex;
  align-items: center;
}
.dashboard-item {
  cursor: pointer;
  border: 1px solid #EAECF0;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  border-radius: 6px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.active-box .dashboard-item {
  background-color: #f9f9f9;
}

.dashboard-item p {
  position: relative;
}
.dash-circle {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-left: 4px;
  vertical-align: middle;
  margin-bottom: 2px;
}
.dash-circle.green-active {
  background-color: #61C454;
}
.dash-circle.light-blue-active {
  background-color: #FFC0F9;
}
.dash-circle.blue-active {
  background-color: #5485ED;
}
.dash-circle.red-active {
  background-color: #E65C5C;
}
.dash-circle.grey-active {
  background-color: #B4B4B4;
}
.dash-circle.sky-active {
  background-color: #54EDE4;
}
.dark-green-active {
  background-color: #B1B419;
}
.dark-coffee-active {
  background-color: #992B2B;
}
.dash-user-status {
  font-size: 14px;
  color: #425466;
  font-weight: 500;
  margin-bottom: 14px;
}
.dash-user-data {
  font-size: 18px;
  color: #0A2540;
  font-weight: 500;
  display: flex;
}
.graphPercent {
  font-size: 12px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  padding: 2px 10px 2px 8px;
  color: #0A2540;
  gap: 4px;
  font-weight: 500;
}
.dash-green-growth {
  color: #027A48;
}
.dash-red-growth{
  color: #f87171;
}
.dashboard-item-box .dashboard-item-inner:not(:last-child) {
  margin-right: 25px;
}
.dashboard-item:last-child {
  margin-right: 0;
}
.datepickerButton {
  position: relative;
}
.datepickerButton .date {
  border: 1px solid #eaecf0;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, .08), 0 4px 6px -2px rgba(16, 24, 40, .03);
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 99;
}
.dashboardGraph {
  padding-top: 0;
}
.analyticsCharts {
  position: relative;
  display: block;
  border: 1px solid #EAECF0;
  border-radius: 6px;
  padding: 14px;
  padding-bottom: 3px;
  padding-top: 9px;
}
.calendarButton {
  background: #ffffff;
  border: 0;
  box-shadow: 0 8px 24px hsla(210, 8%, 62%, .2);
  border-radius: 6px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  color: #344054;
  font-size: 14px;
  line-height: 20px;
}
.calendarButton img {
  margin-right: 10px;
  margin-top: -1px;
}
.daterange {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
  border-radius: 2px;
  align-items: center;
  max-width: calc(100% - 190px);
  margin-left: 189px;
  border-left: 1px solid #f1f3f5;
}
.datepickerButton .rdrDefinedRangesWrapper .rdrStaticRangeSelected,
.date .rdrDefinedRangesWrapper .rdrStaticRanges:not(:has(.rdrStaticRangeSelected))+.rdrInputRanges {
  color: #007aff !important;
  font-size: 14px !important;
}
.daterange-update {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-left: 8px;
}
.daterange span {
  margin: auto;
}
.datepickerButton .rdrMonthAndYearWrapper,
.dateFieldDrop .rdrMonthAndYearWrapper {
  padding-top: 0;
}
.datepickerButton .rdrMonthsHorizontal {
  margin-top: -47px;
}
.datepickerButton .rdrMonthName {
  text-align: center;
  max-width: calc(100% - 25px);
  color: #344054;
  font-size: 16px;
}
.datepickerButton .rdrWeekDay {
  color: #344054;
  font-size: 14px;
}
.datepickerButton .rdrMonthsHorizontal .rdrMonth:first-child .rdrMonthName {
  margin-left: 25px;
}
.datepickerButton .rdrDayToday .rdrDayNumber span:after,
.datepickerButton .rdrDayToday:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span:after,
.datepickerButton .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span:after,
.datepickerButton .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span:after {
  display: none;
}
.datepickerButton .rdrDay {
  display: inline-block;
  height: 40px;
  width: 44.43px;
  font-size: 14px;
}
.datepickerButton .rdrDayNumber span {
  color: #344054;
}
.datepickerButton .rdrDayPassive .rdrDayNumber span {
  color: #667085;
}
.datepickerButton .rdrDayStartPreview.rdrDayEndPreview,
.datepickerButton .rdrDayHovered .rdrDayStartPreview.rdrDayEndPreview {
  border: 0;
}
.datepickerButton .rdrInRange+.rdrDayStartPreview.rdrDayEndPreview {
  display: none;
}
.datepickerButton .rdrDayNumber {
  display: inline-block;
  height: 36px;
  width: 36px;
  line-height: 36px;
  border-radius: 50%;
  left: 2px;
  top: 4px;
  z-index: 2;
}
.datepickerButton .rdrDayHovered:not(:has(.rdrStartEdge, .rdrEndEdge, .rdrDayEndPreview)) .rdrDayNumber {
  background: #F9FAFB;
}
.datepickerButton .rdrSelected,
.datepickerButton .rdrInRange,
.datepickerButton .rdrStartEdge,
.datepickerButton .rdrEndEdge {
  height: 36px;
}
.datepickerButton .rdrStartEdge,
.datepickerButton .rdrEndEdge {
  color: #007aff !important;
  width: 36px;
  border-radius: 50%;
  z-index: 2;
  top: 4px
}
.datepickerButton .rdrEndEdge {
  left: 2px;
  right: auto;
}
.datepickerButton .rdrEndEdge:not(.rdrStartEdge)~.rdrDayNumber {
  width: 36px;
}
.datepickerButton .rdrInRange {
  background: #F9FAFB;
}
.datepickerButton .rdrDayWeekend.rdrDayEndOfWeek .rdrInRange {
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
}
.datepickerButton .rdrDayWeekend.rdrDayStartOfWeek .rdrInRange {
  border-top-left-radius: 17px;
  border-bottom-left-radius: 17px;
  left: -2px;
}
.datepickerButton .rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span {
  color: #344054;
}
.datepickerButton .rdrDay:has(.rdrDayStartPreview)+.rdrDay .rdrDayInPreview,
.datepickerButton .rdrDay:has(.rdrStartEdge)+.rdrDay .rdrDayInPreview,
.datepickerButton .rdrDay:has(.rdrStartEdge)+.rdrDay .rdrInRange {
  left: -17px;
}
.datepickerButton .rdrDay:not(.rdrDayEndOfWeek):has(+ .rdrDay .rdrDayEndPreview) .rdrDayInPreview,
.datepickerButton .rdrDay:has(+ .rdrDay .rdrEndEdge) .rdrDayInPreview,
.datepickerButton .rdrDay:not(.rdrDayEndOfWeek):has(+ .rdrDay .rdrEndEdge) .rdrInRange {
  right: -17px;
}
.datepickerButton .rdrDayStartPreview,
.datepickerButton .rdrDayEndPreview,
.datepickerButton .rdrDayInPreview {
  border: 0;
}
.datepickerButton .rdrDayInPreview {
  background-color: #F9FAFB;
  bottom: 0;
}
.datepickerButton .rdrDay:not(:has(.rdrStartEdge.rdrEndEdge)) .rdrDayEndPreview {
  background: #F9FAFB;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  left: 2px;
  top: 4px;
}
.datepickerButton .rdrDay.rdrDayHovered:has(.rdrDayStartPreview.rdrDayEndPreview) .rdrDayEndPreview {
  background: #F9FAFB;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  left: 2px;
  top: 4px;
}
.datepickerButton .rdrDayStartOfWeek .rdrEndEdge,
.datepickerButton .rdrDayEndOfWeek .rdrStartEdge {
  border-radius: 50%;
}
.datepickerButton .rdrDayStartOfMonth .rdrInRange {
  border-top-left-radius: 17px;
  border-bottom-left-radius: 17px;
}
.datepickerButton .rdrDayEndOfMonth .rdrInRange {
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
  width: 44px;
}
.datepickerButton .rdrDayStartOfMonth+.rdrDay .rdrInRange,
.datepickerButton .rdrDayStartOfMonth+.rdrDay .rdrDayInPreview {
  left: 0;
}
.datepickerButton .rdrDay.rdrDayEndOfWeek .rdrInRange,
.datepickerButton .rdrDay.rdrDayEndOfWeek .rdrDayInPreview {
  right: 0;
}
.datepickerButton .rdrDay.rdrDayStartOfWeek:has(+ .rdrDay .rdrDayEndPreview) .rdrDayInPreview {
  right: -17px;
}
.datepickerButton .rdrDayEndOfMonth .rdrStartEdge.rdrEndEdge,
.datepickerButton .rdrDayEndOfWeek .rdrStartEdge.rdrEndEdge,
.datepickerButton .rdrDayStartOfMonth .rdrStartEdge.rdrEndEdge,
.datepickerButton .rdrDayStartOfWeek .rdrStartEdge.rdrEndEdge {
  border-radius: 50%;
}
.datepickerButton .rdrDayEndPreview+.rdrDayNumber {
  background-color: transparent;
}
.date .rdrMonths {
  border-bottom: 1px solid #f1f3f5;
}
.date .empty:after {
  content: "";
  height: 15px;
  border: 1px solid black;
  position: absolute;
  right: 0;
}
.date .empty {
  position: relative;
}
.daterange-start,
.daterange-end,
.daterange-btn-cancel {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  color: #373737;
  background-color: transparent;
}
.daterange-btn-apply {
  cursor: pointer;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 8px;
  border: #007aff;
  border-radius: 6px;
  color: #fff;
  background-color: #007aff;
  height: 35px;
  width: 65px;
}
.date .rdrMonths .rdrMonth:first-child {
  border-right: 1px solid #f1f3f5;
}
.datepickerButton .rdrInputRanges,
.datepickerButton .rdrInputRange {
  padding: 0;
}
.datepickerButton .rdrInputRange span,
.datepickerButton .rdrStaticRange span {
  font-size: 14px;
  color: #344054;
  font-weight: 500;
  width: 160px;
  border-radius: 6px;
  padding: 10px;
  margin: 0 15px;
}
.datepickerButton .rdrStaticRange {
  border: none;
}
.datepickerButton .rdrDefinedRangesWrapper {
  padding: 15px 0 0;
  width: 190px;
}
.datepickerButton .rdrStaticRange.rdrStaticRangeSelected span {
  color: #007aff !important;
  background-color: #f9fafb;
  font-size: 14px;
}
.datepickerButton .rdrDefinedRangesWrapper .rdrStaticRange:hover .rdrStaticRangeLabel,
.datepickerButton .rdrDefinedRangesWrapper .rdrStaticRange:focus .rdrStaticRangeLabel {
  background: #f9fafb;
}
.datepickerButton .rdrDayPassive .rdrDayNumber {
  color: #667085;
  font-size: 14px;
  display: none;
}
.datepickerButton .rdrDay:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span,
.datepickerButton .rdrDay:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span {
  color: #FFFFFF;
}
.datepickerButton .rdrNextPrevButton,
.dateFieldDrop .rdrNextPrevButton {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  border: 0;
  background-color: transparent;
}
.dateFieldDrop .rdrMonthAndYearPickers select:hover {
  background-color: transparent;
}
.datepickerButton .rdrPprevButton,
.datepickerButton .rdrNextButton,
.dateFieldDrop .rdrNextButton,
.dateFieldDrop .rdrPprevButton {
  position: relative;
}
.datepickerButton .rdrPprevButton i,
.dateFieldDrop .rdrPprevButton i {
  border: none;
  content: "";
  background-image: url('../../images/down.svg');
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  width: 12px;
  height: 18px;
  display: block;
  left: auto;
  position: absolute;
  right: 5px;
  top: 5px;
  transform: rotate(92deg);
  transition: 0.3s;
}
.datepickerButton .rdrNextPrevButton:hover,
.datepickerButton .rdrNextButton:hover,
.dateFieldDrop .rdrNextPrevButton:hover,
.dateFieldDrop .rdrNextButton:hover {
  background-color: transparent;
}
.datepickerButton .rdrNextButton i,
.dateFieldDrop .rdrNextButton i {
  border: none;
  content: "";
  background-image: url('../../images/down.svg');
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  width: 12px;
  height: 18px;
  display: block;
  left: auto;
  position: absolute;
  right: 5px;
  top: 5px;
  transform: rotate(270deg);
  transition: 0.3s;
}
.dashboard-table-info p {
  display: inline-block;
  margin: 10px 0px 10px 0px;
  font-weight: 500;
}

.analyticsSelect
    .detailsPopup-inner
    .popup__content
    .filter-select__multi-value {
    width: calc(100% - 10px);
    justify-content: space-between;
    background-color: rgb(241 245 249);
    border-radius: 4px;
    margin-right: 10px;
    margin-left: 0;
}

.analyticsSelect
    .detailsPopup-inner
    .popup__content
    .filter-select__indicators {
    position: absolute;
    right: 0;
    top: 4px;
}

.analyticsSelect
    .detailsPopup-inner
    .popup__content
    .filter-select__multi-value
    .filter-select__multi-value__label {
    width: 100%;
    padding: 2px 8px;
}

.analyticsSelect
    .detailsPopup-inner
    .popup__content
    .filter-select__value-container--has-value {
    padding-left: 8px;
    padding-right: 18px;
    height: 100%;
    overflow: visible;
    max-width: 100%;
}

.analytics
    .analyticsSelect
    .popup__content
    .select-container
    .filter-select__multi-value__remove:hover {
    background-color: #f1f5f9;
}

.analytics
    .analyticsSelect
    .popup__content
    .select-container
    .filter-select__multi-value__remove {
    cursor: pointer;
}

.analyticsSelect
    .detailsPopup-inner
    .popup__content
    .filter-select__indicators
    > .css-15lsz6c-indicatorContainer
    ~ .css-15lsz6c-indicatorContainer {
    display: block;
}

.analytics
    .analyticsSelect
    .popup__content
    .select-container
    + .select-container
    .css-p1jp6k-Input,
.analytics
    .analyticsSelect
    .popup__content
    .select-container
    + .select-container
    .css-1q3xva4-control
    .css-5mdhh5-Input {
    padding-left: 0;
}

.analytics
    .analyticsSelect
    .popup__content
    .select-container
    + .select-container
    .css-1q3xva4-control:has(.filter-select__multi-value) {
    border-radius: 8px;
}

.analytics
    .analyticsSelect
    .popup__content
    .select-container
    + .select-container:has(.filter-select__multi-value) {
    height: auto;
}

.analytics
    .analyticsSelect
    .popup__content
    .select-container
    + .select-container
    .css-1q3xva4-control:has(.filter-select__multi-value)
    ~ .css-1nmdiq5-menu {
    top: auto;
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
        rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.1) 0px 4px 6px -4px;
}

.analyticsSelect
    .detailsPopup-inner
    .popup__content
    .filter-select__indicators
    .filter-select__clear-indicator {
    display: none;
}

.analytics
    .analyticsSelect
    .popup__content
    .select-container
    + .select-container
    .css-1q3xva4-control:has(.filter-select__multi-value)
    ~ .css-1nmdiq5-menu
    .css-1wlit7h-NoOptionsMessage {
    font-size: 12px !important;
    text-align: left;
}

.analytics
    .analyticsSelect
    .popup__content
    .select-container
    + .select-container
    .css-1q3xva4-control:has(.filter-select__multi-value)
    ~ .css-1nmdiq5-menu
    .css-1wlit7h-NoOptionsMessage {
    padding: 8px 16px;
    color: rgb(51, 65, 85);
    font-size: 14px;
    line-height: 19px;
}


.analytics .analyticsSelect {
  width: 450px;
  display: flex;
  justify-content: flex-end;
}

.analytics
  .analyticsSelect
  .period-selections
  .selected-period.homepage-select {
  min-width: unset;
  max-width: unset;
}

.analytics .analyticsSelect .filter-selections .periods-options > div.selected {
  font-weight: 500;
}

.analytics
  .analyticsSelect
  .filter-selections
  .periods-options
  > div:nth-child(odd) {
  border-bottom: none;
}

.analytics
  .analyticsSelect
  .filter-selections
  .periods-options
  > div:nth-child(odd):after {
  display: none;
}

.selections-dropdown:empty {
  display: none;
}

.analytics .analyticsSelect .filter-selections .periods-options > div {
  margin: 0;
  line-height: 20px;
}

.analytics .analyticsSelect .select-container:first-child .custom-placeholder {
  padding: 5px 12px;
  border-radius: 4px;
  min-height: 36px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent !important;
}

.analytics
  .analyticsSelect
  > .select-container:first-child
  .custom-placeholder
  img {
  top: 12px;
  left: 14px;
  max-width: 12px;
}

.analytics
  .analyticsSelect
  > .select-container:first-child
  .custom-placeholder:hover {
  background-color: rgb(226 232 240) !important;
}

.analytics .analyticsSelect > .select-container:first-child span + div {
  background: transparent !important;
}

.analytics .analyticsSelect > .select-container:first-child span + div > div {
  padding: 0;
}

.analytics .analyticsSelect .period-selections {
  width: 180px;
  border: none;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
  background-color: #fff;
  border-radius: 4px;
  height: 36px;
  cursor: pointer;
  position: relative;
}

.analytics .analyticsSelect .period-selections .selected-period {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  padding: 0 12px;
  font-size: 14px;
  font-weight: 500;
}

.analytics .analyticsSelect .filter-selections .selected-filter:hover,
.analytics .analyticsSelect .period-selections .selected-period:hover {
  background-color: rgb(226 232 240);
}

.analytics .analyticsSelect .filter-selections .selected-filter {
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 12px 0 28px;
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  box-shadow: none;
  width: 72px;
  color: #64748b;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}
.analytics .analyticsSelect > .select-container:last-child .css-5mdhh5-Input {
  padding-left: 0;
}
.analytics .analyticsSelect .select-container svg {
  fill: #425466;
}

.detailsPopup-content h2,
.analyticsSelect .detailsPopup-inner h2 {
    font-size: 20px;
    color: #1e293b;
    font-weight: 700;
    border-bottom: 1px solid #cbd5e1;
    padding-bottom: 15px;
    margin: 3px 0 15px;
    width: 100%;
}
.analytics .analyticsSelect .select-container:first-child .css-6wyr25-control {
  background-color: transparent;
}

.analyticsSelect .detailsPopup-inner {
  max-width: 460px;
}

.analyticsSelect .detailsPopup-inner {
  max-width: 460px;
  min-height: auto;
  padding-bottom: 25px;
}

.analyticsSelect .detailsPopup-inner .popup__content {
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 15px;
  gap: 10px;
  align-content: flex-start;
}

.analyticsSelect .detailsPopup-inner .popup__content > div:first-child {
  width: 100%;
  font-size: 14px;
  margin-bottom: 0;
  color: #334155;
  font-weight: 500;
}

.analytics .analyticsSelect .popup__content .select-container {
  width: calc(30% - 5px) !important;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  border: 1px solid #cbd5e1;
}

.analytics .analyticsSelect .popup__content .select-container svg {
  fill: #64748b;
  width: 15px;
  height: 15px;
}

.analytics
  .analyticsSelect
  .popup__content
  .select-container
  + .select-container {
  width: calc(70% - 5px) !important;
  height: 41px;
}

.analytics
  .analyticsSelect
  .popup__content
  .select-container
  .selectPlaceHolder {
  margin-left: 0;
}

.analytics .analyticsSelect .filter-selection {
  cursor: pointer;
}
.selections-dropdown {
  position: absolute;
  z-index: 9;
  background: #fff;
  width: 100%;
  top: 50px;
  font-size: 14px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  min-width: 180px;
  left: 0;
}
.analytics .period-selections > .periods-options > div:not(.date):nth-child(odd) {
  border-bottom: 0;
  position: relative;
}
.analytics .analyticsSelect .period-selections .selected-period:hover,
.analytics .analyticsSelect .selections-dropdown > div:hover  {
  background-color: #f9f9f9;
}
.analytics .selections-dropdown > div:not(.date):last-child{
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.analytics .selections-dropdown > div:not(.date):last-child::after{
  border-bottom: none !important;
}

.analytics .selections-dropdown > div.selected {
  font-weight: 700;
}
.dashboard-item .analyticsDate {
  color: #1e293b;
  font-size: 12px;
  margin-bottom: 8px;
}
.compareValue{
  color: #1e293b;
}

.dashboard-item .analyticsDate.compareDate,
.compareCount {
  color: #64748b;
  padding-top: 2px;
  margin-bottom: 0;
}
.dashboard-item .compareValue{
  margin-bottom: 10px;
}

.analytics .selections-dropdown > div:hover {
  background-color: rgb(241 245 249);
}

.analytics .period-selections > .periods-options > div:not(.date):nth-child(odd):after {
  content: "";
  border-bottom: 1px solid #e2e8f0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: block;
  position: absolute;
}
.arrow-section {
  display: flex;
  margin-right: 16px;
  box-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  border-radius: 4px;
}
.arrow-section button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.arrow-section button {
  line-height: 24px;
  border: 0;
  cursor: pointer;
  background-color: #fff;
  padding: 0 7px;
  width: auto;
  display: flex;
  align-items: center;
}
.arrow-section button svg {
  height: 16px;
  overflow: visible;
  width: 16px;
}
.arrow-section button.disable {
    background-color: #cbd5e1;
}

.arrow-section button:last-child {
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.analytics .analyticsSelect .period-selections + span {
  font-size: 14px;
  font-weight: 500;
  color: #1e293b;
  line-height: 33px;
  margin: 0 8px;
  display: inline-block;
  vertical-align: middle;
}

.analytics .datepickerButton .rdrDefinedRangesWrapper {
  display: none;
}
.datepickerButton .rdrDefinedRangesWrapper {
  padding: 15px 0 0;
  width: 190px;
}
.rdrDefinedRangesWrapper {
  font-size: 12px;
  width: 226px;
  border-right: solid 1px #eff2f7;
  background: #fff;
}

.rdrCalendarWrapper {
  color: #000000;
  font-size: 12px;
}

.rdrDateRangeWrapper {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.rdrCalendarWrapper {
  box-sizing: border-box;
  background: #ffffff;
  display: inline-flex;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.datepickerButton .rdrInputRangeInput, .datepickerButton .rdrDateDisplayWrapper, .datepickerButton .rdrMonthAndYearPickers {
  display: none;
}
.rdrDateDisplayWrapper {
  background-color: rgb(239, 242, 247);
}
.rdrDateDisplay {
  margin: 0.833em;
}

.rdrDateDisplay {
  display: flex;
  justify-content: space-between;
}
.rdrDateDisplayItemActive {
  border-color: currentColor;
}

.rdrDateDisplayItem {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  border: 1px solid transparent;
}
.rdrDateInput {
  position: relative;
}
.rdrDateDisplayItemActive input {
  color: #7d888d;
}

.rdrDateDisplayItem input {
  cursor: pointer;
  height: 2.5em;
  line-height: 2.5em;
  border: 0px;
  background: transparent;
  width: 100%;
  color: #849095;
}
.rdrDateInput input {
  outline: none;
}
.rdrDateDisplayItem input {
  text-align: inherit;
}
.rdrDateDisplayItem + .rdrDateDisplayItem {
  margin-left: 0.833em;
}
.rdrDateDisplayItem {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  border: 1px solid transparent;
}
.rdrDateInput {
  position: relative;
}
.rdrDateDisplayItem {
  flex: 1 1;
  width: 0;
  text-align: center;
  color: inherit;
}
.rdrDateDisplayItem input {
  cursor: pointer;
  height: 2.5em;
  line-height: 2.5em;
  border: 0px;
  background: transparent;
  width: 100%;
  color: #849095;
}

.rdrDateInput input {
  outline: none;
}
.rdrDateDisplayItem input {
  text-align: inherit;
}
.analytics .daterange {
  max-width: 100%;
  margin-left: 0;
}

.daterange {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
  border-radius: 2px;
  align-items: center;
  max-width: calc(100% - 190px);
  margin-left: 189px;
  border-left: 1px solid #f1f3f5;
}
.daterange-update {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-left: 8px;
}
.daterange span {
  margin: auto;
}
.daterange-btn-apply {
  cursor: pointer;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 8px;
  border: #007aff;
  border-radius: 6px;
  color: #fff;
  background-color: #007aff;
  height: 35px;
  width: 65px;
}
.analytics .periods-options .date {
  top: 0;
  background-color: #fff !important;
  border: 1px solid #eaecf0 !important;
  padding: 0;
}
.dashboardChart .visitor-grreapoh{
  cursor: pointer;
}

.analytics .selections-dropdown > div {
  font-weight: 500;
  line-height: 17px;
  margin: 0;
  color: #1e293b;
  padding: 10px 16px;
  cursor: pointer;
}
.date {
  position: absolute;
  z-index: 99;
  top: 50px;
  right: 0;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}
#chartjs-tooltip {
  background-color: rgba(25, 30, 56);
  position: absolute;
  font-size: 14px;
  font-style: normal;
  padding: 10px 12px;
  pointer-events: none;
  border-radius: 5px;
  z-index: 100;
  top: 0;
}
.grapph-tooltip {
  color: #f1f5f9;
  min-width: 120px;
}
.tooltip-mark{
  height: 12px;
  width: 12px;
  border-radius: 50%;
}
.user-mark{
  background-color: rgba(0, 255, 0.8);
  
}
.newUser-mark{
  background-color: rgba(84, 133, 237, 0.5);
}

.tooltip-title {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 5px;
  text-transform: capitalize;
  color: #f1f5f9;
}

.grapph-tooltip .tooltip-subtitle, .tooltip-data {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
  color: #f1f5f9;
}
.activeUser{
  background-color: #61C454;
}
.dailyActiveUsers{
  background-color: #FFC0F9;
}
.newUser{
  background-color: #5485ED;
}
.tooltip-pages{
  background-color: #54EDE4;
}
.tooltip-free{
  background-color: #808080;
}
.tooltip-publication{
  background-color: #992B2B;   
}
.tooltip-newsletter{
  background-color: #B1B419;   
}
.tooltip-delete{
  background-color:#E65C5C;    
}
.tooltip-unlimited{
  background-color: #0000ff ;
}
.tooltip-PA{
  background-color: #0000ff;
}

.grapph-tooltip .tooltip-mark.compare-mark {
  background-color: rgb(100 116 139);
}

.tooltip-value {
  font-size: 16px;
  font-weight: 600;
}
.tooltip-data .tooltip-mark.compare-unlimited,
.tooltip-data .tooltip-mark.compare-pa{
  background-color: rgba(0, 0, 255, 0.5);
}
.tooltip-data .tooltip-mark.compare-free{
  background-color: rgba(128, 128, 128, 0.5);
}
.tooltip-data .tooltip-mark.compare-page{
  background-color: rgba(135, 206, 235, 0.5);
}
.tooltip-data .tooltip-mark.compare-publication{
  background-color: rgba(165, 42, 42, 0.5);
}
.tooltip-data .tooltip-mark.compare-newsletter{
  background-color: rgba(177, 180, 25, 0.5);
}
.tooltip-data .tooltip-mark.compare-delete{
  background-color: rgba(255, 0, 0, 0.5);
}


.grapph-tooltip .tooltip-link {
  font-weight: 600;
  font-style: italic;
}

.grapph-tooltip .tooltip-hide {
  display: none;
}
@media (max-width: 1200px) {

  .dashboard-item {
    padding: 10px;
    min-height: 80px;
  }
  .datepickerButton {
    margin-top: 20px;
  }
  .dashboard-item-box .dashboard-item-inner:not(:last-child) {
    margin-right: 10px;
  }
  .dash-user-status {
    font-size: 13px;
  }
  .dash-user-data {
    font-size: 13px;
  }
  .dash-green-growth {
    margin-left: 5px;
    padding: 5px 7px;
    font-size: 13px;
    padding-top: 0;
  }
}

@media (max-width: 992px) {

  .dashboard-top-wrapper {
    display: block;
  }

}
@media screen and (max-width: 768px) {
  .datepickerButton {
    margin-top: 20px;
}

.analytics .analyticsSelect .period-selections {
    margin-top: 0;
}

.analytics .analyticsSelect .filter-selections {
    margin-top: 0;
}
}
@media (max-width: 767px) {

  .dashboard-top-text {
    font-size: 24px;
    line-height: 30px;
  }
  .dashboard-top-button {
    margin-top: 20px;
  }
  .dashboard-top {
    flex-direction: column;
    align-items: flex-start;
  }
  .dashboard-box {
    flex-direction: column;
  }
  .dashboard-box .dashboard-item {
    width: 100%;
    padding: 10px;
    margin-right: 0;
  }
  .dashboard-item-box {
    flex-wrap: wrap;
  }
  .dashboard-box-wrapp {
    display: block;
  }
  .dashboard-item-box .dashboard-item-inner {
    width: calc(100% - 20px);
    margin: 10px;
}
.analytics .analyticsSelect {
  width: 100%;
}
.analytics .analyticsSelect .select-container:last-child {
  max-width: unset;
  min-width: unset;
}

.analytics .analyticsSelect .select-container:first-child {
  width: auto;
}

.analytics .analyticsSelect .select-container .filter-select__indicators {
  display: none;
}
.analytics .analyticsSelect .period-selections + span {
  display: none;
}

}

@media screen and (max-width: 480px) {
  .analytics .analyticsSelect .period-selections {
    width: 130px;
    min-width: 130px;
    margin-left: 5px;
}
.analytics .analyticsSelect .period-selections .selected-period {
  font-size: 12px;
}
}