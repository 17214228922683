.main-wrapper .error-page {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 0;
    min-height: calc(100vh - 92px);
}

.erorBlock {
    padding: 45px 10px 10px;
    text-align: center;
}

.erorBlock span {
    color: #007aff;
    font-size: 16px;
    font-weight: 600;
}

.erorBlock h1 {
    color: #101828;
    font-size: 46px;
    font-weight: 600;
    margin-bottom: 18px;
    margin-top: 10px;
}

.erorBlock p {
    color: #475467;
    font-size: 16px;
    font-weight: 400;
}

.errorSubtitle {
    margin-top: 10px;
}

.erorButton {
    display: flex;
    justify-content: center;
    margin: 35px auto 0;
}

.erorButton_left, .erorButton_right {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
}

.erorButton_left {
    align-items: center;
    background-color: initial;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    color: #344054;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    outline: none;
}

.erorButton_right {
    background: #007aff;
    border: 1px solid #007aff;
    border-radius: 8px;
    color: #fff;
    text-align: center;
}

.erorButton_left, .erorButton_right {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
}