.profile-section {
    margin-bottom: 30px;
}
.profile-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 18px 23px;
    border: 1px solid #EAECF0;
    border-radius: 6px;
    position: relative;
}
.profile-item:first-child {
    width: calc(100% - 240px);
}
.profile-item:last-child {
    width: 240px;
}
.profile-date-content {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 15px;
    position: relative;
}
.profile-date-content .icon-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 10px 5px;
    text-decoration: none;
    color: #fff;
    width: 40px;
    height: 40px;
    cursor: pointer;
}
/* .profile-date-content .icon-dots:hover {
    background: #f9f9f9;
    color: #101828;
} */
.profile-date-content .profile-date {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #344054;
}
.profile-date-content .profile-sub-text {
    color: #667085;
    margin-left: 2px;
}
.profile-item-card {
    display: flex;
    align-items: center;
}
.profile-image {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    overflow: hidden;
}
.profile-image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.profile-content {
    margin-left: 22px;
    width: calc(100% - 92px);
}
.profile-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #344054;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: capitalize;
}
.profile-text {
    font-size: 14px;
    line-height: 24px;
    color: #667085;
    margin-bottom: 4px;
}
.profile-btn {
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    color: #0086C9;
    padding: 5px 10px;
    border: 1.5px solid #0086C9;
    border-radius: 6px;
    text-decoration: none;
    display: inline-block;
    transition: all 0.3s linear;
    text-transform: capitalize;
}
.profile-card.profile-data {
    border: 0;
    padding: 0;
    align-items: stretch;
    gap: 20px;
}
.profile-card.profile-data .profile-item-card {
    padding: 20px 23px;
}
.profile-data .profile-item {
    border: 1px solid #EAECF0;
    border-radius: 6px;
}
.profile-card.profile-data .profile-item:first-child {
    width: calc(29% - 20px);
}
.profile-card.profile-data .profile-item:nth-child(2) {
    width: calc(49% - 20px);
}
.profile-card.profile-data .profile-item:nth-child(3) {
    width: calc(23% - 20px);
    display: flex;
    align-items: center;
    justify-content: end;
    border: none;
}
.profile-social-content {
    padding: 14px 14px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}
.social-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33.33%;
}
.profile-social-content .social-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 6px 0;
    color: #0A2540;
    text-align: center;
}
.profile-social-content a {
    font-size: 14px;
    line-height: 20px;
    color: #007AFF;
    text-decoration: none;
}
.profile-item.profile-social-item {
    padding: 0;
}
.profile-buttons {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 12px;
    position: relative;
}
.profile-buttons a svg {
    margin-right: 6px;
}
.profile-buttons .form-btn {
    display: flex;
    align-items: center;
}
.profile-buttons .icon-dots {
    padding: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 40px;
}
.profile-buttons .icon-dots img{
    margin-right: 0;
}
.profile-content .profile-btn-pro {
    border: 1.5px solid #1570EF;
    color: #1570EF;
}
.profile-buttons .icon-profile:hover {
    background-color: #573CFF;
}
.popup-container {
    padding: 10px 16px 10px 10px;
    background: #fff;
    border: 1px solid #eaecf0;
    border-radius: 6px;
    box-shadow: 0 12px 16px -4px rgba(16,24,40,.08),0 4px 6px -2px rgba(16,24,40,.03);
    min-width: 160px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 999;
    margin-bottom: -55px;
}
.popup-delete {
    cursor: pointer;
}
.popup-delete span {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.profile-buttons .addDropdown {
    top: 45px;
    right: 0;
}

@media (max-width: 1400px) {

    .profile-card.profile-data .profile-item:first-child {
        width: calc(40% - 12px);
    }
    .profile-card.profile-data .profile-item:nth-child(2) {
        width: calc(60% - 12px);
    }
    .profile-card.profile-data .profile-item:nth-child(3) {
        width: 100%;
        justify-content: start;
    }
    .profile-tabs .profile-tab-item {
        margin-right: 10px;
    }

}

@media (max-width: 991px) {

    .profile-social-content .social-title {
        margin: 3px 0;
    }
    .profile-social-content a {
        font-size: 12px;
    }

}

@media (max-width: 767px) {
    .profile-card.profile-data .profile-item:first-child,
    .profile-card.profile-data .profile-item:nth-child(2) {
        width: 100%;
    }
    .profile-card.profile-data {
        flex-direction: column;
        gap: 0;
        width: 100%;
        row-gap: 15px;
    }
}

@media (max-width: 560px) {

    .profile-social-content {
        flex-wrap: wrap;
    }
    .social-data {
        width: 50%;
    }
    .social-data:not(:last-child) {
        margin-bottom: 15px;
    }
    .social-data:last-child {
        width: 100%;
    }
    .profile-item:first-child,
    .profile-item:last-child {
        width: 100%;
    }
    .profile-date-content {
        margin-top: 15px;
        justify-content: space-between;
    }
    
    
}