.contactForm *::-webkit-input-placeholder {
    /* Edge */
    color: #525866;
}

.contactForm *:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #525866;
}

.contactForm *::placeholder {
    color: #525866;
}

.contactFormContent .formField::-ms-input-placeholder {
    color: var(--white);
}

.contactFormContent .formField::placeholder {
    color: var(--white);
}

.contactFormContent .formField::-webkit-input-placeholder {
    color: var(--white);
}
.formControl, .contactContent{
    pointer-events: none;
}
.form-contact .formControl:first-child {
    width: 30%;
    margin-right: 30px;
}

.form-contact .formGroup {
    display: flex;
    flex-direction: row;
}

.contactForm label {
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    display: block;
    width: 100%;
    margin-bottom: 6px;
}
.labelBtnWrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.contactForm .btnWrapper {
    width: 83px;
    padding: 0;
    margin-left: 17px;
}

.contactFormTitle {
    color: var(--secondary);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 20px;
    line-height: 24px;
    letter-spacing: -0.32px;
}

.contactContent .contactTitle {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0;
}

.contactBrief {
    font-size: 14px;
    opacity: 0.8;
}

.contactForm .formField::placeholder{
    color: var(--white);
}

.contactContent ul li .iconContact {
    position: absolute;
    left: 0;
    top: 8px;
}

.contactForm .confirmSubscribe {
    cursor: pointer;
    background-image: var(--send);
    background-repeat: no-repeat;
    background-position: 13px;
    background-size: 13px;
    background-color: var(--white);
    border-radius: 12px;
    padding: 10px 10px 10px 35px;
    border-color: transparent;
    color: var(--primary);
    font-size: 14px;
    font-weight: 600;
    line-height: 18.2px; 
}

textarea#message {
    resize: none;
}

.contactForm {
    width: 100%;
    border-top: 1px solid var(--secondary);
    padding-top: 20px;
}

.contactForm input:internal-autofill-selected {
    background-color: tranparent !important;
}

/* .contactFormContent .formGroup {
    display: flex;
    width: 100%;
    justify-content: space-between;
} */

.contactContent h4 {
    font-weight: 600;
    margin-bottom: 15px;
}

.contactContent ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin-top: 18px;
    margin-bottom: 10px;
    padding: 0;
}
.contactContent p {
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    color: var(--white);
    margin: 0;
}
.contactContent ul li {
    margin-bottom: 15px;
    position: relative;
    width: 50%;
    padding-left: 35px;
    margin-bottom: 14px;
    background-position: left;
    background-repeat: no-repeat;
    height: 100%;
}
.contactSection .heroContent .contactContent  p {
    margin-bottom: 0;
    font-size: 14px;
    color: var(--white);
    letter-spacing: -0.28px;
}

.contactForm .formField {
    border-radius: 6px;
    background-color: var(--primary);
    border: 1px solid var(--secondary);
    box-shadow: 0px 1px 2px 0px rgba(228, 229, 231, 0.24);
    padding: 10px 10px 10px 12px;
    color: var(--secondary);
    outline: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.28px;
    width: 100%;
}

.contactForm .formField::placeholder{
	color: var(--white);
}
.contactForm .formControl {
    margin-bottom: 15px;
}

.contactForm .formControl.labelBtnWrap {
    margin-top: 15px;
    margin-bottom: 0;
}

.contactContent .social-icons-cover {
    margin-bottom: 10px;
    margin-top: 10px;
}
.contactForm .formField:not(.firstName) {
    width: 100%;
    padding-left: 35px;
    background-repeat: no-repeat;
    background-position: 8px;
}

.contactForm .emailAddress.formField {
    background-image: url('../../../images/form-mail.svg');
}
.contactForm .phoneNumber.formField {
    background-image: url('../../../images/form-phone.svg');
}
ul.social-icons-cover li {
    padding-left: 0;
    width: auto;
}
.contactForm .formField.textarea {
    padding-left: 15px;
    resize: vertical;
    min-height: 150px;
}
.contactContent ul{
    padding: 0 !important;
}
.contactContent ul li,.contactContent ul{
    list-style-type: none !important;
}

@media (min-width: 1201px) {
    .hero-content {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        margin-left: 120px;
        height: 100vh;
        padding: 80px 95px 80px 70px;
    }
}

@media(min-width:768px){

    .contactFormContent .formGroup .formControl:nth-child(1) {
        width: 100%;
        /* width: 28%; */
        /* padding-right: 14px; */
    }
    .contactFormContent .formGroup .formControl:nth-child(2) {
        width: 100%;
        /* width: 34%; */
        /* padding-right: 14px; */
    }
    .contactFormContent .formGroup .formControl:nth-child(3) {
        width: 100%;
        /* width: 38%; */
    }

}

@media(max-width:1400px){
    .contactSection .page-content {
        padding-right: 0;
        margin-right: 0;
    }
}
@media(max-width:1200px){

    .contactSection .heroContent {
        padding: 20px;
    }

}

@media(max-width:767px){

    .contactFormContent .formGroup {
        flex-direction: column;
    }
   

}
@media(max-width:576px){

    .contactContent ul li{
        width:100%;
    }

}