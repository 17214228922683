* {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 400;
  box-sizing: border-box;
}
body {
  background: white;
}
body,
html {
  overflow-x: hidden;
  position: relative;
}
p {
  margin-bottom: 0;
  margin-top: 0;
}
li {
  list-style-type: none;
}
ul {
  margin-block-end: 0;
  margin-block-start: 0;
  list-style-type: none;
}
a {
  text-decoration: none;
}
.container {
  max-width: 1220px;
  width: 100%;
  margin: 0 auto;
}

.primary-button { 
  box-shadow: 0 1px 3px #0000001a;
  background-color: #000;
  color: #fff;
  border-radius: 6px;
  border: 1px solid #000;
}
.secondary-button {
  background-color: #fff;
  color: #344054;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  box-shadow: 0 1px 3px #0000001a;
}

/* .primary-button img, 
.secondary-button img,
.primary-button svg, 
.secondary-button svg {
    margin-right: 5px;
} */

.button:hover {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  transform: translateY(-1px);
}

.btn_loading {
  pointer-events: none;
}
.settingPage-loader {
  position: relative;
  min-width: 32.95px;
  bottom: 15px;
}
.ring-loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  margin: 7px auto;
  top: 0;
  left: 0;
  right: 0;
  animation: rotate 1s linear infinite;
}
.ring-loader:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 3px solid white;
  animation: prixClipFix 2s linear infinite;
  top: 0;
}
.main-content .content-wrapper {
  min-height: calc(100vh - 67px);
  padding: 30px 36px;
  display: block;
  margin-bottom: 67px;
}
.pageSearchInput {
  width: 400px;
  position: relative;
}
.pageSearchInput input {
  padding-left: 40px;
}
.uploadMediaPopup .pageSearchInput input {
  border-color: #eaecf0;
  background-color: #fff;
}
.pageSearchInput input:not([type="checkbox"]) {
  height: 42px !important;
}
.pageSearchInput input {
  width: calc(100% - 5px);
  height: 42px !important;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  padding: 5px 5px 6px 40px;
  outline: none;
  right: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #101828;
}
.pageSearchInput input.inputFields::placeholder,
.inputFields::placeholder,
.formControltems .inputFields-select.role-dropdown p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #667085;
}
.no-country-selected button span,
.roleTypeSelect .no-selected {
  color: #667085;
}
.pageSearchInput input::-ms-input-placeholder{
  color: #425466;
}
.commonPage{
  padding-left: 36px;
}
.pageSearchInput img {
  left: 15px;
  max-width: 14px;
  position: absolute;
  top: 13px;
  z-index: 1;
}
.pagesSearch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
}
.uploadMediaPopup .pagesSearch {
  margin-bottom: 24px;
  padding-top: 0;
}
.pageSearchTop {
  display: flex;
  align-items: center;
  gap: 15px;
  width: calc(100% - 30px);
  flex-wrap: wrap;
}
.pageTeam .pageSearchTop {
  width: calc(100% - 230px);
}
.pageTeam .actions-buttons {
  width: 230px;
}
.pageSearchInput.mobile-team-searchbar {
  display: none;
}
.select-dropdown {
  width: 170px;
  border: 1px solid #eaecf0;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  overflow: hidden;
}
.select-dropdown div {
  border: none;
  box-shadow: none;
  border-radius: 0;
}
.heading {
  font-size: 16px;
  font-weight: 500;
  color: #0a2540;
  line-height: 24px;
  margin-bottom: 15px;
}
.form-btn {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  padding: 8px 13px;
  display: inline-block;
  width: auto;
  cursor: pointer;
}
.add-new-member .form-btn.new-member-btn {
  min-width: 165px;
}
.actions-buttons .form-btn {
  display: flex;
  align-items: center;
  gap: 5px;
}
.save-change {
  margin-top: 28px;
  min-height: 42px;
  min-width: 118px;
}
.profilePictureItems {
  margin-top: 0;
  margin-bottom: 15px;
}
.formControlFields .selectField div {
  min-height: auto;
  height: auto;
  color: #425466;
}
.add-new-member,
.add-new-file {
  margin-left: 10px;
}
.add-new-member span{
  font-weight: 500;
}
.pageMedia .addDropdown li p {
  text-transform: uppercase;
}
.actions-buttons {
  display: flex;
  align-items: center;
  width: 25%;
  justify-content: flex-end;
  position: relative;
}
.settingContent .pagesSearch .pageSearchInput,
.TeamDetails .pagesSearch .pageSearchInput {
  width: calc(100% - 50px);
}
.settingContent .pagesSearch .actions-buttons,
.TeamDetails .pagesSearch .actions-buttons {
  width: 40px;
  margin-left: 10px;
}
.select-country ul {
  border: 1px solid #eaecf0;
  font-size: 14px !important;
  color: #425466 !important;
  padding: 0;
  border-radius: 6px;
  max-height: 185px;
  overflow-y: auto;
}
.select-country button span {
  padding: 0;
}
.select-country button span > span:first-child {
  margin-right: 10px;
}
.select-country ul div {
  margin-bottom: 10px;
}
.select-country ul li > span {
  color: #101828;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.uploadMediaPopup .pagesSearchIcon {
  position: relative;
  z-index: 1;
}
.pagesSearchIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.select-country button:after {
  content: "";
  background-image: url("images/icondropdown.svg");
  background-repeat: no-repeat;
  background-position: 0 5px;
  border: none !important;
  width: 15px !important;
  height: 15px !important;
  background-size: auto 7px;
  margin-left: 5px;
}
.select-country > div {
  margin-bottom: 0;
  padding-bottom: 0;
}
.select-country button[aria-expanded="true"]:after {
  transform: rotate(180deg);
  margin-top: 3px;
  right: 3px;
  position: relative;
}
.select-country ul input[type="text"] {
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  font-size: 14px;
  width: calc(100% - 20px);
  padding-left: 30px; 
  background-image: url('../src/images/search.svg'); 
  background-position: 10px center; 
  background-repeat: no-repeat;
  background-size: 13px 13px;
  height: 40px;
}
.select-country ul li svg,
.select-country button span svg {
    border-radius:  2px;
    width: 1.5em;
}
.userrole-dropdown {
  position: relative;
}
.publication-list .userrole-dropdown{
  padding: 0;
  padding-left: 0 !important;
}
.addDropdown {
  position: absolute;
  width: 100%;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  background-color: #fff;
  z-index: 999;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  /* margin-top: 8px; */
}
.uploadMediaPopup .mediadate-dropdown .selectFields {
  min-width: unset;
  color: #677185;
}
.uploadMediaPopup .mediadate-dropdown .addDropdown.dateDropdown {
  margin-top: 0;
}
.uploadMediaPopup .pagesSearch .mediadate-dropdown .dateDropdown ul {
  max-height: 195px;
  overflow-y: auto;
}
.uploadMediaPopup .pagesSearch .mediadate-dropdown .dateDropdown {
  min-width: 170px;
}
.addDropdown ul{
  margin: 0;
  padding: 0;
}
.node-file .dropdown-wrapp .addDropdown ul,
.node-image .dropdown-wrapp .addDropdown ul,
.node-video .dropdown-wrapp .addDropdown ul,
.node-gallery .dropdown-wrapp .addDropdown ul,
.node-logoSlider .dropdown-wrapp .addDropdown ul,
.node-product .dropdown-wrapp .addDropdown ul {
  margin: 0;
  padding: 0;
  max-height: 195px;
  overflow-y: auto;
}
.node-file .dropdown-wrapp .dropdown.addDropdown ul li,
.node-image .dropdown-wrapp .dropdown.addDropdown ul li,
.node-video .dropdown-wrapp .dropdown.addDropdown ul li,
.node-gallery .dropdown-wrapp .dropdown.addDropdown ul li,
.node-logoSlider .dropdown-wrapp .dropdown.addDropdown ul li,
.node-product .dropdown-wrapp .dropdown.addDropdown ul li {
  margin-top: 0;
  margin-bottom: 0;
}
.addDropdown li input[type="checkbox"] {
  background-color: inherit;
}
.addDropdown ul li {
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  padding: 10px 16px 10px 10px;
  color: #111827;
  align-items: center;
}
.addDropdown li p,
.dropdown-wrapp ul li span {
  width: max-content;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  color: #101828;
}
.addDropdown li:hover,
.select-country ul li:hover,
.selectListOption .selectOption:hover {
  background: #f9f9f9;
  color: #101828;
}
.profile-buttons .addDropdown.page-dropdown {
  width: auto;
  right: auto;
}
.page-dropdown ul li {
  padding: 10px 20px;
}
.page-dropdown ul li p {
  font-weight: 500;
  color: #111827;
}

.select-country ul li {
  padding: 8px 10px;
}
.popupTopClose {
  float: right;
  position: relative;
  top: 0;
  right: 0;
  cursor: pointer;
}
.popupTopClose img {
  width: 15px;
  margin-right: 0;
}
.addTeamMember .formControltems .select-country button,
.formControltems .roleTypeSelect {
  height: 45px;
}
.addTeamMember .profilePictureItems {
  margin-bottom: 0;
}
.roleTypeSelect .selectMain p {
  position: relative;
  top: -1px;
}
.role-field {
  position: relative;
}
.role-dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
}
.role-dropdown img {
  width: 12px;
}
.add-new-member > button,
.add-new-file button {
  min-width: 170px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.pagesNew .add-new-member > button,
.add-new-file button {
  min-width: unset;
}
.NoResults {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 12px;
  min-height: 410px;
  padding-bottom: 0;
  padding-top: 0;
  text-align: center;
  border-top: 1px solid #eaecf0;
}
.noResultsBody {
  max-width: 350px;
}
.noResultsBody > img {
  width: 170px;
}
.noResTitle {
  color: #101828;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 8px;
  margin-top: 20px;
  text-align: center;
}
.noResDesc {
  color: #475467;
  font-weight: 400;
  margin-bottom: 0;
  text-align: center;
}
.noResButton,
.noResDesc {
  font-size: 14px;
  line-height: 20px;
}
.fieldsLabel {
  color: #344054;
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 5px;
}
.fieldsDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}
.generalBlockItems {
  margin-bottom: 15px;
}
input[type="checkbox"],
.addDropdown .userolesSelect,
.settingsMenuItem input {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  background: #fff;
  border: none !important;
  content: none;
  cursor: pointer;
  outline: none;
  cursor: pointer;
}
.addDropdown .userolesSelect {
  margin: 0 0 0 4px;
}
input[type="checkbox"]:before {
  content: "";
  font-size: 15px;
  border: 1px solid #d0d5dd;
  display: block;
  width: 15px;
  background: none;
  height: 15px;
  margin-right: 7px;
  border-radius: 4px;
  min-width: 16px;
  min-height: 16px;
  margin-right: 15px;
  background-size: 60%;
  background-position: 4px center;
  background-repeat: no-repeat;
  accent-color: #e6f2ff;
  margin-right: 6px;
}
input[type="checkbox"]:checked:before {
  background-image: url(images/checkbox-black.svg);
  border: 1px solid #344054;
}
.popupFields {
  margin-top: 15px;
}
.inputFields {
  padding: 10px 15px;
}
.category-field.inputFields{
  height: 46px;
}
.inputFields,
.inputFields-select,
.select-country button,
.userrole-dropdown button,
.userrole-select,
.select-dropdown,
.newpageCard .linkDataTitle,
.newpageCard .linkDataDiscripation,
.profileUrlFields .inputFieldIcon .urlFields {
  width: 100%;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  height: 46px;
  outline: none;
  padding: 10px 14px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #101828;
}
.newpageCard .linkDataTitle {
  color: #101828;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  margin-bottom: 15px;
  background-color: #fff;
  width: 100%;
  display: block;
  padding: 10px 15px;
  border-radius: 6px;
  border: 1px solid #eaecf0;
  outline: none;
  resize: none;
  height: auto;
  min-height: 42px;
}
.newpageCard .linkDataDiscripation{
  line-height: 20px;
}
.inputFields{
  height: 45px;
}
.category-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.category-field input {
  border: none;
  width: 100%;
  outline: none;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  padding-left: 0;
}
.category-field input::placeholder {
  color: #101828;
  font-size: 14px;
  line-height: 24px;
}
.category-field img {
  width: 12px;
}
.profileUrlFields .inputFieldIcon .urlFields{
  line-height: 23px;
}
/* .category-dropdown .subscriberPlan{
  height: 40px;
} */
.dropdown-wrapp button,
.userrole-dropdown button {
  background-color: transparent;
  min-width: 170px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 14px;
  height: 42px;
}
.newsletter-dropdown button {
  min-width: 170px;
}
.newsletter-dropdown .addDropdown {
  width: 170px;
}
.select-country {
  position: relative;
}
.select-country button {
  font-size: 14px !important;
  min-width: 170px;
  padding: 5px 14px;
}
.pageTeam .select-country .countryDropdown ul {
  border: none;
}
.inputFields-select svg path {
  fill: #667085;
}
.select-country ul input[type="text"] {
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  font-size: 14px;
}
.formControltems {
  margin-bottom: 10px;
}
.adressInputBlock .inputFields {
  padding-left: 40px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.formControlInputs {
  display: flex;
  margin: 0 -10px;
}
.formControlFields {
  width: calc(50% - 20px);
  margin: 0 10px;
}
.formControlInputs.formControlFull {
  display: block;
  margin: 0;
}
.formControlFull .formControlFields {
  width: 100%;
  margin: 0;
}
.generalContent .profilePictureItems {
  margin-bottom: 0;
}
.inputFieldsIcon {
  position: relative;
}
.inputFieldsIcon img {
  position: absolute;
  left: 15px;
  top: 16.5px;
  max-width: 14px;
}
.settingContent .inputFieldsIcon img,
.addTeamMember .inputFieldsIcon img{
  top: 15px;
  max-width: 16px;
}
.formControltems .user-email img {
  top: 16px;
}
.UserDetails .inputFieldsIcon img {
  top: 15px;
  max-width: unset;
}
.TeamDetails .inputFieldsIcon img {
  top: 16px;
}
.navigationBlocks .pageSearchInput img {
  top: 13.5px;
  max-width: 14px;
}
.inputFieldsIcon .inputFields {
  padding-left: 40px;
}
.profilePictureItems .avatarBlock {
  align-items: center;
  display: flex;
}
.profilePictureItems .registerProfile-block {
  align-items: center;
  background: #f2f4f7;
  border: 6px solid #f9fafb;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 55px;
  justify-content: center;
  padding: 7px 7px 10px;
  text-align: center;
  width: 55px;
}
.profilePictureItems input:not([type="checkbox"]) {
  background: #fff;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  color: #101828;
  font-size: 14px;
  height: 45px;
  line-height: 24px;
  outline: none;
  padding: 10px 14px;
  width: 100%;
}
.profilePictureItems .fileUploadProfile-box {
  align-items: center;
  background: #fff;
  display: flex;
  margin-left: 20px;
  min-height: 85px;
  padding: 16px 24px;
  z-index: 0;
  padding-left: 0;
}
.profilePictureItems .fileUploadCover_Box,
.profilePictureItems .fileUploadProfile-box {
  border: 1px solid #eaecf0 !important;
  border-radius: 12px;
  cursor: pointer;
  position: relative;
}
.profilePictureItems .newPageImageUploadText,
.profilePictureItems .uploadFileBlock .newPageImageUploadText {
  color: #475467;
  font-size: 14px;
  line-height: 24px;
  padding-top: 10px;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
}
.featureImgMiddleContent .newPageImageUploadText {
  text-align: center;
}
.newpageLeftRow .featureImgMiddleContent .newPageImageUploadText {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #475467;
}
.newpageLeftRow .featureImgMiddleContent .newPageImageUploadText b {
  font-weight: bold;
}
.newpageLeftRow p.error-message {
  font-size: 14px;
  color: #d92d20;
  text-align: center;
  font-weight: 400;
  margin-bottom: 0;
}
.media-grid-layout .list-search input[type="checkbox"] {
  font-size: 0;
  width: 18px;
  height: 18px;
  border: 1px solid transparent;
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  position: relative;
  padding: 0 0 0 0;
  margin: 0;
  margin-right: 9px !important;
  right: 0;
  top: 0;
}
.profilePictureItems .avatarBlock .newPageImageUploadText {
  padding-top: 0;
}
.profilePictureItems .newPageImageUploadText span {
  font-size: 14px;
  font-weight: 600;
}
.requirements-box {
  align-items: flex-start;
  border: 1px solid #eaecf0;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 15px 18px;
}
.requirements-box > p {
  margin-bottom: 10px;
}
.requirements-box ul {
  margin: 0;
  padding: 0;
}
.requirements-box .requirement-list ul {
  padding-top: 8px;
}
.requirement-list li {
  color: #000;
  padding-bottom: 8px;
  padding-left: 24px;
  position: relative;
}
.requirement-list li,
.requirements-box > p {
  font-size: 14px;
  font-weight: 700;
}
.requirement-list li:not(.fulfilled):before {
  position: absolute;
  content: "•";
  width: 10px;
  height: 10px;
  border-radius: 0;
  left: 8px;
  top: -2px;
}
.requirement-list li.fulfilled:before {
  content: "✓";
  position: absolute;
  left: 5px;
  width: 10px;
  height: 10px;
}
.requirement-list li:last-child {
  padding-bottom: 0;
}
.requirement-list li.fulfilled {
  color: #0a8852;
}
.settingPassword .requirements-box {
  margin-bottom: 10px;
}
.error-message {
  font-size: 14px;
  color: #d92d20;
  margin-top: 5px;
}
.UserDetails .generalContent .error-message {
  margin-top: 5px;
}
.newpage_ProfileImg {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
}
.profileImg-loader,
.registerProfileImg-loader {
  padding: 0px 0px 0px 5px;
}
.registerProfileImg-loader {
  position: relative;
  height: calc(100% - 4px);
}
.registerProfileImg-loader .progress {
  position: absolute;
  top: 50%;
  width: 35px;
  left: calc(50% - 20px);
}
.avatarBlock .registerProfileImg-loader .progress {
  transform: translateY(-50%);
}
.avatarUpload .avatarUpload-img {
  height: auto;
  width: auto;
}
.registerProfile-block {
  height: 55px;
  width: 55px;
  cursor: pointer;
  border: 6px solid #f9fafb;
  border-radius: 50%;
  text-align: center;
  padding: 6px 8px 11px 10px;
  background: #f2f4f7;
  display: flex;
  align-items: center;
  justify-content: center;
}
.registerProfile-block:has(.newpage_ProfileImg) {
  padding: 0;
  border: 0;
  background: transparent;
  margin-top: 7px;
}
.avatarBlock .registerProfile-block {
  border: 0;
  padding: 0;
  height: 64px;
  width: 64px;
}
.overlayModal,
.profileOverlayModal {
  background-color: rgba(0,0,0,.5);
  inset: 0;
  position: fixed;
  z-index: 99999;
}
.featuredImageModal {
  background-color: #fff;
  border: 1px solid #eaecf0;
  border-radius: 6px;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
      0 4px 6px -2px rgba(16, 24, 40, 0.03);
  left: 50%;
  max-width: 426px;
  opacity: 1;
  padding: 20px;
  position: fixed;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  visibility: visible;
  width: 90%;
  z-index: 999999;
}
.profile-img-model .featuredImageModal {
  max-width: 426px;
  min-height: 215px;
  padding: 24px;
}
.featuredImageModal .newPageRightRow {
  margin-top: 0;
}
.featuredImageModal .featuredImageModalbtn {
  display: flex;
  margin-top: 20px;
}
.generalImageAdmin {
  width: 384px;
}
.newpageImageUpload {
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 384px;
  min-height: 215px;
  position: relative;
  z-index: 0;
}
.newteamprofileImage{
  display: flex; 
}
.newteamprofileImage .fileUploadProfile-box{
  margin-left: 20px; 
  padding-top: 0 ;
  padding-bottom: 0 ;
  border: 0 !important;
}
.newteamprofileImage  .newPageImageUploadText{
  text-align: left !important;
}
.generalBlockItems .newpageImageUpload,
.newpageImageUpload {
  background: #fff;
  border: 1px solid #eaecf0;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  cursor: pointer;
}
.media-gallery .newpageImageUpload {
  margin: 30px auto 25px;
  max-width: 680px;
  border-color: #eaecf0;
  padding: 20px;
}
.media-gallery .featureImgMiddleContent img {
  align-items: center;
  background: #f2f4f7;
  border: 6px solid #f9fafb;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 44px;
  justify-content: center;
  padding: 4px;
  text-align: center;
  width: 44px;
  object-fit: cover;
}
.profile-img-model .newpageImageUpload {
  border-radius: 50%;
  height: 125px;
  margin: 0 auto;
  min-height: 0;
  min-height: auto;
  width: 125px;
}
#newpageImageUpload:has(canvas) {
  border: none;
  box-shadow: none;
}
.newPageRightRow.imageUploadBox:has(.newpage_CoverImg) .newpageImageUpload {
  display: unset;
}
.featuredImageModal .crop-container {
  border: 1px solid #eaecf0;
  border-radius: 6px;
  line-height: 0;
  min-height: 100%;
  padding-top: 50%;
  position: relative;
}
.featurboxModal .crop-container {
  border: 0;
  padding-top: 0;
}
.profile-img-model .featuredImageModal .modal-content .crop-container {
  height: 125px;
  margin: 0 auto;
  min-height: 125px;
  width: 125px;
}
.featurboxModal .imageEditor canvas,
.imageEditor.imageUploaded {
  border: none;
}
.profile-img-model .featuredImageModal .modal-content .crop-container .imageEditor {
  border-radius: 50%;
  height: 125px !important;
  overflow: hidden;
  width: 125px !important;
}
.imageEditor canvas {
  border-radius: 6px !important;
  height: 100% !important;
  width: 100% !important;
}
.imageEditor canvas {
  background: transparent;
  border-radius: 6px;
  cursor: pointer;
  min-height: 100px;
  object-fit: cover;
  position: relative;
  z-index: 999;
}
.featurboxModal .imageEditor canvas,
.imageEditor.imageUploaded {
  border: none;
}
.profile-img-model .imageEditor canvas {
  min-height: 0 !important;
}
.featuredImageModal .replaceImageRange {
  align-items: center;
  display: flex;
  gap: 15px;
  margin-top: 18px;
  padding: 0 10px;
}
.replaceImgBtn {
  min-width: 121px;
}
.featuredImageModal .uploadImage {
  align-items: center;
  color: #344054;
  display: flex;
  font-weight: 500;
  padding: 10px;
}
.featuredImageModal .cancel,
.featuredImageModal .uploadImage {
  background-color: initial;
  border: 1px solid #ddd;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
}
.featuredImageModal .replaceImgBtn span {
  color: #344054;
  font-weight: 500;
  line-height: 1;
  margin-left: 0;
}
.featuredImageModal .imgRange {
  align-items: center;
  display: flex;
  height: 38px;
  padding-left: 0;
  width: 100%;
}
.featuredImageModal .coverImgLeft {
  height: 16px;
  margin-right: 5px;
}
.featuredImageModal .coverImgRight {
  height: 20px;
  margin-left: 5px;
}
.featuredImageModal .zoom-range {
  width: 100%;
}
.featuredImageModal input.zoom-range {
  -webkit-appearance: none;
  appearance: none;
  background-color: #b7bcc2;
  border-radius: 6px;
  height: 3px;
}
.featuredImageModal .imgRange {
  align-items: center;
  display: flex;
  height: 38px;
  padding-left: 0;
  width: 100%;
}
.featuredImageModal .savePicture {
  /* background-color: #1c1c1c; */
  /* border: #1c1c1c; */
  /* border-radius: 6px; */
  /* color: #fff; */
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  height: 45px;
  margin-left: 15px;
  padding: 4px 8px;
  width: 50%;
}
.featuredImageModal .cancel {
  /* color: #373737; */
  font-weight: 600;
  padding: 8px;
  width: 50%;
}
.profile-img-model .featuredImageModal .cancel {
  color: #344054;
}
.profile-img-model .featuredImageModal .cancel,
.profile-img-model .featuredImageModal .savePicture {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}
.featuredImageModal input.zoom-range::-webkit-slider-thumb {
  height: 15px;
  width: 15px;
  background-color: #344054;
  border-radius: 50%;
  appearance: none;
  z-index: 99;
  cursor: grab;
}
.featuredImageModal input.zoom-range.slider-cursor::-webkit-slider-thumb {
  cursor: grabbing;
}
.featuredImageModal input.zoom-range::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #344054;
  border-radius: 50%;
  appearance: none;
  z-index: 99;
}
.featuredImageModal input.zoom-range::-ms-thumb {
  height: 15px;
  width: 15px;
  background-color: #344054;
  border-radius: 50%;
  appearance: none;
  z-index: 99;
}
.featureImg-loader {
  padding: 72px 0 0 60px;
}
.newpageImageUpload .featureImg-loader,
.uploadFileBlock .featureImg-loader {
  padding-top: 0;
}
.progress-wrap {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
}
.progress {
  border: 1px solid #f6f6fa;
  border-radius: 15px;
  height: 2px;
  left: 6px;
  position: relative;
  top: 28px;
  width: 70%;
}
.newpageImageUpload .progress,
.uploadFileBlock .progress {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.progress .color {
  -webkit-animation: progress 4s linear infinite;
  animation: progress 4s linear infinite;
  background-color: #007aff;
  border-radius: 15px;
  height: 6px;
  position: absolute;
  width: 0;
}
.timezome-selection {
  background: #fff;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  color: #101828;
  font-size: 14px;
  height: 46px;
  line-height: 24px;
  outline: none;
  padding-left: 20px;
  width: 100%;
}
.stepCheckbox_block {
  align-items: center;
  display: flex;
  margin-bottom: 25px;
  margin-right: 0;
  margin-top: 15px;
}
.stepCheckbox_block .stepCheckbox {
  margin: 0;
}
.generalRow .stepCheckbox {
  width: 30px;
}
.generalRow .generalform-row {
  display: flex;
  width: 100%;
  gap: 20px;
}
.generalRow .generalform-row .formControlFull {
  width: 50% ;
}
.addTeamMember .select-country, 
.generalContent .select-country {
  padding-bottom: 0;
}
.link-btn {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  background: #fff;
  border: 1px solid #eaecf0;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  color: #425466;
  cursor: pointer;
  display: flex;
  padding: 5px 10px;
}
.table-status.Deleted .status-btn {
  color: #ff0000;
  border-color: #ff0000;
  background-color: rgb(255 0 0 / 12%);
}
.dataTable-list .rdt_TableBody .rdt_TableRow {
  min-height: 62px;
}
.dataTable-list .rdt_TableHeadRow .rdt_TableCol:first-child,
.dataTable-list .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child {
  padding-left: 16px;
}
.dataTable-list .rdt_TableHeadRow .rdt_TableCol:last-child,
.dataTable-list .rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child {
  justify-content: flex-end;
  padding-right: 16px;
}
.pagesTable {
  background: #fff;
  border: 1px solid #eaecf0;
  width: 100%;
  margin-top: 20px;
  border-radius: 6px;
  position: relative;
}
.statusTab span {
  padding: 5px 10px;
  mix-blend-mode: multiply;
  border-radius: 6px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
}
.greenStatus {
  background-color: transparent;
  color: #067647;
  border: 1px solid #079455;
}
.deletedStatus {
  background: transparent;
  color: #D92D20;
  border: 1px solid #b42318;
}
.pagesTable .draftStatus {
  background: transparent;
  color: #344054;
  border: 1px solid #475467;
}
.pagesLinkButton {
  padding: 5px 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #344054;
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #d0d5dd;
  box-shadow: 0 1px 3px #0000001a;
  border-radius: 6px;
  cursor: pointer;
}
.pagesLinkButton img, .pagesLinkButton svg {
  margin-right: 5px;
  margin-top: 2px;
  width: 14px;
}
.pagesLinkButton:hover {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(-1px);
}
.publicationsCategory > div {
  display: flex;
  align-items: center;
}
.publicationsCategory span {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  color: #344054;
  line-height: 20px;
  padding: 5px 10px;
  text-align: center;
  border-radius: 6px;
  margin-right: 5px;
  background: #fff;
  border: 1px solid #d0d5dd;
  box-shadow: 0 1px 3px #0000001a;
}
.publicationsCategory .categoryList span {
  border: 0;
  box-shadow: none;
  padding: 5px;
}
.categoryTooltip {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  padding: 5px 10px;
  text-align: center;
  border-radius: 6px;
  margin-right: 4px;
  background: #fff;
  border: 1px solid #d0d5dd;
  box-shadow: 0 1px 3px #0000001a;
  color: #344054;
  display: inline-flex;
  position: absolute;
  left: 20px;
  bottom: 0;
  opacity: 0;
  transition: 0.3s;
}
.categoryList {
  position: relative;
  cursor: pointer;
}
.categoryTooltip p:last-child {
  padding-right: 0;
}
.categoryTooltip p {
  font-weight: 500;
  white-space: nowrap;
  padding-right: 4px;
}
.categoryTooltip p::after {
  content: ",";
  position: relative;
  right: 0px;
}
.categoryTooltip p:last-child::after {
  display: none;
}
.categoryList:hover .categoryTooltip {
  left: 30px;
  opacity: 1;
  z-index: 9;
}
.toSubject {
  text-align: center;
  margin-right: 4px;
  border-radius: 6px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  padding: 5px 10px;
}
.pageFreePlanLabel {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  padding: 5px 10px;
  background: transparent;
  color: #344054;
  border: 1px solid #475467;
}
.pagePaidPlanLabel {
  border: 1px solid #079455;
  color: #067647;
  padding: 5px 10px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}
.pageAllSubscriberLabel{
  border: 1px solid #d0d5dd;
  background-color: #fff;
  color: #344054;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}
.pagePaidPlanLabel.month {
  border: 1px solid #3E4784;
  color: #363F72;
  padding: 5px 10px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}
.pagesTableAction {
  cursor: pointer;
  line-height: 0;
}
.editTitle, .editTitle:hover,.support-title ,.support-title:hover,.media-list .file-name,.media-list .file-name:hover {
  color: #101828 !important;
  font-weight: 500;
  padding-right: 50px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.link-btn img {
  margin-right: 5px;
  width: 14px;
}
.categoriesPage .add-new-member > button {
  min-width: unset;
}
.twoItemWrap {
  flex-wrap: nowrap;
}
.twoItemWrap .actions-buttons {
  width: 40px;
  margin-left: 10px;
}
.twoItemWrap .pageSearchInput {
  width: calc(100% - 50px);
}
.country-flag svg {
  margin-right: 8px;
}
.country-flag {
  display: flex;
  align-items: center;
}
.main-content .content-wrapper:has(.commonPage) {
  padding: 0;
}

.newpageContent {
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  min-height: calc(100vh - 67px);
}
.newpageLeft {
  max-height: calc(100dvh - 100px);
  overflow-y: auto;
  padding-right: 20px;
  margin-right: 20px;
  width: calc(100% - 410px);
  padding-top: 30px;
}
.newpageContent .newpageLeft:has(.homepage-content) {
  max-height: calc(100vh - 100px);
}
.breadcrumbs {
  align-items: center;
  display: flex;
  font-size: 14px;
  gap: 12px;
  justify-content: flex-start;
  margin-bottom: 20px;
  position: relative;
  text-transform: capitalize;
}
.breadcrumbs .home-icon {
  max-width: 15px;
}
.breadcrumbs a {
  color: #425466;
}
.breadcrumbs a span {
  text-transform: capitalize;
  font-weight: 500;
}
.newpageImageUpload .cover_image{
  width: 384px;
}
/* custom scroll */
.categories-list::-webkit-scrollbar-track,
.newpageRight::-webkit-scrollbar-track,
.newpageLeft::-webkit-scrollbar-track,
.select-country ul::-webkit-scrollbar-track,
.publicationListDropdown ul::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: rgb(115 115 115 / 0.2);
}
.newpageLeft::-webkit-scrollbar,
.newpageRight::-webkit-scrollbar,
.categories-list::-webkit-scrollbar,
.select-country ul::-webkit-scrollbar,
.publicationListDropdown ul::-webkit-scrollbar {
  height: 5px;
  background-color: #fff;
  width: 6px;
}
.categories-list::-webkit-scrollbar-thumb,
.newpageRight::-webkit-scrollbar-thumb,
.newpageLeft::-webkit-scrollbar-thumb,
.select-country ul::-webkit-scrollbar-thumb,
.publicationListDropdown ul::-webkit-scrollbar-thumb {
  border-radius: 6px;
  padding: 5px;
  background: #ababab;
}
.newpageRight {
  border-left: 1px solid #e6e6e6;
  margin-top: 0;
  max-height: calc(100vh - 67px);
  padding: 0;
  overflow-y: auto;
  position: relative;
  width: 410px;
}
.newpageRight > .buttonsDesktop,
.newpageRight > .newPageRightRow {
  border-bottom: 1px solid #e6e6e6;
  padding: 20px 30px;
}
.newpageRightSticky .buttonsDesktop {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
}
.newpageRightButtons {
  display: inline-flex;
  position: relative;
  height: 44px;
}
.newpageRightButtons button {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  display: flex;
  gap: 8px;
  /* border-radius: 6px; */
  align-items: center;
  padding: 10px;
  /* border: none; */
  /* color: #fff; */
  cursor: pointer;
}
.newpageRightButtons button .ring-loader {
  display: block;
  margin: 12px auto;
  right: 8px;
}
.new-page-loader .newpageRightButtons button .ring-loader,.new-newsletter-loader .newpageRightButtons button .ring-loader{
  right: 0 !important;
}
.new-publication-loader .newpageRightButtons button .ring-loader{
  margin: 7px auto !important;
  right: 0 !important;
}
.new-publication-loader .updatebutton-loader{
      position: relative;
    min-width: 49.44px;
    bottom: 16px
}

.newpageRightButtons .updateDraftbutton-loader {
  position: relative;
  min-width: 87.1px;
  bottom: 20px;
}
.draftButton-loader {
  position: relative;
  min-width: 80px;
  bottom: 16px;
  pointer-events: none;
}
.new-page .newpageRightButtons .draft-button .draftButton-loader,
.new-newsletter .newpageRightButtons .draft-button .draftButton-loader {
  bottom: 20px;
}
.cancel:before {
  border: 3px solid #344054;
}
.newpageRightButtons .previewButtonLoader {
  position: relative;
  min-width: 99.47px;
  bottom: 0;
  pointer-events: none;
  border: 1px solid #eaecf0 !important;
  border-radius: 6px;
  /* margin-left: 8px; */
}
.newpageRightButtons .previewButtonLoader .cancel:before {
  border: 3px solid #344054;
}
.newpageRightButtons .previewButtonLoader .cancel {
  top: 6px;
}
.newpageRightButtons.updateDraftButton button:first-child {
  min-width: 107px;
}
.newpageRightButtons button.saveAsDraftButton {
  position: relative;
  min-width: 104px;
}
.newpageRightButtons button.saveAsDraftButton .ring-loader {
  right: 0;
}
.newpageRightButtons button.saveAsDraftButton .ring-loader:before {
  border-color: #0a0a0a;
}
.newpageRightButtons button:first-child {
  /* background: #0a0a0a; */
  margin-right: 8px;
  /* min-width: 69px; */
  min-height: 42px;
  position: relative;
  justify-content: center;
  border-radius: 6px !important;
}
.new-publication .newpageRightButtons button:first-child {
  border-radius: 6px;
}
.newPublication .newpageRightButtons .publishbutton-loader .ring-loader {
  top: 5px;
}
.newpageRightButtons .publish-button {
  width: 97.3px;
}
.newpageRightButtons .draft-button {
  width: 104px;
}
.newpageRightButtons .update-button {
  width: 74.44px;
}
.newpageRightButtons .preview-button {
  width: 99.47px;
}
.newpageRightButtons .updatedraft-button {
  width: 110.97px;
}
.newpageRightButtons .send-button {
  width: 76.67px;
}
.newpageRightButtons .schedule-button {
  width: 102.5px;
}
.newpageRightButtons button:first-child p {
  font-weight: 600;
}
.newpageRightButtons button:nth-child(2) {
  margin-right: 8px;
}
/* .newpageRightButtons button:nth-child(2),
.newpageRightButtons button:nth-child(3) {
  border: 1px solid #eaecf0;
  color: #344054;
  background-color: #fff;
} */
.iconDropWrap {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.newPageRightFullUrl,
.newPageRightTitle {
  color: #344054;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.newPageRightFullUrl {
  font-weight: 600;
}
.iconDropWrap span {
  font-weight: 500;
  margin-right: 3px;
  width: calc(100% - 15px);
  display: block;
}
.expanded {
  max-height: 1000px;
  transition: max-height 0.8s ease-in-out 0s;
}
.collapse {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease-in-out 0s;
}
.contentDrop > div.schedule-checkbox {
  margin-top: 9px;
}
.dateTimeCheckbox {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-top: 12px;
}
.adressInputBlock .adressLink {
  top: 24px;
}
.schedule-date-selector,
.schedule-time-selector {
  align-items: center;
  background: #fff;
  border: 1px solid #eaecf0;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  color: #101828;
  cursor: pointer;
  display: flex;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  height: 45px;
  line-height: 24px;
  outline: none;
}
.schedule-date-selector {
  justify-content: space-between;
  padding: 8px 12px 8px 20px;
  width: calc(100% - 9.25ch);
}
.schedule-time-selector {
  gap: 0.25ch;
  justify-content: center;
  padding: 9px;
  position: relative;
  width: 9.25ch;
}
.schedule-time-selector > input {
  border: none;
  box-sizing: initial;
  color: #101828;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  outline: none;
  text-align: center;
  text-transform: uppercase;
  width: 2ch;
}
.schedule-time-selector > input.normal-width {
  width: 1.7ch;
}
.schedule-time-selector > input.schedule-hour {
  text-align: right;
}
.schedule-time-selector > input.schedule-minute {
  text-align: left;
}
.verticle-dots {
  left: 0;
  position: relative;
}
.pubTypeSelect {
  margin-top: 10px;
}
.adressInputBlock {
  position: relative;
}
.urlInput {
  display: block;
  width: 100%;
}
.adressLink,
.publication-Link .adressInputBlock .url-icon {
  left: 14px;
  position: absolute;
  top: 1.48rem;
  width: 17px;
  height: 17px;
}
.contactpage-content .adressLink{
  position: absolute;
    top: 1.5rem;
    left: 14px;
    width: 17px;
}
.newPageRightFullUrl span {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  word-break: break-all;
}
.newPageRightFullUrl .pageurl {
  margin-left: 5px;
}
.newPageRightFullUrl .link-external {
  margin-left: 4px;
  position: relative;
  top: 3px;
}
.categories-list {
  max-height: 165px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}
.list-search li {
  margin: 12px 0;
}
.list-search li:first-child {
  margin-top: 5px;
}
.list-search label {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
}
.list-search input[type="checkbox"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  border: 1px solid transparent;
  font-size: 0;
  height: 18px;
  margin: 0;
  padding: 0 30px 0 0;
  position: relative;
  width: 18px;
}
.list-search p,
.new-category {
  color: #344054;
  font-size: 14px;
  line-height: 20px;
}
.list-search p {
  text-transform: capitalize;
}
.list-search p,
.new-category {
  color: #344054;
  font-size: 14px;
  line-height: 20px;
}
.new-category {
  align-items: center;
  background-color: initial;
  border: none;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  padding: 0;
}
.new-category img {
  margin-left: 4px;
  margin-right: 10px;
}
.newpageRight > .buttonsDesktop,
.newPageRight.desktopFeatures {
  border-bottom: 1px solid #e6e6e6;
  padding: 20px 30px;
}
.newpageRight > .desktopFeatures {
  border-bottom: 1px solid #e6e6e6;
}
.newpageRight > .newPageRightRow:not(.publication-type, .dateFieldDrop) {
  overflow: hidden;
}
.newpageRight > .desktopFeatures:has(.expanded) {
  border-color: transparent;
}
.newPageRightRow.imageUploadBox {
  margin-top: 10px;
}
.dropdownIcon {
  transition: all 0.2s linear;
  width: 12px;
}
.newPageRight:has(.expanded) .dropdownIcon,
.newPageRightRow:has(.expanded) .dropdownIcon {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.buttonsMobile {
  display: none;
}
.textBlock {
  margin-top: 28px;
  position: relative;
  top: 0;
}
.publicationLeft .textBlock {
  margin-top: 12px;
}
#post-title {
  font-size: 36px;
  line-height: 48px;
}
#post-sub-title[placeholder]:empty:before,
#post-title[placeholder]:empty:before {
  color: #667085;
  content: attr(placeholder);
}
#post-sub-title,
#post-title {
  background: transparent;
  border: none;
  box-shadow: none;
  color: #0a0a0a;
  font-weight: 500;
  gap: 8px;
  outline: none;
  padding: 0;
  width: 100%;
}
#post-sub-title {
  font-size: 18px;
  line-height: 24px;
  margin-top: 20px;
}
.newpageLeftRow .charactersLeft {
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.publicationLeft .textBlock {
  margin-top: 12px;
}
.editor-block {
  max-width: 740px;
  margin: auto;
  padding: 0 20px;
  margin-top: 30px !important;
}
.newPublication.publication-Regular .publicationLeft,.new-newsletter .publicationLeft,.new-page .publicationLeft{
  margin-right: 36px;
  padding-right: 80px;
  padding-left: 85px;
}
.authorsBlocks {
  margin-top: 10px;
}
.authorInput {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}
.authorsBlocks .authorInput input {
  padding-left: 10px;
  margin-top: 0;
}
.autherTrashIcon {
  margin-bottom: 9px;
  cursor: pointer;
}
.newAuthorBlock:has(.validateError) .authorBlockInputs input,.newAuthorBlock:has(.validateError) .autherTrashIcon {
  margin-bottom: 0;
}
.inputReadOnly {
  background: #f8fafc !important;
  -webkit-user-select: none;
  user-select: none;
}
.addAuthor {
  align-items: center;
  color: #344054;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.addAuthor img {
  margin-right: 5px;
}
.publishStatus {
  align-items: center;
  color: #344054;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  margin-top: 15px;
}
.draftStatus,
.publishStatus .draftStatus {
  background: transparent;
  color: #667085;
}
.publishStatus span {
  background: transparent;
  color: #667085;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  margin-left: 5px;
  margin-top: 0;
  padding: 0;
  text-align: center;
}
.newpageRightButtons .newpageMoreButtons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* background: #f2f2f7;
  border-radius: 6px;
  color: #344054; */
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  gap: 8px;
  justify-content: center;
  line-height: 22px;
  opacity: 0.7;
  text-align: center;
  width: 40px;
}
.publishedButtonsList {
  background: #fff;
  border: 1px solid #eaecf0;
  border-radius: 6px;
  box-shadow: 0 12px 16px -4px rgb(16 24 40/8%),
      0 4px 6px -2px rgb(16 24 40/3%);
  left: 0;
  position: absolute;
  top: 50px;
  width: 200px;
  z-index: 9999;
}
.publishedButtonsList li {
  color: #111827;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 20px;
}
.publishedButtonsList li:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.publishedButtonsList li:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.publishedButtonsList ul {
  margin: 0;
  padding: 0;
}
.publicationListDropdown li:hover,
.publishedButtonsList li:hover {
  background: #f9f9f9;
}
.img-captions {
  background: #fff;
  border: 1px solid #eaecf0;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  font-size: 14px;
  height: 44px;
  margin-bottom: 10px;
  margin-top: 15px;
  outline: none;
  padding: 10px 14px;
  width: 100%;
}
.select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff;
  background-image: url("images/icondropdown.svg") !important;
  background-position-x: calc(100% - 13px) !important;
  background-position-y: 52% !important;
  background-repeat: no-repeat !important;
  background-size: 12px !important;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  /* box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05); */
  color: #101828;
  font-size: 14px;
  font-weight: 400;
  height: 46px;
  outline: none;
  padding: 10px 14px;
  position: relative;
  width: 100%;
}
.subscriberDrop .select.planTypeSelect {
  margin: 10px 0;
}
.selectMain {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
}
.selectListOption p,
.selectMain {
  color: #101828;
  font-weight: 400;
}
.selectMain p {
  color: #101828;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.overlayLock-publications-type {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  cursor: pointer;
  z-index: 5;
}
.planLayout {
  display: flex;
}
.selectOption.lock img {
  width: 16px;
  bottom: 0;
}
.pubTypeSelect .selectListOption .selectOption {
  box-shadow: none;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  color: #111827;
  padding-top: 10px;
  cursor: pointer;
  padding-bottom: 10px;
}
.pubTypeSelect .selectListOption,
.planTypeSelect .selectListOption {
  z-index: 1;
}
.new-publication .selectOption {
  display: flex;
  justify-content: space-between;
}
.pubTypeSelect .selectListOption p {
  margin-bottom: 0;
  margin-left: 0;
}
.pubTypeSelect .selectListOption {
  overflow: visible;
}
.newPageRightRow .calendar-container {
  position: relative;
  z-index: 1;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  background: #fff;
  border: 1px solid #eaecf0;
  top: 9px;
}
.adressInputBlock .urlInputLeft.inputFields {
  padding: 8px 12px;
  gap: 8px;
  height: 45px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  outline: none;
  margin-top: 0;
  padding-left: 40px;
  font-size: 14px;
  margin-bottom: 0;
}
.newpageLeftRow h2 {
  color: #0a2540;
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
  margin: 0;
}
.linkBox {
  width: 100%;
}
.linkBlockPreview {
  display: flex;
  box-shadow: none;
  align-items: center;
  border: 1px solid #8080805c;
  border-radius: 0;
  padding: 15px;
  margin-top: 10px;
}
.linkBlockPreview .textBlock {
  margin: 0 !important;
}
.linkBlockPreview .textBlock.linkBox {
  display: flex;
  align-items: center;
}
.linkBlockPreview .linkImageSection {
  display: flex;
  align-items: center;
  width: 90px !important;
  height: auto;
}
.publication-Link .linkBox .previewImage {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 0;
}
.linkBlockPreview .linkData {
  width: calc(100% - 90px);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  padding-left: 15px;
}
.linkBlockPreview .siteData {
  display: flex;
  align-items: center;
  width: 100%;
}
.linkBlockPreview .siteData img {
  margin-right: 6px;
  border-radius: 0;
  object-fit: unset;
  width: 18px;
  height: 18px;
}
.linkBlockPreview .siteData p {
  margin: 0;
  font-size: 14px;
  color: #475467;
}
.linkBlockPreview .linkDataTitle {
  color: #101828;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 0;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.linkBlockPreview .linkBox {
  border-radius: 0;
  box-shadow: none;
  border: 0;
  background-color: transparent;
}
.link-publications-data {
  margin-top: 20px;
  margin-bottom: 10px;
}
.newpageCard .linkDataDiscripation {
  padding: 10px 15px;
  border-radius: 6px;
  border: 1px solid #eaecf0;
  color: #475467;
  font-size: 14px;
  line-height: 20px;
  height: auto;
  min-height: 40px;
  width: 100%;
  outline: none;
  resize: none;
}
.url-lable {
  color: #0a2540;
}

.newpageLeftRow p {
  color: #344054;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 11px;
}
.textInfoBlock p {
  margin-bottom: 0;
}
.fileUploadBox .fileUploadedCover p {
  color: #475467;
  margin: 0;
}
.newpageLeftRow .label {
  color: #0a2540;
  margin-bottom: 5px;
  font-weight: 500;
}
.video-box-group .video-box-item .contentDrop:has(.validateError) .newpageLeftRow.textBlock {
  padding-bottom: 0;
}
.adressLinkLeftInput,
.publication-Link .adressInputBlock .url-icon,
.youtube-box-check .linkData .adressLink,
.vimeo-box-check .linkData .adressLink {
  top: 14px;
}
.newpagenotes {
  border-radius: 6px;
  border: 1px solid #eaecf0;
  background: #fbfbfb;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 16px;
  width: 100%;
}

.newpagenotes p,
.file-publication .newpagenotes p {
  margin-bottom: 0;
}
.newpagenotes strong {
  font-size: 14px;
  font-weight: 600;
}
.linkDataBox .linkDataTitle {
  resize: none;
  padding: 10px 15px;
  margin-bottom: 0;
  height: 40px;
}
.link-publications-data .linkData .linkDataTitle {
  margin-bottom: 15px;
}
.custom-skeleton-picture {
  width: 103px;
}
.custom-skeleton {
  width: 100%;
  display: flex;
  height: auto;
  background: #fff;
  border: 1px solid #eaecf0;
  box-shadow: 0 1px 3px #0000001a;
  border-radius: 6px;
  outline: none;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
}
.custom-skeleton-content {
  width: calc(100% - 103px);
  padding: 16px 16px 6px 20px;
}
.custom-skeleton-picture .custom-skeleton-item {
  height: 100%;
  border-radius: 0;
}
.custom-skeleton-content .custom-skeleton-item:last-child {
  width: 80%;
}
.custom-skeleton-item {
  height: 15px;
  background-color: #ececee;
  margin-bottom: 10px;
  border-radius: 6px;
}
.custom-skeleton-title {
  height: 15px;
  background-color: #e1e6ed;
}
.custom-skeleton-picture .custom-skeleton-item {
  height: 100%;
  margin-bottom: 0;
}
@keyframes loading {
  0% {
      opacity: 0.6;
  }

  50% {
      opacity: 1;
  }

  100% {
      opacity: 0.6;
  }
}
.custom-skeleton-item {
  animation: loading 1.5s infinite;
}

.uploadFileBlock {
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border: 1px solid #d0d5dd;
  cursor: pointer;
  justify-content: center;
  max-width: 384px;
  min-height: 215px;
  position: relative;
  z-index: 0;
}

.fileUploadBox .uploadFileBlock {
  max-width: 100%;
  min-height: 206px;
}
.fileUploadBox .fileUploadCover_Img {
  align-items: center;
  background: #f2f4f7;
  border: 6px solid #f9fafb;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 0 auto;
  padding: 5px !important;
  width: 40px;
}
.newpagefile .linkDataDiscripation {
  height: 130px;
  min-height: 130px;
  resize: none;
  padding: 10px 15px;
}
.contactpageCard .pageText {
  min-height: 80px;
}
.adressInputBlock input {
  padding: 8px 12px;
  gap: 8px;
  height: 45px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  outline: none;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 40px;
  font-size: 14px;
}
.adressInputBlock.urlInput > input {
  padding-left: 40px;
}
.file-publication h2,
.file-publication .file-pub-content,
.file-publication .newpagenotes,
.video-publication h2,
.video-publication .publication-content,
.image-publication h2,
.image-publication .publication-content,
.link-publication h2,
.link-publication .publication-content {
  margin-bottom: 16px;
}
.video-box-group {
  display: block;
  width: 100%;
  margin-top: 12px;
}
.video-box-group .video-box-item {
  border-radius: 6px;
  border: 1px solid #eaecf0;
  background: #fff;
  margin-bottom: 10px;
}
.video-box-group .video-box-item label {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 100px 16px 16px;
  position: relative;
  cursor: pointer;
}
.video-box-group .video-box-item input[type="radio"] {
  box-shadow: none;
  font-size: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #d0d5dd;
  cursor: pointer;
}
.video-box-group .video-box-item .icon-social-small {
  max-width: 26px;
  margin: 0 14px;
}
.video-box-group .video-box-item label p {
  color: #0a2540;
  font-size: 14px;
  font-weight: 500 !important;
  letter-spacing: -0.28px;
  margin-bottom: 0;
}
.video-box-group .video-box-item label p span {
  display: block;
  color: #425466;
  font-size: 14px;
  line-height: 25.2px;
  letter-spacing: -0.3px;
}
.video-box-group .video-box-item .contentDrop {
  padding: 0 55px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}
.video-box-group .video-box-item .expanded {
  max-height: 50000px;
}
.video-box-group .video-box-item .contentDrop .newpageCard {
  padding-bottom: 0;
  margin-bottom: 0;
}
.upgrade-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
  align-items: center;
}
.video-box-group .video-box-item label {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
  position: relative;
  cursor: pointer;
}
.uploded-video-box-check .upgrade-box .plan-upgrade-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  padding: 8px 13px;
  height: 45px;
}
.video-box-group
  .video-box-item.uploded-video-box-check
  .contentDrop
  .newpageCard {
  padding-bottom: 5px;
}
.video-box-group .video-box-item .contentDrop .newpageCard,
.video-box-group .video-box-item .contentDrop .newpageLeftRow.textBlock {
  padding-bottom: 22px;
}
.video-box-group .skeleton-video-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}
.video-box-group
.video-box-item
.contentDrop
.newpageLeftRow.textBlock
.player-wrapper {
  margin-top: 0;
}
.video-box-group
  .video-box-item
  .contentDrop
  .newpageLeftRow.textBlock
  + .newpageLeftRow.textBlock {
  margin-top: 0;
  padding-bottom: 0;
}
.video-box-group .skeleton-video,
.themeLayout .skeleton-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #ececee;
  border-radius: 6px;
  margin-bottom: 15px;
  animation: loading 1.5s infinite;
}
.themeLayout .skeleton-video-wrapper {
  position: absolute;
  padding-top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  top: 0;
  margin: 0;
}
.youtube-box-check .adressInputBlock input,
.vimeo-box-check .adressInputBlock input {
  margin-bottom: 0;
  margin-top: 0;
}
.youtube-box-check .adressInputBlock + .label,
.vimeo-box-check .adressInputBlock + .label {
  margin-top: 15px;
}
.fileUploadBox .fileUploadedCover {
  text-align: center;
}

.fileUploadBox .fileUploadCover_Img {
  margin: 0 auto;
  background: #f2f4f7;
  border: 6px solid #f9fafb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 5px !important;
}

.fileUploadBox .fileUploadedCover_wrapp {
  margin: 0 auto;
  background: #f2f4f7;
  border: 6px solid #f9fafb;
  display: inline-block;
  padding: 10px;
  border-radius: 50%;
  width: 53px;
  height: 53px;
  line-height: 28px;
  margin-bottom: 10px;
}
.fileUploadedCover_wrapp img {
  max-width: 20px;
}
.uploadFileBlock .uploadFileBlockDetails {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
  margin: 0 auto;
}
.fileUploadBox .fileUploadedCover .uploaded-file-name {
  margin-bottom: 6px;
}
.player-wrapper {
  display: none;
}
.player-wrapper.player-ready {
  display: block;
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  border-radius: 6px;
  overflow: hidden;
}
.skeleton-video-wrapper,
.player-wrapper.player-ready {
  margin: 22px 0;
}
.popupBlock .player-wrapper.player-ready {
  margin: 0 0 22px;
}
.player-wrapper.player-ready .react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.list-search .feature-img {
  margin: 10px 0;
}
.uploadGrid {
  user-select: none;
  display: flex;
  flex-wrap: wrap;
  margin: 22px -10px 0;
}
.uploadItem img:not(.uploadClose) {
  user-select: none;
}
.uploadItem {
  user-select: none;
  cursor: grab;
  width: calc(20% - 20px);
  margin: 0 10px 20px;
  border-radius: 4px;
  border: 1px solid #eaecf0;
  background-color: #fbfbfb;
  position: relative;
}
.uploadCard {
  display: block;
  height: 100%;
  position: relative;
  border-radius: 4px;
}
.uploadGrid  .uploadPicture {
  height: 100%;
}
.uploadPicture:before, .uploadPDF:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.uploadThumbnail {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  transition: opacity 0.1s;
  object-fit: cover;
}
.uploadPictureCenter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.uploadPicture img, .uploadPDF img {
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  border-radius: 4px;
  bottom: 0;
  right: 0;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}
.uploadContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s linear;
  background-color: rgba(255, 255, 255, 0.95);
}
.uploadClose {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  user-select: unset;
  pointer-events: unset;
}
.uploadContent .uploadDetails {
  display: flex;
  padding: 4px 15px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #eaecf0;
  background: #fff;
  box-shadow: 0px 10px 50px -3.75px rgba(0, 0, 0, 0.01),
      0px 2.29px 11.451px -2.5px rgba(0, 0, 0, 0.03),
      0px 0.603px 3.016px -1.25px rgba(0, 0, 0, 0.04);
  color: var(--fiord);
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.3s linear;
}
.uploadItem:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-radius: 0;
  background-color: #eaecf0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s linear;
  overflow: hidden;
}
.uploadCard:hover .uploadContent .uploadDetails {
  margin-top: 0;
}
.uploadGrid .uploadItem .picture {
  position: unset;
}
.media-grid-layout .progress-wrap {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  width: 100%;
  margin: 0 auto;
}

.media-grid-layout .progress-wrap .progress {
  top: -2px;
  left: unset;
  margin: 0 auto;
  text-align: center;
}
.dateFieldDrop .rdrDay {
  display: inline-block;
  height: 40px;
  width: 44.43px;
  font-size: 14px;
}
.dateFieldDrop .rdrEndEdge {
  left: 2px;
  right: auto;
}
.dateFieldDrop .rdrStartEdge,
.dateFieldDrop .rdrSelected {
  color: #007aff !important;
  width: 36px;
  border-radius: 50%;
  z-index: 2;
  top: 4px;
  height: 36px;
}
.dateFieldDrop .rdrDayNumber {
  display: block;
  height: 36px;
  width: 36px;
  line-height: 36px;
  border-radius: 50%;
  left: 2px;
  top: 4px;
  z-index: 2;
}
.dateFieldDrop .rdrDayNumber span:after,
.dateFieldDrop .rdrDayHovered .rdrDayNumber:after {
  display: none;
}
.dateFieldDrop .rdrDayHovered .rdrDayNumber {
  background-color: #f9fafb;
}
.dateFieldDrop .rdrDayStartPreview,
.dateFieldDrop .rdrDayInPreview,
.dateFieldDrop .rdrDayEndPreview {
  border: none;
}
.dateFieldDrop .rdrDayHovered .rdrSelected ~ .rdrDayNumber {
  background-color: transparent;
}
.dateFieldDrop .rdrWeekDay {
  font-weight: 400;
  line-height: 2.667em;
  color: #344054;
  font-size: 14px;
}
.dateFieldDrop .rdrDayNumber span {
  color: #344054;
}
.dateFieldDrop .rdrMonthAndYearPickers select {
  padding: 10px 30px 10px 10px;
  border-radius: 4px;
  color: #344054;
  font-weight: 600;
  font-size: 16px;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("images/down.svg") !important;
  background-repeat: no-repeat !important;
  background-position-x: 100% !important;
  background-size: 15px !important;
  background-position-x: 96% !important;
  background-position-y: 52% !important;
}
.verticle-dots {
  position: relative;
  left: 0;
}
.dateFieldDrop select option {
  padding: 10px 8px !important;
  height: 50px !important;
  text-align: left;
}
.apexcharts-tooltip.apexcharts-theme-light {
  background-color: #fff !important;
  border: 1px solid #f2f4f7 !important;
  box-shadow: 0 8px 24px hsla(210, 8%, 62%, 0.2) !important;
}
/*
.apexcharts-tooltip-series-group .apexcharts-tooltip-y-group .apexcharts-tooltip-text-y-label {
color: #5485ED;
}
*/
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  padding-bottom: 0;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-marker {
  display: none;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background-color: inherit !important;
  border-bottom: none !important;
  padding: 10px 10px 5px;
  color: #425466 !important;
}
.apexcharts-tooltip-text .apexcharts-tooltip-text-y-value {
  margin-left: 0;
  font-weight: 500;
  color: #0a2540;
}
.apexcharts-tooltip-series-group .apexcharts-tooltip-text,
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  font-family: "Inter" !important;
  font-size: 14px !important;
}
.apexcharts-tooltip-y-group {
  padding: 0;
}
.apexcharts-tooltip-text .apexcharts-tooltip-text-y-value {
  margin-left: 0;
  font-weight: 500;
  color: #0a2540;
}
.apexcharts-tooltip-series-group {
  margin-bottom: 5px;
}
.profileUrlFields .inputFieldIcon {
  display: flex;
  align-items: center;
}
.profileUrlFields .inputFieldIcon .urlFields {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  width: auto;
  padding: 10px 15px;
  -webkit-user-select: none;
  user-select: none;
  height: 45px;
}
.profileUrlFields .inputFieldIcon .inputFields {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.textBlock .ck.ck-editor__editable_inline {
  min-height: 465px;
  border: 0;
}
.textBlock .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.textBlock .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border: 0;
  box-shadow: none;
}
.home-publication .newpageRight .newpageMoreButtons,
.contact-publication .newpageRight .newpageMoreButtons {
  display: none;
}
.homepageCard .page-summary-msg {
  padding-top: 10px;
  margin-bottom: 10px;
  text-align: right;
}
.homepageCard,
.contactpageCard {
  width: 100%;
}
.borderBox {
  border: 1px solid #eaecf0;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  margin-top: 5px;
  padding: 15px 16px 16px;
}
.homepageCard .newpagefile .label {
  font-weight: 500;
  margin-bottom: 8px !important;
}
.homepageCard .newpagefile .lbl-description {
  margin-bottom: 18px;
}
.homepageCard .newpageLeftRow .list-search p {
  margin-top: 3px;
  font-weight: 500;
}
.contactpageCard > .newpageLeftRow {
  margin-bottom: 15px;
}
.contactpageCard .linkDataTitle {
  padding: 10px 15px;
}
.display-form-group {
  padding-top: 2px;
}
.display-form-group .display-form-item p{
  font-weight: 500;
  color: #344054;
}
.display-form-group .display-form-item > label {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 0;
  padding: 16px 100px 16px 16px;
  position: relative;
  width: 100%;
}
.display-form-group .display-form-item .icon-wrapp {
  margin-right: 15px;
}
.contactpageCard .list-search label p {
  margin-bottom: 0;
}
.btn-upgrade {
  position: absolute;
  right: 16px;
  display: flex;
  padding: 10px;
  align-items: center;
  border-radius: 6px;
  background: #000;
  mix-blend-mode: multiply;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.24px;
  border: 0;
}
.newpageLeftRow.formControlFields {
  margin-top: 0;
}
.display-form-group .display-form-item .contentDrop {
  padding: 0 16px 20px 48px;
}
.display-form-group input[type="checkbox"]:checked:before {
  min-width: 18px;
  min-height: 18px;
  height: 18px;
  width: 18px;
}
.contactpageCard .adressInputBlock .inputFields {
  margin-top: 0;
}
.contactpageCard .adressInputBlock .adressLink {
  top: 14px;
}

/* --- grid layout start ---  */
.pagesbtn {
  border: none;
  line-height: 1.5;
  display: flex;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
}
.pagesbtnTitel {
  font-weight: 500;
  font-size: 13px;
  line-height: 1.5;
  margin-left: 8px;
  transition: width 0.7s;
}
.pageMedia .pagesSearchIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.deleteIcon {
  position: relative;
}
.deleteTooltip {
  position: absolute;
  left: -15px;
  top: 30px;
  background: #ffffff;
  border: 1px solid #f2f4f7;
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #344054;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  padding: 8px 12px;
  transition: 0.3s;
  opacity: 0;
}

.deleteIcon:hover .deleteTooltip {
  opacity: 1;
  left: -7px;
  top: 40px;
}

.deleteIcon img {
  top: 0px;
  position: relative;
  cursor: pointer;
}

.select-file .selectFields,
.mediadate-dropdown .selectFields {
  color: #425466;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  background-color: transparent;
  border: 1px solid #eaecf0;
  border-radius: 6px;
  padding: 10px 14px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.uploadMediaPopup .selectFields {
  min-width: unset;
  color: #677185;
}
.pagesSearchdeleteIcom,
.pagesSearchlistIcon,
.pagesSearchgridIcon,
.mediaGallery .deleteIcon {
  line-height: 0;
}
.pagesSearchlistIcon.active svg path {
  stroke: rgba(87, 60, 255, 1);
}
.pagesSearchgridIcon.active svg path {
  stroke: rgba(87, 60, 255, 1);
}
.dropdown-wrapp {
  position: relative;
}
.dropdown-wrapp ul {
  border: none;
}
.dropdown-wrapp ul li {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 16px 10px 10px;
}
/* .dropdown-wrapp ul li:not(:last-child) {
  margin-bottom: 10px;
} */
.dropdown-wrapp img {
  width: 12px;
}
.file-wrapper textarea {
  height: 80px;
}

.dropdown {
  background: white;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 6px;
  position: absolute;
  right: 0;
  top: 50px;
  min-width: 160px;
  overflow: hidden;
  z-index: 999999;
}
.dropdown.addDropdown {
  right: auto;
}
.mediaGallery .pagesSearch .dropdown.dateDropdown {
  width: max-content; 
}
.categoriesDropdown {
  max-height: 300px;
  overflow-y: auto;
}
.countryDropdown ul {
  max-height: 195px;
  overflow: hidden auto;
  background: white;
  /* border: 1px solid #eaecf0; */
  box-shadow: 0px 12px 16px -4px rgb(16 24 40 / 8%),
      0px 4px 6px -2px rgb(16 24 40 / 3%);
  border-radius: 6px;
}
.select-country .selectFields,
.select-country .addDropdown.countryDropdown {
  min-width: 212px;
}
.countryDropdown ul::-webkit-scrollbar {
  width: 5px;
  background: #d0d5dd;
}
.countryDropdown ul::-webkit-scrollbar-thumb {
    background: #667085;
}
.node-file .dropdown-wrapp .countryDropdown ul::-webkit-scrollbar-thumb {
  background: #ababab;
}
.countryDropdown ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.welcomeOverlay {
  position: fixed !important;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}
.welcomeOverlay.mediaModalOverlay {
  opacity: 1;
  visibility: visible;
  z-index: 9999;
}
.hidden-message .welcomeOverlay {
  opacity: 1;
  visibility: visible;
  z-index: 9;
}
.welcomeModal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: #fff;
  opacity: 1;
  z-index: 999999;
  visibility: visible;
  border: 1px solid #eaecf0;
  padding: 25px;
  border-radius: 6px;
  max-width: 400px;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
      0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}
.welcomeTitle {
  color: #101828;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 15px;
  margin-top: 0;
  padding: 0;
}
.welcomeText {
  color: #475467;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.welcomeModalbtn {
  display: flex;
  margin-top: 20px;
  gap: 15px;
}
.welcomeModalbtn button {
  border-radius: 6px;
  cursor: pointer;
  padding: 8px;
  width: 50%;
  height: 45px;
  font-weight: 600;
}
.welcomeModalbtn .cancel {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 395px;
}
.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid rgb(100 116 139);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  100% {
      transform: rotate(360deg);
  }
}
.grid-container {
  max-width: 100%;
  border: 1px solid #eaecf0;
  padding: 20px;
  margin-top: 25px;
  border-radius: 12px;
}
.gridIitem {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  gap: 24px;
}
.gridImg {
  max-width: 100%;
  border: 1px solid #eaecf0;
  border-radius: 4px;
  margin-bottom: 0;
  line-height: 1;
}
.gridImg .item {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: inherit;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.gridImg.type-doc .item {
  object-fit: none;
  max-height: calc(100% - 37px);
  background-color: #fbfbfb;
}
.uploadMediaPopup .file-wrapper.show-wrapper {
  left: 0;
}
.uploadMediaPopup .file-wrapper {
  background-color: #fbfbfb;
  position: relative;
  display: inline-block;
  width: 30%;
  vertical-align: top;
  left: 30%;
  border-left: 1px solid #eaecf0;
}
.uploadMediaPopup .file-wrapper .file {
  padding: 24px;
}
.modalImage {
  text-align: center;
  border-radius: 6px;
  margin-bottom: 15px;
}
.modalImage img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover;
  object-position: top;
}
.file-wrapper .filename {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #0a2540;
  word-break: break-all;
}
.file-wrapper .modalContent .contentrow {
  padding: 0;
}
.modalPopup .contentrow,
.file-wrapper .modalContent .contentrow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px 20px;
  flex-wrap: wrap;
  padding: 24px;
}

.file-wrapper .modalContent .contentrow .title {
  font-weight: 600;
  color: #0a2540;
  margin-right: 5px;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 0;
}

.modalPopup .title,
.modalPopup .formContent label,
.modalPopup .formContentbtn,
.file-wrapper .title,
.file-wrapper .formContent label,
.file-wrapper .formContentbtn {
  font-size: 14px;
  font-weight: 500;
  color: #0a2540;
}
.file-wrapper .modalContent .contentrow > div {
  margin-bottom: 0;
  margin-right: 0;
  font-size: 14px;
}
.tiptop-editor .modalContentform .formContentbtn.uploadedMedia-Button {
  margin-bottom: 11px;
}
.media-grid-layout .gridImg {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid #e5e7eb;
}
.media-grid-layout input[type="checkbox"] {
  position: absolute;
  top: 10px;
  right: 10px;
  appearance: none;
  border-radius: 6px;
  /* z-index: 99; */
}
.media-grid-layout .gridImg:has( input[type="checkbox"]:checked ) {
  border-color: #1c1c1c;
}
.media-grid-layout input[type="checkbox"]::before {
  margin-right: 0;
}
.media-grid-layout input[type="checkbox"]:not(:checked)::before {
  background-color: #fff;
}
.pagesSearch .pagesSearchlistIcon,
.pagesSearch .pagesSearchgridIcon {
  cursor: pointer;
}
.gridIitem .itemName {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 10px;
  border-top: 1px solid #eaecf0;
  text-align: center;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-color: #fbfbfb;
  color: #425466;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.NoResults .noResButton {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}
.NoResultsMedia .noResButton {
  margin-top: 15px;
}
.NoResultsMedia a{
  color: #101828;
}
.media-grid-layout .NoResults {
  border-top: none;
}
.NoResults .noResultsBody {
  max-width: 350px;
}
.NoResults .noResultsBody > img {
  width: 170px;
}
/*
.NoResults .noResDesc {
  margin-bottom: 30px;
}
*/

.NoResults .editTitle,
.NoResults .editTitle:hover {
  color: #0a2540;
  font-weight: 500;
}
.NoResults .noResult-button {
  padding-right: 0;
  color: #344054 !important;
}
.noResButton svg {
  margin-right: 5px;
  color: black;
}
/* --- grid layout end ---  */
.pagesTableItem .table-five-column .table-head > div:nth-child(1) {
  width: 60%;
}
.picture-main {
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
}
.picture-main .file-picture {
  height: 50px;
}
.picture-main .file-picture img {
  width: 50px;
  height: 50px;
  border-radius: 4px;
}
.picture-main .file-picture.type-image img {
  object-fit: cover;
}
.pagesTableItem .table-item > div:nth-child(1) {
  width: 100%;
  height: 80px;
}
.file-data-wrapper .file-name {
  font-weight: 500;
  text-transform: capitalize;
  color: rgba(10, 37, 64, 1);
}
.pagesTableItem .table-action {
  cursor: pointer;
}
.fileUploadBox .uploadFileBlock {
  max-width: 770px;
  min-height: 206px;
  margin: 0 auto;
}
.popupContainer {
  position: fixed;
  z-index: 9999;
  top: 0;
  display: flex;
  margin: auto;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.popupOverlay {
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 50%);
  z-index: 12;
  opacity: 1;
  visibility: visible;
}
.uploadMediaPopup .popupBlock {
  width: calc(100dvw - 60px);
  max-width: calc(100dvw - 60px);
  height: calc(100dvh - 60px);
}
.popupBlock {
  width: 520px;
  margin: auto;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  z-index: 15;
  border-radius: 6px;
}
.uploadMediaPopup .popupBlock .popupBlock-wrapper {
  display: block;
  padding: 0;
  height: 100%;
}

.uploadMediaPopup .popupBlock .popupBlock-wrapper .popupBlock-inner {
  height: 100%;
}

.uploadMediaPopup .popupBlock .popupTopClose {
  top: 20px;
  right: 20px;
}

.popupBlock .popupTopClose,
.uploadMediaPopup .mediaDeleteModal .popupTopClose {
  float: right;
  position: relative;
  top: -5px;
  right: -5px;
}
.upgradepopup .popupTop {
  display: flex;
  align-items: flex-start;
  justify-content: space-between !important;
}
.upgradepopup .popupModalBtn {
  display: flex;
  justify-content: center !important;
  margin-top: 20px;
}
.popupTop {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.modalPopup .popupOverlay {
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 50%);
  z-index: 0;
  opacity: 1;
  visibility: visible;
}
.modalPopup .popupBlock .popupBlock-wrapper {
  padding: 0;
  background-color: transparent;
  margin: 5px 5px 5px 0;
}
.modalPopup .popupBlock {
  max-width: 1076px;
  margin: auto;
  background-color: #fff;
  padding: 0;
  border: 1px solid #eaecf0;
  border-radius: 6px;
  width: calc(100% - 30px);
}
.modalPopup .popupBlock .popupBlock-wrapper .mediaGallery {
  width: 100%;
  background-color: transparent;
  border: none;
  max-width: 1076px;
  margin: 0 auto;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  position: relative;
}
.popupBlock-wrapper {
  /* padding: 20px; */
  display: flex;
  column-gap: 30px;
  overflow-y: auto;
  max-height: calc(100dvh - 40px);
  overflow-x: hidden;
  margin: 5px;
}
.modalPopup .file {
  width: calc(50% - 4px);
  position: relative;
}
.modalPopup .popupBlock:after {
  content: "";
  background-color: #eaecf0;
  width: 1px;
  height: 100%;
  left: -10px;
  right: 0;
  margin: 0 auto;
  position: absolute;
  top: 0;
}
.modalPopup .file .modalHeader {
  padding: 20px 24px;
  border-bottom: 1px solid #eaecf0;
}
.mediaGallery .modalHeader {
  margin-top: -5px;
}
.modalPopup .file .filename,
.modalPopup .previewname {
  font-size: 16px;
  font-weight: 500;
  color: #0a2540;
  word-break: break-all;
}
.modalPopup .modalContent {
  min-height: 370px;
  padding: 15px 24px;
}
.modalPopup .contentrow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px 20px;
  flex-wrap: wrap;
  padding: 0 0 15px;
  border-bottom: 1px solid #eaecf0;
}
.modalPopup .title {
  font-size: 14px;
  font-weight: 500;
  color: #0a2540;
}
.modalPopup .subtext {
  font-size: 14px;
  font-weight: 400;
  color: #425466;
  margin-left: 6px;
}
.modalPopup .modalContentform {
  padding: 15px 0 0;
}
.modalPopup .formContent {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}
.modalPopup .formContent label {
  width: 100px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #0a2540;
}
.modalPopup .modalInput {
  cursor: unset;
  width: 70%;
  padding: 10px;
  border: 1px solid #eaecf0 !important;
  border-radius: 5px;
  margin-right: 0;
  font-size: 14px;
  font-weight: 400;
  color: #425466;
  outline: none;
  min-height: 40px;
  resize: none;
}
.modalPopup .modalInput::placeholder,
.formControlFields input::placeholder {
  color: #425466;
}
.modalPopup .formContent .formURL .modalInput {
  width: 100%;
  padding-right: 60px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.modalPopup textarea {
  height: 80px;
}
.modalPopup .formContent.fromDesc {
  align-items: flex-start;
}
.modalPopup .formContent .formURL,
.file-wrapper .formContent .formURL {
  width: calc(100% - 130px);
  position: relative;
}
.modalPopup .formUrlImg {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 12px;
  margin-right: 12px;
  gap: 10px;
}
.modalPopup .formContent .formURL .modalInput {
  width: 100%;
  padding-right: 80px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.modalPopup .popupBlock-wrapper .tooltip-text,
.file-wrapper .tooltip-text,
.previewTooltipblock .text-tooltip {
    position: absolute;
    right: 10px;
    font-size: 11px;
    background: #fff;
    color: #344054;
    padding: 6px 8px;
    border-radius: 6px;
    font-weight: 500;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}
.previewTooltipblock .text-tooltip{
  width: max-content;
  right: 0;
  top: 50px;
  border: 1px solid #f2f4f7;
  padding: 3px 6px;
}
.userrole-select.team-user-role .css-1lvqp10-Input{
  height: unset;
}

.modalPopup .popupBlock-wrapper .tooltip-text:after,
.file-wrapper .tooltip-text:after,
.previewTooltipblock .text-tooltip:after {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 30px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #f2f4f7 transparent;
}
.previewTooltipblock .text-tooltip:after {
  left: auto;
  right: 40px;
  border-color: transparent transparent #fff transparent;
  left: 17px;
}
.modalPopup .formUrlImg a {
  display: inline-block;
  vertical-align: top;
  transition: all 0.2s linear;
  opacity: 1;
}
.modalPopup .modalDelete {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.modalPopup .modalDelete img {
  margin-right: 10px;
}
.modalPopup .formContentbtn {
  font-size: 14px;
  font-weight: 500;
  color: #0a2540;
}
.modalPopup .modalActions.mobile-view {
  display: none;
}
.modalPopup .modalActions {
  text-align: center;
  margin-top: 0;
  border-top: 1px solid #eaecf0;
  padding: 24px 24px 19px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.modalPopup .cancelButton {
  /* background: #fff;
  border: 1px solid #d0d5dd !important;
  box-shadow: 0 1px 2px 0 #1018280d;
  color: #344054; */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  width: 50%;
}
.modalPopup .modalActions .saveButton {
  width: 50%;
  margin-right: 0px;
  /* border-radius: 6px; */
  /* background: #1c1c1c;
  border: 1px solid #1c1c1c;
  box-shadow: 0 1px 2px 0 #1018280d;
  color: #fff; */
  font-size: 14px;
}
.settingsBlue {
  line-height: 1.5;
  display: flex;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
}
.modalPopup .preview {
  width: 50%;
}
.modalPopup .modalImg.type-doc img {
  object-fit: none;
  width: auto;
  height: auto;
  border-radius: 8px;
  max-width: 100%;
  max-height: 100%;
}
.modalPopup .modalHeader,
.modalPopup .contentrow {
  padding: 20px 24px;
  border-bottom: 1px solid #eaecf0;
}
.modalPopup .mediaGallery .popupClose {
  cursor: pointer;
  margin-top: 15px;
  position: absolute;
  right: 15px;
}
.popupTop img:last-child {
  width: 15px;
  margin-right: 0;
}

.popupBlock .popupTopClose:after {
  content: "";
  display: block;
  clear: both;
}
.uploadMediaPopup .popupBlock .popupContent {
  margin-bottom: 0;
}
.popupBlock .popupContent {
  margin-bottom: 15px;
}
.uploadMediaPopup .popupBlock .popupContent .popupTitle {
  padding: 10px 24px 0;
}
.popupTitle {
  color: #101828;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 15px;
  margin-top: 0;
  padding: 0;
}
.uploadMediaPopup .tabTitle {
  display: flex;
  align-items: center;
  margin: 5px 0 0;
  border-bottom: 1px solid #eaecf0;
  padding: 0 24px;
}
.uploadMediaPopup .tabTitle li:not(:last-child) {
  margin-right: 20px;
  font-weight: 600;
}

.uploadMediaPopup .tabTitle li.active {
  color: #0a2540;
  font-weight: 600;
}
.uploadMediaPopup .tabTitle li {
  cursor: pointer;
  padding-bottom: 10px;
  position: relative;
  color: #425466;
  font-size: 14px;
  font-weight: 500;
}
.uploadMediaPopup .tabTitle li.active:after {
  width: 100%;
}

.uploadMediaPopup .tabTitle li:after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0a2540;
  margin: 0 auto;
  transition: all 0.2s linear;
}
.newpageLeftRow input {
  padding: 8px 12px;
  gap: 8px;
  height: 45px;
  width: 100%;
  background: #fff;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  outline: none;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 40px;
  font-size: 14px;
}
.uploadMediaPopup .mediaSearchInput input {
  margin-top: 0px;
  margin-bottom: 0;
}
.uploadMediaPopup input[type="checkbox"] {
  margin-top: 3px;
}
.uploadMediaPopup .fileUploadBox .uploadFileBlock {
  height: calc(100dvh - 188px);
  padding: 15px;
  width: calc(100% - 48px);
  margin: 0 auto;
}

.fileUploadBox .uploadFileBlock {
  max-width: 100%;
  min-height: 206px;
}
.uploadFileBlock {
  background: #fff;
  border: 1px solid #eaecf0;
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  min-height: 215px;
  max-width: 384px;
  position: relative;
  z-index: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fileUploadBox .fileUploadCover_Img {
  margin: 0 auto;
  background: #f2f4f7;
  border: 6px solid #f9fafb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 5px !important;
  margin-bottom: 5px;
}

.fileUploadCover_Img {
  margin: 0 auto;
  display: block;
}
.newpageLeftRow .newPageImageUploadText span {
  color: #1c1c1c;
}
.newPageImageUploadText span {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.popupTop img:last-child {
  cursor: pointer;
}
.uploadMediaPopup .popupBlock .popupBlock-wrapper .popupBlock-inner .poppContentWrapper {
  display: block;
  height: 100%;
  max-height: calc(100dvh - 140px);
}
.uploadMediaPopup .grid-container {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  padding: 24px;
  border: none;
  border-radius: 0;
  margin-top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100dvh - 130px);
}
.modalPopup .modalImg {
  text-align: center;
  padding: 24px 19px 14px 24px;
  height: calc(100% - 58px);
  max-height: calc(404px + 91px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.modalPopup .contentrow {
  padding: 0 0 15px;
}
.modalPopup .modalImg.type-doc img {
  object-fit: none;
}
.modalPopup .modalImg img {
  width: auto;
  height: auto;
  border-radius: 0;
  max-width: 100%;
  max-height: 100%;
}
.modalPopup .preview .video-box-group {
  padding: 0 15px 0 20px;
}
.uploadMediaPopup .show-wrapper .video-box-group {
  margin-top: 0;
}
.modalPopup .preview .video-box-group .skeleton-video-wrapper,
.uploadMediaPopup .show-wrapper .video-box-group .skeleton-video-wrapper {
    margin-top: 0;
}
.video-box-group {
  display: block;
  width: 100%;
  margin-top: 12px;
}
.modalPopup .preview .video-box {
  display: flex;
  height: calc(100% - 55.19px);
  align-items: center;
  justify-content: center;
}
.modalPopup .preview .skeleton-video-wrapper {
  height: calc(100% - 103px);
  width: calc(100% - 30px);
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-top: 56.25%;
}
.skeleton-video-wrapper,
.player-wrapper.player-ready {
  margin: 22px 0;
}
.video-box-group .skeleton-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #ececee;
  border-radius: 6px;
  margin-bottom: 15px;
  animation: loading 1.5s infinite;
}
.player-wrapper {
  display: none;
}
.uploadMediaPopup .grid-container.show-wrapper {
  width: 70%;
}
.uploadMediaPopup .file-wrapper .file {
  padding: 24px;
}
.file-wrapper .modalContent .contentrow {
  padding: 0;
}
.file-wrapper .modalContentform {
  border-top: 1px solid #e2e4e9;
  padding-top: 15px;
  margin-top: 15px;
}
.formContent {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}
.file-wrapper .formContent label {
  font-size: 14px;
  font-weight: 500;
  color: #0a2540;
}
.file-wrapper .modalInput {
  cursor: unset;
  width: calc(100% - 130px);
  padding: 10px;
  border: 1px solid #eaecf0 !important;
  border-radius: 5px;
  margin-right: 0;
  font-size: 14px;
  font-weight: 400;
  color: #425466;
  outline: none;
  min-height: 40px;
  resize: none;
  display: inline-block;
  vertical-align: middle;
}
.modalPopup .modalInput::placeholder,
.file-wrapper .modalInput::placeholder {
    color: #425466;
}
.file-wrapper .formContent label {
  width: 100px;
  margin-right: 10px;
}
.file-wrapper .formUrlImg {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 12px;
  margin-right: 12px;
  gap: 10px;
}
.file-wrapper .formContent .formURL .modalInput {
  width: 100%;
  padding-right: 60px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.modalContent .modalActionsButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.file-wrapper .modalDelete {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  cursor: pointer;
}
.file-wrapper .modalDelete img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}
.file-wrapper .modalActions {
  text-align: center;
  margin-top: 0;
  border-top: 1px solid #eaecf0;
  padding: 24px 24px 19px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.uploadMediaPopup .modalActions button {
  height: 44px;
}
.file-wrapper .cancelButton {
  width: 50%;
}
.file-wrapper .cancelButton,
.file-wrapper .modalActions .saveButton {
  padding: 10px 13px;
  cursor: pointer;
  border-radius: 6px;
  font-weight: 500;
}
.modalPopup .cancelButton,
.file-wrapper .cancelButton {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.file-wrapper .modalActions .saveButton {
  width: 50%;
  margin-right: 0px;
}
.file-wrapper .saveButton {
  font-size: 14px;
}
.modalDelete .settingPage-loader {
  position: relative;
  min-width: 28px;
  bottom: 15px;
}
.modalDelete .settingPage-loader .ring-loader::before {
  border-color: #0a2540;
  border-width: 2px;
}
.modalDelete .settingPage-loader .ring-loader {
  margin: 0;
  top: 8px;
}
.pageSearchTop {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}
.dropdown-wrapp {
  position: relative;
}
.newpageImageUpload.draging {
  border-color: #573cff;
}
.draging:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}

.draging {
  opacity: 0.6;
  /* background: #ebebec; */
}
.fileUploadBox.draging .uploadFileBlock,
.mediaGallery .fileUploadBox.draging .uploadFileBlock {
  border-color: #573cff;
}
.uploadMediaPopup .grid-container::-webkit-scrollbar-thumb,
.uploadMediaPopup .file-wrapper .file::-webkit-scrollbar-thumb {
  border-radius: 6px;
  padding: 5px;
  background-color: #eaecf0;
}

.uploadMediaPopup .grid-container::-webkit-scrollbar-thumb:hover,
.uploadMediaPopup .file-wrapper .file::-webkit-scrollbar-thumb:hover {
  background-color: #f2f2f2;
}

.uploadMediaPopup .grid-container::-webkit-scrollbar-track,
.uploadMediaPopup .file-wrapper .file::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #f9fafc;
}

.uploadMediaPopup .grid-container::-webkit-scrollbar,
.uploadMediaPopup .file-wrapper .file::-webkit-scrollbar {
  height: 5px;
  background-color: #f9fafc;
  width: 6px;
}
.uploadMediaPopupOverflow {
  overflow: hidden;
}
.uploadMediaPopup .file-wrapper.show-wrapper {
  left: 0;
  position: sticky;
  bottom: -1px;
}
.file-wrapper .modalActions {
  position: sticky;
  bottom: 0;
  background-color: #fbfbfb;
}
.uploadMediaPopup .file-wrapper .file {
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100dvh - 226px);
}
.modalActionsForMobile {
  display: none;
}
.uploadMediaPopupOverflow{
overflow: hidden;
}
.draging:before {
  content: "";
  position: absolute;
  /* height: 100%; */
  width: 100%;
}

.draging {
  opacity: .6;
  /* background: #ebebec; */
}
.fileUploadBox.draging .uploadFileBlock,
.mediaGallery .fileUploadBox.draging .uploadFileBlock {
  border-color: #573CFF;  
}
.newpageImageUpload.draging {
  border-color: #573CFF;
}
.theamLight,
.theamDark {
  position: relative;
  line-height: 0px;
  border: 2px solid transparent;
  border-radius: 10px;
  overflow: hidden;
}

.theamDark.active,
.theamLight.active {
  border-color: #573CFF;
}
.themetype {
  width: 100%;
  object-fit: cover;
  cursor: pointer;
  min-width: 210px;
  border-radius: 0;
  z-index: 1;
  position: relative;
}
.theme {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 16px;
}

.coverimg.coverimg-dark, .coverimg.coverimg-light {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  max-width: 65%;
  max-height: 100%;
  border-radius: 0;
  transform: translate3d(0, 0, 0);
  shape-rendering: geometricPrecision;
  z-index: 0;
}
.rowChangeImage, .rowChangeCover{
  display: flex;
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
}
.generalBlockItems {
  width: 100%;
  padding-left: 50px;
  max-width: 560px;
}
.generalRowDesc {
  min-width: 280px;
}
.generalRowDesc .rowTitle,
.rowChangeCover .coverimgTitle{
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}
.generalRowDesc .rowdiscription,
.rowChangeCover .coverimgDes{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}
.fileUploadProfile-box {
  min-height: 85px;
  position: relative;
  z-index: 0;
  margin-left: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.generalBlockItems .newPageImageUploadText {
  text-align: left;
  padding-top: 0px;
}
.avatarBlock, .themeRow {
  display: flex;
}
.fileUploadProfile-box .newPageImageUploadText .btnUpload {
  color: #525866;
  display: inline-block;
  font-weight: 500;
  margin-top: 5px;
  padding: 4.5px 7px;
}
.userrole-dropdown .subscriberFillter{
  min-width: 194px;
}
.generalTop {
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid #eaecf0;
}
.generalTopLeftTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}
.generalTopLeftDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin-top: 4px;
}
.twoFactorContent .twoFactorRow {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 1000px;
}
.twoFactor .billingText {
  max-width: 500px;
  width: 100%;
}
.billingText .settingsMenuBlockTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  padding: 0;
}
.twoFactor .billingText .twoFactorTopLeftDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin-top: 0;
  width: 100%;
}
.twoFactorContent .factorRowCheckbox {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding-left: 30px;
}
.addFactor_btn {
  font-size: 14px;
  border-radius: 6px;
  display: flex;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
  border: 1px solid #d0d5dd;
  cursor: pointer;
}
.popupAddFactor .popupBlock-inner {
  margin: 5px;
  padding: 20px;
  max-height: calc(100dvh - 30px);
  overflow-y: auto;
  width: 100%;
}
.popupBlock.popupAddFactor {
  width: 450px;
}
/* .popupBlockPlan .popupDesc {
  margin-top: 5px;
} */
.popupDesc {
  color: #475467;
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 5px;
}
.popupContent-main .popupContent-item {
  border: 1px solid rgba(234, 236, 240, 1);
  border-radius: 6px;
  width: 100%;
  padding: 15px;
  margin-top: 10px;
  cursor: pointer;
}
.popupContent-main .popupContent-item .popupContent-inner {
  display: flex;
}
.popupContent-item .changePlan-check {
  width: 30px;
  display: inline-block;
}
.changePlan-check input {
  padding: 10px 14px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  max-height: 44px;
  width: 100%;
  outline: none;
}
.popupContent-inner input[type="radio"] {
  visibility: hidden;
  cursor: pointer;
}
.changePlan-check input[type="radio"]:checked:after,
.changePlan-checkBox:after {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: block;
  border: 2px solid #e2e4e9;
  z-index: 4;
  position: relative;
  visibility: visible;
  top: -4px;
  left: -2px;
}
.changePlan-check input[type="radio"]:checked:after {
  background-image: url(images/icon-check.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 18px;
  height: 18px;
}
.popupContent-item .changePlan-subText {
  padding-left: 10px;
  display: inline-block;
  width: calc(100% - 30px);
}
.popupContent-main .popupContent-item .popupContent-inner .changePlan-subText h2 {
  color: #0a2540;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}
.popupContent-main .popupContent-item .popupContent-inner .changePlan-subText .changePlan-text {
  color: #475467;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 10px;
  width: 100%;
}
.popupAddFactor .popupButtons {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
}
.popupAddFactor .popupButtons .sendCodeBtn {
  min-width: 94.45px;
}
.popupAddFactor .popupButtons .verifyLoader {
  width: 73.33px;
}
.settingsWhite {
  color: #344054;
  font-size: 13.33px;
  margin-right: 12px;
  line-height: 1.5;
  border-radius: 6px;
  display: flex;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
  background-color: transparent;
  border: 1px solid #d0d5dd;
}
.billingSaveBtn {
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 14px;
}
.settingsBlue {
  font-size: 13.33px;
  margin-right: 12px;
  /* background-color: #1c1c1c; */
  /* color: #fff; */
  border: none;
  line-height: 1.5;
  /* border-radius: 6px; */
  display: flex;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  /* box-shadow: 0px 4px 8px -4px rgba(16, 24, 40, 0.5); */
  white-space: nowrap;
  min-width: 75px;
}
.saveButtons .navigationBtn{
  width: 118px;
}
.popupAddFactor .emailAddress-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #344054;
  margin-bottom: 10px;
}
.popupAddFactor .popupBlock-wrapper .input-phNumber input {
  padding: 5px 5px 6px 40px;
  min-height: 42px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  outline: none;
}
.popupBlock-wrapper .background_icon {
  background-position: 13px !important;
  background-repeat: no-repeat !important;
  background-size: 20px !important;
  padding-left: 40px !important;
}
.icon-public-number {
  background-image: url(images/step-icon-phone.svg) !important;
}
.input-verify-code .codeSection {
  display: flex;
}
.codeSection input.code-input {
  height: 40px !important;
  width: 40px !important;
  margin-right: 10px;
  margin-bottom: 5px;
  line-height: 1.5;
  padding: 0;
  text-align: center;
  max-height: unset;
  font-size: 24px;
  color: #101828;
}
.popupAddFactor .popupBlock-wrapper input {
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  font-weight: 400;
  outline: none;
}
.resend-text p {
  font-size: 14px;
  line-height: 20px;
  color: rgba(71, 84, 103, 1);
  margin-top: 10px;
}
.resend-text a {
  text-decoration: underline;
  color: rgba(71, 84, 103, 1);
}
.popupAddFactor .popupButtons svg {
  margin-right: 8px;
}
.codeSection input::placeholder {
  font-weight: 500;
  font-size: 26px;
  color: rgba(208, 213, 221, 1);
}
.popupAddFactor input[type="number"]::-webkit-inner-spin-button,
.codeSection input[type="number"]::-webkit-inner-spin-button{
  display: none;
}
.removeFactor_btn {
  font-size: 14px;
  display: flex;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
  margin-left: 15px;
  width: 108px;
}
.active-plan-background .changePlan-subText {
  padding-left: 0;
  width: 100%;
}
.active-plan-background {
  background-color: #f6f8fa;
}
.popupContent-main .popupContent-item.active-plan-background {
  cursor: unset;
}
.active-plan {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.active-plan .active-sub {
  padding: 2px 8px 2px 8px;
  font-size: 12px;
  line-height: 18px;
  color: rgba(6, 118, 71, 1);
  font-weight: 500;
  border-radius: 20px;
  border: 1px solid rgba(171, 239, 198, 1);
  background-color: rgba(236, 253, 243, 1);
  width: auto;
  position: relative;
  top: -1px;
}
.popupBlock-inner::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
}
.popupBlock-inner::-webkit-scrollbar-track {
    background: #f9fafc; 
    padding-right: 5px;
}
.popupBlock-inner::-webkit-scrollbar-thumb {
    background: #eaecf0; 
    border-radius: 30px;
}
.popupButtons {
  margin: 24px 0;
  display: flex;
}
.backButton {
  margin-right: 15px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 100px;
  height: 45px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #344054;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 15px;
}
.twoFactor .popupButtons{
  margin-bottom: 0 !important;
}
.twoFactor .popupButtons button{
  margin-bottom: 0 !important;
  min-width: unset;
  padding: 8px 13px;
  min-height: 42px;
  display: unset;
  font-size: 14px;
  line-height: 22px;
}
.login .backButton svg {
  width: auto;
  height: auto;
}
.backButton svg {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}
.inactiveUser{
  background-color: #f2f2f2f2;
  pointer-events: none;
}
.user-status .inactiveUser{
  background: #f2f2f2f2;
}
.dropdown-wrapp .userPlanBtn{
  min-width: 187px;
}
.profilePictureItems .selectProfile{
  border: none !important;
}
.profilePictureItems .profileImageBox{
  text-align: left !important;
}
.notification {
  width: 400px;
  height: auto;
  background: #ffffff;
  border: 1px solid #f2f4f7;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 12px;
  padding: 16px;
  position: fixed;
  left: 40px;
  bottom: 40px;
  z-index: 9999999999;
  display: flex;
  justify-content: space-between;
}

.notificationTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
}

.notificationDesc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}

.notifyStatus {
  margin-right: 15px;
  position: relative;
  bottom: 10px;
}

.notifyClose {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
}

.notification {
  transition: 0.3s;
  transition-timing-function: cubic-bezier(1, 0.01, 0.31, 0.98);
  left: -100%;
}

.notificationActive {
  left: 20px;
}
.notificationMessage{
  justify-content: normal;
  align-items: center;
  width: 400px;
}
.notifyClose{
  position: unset;
  margin-right: 15px;
}
.notificationMessage .notifyClose{
  margin-left: 15px;
  position: unset;
}
.notificationDesc{
  padding-right: 4px;
}
.notificationLinks .linkText{
  font-size: 14px;
  line-height: 20px;
}
.notificationMessage .notifyImg{
  bottom: 0;
}
.notificationMessage .notifyMessage{
  display: inline-block;
}
.notificationLinks{
  display: flex;
}
.modalActions .editMediaBtn {
  height: 48px;
}
.subscriberSelect {
  border: 1px solid #eaecf0;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 47px;
  outline: none;
  padding-left: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
}
.subscriberDrop .subscriberLock{
  pointer-events: none;
  opacity: 0.7;
}
.newpageRight > .subscriberDrop {
  overflow: unset !important;
}
.newsletter-lock .lockicon{
  width: 15px;
}
.planLayout {
  display: flex;
}
.css-1wkmffb-IndicatorsContainer,.css-11iz7oq-singleValue{
  margin-top: 7px !important;
}
.newPublication .newpageImageUpload .featureImgMiddleContent,
.newPublication .newpageImageUpload div .newpage_CoverImg,
.newNewsletterPageContent .newpageImageUpload .featureImgMiddleContent,
.newNewsletterPageContent .newpageImageUpload div .newpage_CoverImg,
.newpageContentImage .newpageImageUpload .featureImgMiddleContent,
.newpageContentImage .newpageImageUpload div .newpage_CoverImg {
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-width: 100%;
}
.publicationSuccessModal{
  max-height: 624px;
  height: 100%;
  overflow: auto;
}
.pageSuccessModal{
  max-height: 533px;
  height: 100%;
  overflow: auto;
}
.previewModal {
  width: 90%;
  max-width: 426px !important;
  padding: 24px !important;
  height: calc(100dvh - 40px);
}
.previewModal .preview-title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: #1C1C1C;
  margin-top: 0;
  margin-bottom: 4px;
}

.previewModal .preview-description {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #475467;
  margin-bottom: 18px;
}

.previewModal .preview-description b {
  font-weight: 600;
  color: #1C1C1C;
}

.previewModal .preview-linkContent label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  margin-bottom: 6px;
}

.previewModal .preview-linkBox {
  display: flex;
  align-items: center;
  margin-top: 6px;
  gap: 16px;
}

.preview-linkContent {
  position: relative;
}

.previewModal .preview-linkBox input {
  font-size: 14px;
  color: #425466;
  line-height: 24px;
  border-radius: 6px;
  background-color: #ffffff;
  outline: none;
  width: 100%;
  border: 1px solid #d0d5dd;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  height: 46px;
  padding: 10px 15px;
}

.previewModal .preview-linkBox svg {
  cursor: pointer;
}

.previewModal .previewImage {
  width: 100%;
  min-width: 200px;
  line-height: 0;
  margin-bottom: 10px;
}

.previewModal .previewImage img {
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.previewModal .preview-button-wrap {
  margin-top: 23px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.closeNewsletter{
  width: 100% !important;
}
.previewClose{
  float: right;
  position: relative;
  top: -5px;
  right: -5px;
}
.preview-linkContent .text-tooltip {
  position: absolute;
  font-size: 11px;
  background: #fff;
  color: #344054;
  padding: 5px 6px;
  border-radius: 6px;
  font-weight: 500;
  margin-top: 40px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border: 1px solid #f2f4f7;
  top: 25px;
  right: -16px;
}
.preview-linkContent .text-tooltip:after {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 17px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #f2f4f7 transparent !important;
  left: unset !important;
  color: #344054;
  font-weight: 500;
  font-size: 11px;
}
.shareIcons {
  display: flex;
  align-items: center;
}
.shareIcons img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  margin-top: 8px;
  margin-right: 8px;
  cursor: pointer;
}
.preview-share{
  margin-top: 10px;
}
body:has(.preview-loader ) .content-wrapper{
  padding: 0;
  margin: 0;
}
body:has(.preview-error ) .sidebar,body:has(.preview-error ) .main-footer,body:has(.preview-loader ) .main-footer{
  display: none;
}
body:has(.preview-error ) .main-content,body:has(.preview-loader ) .main-content {
  width: 100%;
  left: 0;
  transition: unset !important;
}
.selectListOption .timeZoneLabel {
  color: #808080d9;
  padding: 10px 20px 0;
  font-size: 12px;
  font-weight: 500;
}
.selectListOption .selectOption {
  box-shadow: none;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  color: #111827;
  padding-top: 10px;
  cursor: pointer;
  padding-bottom: 10px;
  background: #fff;
}
.socialLinkSelect .socialSelectOptions p {
  margin-bottom: 0;
  line-height: 24px;
}
.socialLinkSelect img {
  margin-left: 0px !important;
}
.socialLinkSelect .selectOption img {
  bottom: 0;
}
.editor-error {
  max-width: 740px;
  margin: auto;
}
.newNewsletterPageContent .editor-error {
  padding: 0 20px;
  margin-top: 5px;
}
.newNewsletterPageContent .newpageRightButtons button:first-child {
  border-radius: 6px;
}
.editor-error-space {
  padding: 0 20px;
}
.main-content .stickyBar {
  width: calc(100% - 312px);
}
.main-content .fullStickyBar {
  width: calc(100% - 81px);
}
.dataTable-list .rdt_TableHeadRow .rdt_TableCol .__rdt_custom_sort_icon__ {
  background-image: url(./images/down-arrow.svg);
  width: 12px;
  height: 12px;
  margin-left: 5px;
  background-repeat: no-repeat;
  background-position: center;
}
.dataTable-list .rdt_TableHeadRow .rdt_TableCol .desc.__rdt_custom_sort_icon__ {
  transform: rotate(180deg);
}

.dataTable-list{
  background: #ffffff;
  border: 1px solid #eaecf0;
  width: 100%;
  margin-top: 20px;
  border-radius: 6px;
  position: relative;
}
.dataTable-list input[type="checkbox"]:before{
  margin-right: 15px;
}
.dataTable-list .rdt_TableHeadRow .rdt_TableCol {
  padding: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: flex-start;
}
.dataTable-list .rdt_TableHeadRow div[data-column-id] {
  font-weight: 500;
  color: #4e575c;
  font-size: 14px;
}
.dataTable-list > div:first-child {
  padding-bottom: 80px;
  margin-bottom: -80px;
}
.dataTable-list > div:last-child {
  position: relative;
}
.pagesTable .rdt_TableHeadRow div[data-column-id]:hover {
  opacity: 1;
}
.pagesTable .rdt_TableHeadRow .rdt_TableCol input[type="checkbox"] {
  margin: 0;
}
.pagesTable .rdt_TableBody .rdt_TableRow .rdt_TableCell input[type="checkbox"] {
  margin-bottom: 0;
}
.dataTable-list .user-status a.user-link, .user-status {
  align-items: center;
  display: flex;
  gap: 12px;
}
.dataTable-list .rdt_TableHeadRow .rdt_TableCol:last-child, .dataTable-list .rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child {
  justify-content: flex-end;
  padding-right: 16px;
}
.dataTable-list .table-action {
  padding-right: 0;
}
.pagesTable.loading-list {
  height: 395px;
  overflow: hidden;
}
.pagesTable.loading-list:before {
  content: "";
  background: #fff;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}
.pagesTable.loading-list:after {
  position: absolute;
  content: "";
  border: 3px solid #f3f3f3;
  border-top: 3px solid rgb(100 116 139);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: rotate 1s linear infinite;
  z-index: 2;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
}
.homepage-content h2, .contactpage-content h2 {
  color: #0a2540;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  margin: 0 0 15px;
}
.newpageLeftRow.homepage-content p.linkDataDesc, .contactpage-content > p {
  line-height: 24px;
}
.newpageLeftRow.homepage-content p.linkDataDesc, .contactpage-content > p {
  margin-bottom: 18px;
  color: #425466;
  font-weight: 400;
  line-height: 25.2px;
  word-wrap: break-word;
}
.newpageCard.homepageCard .linkDataDiscripation {
  padding: 12px 14px;
}
.home-publication .newpageRight .newpageMoreButtons, .contact-publication .newpageRight .newpageMoreButtons {
  display: none;
}
.contactpageCard .contact-page-text, .contactpageCard .publicAddress.contact-page-text, .contactpageCard .contact-inputBlock input  {
  color: #101828;
  border: 1px solid #d0d5dd;
}
.contactpageCard > .contactpageWrap {
  display: flex;
  flex-wrap: wrap;
}
.contactpageCard .publicMailInputbox {
  padding: 10px 12px 12px 40px;
  font-size: 14px;
  margin: 0 0 10px 0 !important;
}
.contactpageWrap .publicinputBox {
  margin-bottom: 5px !important;
  color: #101828;
  border: 1px solid #d0d5dd;
  font-size: 14px;
}
.display-form-group .display-form-item:last-child {
  margin-bottom: 0;
}

.display-form-group .display-form-item.paid-plan-form {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 22px;
}
.display-form-group .display-form-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
  position: relative;
  margin-bottom: 0;
}
.display-form-group .display-form-item {
  border-radius: 6px;
  border: 1px solid #eaecf0;
  margin-bottom: 22px;
}
.display-form-group .display-form-item span {
  display: block;
  font-weight: 400;
}
.display-form-group .display-form-item .more-contact-detail {
  padding: 0;
}
.paid-user-detailBox {
  width: 100%;
  margin-top: 10px;
}
.display-form-group .display-form-item .contentDrop .newpageLeftRow {
  margin-top: 15px;
  width: 50%;
  margin-bottom: 5px;
}
.contactpage-content .newpageLeftRow .validateError {
  margin-top: 0;
  margin-bottom: 0;
}
.categoriespage-list div[data-column-id = '2'], .categoriespage-list div[data-column-id = '3'], .categoriespage-list div[data-column-id = '4'] {
  justify-content: flex-end;
  padding-right: 0;
}
.rdt_TableBody{
  padding: 0;
  margin: 0;
}
.media-list .dataTable-list .rdt_TableRow:has(.loading-row) {
  position: relative;
}
.media-list .dataTable-list .rdt_TableRow:has(.loading-row) * {
  opacity: 0;
}
.media-list .dataTable-list .rdt_TableRow:has(.loading-row):before {
  content: "";
  position: absolute;
  height: 7px;
  width: 90%;
  left: 50%;
  top: 50%;
  background: var(--white);
  border: 1px solid rgb(246, 246, 250);
  border-radius: 15px;
  transform: translateX(-50%) translateY(-50%);
}
.media-list .dataTable-list .rdt_TableRow:has(.loading-row):after {
  position: absolute;
  background-color: #573CFF;
  width: 0px;
  height: 6px;
  border-radius: 15px;
  animation: progressbar 4s infinite linear;
  content: "";
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
  max-width: 90%;
}
.emailLink {
  color: #101828 !important;
  font-weight: 500;
}
.verifyStatus {
  border-radius: 6px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}
.pageFreePlanLabel {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  padding: 5px 10px;
  background: transparent;
  color: #344054;
  border: 1px solid #475467;
}
.pagePaidPlanLabel {
  border: 1px solid #079455;
  color: #067647;
  padding: 5px 10px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}
.pagePaidPlanLabel.month {
  border: 1px solid #3E4784;
  color: #363F72;
  padding: 5px 10px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}
.pagePaidPlanLabel.year {
  border: 1px solid #DC6803;
  color: #B54708;
  padding: 5px 10px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}
.verifySubscribe {
  color: #067647;
  border: 1px solid #079455;
}
.verifyDelete {
  color: #b42318;
  border: 1px solid #eb5757;
}
.verifyPending {
  color: #B54708;
  border: 1px solid #DC6803;
}
.verifyTrial {
  color: #026AA2;
  border: 1px solid #0086C9;
}
@keyframes rotate {
  100% {
      transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
      clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
      clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          100% 100%,
          100% 100%
      );
  }

  75% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  100% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

@media screen and (min-width: 1025px) {
  .uploadCard:hover .uploadContent {
    opacity: 1;
    visibility: visible;
  }
}
@media screen and (max-width: 1400px) {
  .profile-tabs .profile-tab-item {
      margin-right: 15px;
  }
  .main-content .content-wrapper {
      padding: 22px;
  }
  .pagesNew .pageSearchTop .pageSearchInput {
      margin-bottom: 0;
      width: calc(50% - 15px);
  }
  .pagesNew .pageSearchTop {
      width: 100%;
      margin-bottom: 15px;
  }
  .pagesNew .actions-buttons {
      width: 100%;
  }
  .pagesSearch {
      align-items: flex-start;
  }
  .twoItemWrap {
    align-items: center;
  }
}

@media screen and (max-width: 1260px) {
  .newpageLeft {
    min-height: 300px;
  }
  .newpageContent {
      display: block;
  }
  .newpageContent .newpageLeft {
      margin-right: 0;
      width: 100%;
      padding: 22px;
      max-height: unset !important;
  }
  .newpageRight {
      border-left: 0;
      border-top: 1px solid #e6e6e6;
      overflow-x: hidden;
      width: 100%;
      max-height: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 5px 0 0;
  }
  .buttonsDesktop {
      display: none;
  }
  .newpageRight > .newPageRightRow,
  .new-page .newPageRight.desktopFeatures,
  .new-page .buttonsMobile {
      padding: 22px;
  }
  .commonPage {
      padding: 0;
  }
  .newPageRight.desktopFeatures {
      padding: 20px 0;
  }
  .buttonsMobile {
      display: block;
      padding: 22px;
  }

  .textBlock .ck.ck-editor__editable_inline {
      padding: 0;
  }
  .file-wrapper .modalInput {
      width: calc(100% - 100px);
  }
}

@media screen and (max-width:1200px) {
  .uploadItem {
    width: calc(25% - 20px);
}
}

@media screen and (max-width: 1024px) {
  .main-content .stickyBar,.main-content .fullStickyBar{
    width: 100%;
  }
  .main-content:has(.popupContainer) .sidebar {
    z-index: 0;
  }
  .main-wrapper:has(.popupModalOverlay) .sidebar::before,.main-wrapper:has(.overlayModal) .sidebar::before,.main-wrapper:has(.profileOverlayModal) .sidebar::before, .main-wrapper:has(.welcomeOverlay) .sidebar::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    right: 0;
    z-index: 99;
  }
  .main-wrapper:has(.popupOverlay) .sidebar{
    z-index: 0;
  }
  .main-content .content-wrapper{
    margin: 67px 0;
  }
  .main-wrapper .main-content {
      left: 0;
      position: relative;
      transition: all 799ms cubic-bezier(0.74, 0.215, 0.125, 1.02);
      transition-delay: 0.06s;
      transition-duration: 0.7s;
      transition-timing-function: cubic-bezier(0.74, 0.215, 0.125, 1.02);
      width: 100%;
  }
  .formControlFields .select-country {
      display: block;
      margin-left: 0;
  }
  .pageTeam .pageSearchInput {
      max-width: 300px;
  }
  .addAuthor,
  .newPageRightFullUrl.slug-box,
  .pubTypeSelect {
      margin-bottom: 20px;
  }
  .img-captions {
      margin-bottom: 0;
  }
  .newpageRight > .newPageRightRow.buttonsMobile {
      border-bottom: none;
      overflow: visible;
      border-top: 1px solid #e6e6e6;
  }
  .pagesNew .pageSearchTop {
      margin-bottom: 0;
  }
  .previewTooltipblock .text-tooltip {
    width: max-content;
    right: -199px;
    top: -9px;
  }
  .previewTooltipblock .text-tooltip:after {
      left: 20px;
      right: unset;
  }
}

@media screen and (max-width: 992px) {
  .pageSearchTop .pageSearchInput {
      max-width: 100%;
      margin-bottom: 15px;
  }
  .pageSearchTop .select-country,
  .pageSearchTop .userrole-dropdown {
      width: 50%;
      display: inline-block;
      vertical-align: top;
      position: relative;
  }
  .pageSearchTop .select-country,
  .pageSearchTop .category-dropdown {
      margin: 0;
      padding-left: 7.5px;
  }
  .pageSearchTop .userrole-dropdown {
      padding-right: 7.5px;
  }
  .pageUser .pageSearchTop .select-country,
  .pageTeam .pageSearchTop .select-country {
      padding-left: 0;
      padding-right: 7.5px;
  }
  .pageUser .pageSearchTop .userrole-dropdown,
  .pageTeam .pageSearchTop .userrole-dropdown {
      padding-left: 7.5px;
      padding-right: 0;
  }
  .pageTeam .pageSearchTop .pageSearchInput,
  .pageTeam .pageSearchTop .pageSearchInput input,
  .pageSearchInput.mobile-team-searchbar,
  .pageSearchInput.mobile-team-searchbar input {
    width: 100%;
  }
  .pageTeam .pageSearchInput.desktop-searchbar {
    display: none;
  }
  .pageSearchInput.mobile-team-searchbar {
    display: block;
    margin-bottom: 10px;
  }
  .pagesSearch.pageTeam {
      align-items: flex-end;
  }
  .pageTeam .pageSearchTop {
      gap: 0;
  }
  .UserDetails .pageTeam .pageSearchTop {
      gap: 15px;
  }
  .pagesSearch.pageUser {
      align-items: flex-end;
  }
  .pageUser .pageSearchTop {
      gap: 0;
      max-width: calc(100% - 30px);
  }
  .pageSearchTop .userrole-dropdown .addDropdown {
      width: calc(100% - 7.5px);
  }
  .pageSearchInput input {
      right: 0px;
      width: calc(100% - 20px);
  }
  .modalPopup .formContent .formURL {
      width: calc(100% - 80px);
  }
  .uploadMediaPopup .file-wrapper {
      width: 100%;
      border-left: none;
      border-top: 1px solid #eaecf0;
      height: auto;
  }
  .pageSearchInput input {
      right: 0px;
      width: calc(100% - 20px);
  }
}

@media screen and (max-width: 767px) {
  .modalPopup .popupBlock:after {
    display: none;
  }
  .main-content .content-wrapper{
    margin: 67px 0 99px;
  }
  .previewTooltipblock .text-tooltip:after {
    right: 20px;
    left: unset;
  }
  .previewTooltipblock .text-tooltip {
    width: max-content;
    left: 20px;
    top: -3px;
  }
  .main-content .content-wrapper {
      min-height: calc(100vh - 195px);
  }
  .main-content .content-wrapper {
      padding: 20px 22px;
  }
  .pageTeam .pageSearchTop {
      width: 100%;
  }
  .pageTeam .pageSearchTop .pageSearchInput input {
    width: 100%;
  }
  .dashboard-top-left {
      margin-bottom: 15px;
  }
  .pagesNew .pageSearchTop {
      width: 100%;
      margin-bottom: 15px;
      flex-direction: column;
  }
  .pageSearchTop .userrole-dropdown {
      width: 100%;
      padding-right: 0;
  }
  .pagesNew .pageSearchTop .pageSearchInput {
      margin-bottom: 0;
      width: 100%;
  }
  .pageSearchTop .category-dropdown {
      padding-top: 0;
      padding-left: 0;
  }
  .pagesSearch {
      flex-wrap: wrap;
  }
  .twoItemWrap {
    flex-wrap: nowrap;
  }
  .save-change {
      margin-top: 20px;
  }
  .pageMedia .actions-buttons,
  .pageMedia .pageSearchTop {
      width: 100%;
  }
  .pageMedia .actions-buttons {
      margin-top: 15px;
  }
  .pageSearchTop .userrole-dropdown .addDropdown {
      width: calc(100% - 7.5px);
  }
  .pageSearchTop .select-country {
      width: 100%;
      padding-left: 0;
      margin-top: 15px;
  }
  .pageSearchTop .category-dropdown {
      padding-left: 0;
      width: 100%;
  }
  .pageUser .pageSearchTop {
      width: 100%;
      max-width: 100%;
  }
  .pageUser .pageSearchTop .select-country,
  .pageTeam .pageSearchTop .select-country {
      width: 50%;
      padding-left: 0;
      margin-top: 0;
  }
  .pageUser .pageSearchTop .userrole-dropdown,
  .pageTeam .pageSearchTop .userrole-dropdown {
      width: 50%;
      padding-right: 0;
  }
  .userrole-mobiledropdown{
    width: 100% !important;
    padding-left: 0 !important;
  }
  .pageUser .deleteIcon,
  .pageTeam .actions-buttons {
      width: 100%;
      margin-top: 15px;
  }
  .publication-Link .newpageLeft.publicationLeft,
  .publication-Video .newpageLeft.publicationLeft {
    width: 100%;
  }
  .publication-Link .newpageLeft.publicationLeft .breadcrumbs,
  .publication-Video .newpageLeft.publicationLeft .breadcrumbs,
  .new-page .newpageLeft.publicationLeft .breadcrumbs {
    display: none;
  }
  .uploadItem {
    width: calc(33.33% - 20px);
  }
  .modalPopup .popupBlock .popupBlock-wrapper .mediaGallery {
    display: block;
  }
  .modalPopup .file {
    width: 100%;
    border-right: none;
  }
  .modalPopup .modalContent {
      min-height: 360px;
  }
  .modalPopup .preview {
      width: 100%;
  }
  .modalPopup .mediaGallery .popupClose {
    position: absolute;
    top: 17px;
    right: 20px;
    display: block;
    margin-top: 0;
  }
  .modalPopup .modalActions.desktop-view {
    display: none;
  }
  .modalPopup .modalActions.mobile-view {
    display: flex;
  }
  .modalPopup .modalActions.mobile-view .cancelButton {
    min-height: 42px;
    padding: unset;
  }
  .categoriesPage .pageTeam .pageSearchTop .userrole-dropdown {
      width: 100%;
      padding-left: 0;
  }
  .newPublication .newpageRight {
      overflow: visible;
  }
  .schedule-time-selector {
      gap: 0;
  }
  .schedule-time-selector .verticle-dots {
      margin: 0 0.5ch;
  }
  .newpageRight .newPageRightFullUrl.slug-box,
  .newpageRight > .newPageRightRow .addAuthor,
  .newpageRight > .newPageRightRow .pubTypeSelect {
      margin-bottom: 0;
  }
  .UserDetails .pageTeam .pageSearchTop .userrole-dropdown {
      padding-left: 0;
  }
  .UserDetails .pageTeam .pageSearchTop .userrole-dropdown.category-dropdown {
      padding-left: 15px;
  }
  .pageSearchTopMobile{
    flex-direction: column !important;
  }
  .UserDetails .pageTeam .pageSearchTop {
      gap: 10px;
  }
  .UserDetails .pageTeam .pageSearchTop .userrole-dropdown {
      margin-top: 0;
  }
  .UserDetails .pagesSearch.pageTeam {
      justify-content: flex-end;
  }
  .modalPopup .popupBlock .popupBlock-wrapper {
      height: calc(100dvh - 40px);
  }
  .gridIitem {
      grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  }
  .pagesTableWrapper .pagesSearch {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-wrap: nowrap;
      align-items: flex-start;
  }
  .modalActions .mobile-view {
      display: none;
  }
  .pagesSearch {
      display: flex;
      justify-content: space-between;
      width: 100%;
  }
  .pageSearchInput {
      width: 100%;
      position: relative;
  }
  .uploadMediaPopup .grid-container {
    padding: 15px;
    height: auto;
  }
  .video-box-group .video-box-item .contentDrop {
    padding: 0 20px;
  }
  .dataTable-list .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child{
    min-width: unset;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: flex-start;
  }
  .dataTable-list .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child input{
    margin-left: 0; 
  }
  .dataTable-list .rdt_TableHeadRow .rdt_TableCol:last-child,
  .dataTable-list .rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child {
      justify-content: flex-end;
      position: relative;
      right: 16px;
      padding-right: 0;
      /* min-width: 116px; */

  }
  _::-webkit-full-page-media,
  _:future,
  :root .dataTable-list .rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child {
      right: 32px;
      padding-right: 0;
  }
  _::-webkit-full-page-media,
  _:future,
  :root .dataTable-list .rdt_TableHeadRow .rdt_TableCol:last-child {
      right: 32px;
      padding-right: 0;
  }
  _::-webkit-full-page-media,
  _:future,
  :root .subscriber-list .dataTable-list .rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child {
      right: 16px !important;
      padding-right: 0;
  }
  _::-webkit-full-page-media,
  _:future,
  :root .subscriber-list .dataTable-list .rdt_TableHeadRow .rdt_TableCol:last-child {
      right: 16px;
      padding-right: 0 !important;
  }
  /* :root .addDropdown li p{
    margin-left: 6px;
  } */

  .dataTable-list > div:first-child {
      overflow: auto;
      scrollbar-width: none; /* Hides scrollbar in Firefox */
      -ms-overflow-style: none; /* IE and Edge */
  }
  
  .dataTable-list > div:first-child::-webkit-scrollbar {
      display: none; /* Hides scrollbar in Chrome, Safari */
  }

  .dataTable-list > div:first-child {
      scrollbar-width: none; /* Hides scrollbar in Firefox */
  }

  .dataTable-list > div:first-child {
      -ms-overflow-style: none; /* Hides scrollbar in IE and Edge */
  }
}
@supports (-webkit-backdrop-filter: blur(0)) {
  :root .addDropdown li p,
  :root .pageUser .select-country .countryDropdown .countrySelect::before,
  :root .pageTeam .select-country .countryDropdown .countrySelect::before {
    margin-left: 6px;
  }
}

@media screen and (max-width: 480px) {
  .pageUser .pageSearchTop,
  .pageUser .pageSearchTop .deleteIcon {
      width: 100%;
  }
  .pagesSearch.pageUser {
      flex-direction: column;
  }
  .pagesSearch.pageUser .deleteIcon {
      margin-top: 15px;
  }
  .formControlSelect {
      flex-wrap: wrap;
  }
  .formControlSelect .formControlFields {
      width: calc(100% - 20px);
  }
  .formControlSelect .formControlFields:first-child {
      margin-bottom: 10px;
  }
  .profilePictureItems .avatarBlock .newPageImageUploadText br {
      display: none;
  }
  .uploadItem {
    width: calc(50% - 15px);
    margin: 0 7.5px 15px;
  }
  .pageUser .pageSearchTop .userrole-dropdown,
  .pageUser .pageSearchTop .select-country,
  .pageTeam .pageSearchTop .userrole-dropdown,
  .pageTeam .pageSearchTop .select-country {
      width: 100%;
      padding-right: 0;
      padding-left: 0;
  }
  .pageUser .pageSearchTop .userrole-dropdown,
  .pageTeam .pageSearchTop .userrole-dropdown {
      margin-top: 15px;
  }
  .UserDetails .pageTeam .pageSearchTop .userrole-dropdown.category-dropdown {
      padding-left: 0;
      margin-top: 15px;
  }
  .UserDetails .pageTeam .pageSearchTop .userrole-dropdown {
      margin-top: 15px;
  }
  .pageSearchTop .userrole-dropdown .addDropdown {
      width: 100%;
  }
  .contactpageWrap.formControlInputs {
      flex-direction: column;
  }
  .contactpageWrap.formControlInputs .formControlFields {
      width: calc(100% - 20px);
  }
  .contactpageWrap.formControlInputs .formControlFields + .formControlFields {
      margin-top: 5px;
      margin-bottom: 10px;
  }
  .pagesSearch {
      gap: 10px;
  }
  .pageMedia .pagesSearchIcon {
      justify-content: flex-end;
      width: 100%;
  }
  .pageSearchInput input {
      width: 100%;
  }
  .pageMedia .pageSearchTop {
      justify-content: space-between;
  }
  .modalPopup .modalImg.type-doc {
      height: calc(250px - 55px);
  }
  .modalPopup .modalActions.desktop-view {
      display: none;
  }
  .modalActions .mobile-view {
      display: block;
  }
  .modalPopup .cancelButton {
      padding: 12px 20px;
  }
  .dropdown-wrapp button {
      min-width: 120px;
  }
  .rowChangeImage,.rowChangeCover,.themeRow{
    flex-direction: column;
  }
  .generalBlockItems{
    padding-left: 0;
  }
  .generalBlockItems{
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .theme{
    margin-top: 0px;
  }
  .publication-Link .linkBox {
    gap: 15px;
  }
  .publication-Link .linkBlockPreview .textBlock.linkBox {
    flex-direction: column;
    align-items: flex-start;
  }
  .publication-Link .linkBlockPreview .linkImageSection,
  .publication-Link .linkBlockPreview .linkImageSection img {
    width: 100% !important;
  }
  .publication-Link .linkBlockPreview .linkImageSection img {
    height: auto;
  }
  .publication-Link .linkBox .linkData {
    padding-left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 360px) {
  .pageSearchTop .userrole-dropdown {
      width: 100%;
      padding-right: 0;
  }
  .pageSearchTop .select-country {
      width: 100%;
      padding-left: 0;
  }
  .dropdown-wrapp button {
      min-width: 102px;
  }
  .select-file .selectFields,
  .mediadate-dropdown .selectFields {
      padding: 10px 11px;
  }
  
}

.activeClose:has(.popupChange) .closePlan {
  display: none;
}
@media screen and (max-width: 991px) {
  .infoList-analytics .infoReferrersBlock > div:nth-child(1) {
      min-width: 220px;
  }

  .registertion-step-2 .register-right {
      display: none;
  }
  .uploadMediaPopup
      .grid-container.show-wrapper
      .register-wrapp
      .register-right {
      display: none;
  }

  .uploadMediaPopup .grid-container.show-wrapper {
      width: 100%;
  }

  .registertion-step-2 .register {
      width: 100%;
  }

  .register-wrapp .register {
      width: 100%;
  }

  .register .step {
      margin: 0 auto;
      padding-left: 20px;
      padding-right: 20px;
  }

  .register .step.done {
      padding-top: 20px;
  }

  .registertion-step-2 .register .step,
  .registertion-step-3 .register .step,
  .registertion-step-4 .register .step,
  .registertion-step-5 .register .step,
  .registertion-step-6 .register .step {
      min-height: unset;
      height: auto;
  }

  .registertion-step-2 .register .step,
  .registertion-step-3 .register .step,
  .registertion-step-4 .register .step,
  .registertion-step-5 .register .step,
  .registertion-step-6 .register .step {
      padding-top: 32px;
      padding-bottom: 32px;
      margin-top: 90px;
  }

  .themeLayout .theme .themetype {
      min-width: unset;
  }

  .registertion-step-2 .step h1.step_title,
  .registertion-step-2 .step h1.step_title,
  .registertion-step-3 .step h1.step_title,
  .registertion-step-4 .step h1.step_title,
  .registertion-step-5 .step h1.step_title,
  .registertion-step-6 .step h1.step_title {
      margin-top: 0;
  }

  .marquee:nth-child(1),
  .marquee:nth-child(2),
  .marquee:nth-child(3),
  .marquee:nth-child(4) {
      display: block;
  }

  .marquee,
  .marquee:nth-child(5) {
      display: none;
  }

  .marquee {
      width: calc(25% - 15px);
  }

  .modalPopup .modalHeader {
      padding: 20px 24px;
  }

  .modalPopup .file .modalHeader {
      border-top: none;
      padding-right: 35px;
  }

  .modalPopup .modalContent {
      padding: 15px 24px 6px 24px;
  }

  .modalPopup .contentrow {
      padding: 0 0 15px 0;
  }

  .modalPopup .modalActions {
      padding: 24px 24px 19px;
  }

  .modalPopup .modalImg {
      height: calc(569px - 61px);
  }

  .modalActions input {
      padding: 12px 20px;
  }

  .modalPopup .formContent .formURL,
  .modalPopup .modalInput {
      width: calc(100% - 80px);
  }

  .modalPopup .formContent {
      gap: 10px;
  }

  .popupBlockPlan:has(.popupChange),
  .popupBlock,
  .popupContainer .popupAddFactor {
      width: calc(100% - 30px);
  }

  .uploadMediaPopup .popupBlock {
      width: calc(100dvw - 30px);
      max-width: calc(100dvw - 30px);
  }

  .popupBlockPlan:has(.popupChange) .popupBlock-wrapper {
      flex-direction: column;
  }

  .popupBlockPlan:has(.popupChange) .popupBlock-inner,
  .popupBlockPlan .popupChange {
      width: 100%;
  }

  .popupBlock-wrapper {
      padding: 15px;
      margin: 5px;
  }
  .modalPopup .popupBlock-wrapper {
    padding: 0;
    margin: 0;
  }
  
  .popupBlockPlan .popupBlock-wrapper {
    margin-bottom: 15px;
  }
  
  .popupAddFactor .popupBlock-wrapper {
    margin: 0;
    padding: 0;
  }
  .settingsContent .popupBlock-wrapper {
      margin-bottom: 0px;
  }

  .popupBlockPlan:has(.popupChange) .popupBlock-wrapper {
      margin-bottom: 0;
  }

  .popupBlockPlan .popupChange {
      padding-top: 20px;
  }

  .closePayment {
      display: none;
  }

  .activeClose:has(.popupChange) .closePlan {
      display: block;
  }

  .modalPopup .popupBlock .popupBlock-wrapper .mediaGallery {
      height: 100%;
      margin: 0;
  }

  .uploadMediaPopup .grid-container {
      width: 100%;
      display: block;
      padding: 15px;
      max-height: calc(100dvh - 216px);
  }

  .uploadMediaPopup .file-wrapper {
      width: 100%;
      border-left: none;
      border-top: 1px solid #eaecf0;
      height: auto;
  }

  .uploadMediaPopup .file-wrapper .file {
      display: none;
  }

  .file-wrapper .modalActions {
      padding: 15px 15px 14px;
  }

  .uploadMediaPopup .popupOverlay {
      background-color: rgba(255, 255, 255, 1);
  }

  .uploadMediaPopup .popupBlock .popupBlock-wrapper .popupBlock-inner {
      height: auto;
      border: 1px solid #eaecf0;
      border-radius: 8px;
  }

  .uploadMediaPopup .fileUploadBox .uploadFileBlock {
      width: 100%;
      border: none;
      box-shadow: none;
      height: calc(100dvh - 175px);
  }
  .file-wrapper .modalActionsForMobile {
      text-align: center;
      margin-top: 0;
      border-top: 1px solid #eaecf0;
      padding: 24px 24px 19px;
      display: flex;
      justify-content: space-between;
      gap: 20px;
  }
  .file-wrapper .modalActionsForMobile .saveButton {
      background: #1c1c1c;
      border: 1px solid #1c1c1c;
      box-shadow: 0 1px 2px 0 #1018280d;
      color: #fff;
      font-size: 14px;
      width: 50%;
      border-radius: 6px;
  }
  .modalActionsForMobile .saveButton:disabled {
      opacity: 0.6;
  }
}
@media screen and (max-width: 767px) {
  .uploadMediaPopup .fileUploadBox .uploadFileBlock {
    height: auto;
    min-height: 361px;
  }
  .buttonHideShow .modalActions{
    display: none;
  }
  .modalPopup .popupBlock .popupBlock-wrapper .mediaGallery {
    display: block;
  }
  .modalPopup .modalImg {
    height: auto;
    padding: 0;
    margin: 24px;
  }
  .display-form-group .display-form-item .contentDrop .newpageLeftRow{
    width: 100%;
  }
}
@media screen and (max-width: 430px){
  .twoFactorContent .twoFactorRow {
    flex-direction: column;
  }
  .twoFactorContent .factorRowCheckbox {
    margin-top: 20px;
    padding-left: 0;
  }

  .popupAddFactor .popupBlock-wrapper{
    margin-bottom: 0;
    padding-right: 0;
  }
  .popupAddFactor .popupTopClose{
    top: 3px;
    right: 4px;
  }
  .previewPopupButton{
    flex-direction: column;
  }
  .previewPopupButton button{
      width: 100%;
  }
  .welcomeModal.mediaDeleteModal {
    width: 90%;
  }
  .publication-list .pagesTableWrapper .pagesSearch, 
  .page-list .pagesTableWrapper .pagesSearch,
  .newsletter-list .pagesTableWrapper .pagesSearch,
  .categoriesPage .pagesTableWrapper .pagesSearch,
  .mediaGallery .pagesTableWrapper .pagesSearch {
    flex-direction: column;
  }
  .mediaGallery .pageSearchTop .dropdown-wrapp {
    width: calc(50% - 5px);
  }
  .mediaGallery .pageSearchTop .dropdown-wrapp button {
    width: 100%;
    justify-content: space-between;
  }
  .UserDetails .publication-list .pagesTableWrapper .pageSearchTop .userrole-dropdown,
  .UserDetails .newsletter-list .pagesTableWrapper .pageSearchTop .userrole-dropdown,
  .UserDetails .categoriesPage .pagesTableWrapper .pageSearchTop .userrole-dropdown,
  .UserDetails .subscriber-list .pagesTableWrapper .pageSearchTop .userrole-dropdown {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (max-width:400px) {
  .buttonsMobileView .newpageRightButtons .publish-arrow,
  .buttonsMobileView .newpageRightButtons svg,
  .buttonsMobile .newpageRightButtons .publish-arrow,
  .buttonsMobile .newpageRightButtons svg,
  .buttonsMobile .newpageRightButtons .publish-arrow {
    display: none;
  }
}

@media screen and (max-width: 360px) {
  .gridIitem {
      grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  }
}

@keyframes loading {
  0% {
      opacity: 0.6;
  }

  50% {
      opacity: 1;
  }

  100% {
      opacity: 0.6;
  }
}

@keyframes progressbar {
  0% {
      width: 0%;
  }

  25% {
      width: 50%;
  }

  50% {
      width: 75%;
  }

  75% {
      width: 85%;
  }

  100% {
      width: 100%;
  }
};
