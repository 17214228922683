.preview-newsletter .preview-newsletter__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 16px;
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 9999;
}
.preview-newsletter .preview-newsletter__header p {
    color: #344054;
    font-weight: 500;
    margin-right: 8px !important;
    font-size: 14px;
}
.preview-newsletter .newsletter-buttons {
    display: flex;
    align-items: center;
    gap: 0 10px;
}
.preview-newsletter .publish-loader .ring-loader {
    top: 4px !important;
}
.preview-newsletter .newpageRightButtonsSend button {
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: 500 !important;
    border: 1px solid #333741  !important;
    background-color: transparent !important;
    pointer-events: none;
    color: black !important;
}
.preview-newsletter .newpageRightButtonsPreview {
    align-items: center !important;
    margin-top: 0 !important;
}
.preview-newsletter .newpageRightButtonsPreview button {
    height: 34px;
    min-width: 73px !important;
    font-size: 14px !important;
    line-height: 24px !important;
    font-weight: 500 !important;
    padding: 5px 10px !important;
    min-height: unset;
    margin-right: 0;
}
.preview-newsletter .newpageRightButtonsPreview .updatepreviewbutton-loader {
    background-color: #000 !important;
    bottom: 0 !important;
    min-width: 73px !important;
    border-radius: 4px !important;
    height: 34px !important;
}
.preview-newsletter .newpageRightButtonsPreview .updatepreviewbutton-loader .ring-loader {
   top: 2px !important;
}
.preview-newsletter .preview-edit button {
    padding: 5px 10px;
    gap: 8px;
    background: #fff !important;
    border: 1px solid #333741  !important;
    border-radius: 4px;
    font-weight: 500;
    display: flex;
    align-items: center;
    text-align: center;
    color: #344054;
    cursor: pointer;
    height: 34px;
    font-size: 13px !important;
    line-height: 24px !important;
    width: 92px
}
.newsletter-content .list-item ul,
.newsletter-content .list-item ul li {
    list-style-type: disc !important;
}

.newsletter-content .list-item ol,
.newsletter-content .list-item ol li {
    list-style-type: decimal !important;
}

.preview-newsletter * {
    -ms-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
}
.preview-newsletter a {
    text-decoration: none;
    cursor: pointer;
}
body:has(.preview-newsletter), 
.preview-newsletter table, 
.preview-newsletter td, 
.preview-newsletter a {
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    text-size-adjust: none;
}

.preview-newsletter table,
.preview-newsletter td {
    mso-table-lspace: 0pt;
    mso-table-rspace: 0pt;
}
.preview-newsletter img {
    -ms-interpolation-mode: bicubic;
    display: block;
    height: auto;
    width: 100% !important;  
    margin-left:auto;
    margin-right:auto;
    box-sizing: border-box;
}
body:has(.preview-newsletter) {
    font-family: "SF Pro Display",-apple-system,system-ui,BlinkMacSystemFont,Inter,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    margin: 0;
    padding: 0;
    font-style: normal;
    font-size: 16px;
}
.preview-newsletter .footer-logo {
    max-width: 20px !important;
}
.preview-newsletter p {
    margin: 0 !important;
}
.preview-newsletter .content-cover {
    max-width: 644px;
    width:100%;
    margin:0 auto;
    box-sizing: border-box;
    background-color: #fff;
    margin-top: 50px;
}
.preview-newsletter .desktop {
    display: block !important;
}
.preview-newsletter .mobile {
    display: none !important;
}
.preview-newsletter .content-wrapper {
    background-color: #ffffff;
    padding: 0 0 20px 0;
    display: table;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
}
  
.preview-newsletter .thumbnail-image {
    -ms-interpolation-mode: bicubic;
    height: auto !important;
    width: 100% !important;  
    margin-left:auto !important;
    margin-right:auto !important;
    box-sizing: border-box !important;
    max-width: 640px !important;
    border: none !important;
}
.preview-newsletter .social-icon {
    padding-top: 23.5% !important;
}
.preview-newsletter .social-vimeo-icon {
    padding-top: 21.5% !important;
}

.preview-newsletter .youtube-icon {
    width: 80px !important;
}

.preview-newsletter .newsletter-content p {  
    margin-top: 0;
    margin-bottom: 20px !important;;
    line-height: 27px;
    font-size: 16px;
    font-weight: 400;
    color: #344054;
}

.preview-newsletter .newsletter-content .image-caption,
.preview-newsletter .newsletter-content .video-caption,
.preview-newsletter .newsletter-content .video-caption {
    text-align: left;
    margin-bottom: 0 !important;
    outline: none;
    font-size: 14px;
    color: #344054;
    font-weight: 400;
    line-height: 27px;
    padding: 5px 0;
    margin-top: 0 !important;
    padding-bottom: 0 !important;
    margin: 0 !important;
}
.preview-newsletter .newsletter-content .imageBlock,
.preview-newsletter .newsletter-content .youtube-block,
.preview-newsletter .newsletter-content .product-block,
.preview-newsletter .newsletter-content .vimeo-block{
    padding-bottom: 20px !important;
}
.preview-newsletter.newsletter-content .imageBlock img {
    height: auto;
    width: 100% !important;
    max-width: 100%;
}

.preview-newsletter .newsletter-content .list-item ul,
.preview-newsletter .newsletter-content .list-item ol {
    margin-top: 0 !important;
    margin-bottom: 20px !important;
    margin-left: 0 !important; 
    padding-left: 0 !important; 
    width: 100% !important;
    display: block !important;
    padding-inline-start: 0 !important;
}

.preview-newsletter .newsletter-content .list-item ul li,
.preview-newsletter .newsletter-content .list-item ol li {
    margin-left: 20px !important; 
}

.preview-newsletter .newsletter-content .list-item p {
    margin-bottom: 0 !important;
}

.preview-newsletter .newsletter-content p a {
      color: #3b82f6 ;
}

.preview-newsletter .newsletter-content hr {
    border-width: 0px;
    border-top-width: 1px;
    border-color: #101828;
    margin-bottom: 20px;
}
.preview-newsletter .newsletter-content {
    width: 100% !important;
    max-width: 640px !important;
    padding: 0 20px;
}

.preview-newsletter .newsletter-content h1 {
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 700;
}
.preview-newsletter .newsletter-content h1,
.preview-newsletter .newsletter-content h2,
.preview-newsletter .newsletter-content h3{
    margin-bottom: 20px  !important;
    margin-top: 0 !important;
    text-align: left !important;
    max-width: 640px !important;
}
.preview-newsletter .newsletter-content  h1:first-child,
.preview-newsletter .newsletter-content  h2:first-child,
.preview-newsletter .newsletter-content  h3:first-child{
    margin-top: 0 !important;
}

.preview-newsletter .newsletter-content .table-block td, 
.preview-newsletter .newsletter-content .table-block th {
    position: relative;
    min-width: 100px;
    padding: 0.5rem;
    text-align: left;
    vertical-align: top;          
    border: 1px solid rgba(0, 0, 0, 0.1);
    word-break: break-word;
    overflow-wrap: break-word;
}
.preview-newsletter .newsletter-content .table-block table{
    margin-bottom: 20px !important;
    border-spacing: 0 !important;
    box-sizing: border-box;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    border-radius: 0.25rem;
    border-color: #101828;
    margin-top: 0;
    margin-bottom: 20px;
}
.preview-newsletter .newsletter-content .table-block td p, 
.preview-newsletter .newsletter-content .table-block th p {
    margin: 0px !important;
}
.preview-newsletter .newsletter-content .table-block ul, 
.preview-newsletter .newsletter-content .table-block ol {
    padding-left: 20px;
    margin: 0 !important;
}

.preview-newsletter .newsletter-content td .blockquote-figure,
.preview-newsletter .newsletter-content .blockquote {
    margin: 0 !important;
}
.preview-newsletter .newsletter-content td .blockquote-figure .blockquote p {
    font-weight: 500 !important;
    font-size: 18px;
    margin-bottom: 16px !important;
}
.preview-newsletter .newsletter-content .blockquote-figure > div {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 4px;
    border: 4px solid #101828;
    border-bottom: 0;
    border-right: 0;
    border-top: 0;
    margin-bottom: 20px;
} 
.preview-newsletter .newsletter-content .blockquote-figure figcaption {
    margin-top: 13px;
    overflow: hidden;
    font-size: 16px;
    color: #737373;
}

.preview-newsletter .newsletter-content .bookmark-block p {
    margin-top: 0;
  }
.preview-newsletter .newsletter-content .bookmark-block .linkBox {
    display: block;
    border-radius: 6px;
}
.preview-newsletter .newsletter-content .bookmark-block.link-content {
    padding-bottom: 20px;
}
.preview-newsletter .newsletter-content .bookmark-block.link-content a {
    text-decoration: none !important;
    color: #475467 !important; 
}
.preview-newsletter .newsletter-content .bookmark-block .linkImageSection {
    height: auto;
    width: 90px;
    padding: 15px 0 15px 15px;
    line-height: 0;
}
.preview-newsletter .newsletter-content .bookmark-block .linkImageSection a {
    line-height: 0;
}
.preview-newsletter .newsletter-content .bookmark-block .linkData {
    height: auto;
    width: calc(100% - 90px);
}
.preview-newsletter .newsletter-content .bookmark-block .linkImageSection .previewImage {
    object-fit: cover;
    width: 100%;
    height: 90px;
    border-radius: 0;
    line-height: 0;
    display: inline-block;
}
.preview-newsletter .newsletter-content .bookmark-block .linkDataTitle {
    color: #101828;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 10px;
    margin: 0px 0px 10px 15px !important
}
.preview-newsletter .newsletter-content .bookmark-block .linkDataDiscripation {
    display: none !important;
}
.preview-newsletter .newsletter-content .bookmark-block .siteData {
    display: table !important;
    width: 100% !important;
    margin-top: 15px !important;
    margin-left: 15px !important;
    margin-right: 10px !important;
}
.preview-newsletter .newsletter-content .bookmark-block .siteUrl p{
    line-height: unset !important;
}
.preview-newsletter .newsletter-content .bookmark-block .siteUrl {
    display: block !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    height: 21px !important;
    text-wrap: initial !important;
    margin: 8px 10px 5px 15px !important;
    min-height:21px !important;
    color: #475467 !important;
}
.preview-newsletter .newsletter-content .bookmark-block .siteData img {
    margin-right: 6px !important;
    border-radius: 0 !important;
    object-fit: contain !important;
    width: 18px !important;
    height: 18px !important;
    margin-top:0 !important;
    display: table-cell !important;
    line-height: 0 !important;
}
.preview-newsletter .newsletter-content .bookmark-block .siteData p {
    margin: 0 !important;
    font-size: 14px !important;
    color: #475467 !important;
    font-weight: 400 !important;
    text-wrap: initial; 
    display: table-cell !important;
    width: 100% !important;
    vertical-align: middle;
    line-height: 20px !important;
}
.preview-newsletter .newsletter-content .button-element{
    max-width: 640px !important;
}
.preview-newsletter .newsletter-content .button-element.align-center {
    text-align: center;
}
.preview-newsletter .newsletter-content .button-element.align-left {
    text-align: left;
}
.preview-newsletter .newsletter-content .button-element.align-right {
    text-align: right;
}
.preview-newsletter .newsletter-content .button-element.width-auto button {
    width: auto;
}
.preview-newsletter .newsletter-content .button-element button,
.preview-newsletter .newsletter-content .button-element a {
    background-color: #1c1c1c;
    border: 1px solid #1c1c1c;
    border-radius: 6px;
    color: #ffffff !important;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    padding: 5px 10px;
}
.preview-newsletter .newsletter-content .button-element a {
    margin-bottom: 20px;
}

.preview-newsletter .newsletter-content pre.code-block {
    caret-color: white ;
    color: #f6f6f6 ;
    background: #282c34  ;
    border-radius: 0.375rem ;
    margin: 0 0 20px;
    padding: 1rem ;
    border: 1px solid #D0D5DD ;
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace ;
    line-height: 24px ;
    white-space: pre-wrap ;
    overflow-wrap: break-word ;
}
.preview-newsletter .newsletter-content pre.code-block *::selection {
    background-color: rgba(255, 255, 255, 0.2);
    color: unset ;
}
.preview-newsletter .newsletter-content pre.code-block code {
  color: #f6f6f6 ;
  background-color: #282c34 !important;
  padding: 0;
  box-shadow: none;
}
.preview-newsletter .newsletter-content pre.code-block code *,
.preview-newsletter .newsletter-content pre.code-block code *::selection {
    color: #f6f6f6;
}
.preview-newsletter .newsletter-content pre.code-block code .hljs-comment,
.preview-newsletter .newsletter-content pre.code-block code .hljs-quote,
.preview-newsletter .newsletter-content pre.code-block code .hljs-comment::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-quote::selection {
    color: #a7a7a7;
    font-style: italic;
}
.preview-newsletter .newsletter-content pre.code-block code .hljs-doctag,
.preview-newsletter .newsletter-content pre.code-block code .hljs-keyword,
.preview-newsletter .newsletter-content pre.code-block code .hljs-formula,
.preview-newsletter .newsletter-content pre.code-block code .hljs-doctag::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-keyword::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-formula::selection {
    color: #c678dd;
}
.preview-newsletter .newsletter-content pre.code-block code .hljs-section,
.preview-newsletter .newsletter-content pre.code-block code .hljs-name,
.preview-newsletter .newsletter-content pre.code-block code .hljs-selector-tag,
.preview-newsletter .newsletter-content pre.code-block code .hljs-deletion,
.preview-newsletter .newsletter-content pre.code-block code .hljs-subst,
.preview-newsletter .newsletter-content pre.code-block code .hljs-section::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-name::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-selector-tag::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-deletion::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-subst::selection {
    color: #e06c75;
}
.preview-newsletter .newsletter-content pre.code-block code .hljs-literal,
.preview-newsletter .newsletter-content pre.code-block code .hljs-literal::selection {
    color: #56b6c2;
}
.preview-newsletter .newsletter-content pre.code-block code .hljs-string,
.preview-newsletter .newsletter-content pre.code-block code .hljs-regexp,
.preview-newsletter .newsletter-content pre.code-block code .hljs-addition,
.preview-newsletter .newsletter-content pre.code-block code .hljs-attribute,
.preview-newsletter .newsletter-content pre.code-block code .hljs-meta-string,
.preview-newsletter .newsletter-content pre.code-block code .hljs-string::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-regexp::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-addition::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-attribute::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-meta-string::selection {
  color: #98c379;
}
.preview-newsletter .newsletter-content pre.code-block code .hljs-built_in,
.preview-newsletter .newsletter-content pre.code-block code .hljs-class .hljs-title,
.preview-newsletter .newsletter-content pre.code-block code .hljs-built_in::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-class .hljs-title::selection {
  color: #e6c07b;
}
.preview-newsletter .newsletter-content pre.code-block code .hljs-attr,
.preview-newsletter .newsletter-content pre.code-block code .hljs-variable,
.preview-newsletter .newsletter-content pre.code-block code .hljs-template-variable,
.preview-newsletter .newsletter-content pre.code-block code .hljs-type,
.preview-newsletter .newsletter-content pre.code-block code .hljs-selector-class,
.preview-newsletter .newsletter-content pre.code-block code .hljs-selector-attr,
.preview-newsletter .newsletter-content pre.code-block code .hljs-selector-pseudo,
.preview-newsletter .newsletter-content pre.code-block code .hljs-number,
.preview-newsletter .newsletter-content pre.code-block code .hljs-attr::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-variable::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-template-variable::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-type::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-selector-class::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-selector-attr::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-selector-pseudo::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-number::selection {
  color: #d19a66;
}
.preview-newsletter .newsletter-content pre.code-block code .hljs-symbol,
.preview-newsletter .newsletter-content pre.code-block code .hljs-bullet,
.preview-newsletter .newsletter-content pre.code-block code .hljs-link,
.preview-newsletter .newsletter-content pre.code-block code .hljs-meta,
.preview-newsletter .newsletter-content pre.code-block code .hljs-selector-id,
.preview-newsletter .newsletter-content pre.code-block code .hljs-title,
.preview-newsletter .newsletter-content pre.code-block code .hljs-symbol::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-bullet::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-link::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-meta::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-selector-id::selection,
.preview-newsletter .newsletter-content pre.code-block code .hljs-title::selection {
  color: #61aeee;
}
.preview-newsletter .newsletter-content pre.code-block code .hljs-emphasis {
  font-style: italic;
}
.preview-newsletter .newsletter-content pre.code-block code .hljs-strong {
  font-weight: bold;
}
.preview-newsletter .newsletter-content pre.code-block code .hljs-link {
  text-decoration: underline;
}

.preview-newsletter .newsletter-content .fileBlockTable {
    margin-bottom: 20px;
}
.preview-newsletter .newsletter-content .fileBlockTable .file-detail .file-name {
    color: #101828;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 27px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.preview-newsletter .newsletter-content .fileBlockTable .file-caption {
    width: calc(100% - 100px);
    font-size: 15px;
    color: #475467;
    margin-top: 0 !important;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0 !important;
}
.preview-newsletter .newsletter-content .fileBlockTable .newPagefileUploadText {
    margin: 0 !important;
    font-size: 14px;
}
.preview-newsletter .newsletter-content .fileBlockTable .newPagefileUploadText p {
    margin: 0 !important;
    color : #475467 !important;
}
.preview-newsletter .newsletter-content .fileBlockTable .newPagefileUploadText span {
    color: #777777 !important;
    margin-left: 5px;
    font-size: 14px;
}
.preview-newsletter .newsletter-content .fileBlockTable img {
    width: 47px !important;
}

.preview-newsletter .newsletter-content .product-block .product-title {
    font-size: 22px !important;
    line-height: 2rem !important;
    font-weight: 600 !important;
    margin-bottom: 5px !important;
    display:inline !important;
}
.preview-newsletter .newsletter-content .product-block img {
    height: auto !important;
    object-fit: cover !important;
    display: block !important;
    height: auto !important;
    width: 100% !important;
    max-width: 100% !important;
    margin-left:auto !important;
    margin-right:auto !important;
    box-sizing: border-box !important;
}
.preview-newsletter .newsletter-content .product-block .product-title-wrapper {
    display: inline-block !important;
    margin-top: 7px !important;
    width: 100% !important;
}
.preview-newsletter .newsletter-content .product-block .product-description {
    color: #344054 !important;
    margin: 0 !important;
    font-weight: 500 !important;
}
.preview-newsletter .newsletter-content .product-block .product-button {
    background-color: #1c1c1c;
    border: 1px solid #1c1c1c;
    border-radius: 6px;
    color: #ffffff;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    padding: 5px 10px;
    width: unset !important;
    margin-top: 10px;
    text-align: center;
}
.preview-newsletter .newsletter-content .product-block .product-button a{
    color: #ffffff !important;
}
.preview-newsletter .newsletter-content .product-block .rating .rate.highlighted img {
    width: 27px !important;
}
.preview-newsletter .newsletter-content .product-block .rating {
    float:right !important;
    width:unset !important;
    display:unset !important;
}
.preview-newsletter .newsletter-content .product-block .rating span {
    display: inline-block !important
}
.preview-newsletter .newsletter-content .product-block .rating span img {
    fill: #F2AE40;
    width: 24px !important;
    display: inline-block !important;
}
.preview-newsletter .newsletter-content .product-block .rating .rate {
    font-size: unset !important;
    cursor: auto !important;
}

.preview-newsletter .newsletter-content .columnsBlockTable {
    width: 100%;
    margin-bottom: 20px !important;
}
.preview-newsletter .newsletter-content .columnsBlockTable .column-block.column-left{
    padding-left : 0px !important;
    vertical-align: top !important;
}
.preview-newsletter .newsletter-content .columnsBlockTable .column-block.column-right {
    padding-right: 0px !important;
    vertical-align: top !important;
}
.preview-newsletter .newsletter-content .columnsBlockTable .column-left,
.preview-newsletter .newsletter-content .columnsBlockTable .column-right {
    width: 50%;
}
.preview-newsletter .newsletter-content .columnsBlockTable.layout-sidebar-left .column-left,
.preview-newsletter .newsletter-content .columnsBlockTable.layout-sidebar-right .column-right {
    width: 40% !important;
}
.preview-newsletter .newsletter-content .columnsBlockTable.layout-sidebar-right .column-left,
.preview-newsletter .newsletter-content .columnsBlockTable.layout-sidebar-left .column-right {
    width: 60% !important;
}
.preview-newsletter .newsletter-content .columnsBlockTable .image-preview {
    margin: 0;
}          
.preview-newsletter .newsletter-content .column-block div ul {
    margin: 0 !important;
}
.preview-newsletter .newsletter-content .column-block > *:first-child {
    margin-top: 0px !important;
}

.preview-newsletter .userSubPanel .userName{
    display: block;
    font-size: 16px;
    color: #1c2022;
    font-weight: 700;
    margin-bottom: 0 !important;
    line-height: unset !important
}
.preview-newsletter .userSubPanel .userNamewithLink {
    display: block;
    color: #697882;
    font-size: 16px;
    margin-bottom: 0 !important;
    line-height: unset !important
}

.preview-newsletter .dark-newpageRightButtons button:first-child{
    background-color: #fff;
    color: #0a0a0a;
    font-size: 13px;
    line-height: 24px;
    font-weight: 500;
    border-radius: 4px;
    border: 1px solid black;
    display: flex;
    /* gap: 0 5px; */
    align-items: center;
}
.newpageRightButtons button:first-child{
    border-radius: 4px;
    background: #0a0a0a;
}
.light-newpageRightButtons button:first-child{
    background-color: #000 ;
    color: #fff ;
    font-size: 13px ;
    line-height: 24px ;
    font-weight: 500 ;
    border: 1px solid black;
    display: flex;
    gap: 0 5px;
    align-items: center;
}
.newpageRightButtonsPreview button{
    cursor: pointer;
}
.newpageRightButtonsPreview img{
    width: auto !important;
    margin: 0 !important;
}
.featureimage-caption {
    margin-bottom: 0 !important;
    min-height: 24px;
    outline: none;
    text-align: left;
    font-size: 14px;
    padding: 5px 10px 10px 10px;
}
.newsletter-content .twitterBlock {
    background-color: #fff;
    max-width: 520px;
    margin-bottom: 20px;
}
.newsletter-content .twitterBlock table {
    margin: 0 auto;
    width: 100%;
}
.newsletter-content .twitterBlock .userPanel img {
    width: 48px !important;
    height: 48px; 
    border-radius: 50%;
}
.newsletter-content .twitterBlock .userSubPanel .userName {
    display: block;
    font-size: 16px;
    color: #1c2022;
    font-weight: 700;
    margin-bottom: 0 !important;
    line-height: unset;
} 
.newsletter-content .twitterBlock .tweetText {
    color: #344054; 
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0 !important;
}
.newsletter-content .twitterBlock .displayTime {
    border-bottom: 1px solid #cfd9de;
    margin-bottom: 10px !important; 
    padding-bottom: 10px;
    color: #697882;
    font-size: 14px;
    line-height: 17px;
    font-family: system-ui, Helvetica, Roboto, Calibri, sans-serif;
}
.newsletter-content .twitterBlock .userNamewithLink {  
    display: block;
    color: #697882;
    font-size: 16px;
    margin-bottom: 0;
    line-height: unset;
}
.newsletter-content .twitterBlock .replyCount {
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 4px;
    padding: 8px;
    border: 1px solid rgb(204, 214, 221);
    border-radius: 9999px;
    color: rgb(27, 149, 224);
    width: 100%;
    font-weight: 700;
    background: rgb(255, 255, 255);
    font-size: 16px;
}
.newsletter-content .retweetCount {
    margin-left: 5px;
}
.newsletter-content .likeCount,
.newsletter-content .retweetCount {
    color: #697882;
    font-size: 14px;
}
.newsletter-content .likeCount span,
.newsletter-content .retweetCount span {
    color: #1C2022;
    font-weight: bold;
}
.newsletter-content .displayTime {
    padding-top: 10px;
}
.newsletter-content .page-content .twitterBlock table td {
    padding: 0;
}
.newsletter-content .page-content .twitterBlock table {
    margin-bottom: 0;
}

@media (max-width: 640px) { 
    .preview-newsletter .desktop {
        display: none !important; 
    }
    .preview-newsletter .mobile {
        display: block !important; 
    }
    .preview-newsletter .newsletter-content {
      width: 100% !important;
      max-width: 100% !important;
    }
    .preview-newsletter .newsletter-content .imageBlock img {
      -ms-interpolation-mode: bicubic;
      height: auto !important;
      width: 100% !important;  
      margin-left:auto !important;
      margin-right:auto !important;
      box-sizing: border-box !important;
      max-width: 640px !important;
      border: none !important;
    }
    .preview-newsletter .youtube-icon {
      width: 60px !important;
    }
    .preview-newsletter .social-icon {
      padding-top: 22% !important;
    }
    .preview-newsletter .social-vimeo-icon {
      padding-top: 19.5% !important;
    } 

    .preview-newsletter .newsletter-content .bookmark-block .linkImageSection {
        height: auto;
        width: 100%;
        padding: 15px 15px 0;
        line-height: 0;
    }
    .preview-newsletter .newsletter-content .bookmark-block .linkData {
        height: auto;
        width: 100%;
    }
    .preview-newsletter .newsletter-content .bookmark-block .linkImageSection .previewImage {
        height: auto;
    }
    .preview-newsletter .newsletter-content .bookmark-block .linkDataTitle,
    .preview-newsletter .newsletter-content .bookmark-block .siteData {         
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .preview-newsletter .newsletter-content .product-block img {
        width: 100% !important;
        max-width: 640px !important;
    }
    .preview-newsletter .newsletter-content .columnsBlockTable .column-block {
        width: 100% !important;
    }

    .preview-newsletter .userSubPanel .userName,
    .preview-newsletter.userSubPanel .userNamewithLink {
        font-size: 15px !important;
    }
    .preview-newsletter .content-cover {
        margin-top: 78px;
    }
}