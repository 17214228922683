.popupModalOverlay {
    background-color: rgba(0,0,0,.5);
    opacity: 1;
    position: fixed;
    visibility: visible;
    z-index: 9999;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}
.popupModal {
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    border-radius: 6px;
    left: 50%;
    max-width: 400px;
    opacity: 1;
    padding: 25px;
    position: fixed;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    visibility: visible;
    width: 100%;
    z-index: 999999;
}
.addTeamMember.popupModal {
    padding: 0;
}
.addTeamMember .popupwrapper {
    overflow-y: auto;
    max-height: calc(100dvh - 40px);
    padding: 25px;
  }
.popupModal.singleDeletePage {
    max-width: 495px;
}
.popupModalTitle {
    color: #101828;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 15px;
    margin-top: 0;
    padding: 0;
}
.selectField {
    border: 1px solid #d0d5dd;
    box-shadow: 0 1px 2px rgba(16,24,40,.05);
    border-radius: 6px;
    min-height: 46px;
    padding: 10px 0;
}
.popupModalBtn {
    display: flex;
    gap: 15px;
    margin-top: 20px;
}
.upgradPlanPopupBtn.popupModalBtn {
    justify-content: center;
}
.popupModalBtn button {
    cursor: pointer;
    font-weight: 500;
    /* height: 45px; */
    line-height: 22px;
    padding: 8px 13px;
    width: 50%;
    font-size: 14px;
    min-height: 42px;
}
.addTeamMember .formControlInputs {
    gap: 20px;
    margin: 0;
}
.addTeamMember .formControlFields {
    width: calc(50% - 10px);
    margin: 0;
}
.addTeamMember .formControlFields .select-country button {
    min-width: unset;
}
/* .popupModalBtn .cancel {
    background: #fff;
    border: 1px solid #d0d5dd;
    color: #425466;
}
.popupModalBtn .savePicture {
    background-color: #1c1c1c;
    border: 1px solid #1c1c1c;
    color: #fff;
} */
.popupModalContent {
    color: #475467;
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 5px;
}
.popupModalContent span {
    font-weight: 500;
    color: #0A2540;
    text-transform: capitalize;
}
.popupFields input {
    background: #fff;
    border: 1px solid #d0d5dd;
    border-radius: 6px;
    color: #101828;
    font-size: 14px;
    font-weight: 400;
    height: 44px;
    line-height: 24px;
    outline: none ;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}
.password-field input{
    height: 45px;
    padding: 10px 14px;
}
.publicationListDropdown {
    left: 0;
    right: 0;
    position: absolute;
    box-shadow: 0 1px 2px rgba(16,24,40,.05);
    z-index: 1;
    border: 1px solid #EAECF0;
    border-radius: 6px;
    width: calc(100% - 46px);
    background-color: #fff;
    margin: 7px auto 0;
    top: 67%;
}
.movetocat-wrapp {
    position: relative;
}
.categoriesPage .movetocat-wrapp {
    position: static;
    margin-top: 15px;
}
.publicationListDropdown ul {
    margin: 0;
    padding: 0;
    max-height: 300px;
    overflow-y: auto;
    height: 100%;
}
.publicationListDropdown ul li {
    box-shadow: none;
    color: #111827;
    cursor: pointer;
    padding: 10px 20px;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

@media screen and (max-width: 480px) {
    .popupModal {
        width: calc(100% - 30px);
        margin: 0 auto;
        padding: 15px;
    }
}

@media screen and (max-width:400px) {
    .addTeamMember .popupModalBtn button {
        font-size: 12px;
    }
}