
.login {
  background: #f9fafb;
  min-height: 100vh;
  padding-top: 90px;
  display: flex;
  flex-direction: column;
}

.loginWindow {
  background: #ffffff;
  box-shadow: 0 4px 8px -2px rgba(16, 24, 40, .1), 0 2px 4px -2px rgba(16, 24, 40, .06);
  border-radius: 12px;
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 440px;
  width: calc(100% - 40px);
  margin: 0 auto;
}

.loginWindow>img {
  max-width: 22px;
  height: auto;
  margin: 0 auto;
  display: flex;
}

.loginTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #0a0a0a;
  margin-top: 15px;
}

.loginSubtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #111928;
}

.loginRow {
  margin-top: 10px;
}

.loginRow:empty {
  display: none;
}

.loginRow p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  margin-bottom: 6px;
}

.loginRow input {
  width: 100%;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 6px;
  height: 45px;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #111928;
}

.loginInfo input {
  appearance: none;
  margin: 0;
}

.loginInfo input[type="checkbox"]:before {
  margin-right: 0;
}

.loginInfo {
  display: flex;
  align-items: center;
  margin-top: 24px;
  justify-content: space-between;
}

.loginInfo>div {
  display: flex;
  align-items: center;
}

.loginInfo label {
  cursor: pointer;
  line-height: 20px;
  -webkit-user-select: none;
  user-select: none;
  font-weight: 400;
  font-size: 14px;
  color: #111928;
  margin-left: 10px;
}

.loginInfo a {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}

.loginButton {
  width: 100%;
  height: 45px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 24px;
  margin-bottom: 24px;
  cursor: pointer;
}

.login .loginButton {
  width: 100%;
  height: 44px;
  cursor: pointer;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  color: white;
  line-height: 24px;
  letter-spacing: 0em;
  background: #0a0a0a;
  border: 1px solid #0a0a0a;
  text-align: center;
  margin-bottom: 0;
}

.loginBottom {
  display: flex;
  justify-content: center;
}

.loginBottom a {
  margin-left: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}

.loginBottom {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}

.loginSubtitle span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #0a0a0a;
}

.pvivacy_mobile {
  background-color: #f3f3f3;
  width: 100%;
  display: none;
  text-align: center;
  padding: 30px 25px 30px 25px;
  bottom: 0;
  position: relative;
  margin-top: 0;
  z-index: 3;
}

.pvivacy_mobile {
  display: block;
}

.pvivacy_mobile p {
  font-weight: 400;
  font-size: 14px;
  color: #111928;
}

.pvivacy_mobile > div {
  line-height: 0;
  display: flex;
  align-items: center;
}

.pvivacy_mobile .email-img-block {
  margin-right: 8px;
}

.pvivacy_mobile a {
  color: #475467;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.pvivacy_mobile span {
  font-weight: 400;
  font-size: 14px;
  color: #0a0a0a;
  cursor: pointer;
  text-decoration: underline;
}

.login .pvivacy_mobile {
  background: transparent;
}

.pvivacy_mobile {
  bottom: 0;
}

.pvivacy_mobile>div {
  justify-content: center;
  margin-top: 15px;
}

.registerLoader {
  position: relative;
  bottom: 15px;
}


@media screen and (max-width: 400px) {
  .pvivacy_mobile {
    position: unset;
  }
}

.bg-gradient {
  background: linear-gradient(180deg,rgba(255,255,255,0) 0%,rgb(255, 255, 255)70%);
  flex: none;
  inset: 0 0 -1px;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 1;
}
.bg-images-animate {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 0;
}

.form-design .loginWindow {
  position: relative;
  z-index: 2;
}

.bg-images-frame {
  display: flex;
  flex-wrap: wrap;
  width: auto;
  justify-content: space-around;
  margin: 0 -7.5px;
  height: 100vh;
}

.scroller[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 20s;
}

.scroller[data-speed="slow"] {
  --_animation-duration: 60s;
}

.marquee {
  width: calc(12.5% - 15px);
  margin: 0 7.5px;
  overflow: hidden;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.marquee .bg-image-item {
  position: relative;
  margin: 0;
  opacity: 0.7;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.00);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
  line-height: 0;
}
.marquee .bg-image-item img {
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
    overflow: hidden;
}
.marquee .bg-image-item:hover {
  opacity: 0.8;
}
.marquee .bg-image-item img {
  pointer-events: none;
}
.marquee {
  display: flex;
  overflow: hidden;
  width: 100%;
  user-select: none;
  gap: 15px;
}

.marquee-group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 15px;
  min-width: 100%;
  transform: translateZ(0);
  animation: scroll-y 40s linear infinite;
}

.marquee:nth-child(1) .marquee-group  {
  animation-duration: 35s;
}
.marquee:nth-child(2) .marquee-group  {
  animation-duration: 40s;
}
.marquee:nth-child(3) .marquee-group  {
  animation-duration: 45s;
}
.marquee:nth-child(4) .marquee-group  {
  animation-duration: 50s;
}
.marquee:nth-child(5) .marquee-group  {
  animation-duration: 55s;
}
.marquee:nth-child(6) .marquee-group  {
  animation-duration: 60s;
}
.marquee:nth-child(7) .marquee-group {
  animation-duration: 50s;
}
.marquee:nth-child(8) .marquee-group  {
  animation-duration: 40s;
}


@media (prefers-reduced-motion: reduce) {
  .marquee-group {
    animation-play-state: running;
  }
}

.marquee-vertical {
  --mask-direction: to bottom;
}

.marquee-vertical,
.marquee-vertical .marquee-group {
  flex-direction: column;
}

.marquee-vertical .marquee-group {
  animation-name: scroll-y;
}

@-webkit-keyframes scroll-x {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(calc(-100% - 15px));
    transform: translateX(calc(-100% - 15px));
  }
}

@keyframes scroll-x {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 15px));
  }
}

@-webkit-keyframes scroll-y {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(calc(-100% - 15px));
    transform: translateY(calc(-100% - 15px));
  }
}

@keyframes scroll-y {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(calc(-100% - 15px));
  }
}


.bg-image-item {
  width: 100%;
}

.marquee img {
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 6px;
}

.marquee-vertical img {
  aspect-ratio: 1;
  width: 100%;
}

.wrapper-vertical {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 100vw;
  justify-content: space-around;
}

.wrapper-vertical {
  flex-direction: row;
  height: 100vh;
}

input[type="checkbox"]:before {
  content: "";
  font-size: 15px;
  border: 1px solid #d0d5dd;
  display: block;
  width: 15px;
  background: none;
  height: 15px;
  margin-right: 7px;
  border-radius: 4px;
  min-width: 16px;
  min-height: 16px;
  margin-right: 15px;
  background-size: 60%;
  background-position: 4px center;
  background-repeat: no-repeat;
  accent-color: #e6f2ff;
}
input[type="checkbox"]:checked:before {
  background-image: url('../../images/checkbox.svg');
  border: 1px solid #2b59ff;
}
input[type="checkbox"] {
  cursor: pointer;
}
.loginWindow .error-message,.newpageLeftRow .error-message{
  font-size: 14px;
  color: #d92d20;
  margin-top: 5px;
  margin-bottom: 0;
  font-weight: 400;
}
.inputError {
  border: 1px solid #f04438 !important;
}
.authorsBlocks .validateError {
  margin-bottom: 8px;
}
.validateError,
.newpageLeftRow .validateError,
.publication-Link .list-search .validateError,
.publication-Video .list-search .validateError {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #d92d20;;
  margin-top: 5px;
  text-transform: none;
  margin-bottom: 0;
}
