:root {
    --black: #000000;
    --btn: #0055ff;

    /* Dark thim */
    --primary: #0c0c0c;
    --secondary: #e6e6e6;
    --white: #ffffff;
    --ghost: #CECFD2;
    --darkGreen: #02000A;
    --headerbg: #161616;
    --lightfont: #C4C2C2;
    --aboutsub: #CECFD2;
    --iconboder: #333741;
    --codgray: #1d1d1d;
    --headerhover: rgba(255, 255, 255, 0.65);
    --linear-gradient: linear-gradient(to left, rgba(2, 0, 10, 0) 70%, #02000a 98%);
    --linear-gradient-bottom: linear-gradient(to bottom, rgba(2, 0, 10, 0) 70%, #02000a 98%);
    --buttonbg: #131313;
    --togglebg: #272727;
    --table-border: #C4C2C2;
    --bg-color: #000000;
    --send: url('../../../images/senddark.png');

    /* Light thim */
    --primary-light: #ffffff;
    --secondary-light: #101828;
    --white-light: #101828;
    --ghost-light: #344054;
    --darkGreen-light: #F9F9F9;
    --headerbg-light: #F9F9F9;
    --lightfont-light: #101828;
    --aboutsub-light: #101828;
    --btn-light: #ffffff;
    --iconboder-light: #D0D5DD;
    --headerhover-light: rgba(0, 0, 0, 0.65);
    --linear-gradient-light: linear-gradient(to left, rgba(2, 0, 10, 0) 70%, #FFFFFF 98%);
    --linear-gradient-bottom-light: linear-gradient(to bottom, rgba(2, 0, 10, 0) 70%, #FFFFFF 98%);
    --buttonbg-light: #F6F8FA;
    --togglebg-light: #ffffff;
    --table-light-border: rgba(0, 0, 0, 0.1);
    --bg-light-color: #ffffff;
    --send-light: url('../../../images/send.svg');
}

.light {
    --primary: var(--primary-light);
    --secondary: var(--secondary-light);
    --white: var(--white-light);
    --ghost: var(--ghost-light);
    --darkGreen: var(--darkGreen-light);
    --headerbg: var(--headerbg-light);
    --lightfont: var(--lightfont-light);
    --aboutsub: var(--aboutsub-light);
    --btn: var(--btn-light);
    --iconboder: var(--iconboder-light);
    --headerhover: var(--headerhover-light);
    --linear-gradient: var(--linear-gradient-light);
    --linear-gradient-bottom: var(--linear-gradient-bottom-light);
    --buttonbg: var(--buttonbg-light);
    --togglebg: var(--togglebg-light);
    --tableBorder: var(--table-light-border);
    --bg-color: var(--bg-light-color);
    --send: var(--send-light);
}

#hero-and-body {
    left: 0px;
    position: relative;
    -webkit-transition: left 599ms cubic-bezier(0.74, 0.215, 0.125, 1.02),
        right 599ms cubic-bezier(0.74, 0.215, 0.125, 1.02) !important;
    -moz-transition: left 599ms cubic-bezier(0.74, 0.215, 0.125, 1.02),
        right 599ms cubic-bezier(0.74, 0.215, 0.125, 1.02) !important;
    -o-transition: left 599ms cubic-bezier(0.74, 0.215, 0.125, 1.02),
        right 599ms cubic-bezier(0.74, 0.215, 0.125, 1.02) !important;
    transition: left 599ms cubic-bezier(0.74, 0.215, 0.125, 1.02),
        right 599ms cubic-bezier(0.74, 0.215, 0.125, 1.02) !important;
}

body:has(.preview-newsletter__header) .sidebar {
    display: none;
}
body:has(.preview-newsletter__header) .main-wrapper .main-content {
    width: 100%;
    left: 0;
    transition: unset !important;
}
body:has(.preview-newsletter__header) .main-content .content-wrapper {
    padding: 0;
    margin: 0;
}
body:has(.preview-newsletter__header) .main-footer {
    display: none;
}

#article-detail ,#singlearticle{
    background-color: var(--bg-color);
}
.desktop {
    display: block !important;
}

.mobile {
    display: none !important;
}
.preview-newsletter__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 20px;
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 9999;
}
.preview-buttons .preview-update-btn {
    min-height: unset !important;
    border-radius: 4px;
    margin-right: 0 !important;
    width: auto;
}

.preview-newsletter__header p {
    color: #344054;
    font-weight: 500;
    margin-right: 8px !important;
    font-size: 14px;
}
 .newsletter-buttons {
    display: flex;
    align-items: center;
}
 .newpageRightButtonsSend button {
    font-size: 16px ;
    line-height: 24px ;
    font-weight: 500 ;
    border: 1px solid #333741  ;
    background-color: transparent ;
    pointer-events: none;
    color: black ;
}
 .newpageRightButtonsPreview {
    align-items: center ;
    margin-top: 0 ;
}
 .newpageRightButtonsPreview button {
    height: 34px;
    min-width: 73px ;
    font-size: 14px ;
    line-height: 24px ;
    font-weight: 500 ;
    padding: 5px 10px ;
}
 .newpageRightButtonsPreview .updatepreviewbutton-loader {
    background-color: #000 ;
    bottom: 0 ;
    min-width: 73px ;
    border-radius: 4px ;
    height: 34px ;
}
 .newpageRightButtonsPreview .updatepreviewbutton-loader .ring-loader {
   top: 2px ;
}
.update-button{
    display: flex;
    align-items: center;
    justify-content: center;
}
.publish-loader{
    min-width: 82.98px ;
    min-height: 34px  ;
    bottom: 0 ;
    border-radius: 4px ;
}
.light-newpageRightButtons .update-loader{
    min-width: 73px ;
    min-height: 34px  ;
}
.light-newpageRightButtons .publish-loader{
    background-color: #000 ;
}
.dark-newpageRightButtons .update-loader{
    background-color: #ffffff ;
    min-width: 73px ;
    min-height: 34px  ;
}
.dark-newpageRightButtons .update-loader .ring-loader:before,.dark-newpageRightButtons .publish-loader .ring-loader:before{
    border: 3px solid black ;
}
.dark-newpageRightButtons .publish-loader{
    background-color: #ffffff ;
}
.dark-newpageRightButtons .publish-loader .ring-loader:before{
    border: 3px solid black ;
}
.dark-newpageRightButtons button:first-child{
    background-color: #fff ;
    color: #000 ;
    font-size: 13px ;
    line-height: 24px ;
    font-weight: 500 ;
    border-radius: 4px ;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
}
.published-icon svg,.published-icon img{
    margin-right: 5px !important;
}
.light-newpageRightButtons button:first-child{
    background-color: #000 ;
    color: #fff ;
    font-size: 13px ;
    line-height: 24px ;
    font-weight: 500 ;
    gap: 0;
    border: 0;
}
.publish-loader .ring-loader {
    top: 2px ;
}
 .preview-edit button {
    padding: 5px 10px;
    gap: 8px;
    background: #fff !important;
    border: 1px solid #333741  !important;
    border-radius: 4px;
    font-weight: 500;
    display: flex;
    align-items: center;
    text-align: center;
    color: #344054;
    cursor: pointer;
    height: 34px;
    font-size: 13px !important;
    line-height: 24px !important;
    width: 92px
}
.hero-wrap ::selection {
    background: rgba(80, 116, 161, 1);
    color: var(--white);
}

.hero-wrap {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100%;
}

.hero-wrap .overlay-left {
    width: 50%;
}

.hero-wrap .overlay-right {
    width: 50%;
}

#hero-and-body {
    position: relative;
    width: 100%;
    margin-left: 0;
}

.page_singleArticle__gQtS5 .abc {
    width: 100%;
}

.page_singlearticle {
    width: 100%;
}

.page_singlearticle .overlay {
    background: var(--linear-gradient-bottom) !important;
}

.overlay {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100%);
    background: var(--linear-gradient) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.5em;
    text-align: center;
}

.hero-image {
    display: block;
    height: 100dvh;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    right: 0;
    width: 100%;
}

.hero-image img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.text-white {
    color: var(--white);
}

.text-white.datacaption {
    padding-left: 55px;
}

.publication-content {
    margin-bottom: 20px;
}

.publication-content .publication-title.text-white {
    color: var(--white);
    font-size: 48px;
    line-height: 72px;
    font-weight: 500;
    margin-bottom: 0;
    text-align: start;
}

.publication-content .publication-paragraph.text-white {
    font-size: 22px;
    font-weight: 400;
    color: var(--aboutsub);
    line-height: 27px;
    text-align: start;

}

.publication-title {
    color: var(--secondary);
    font-size: 44px;
    line-height: 64px;
    font-weight: 400;
    margin-bottom: 15px;
    letter-spacing: -0.88px;
}

.heroCaption p {
    color: var(--white);
    font-size: 12px;
    line-height: 16px;
    display: inline-block;
    border-radius: 5px;
}

.publicationContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.preview-buttons {
    display: flex;
    align-items: center;
    gap: 0 10px;
}
.preview-buttons .preview-edit button{
    background-color: var(--darkGreen) !important;
    color: var(--white) !important;
    cursor: pointer;
}

.preview-newsletter__header.light-view p {
    color: #000 !important;
}

.preview-newsletter__header.dark-view p {
    color: #fff !important;
}

.preview-newsletter__header.light-view,
.dark-btn {
    background-color: #fff !important;
    color: #000 !important;
    z-index: 9999;
}

.preview-newsletter__header.dark-view,
.light-btn {
    background-color: #000 !important;
    color: #fff !important;
}

.singleArticle .heroContent {
    padding-bottom: 20px;
}

.singleArticle .publication-social p {
    margin-bottom: 0;
}

.singleArticle .heroWrap {
    position: relative;
    margin-top: 29px;
}

.heroCol .arrowImg {
    position: absolute;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto 90px;
    z-index: 1;
    background-color: #fff;
    border: 1px solid #EAECF0;
    cursor: pointer;
    animation: scrolldown-bottom 4s infinite ease;
}

.heroCol .arrowImg:after {
    content: "";
    position: absolute;
    background-color: #fff;
}

.newpageRightButtonsPreview {
    margin-top: 0;
}

.articleContainer {
    display: flex;
    justify-content: center;
    max-width: 1340px;
    width: 100%;
    margin: auto;
}

.abc {
    width: 100% !important;
}

.articleContainer .articleCard {
    width: 100%;
    max-width: 700px;
    margin-top: -50px;
    z-index: 999;
}

.articleContainer .articleSidebar {
    max-width: 500px;
    position: relative;
}

.articleContainer .articleSidebar:before {
    background-color: #e6e6e6;
    content: "";
    height: 100%;
    left: -38px;
    position: absolute;
    top: 0;
    width: 1px;
}

.articleSidebar .pulications .publication:last-child {
    margin-bottom: 0;
}

.publicationAvatar {
    display: flex;
    align-items: center;
}

.publicationImg img {
    height: auto;
    width: 45px;
    border-radius: 50%;
    margin-right: 15px;
}

.publication-name-date {
    font-size: 14px;
    font-weight: 600 !important;
}

.publication-name-date .publisher-name {
    display: inline-block;
}

.publication-name-date span {
    display: block;
}

.publication-name-date .publisher-name {
    display: inline-block;
}


.articleSidebar .latestPublicationWidget {
    margin-top: 25px;
}

.articleSidebar .pulications .publication {
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
}

.articleSidebar .publicationTitle {
    font-size: 14px;
    line-height: 24px;
    color: var(--white);
    margin-bottom: 5px;
    font-weight: 500;
}

.articleSidebar .publicationTitle a {
    color: var(--white);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: normal;
}

.publication .publicationImage {
    display: block;
    width: 70px;
    padding-top: 70px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    top: 4px;
}

.publicationContent {
    width: calc(100% - 70px);
    padding-left: 12px;
}

.publication .publicationImage img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}

.articleSidebar .publicationDate {
    color: var(--white);
    font-size: 14px;
    line-height: 10px;
}

.articleSidebar .latestPublicationWidget .widgetTitle {
    color: var(--white);
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 15px;
}

.subscriptionContent {
    width: calc(100% - 152px);
    padding: 17px 21px;
}

.articleContainer .articleCard * {
    /* text-align: justify; */
    /* color: var(--white); */
    color: var(--lightfont);
}

.first-letter::first-letter {
    font-size: 50px;
}

.article-img {
    margin-top: 40px;
}

.article-img img {
    border-radius: 8px;
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
}

.article-img-content {
    margin-top: 15px;
    margin-bottom: 30px;
}

.article-img-content i {
    font-size: 16px;
    line-height: 32px;
}

.article-gapping {
    padding: 0 50px;
}

.article-comment-card {
    padding: 24px 30px;
    background: #F9FAFB;
    border-radius: 4px;
}

.article-comment-text {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000;
    margin-bottom: 15px;
}

.article-comment-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.article-comment-form .modalField {
    border: none;
    box-shadow: 0 1px 3px rgba(16, 24, 40, .1), 0 1px 2px rgba(21, 22, 24, 0.06);
    padding: 5px 14px;
}

.article-comment {
    display: flex;
    flex-direction: row;
    margin-bottom: 22px;
}

.article-comment-from {
    gap: 20px;
}

.article-comment .article-comment-photo {
    width: 40px;
    margin-right: 12px;
}

.article-comment-name {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
}

.article-comment-name .article-comment-time {
    margin-left: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #667085;
}

.article-comment-field {
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 0px 8px 8px 8px;
    padding: 6px 14px;
    margin-top: 10px;
}

.article-comment-field p {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #000;
}

.article-children {
    padding-left: 52px;
}

.article-more-load {
    text-align: center;
    margin: 20px 0;
}

.article-more-load .btn-load {
    background-color: #fff;
    border: none;
    outline: none;
    box-shadow: none;
    padding: 13px 25px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #000;
    cursor: pointer;
    border-radius: 10px;
    transition: all 0.3s linear;
}

.article-more-load .btn-load:hover {
    background-color: #000;
    color: #fff;
}

.article-new-comment-text {
    font-size: 16px;
    line-height: 22px;
}

.article-comment-textarea {
    margin: 20px 0 15px;
}

.article-comment-textarea textarea {
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 0px 8px 8px 8px;
    border: none;
    padding: 9px 14px;
    width: 100%;
    height: 115px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}

.article-comment-login {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
}

.article-comment-login a {
    color: #1DA1F2;
}

.article-comment-login span {
    display: block;
}

.btn-comment {
    padding: 15px 17px;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    color: #000;
    font-size: 13px;
    margin-left: 0;
    font-weight: 500;
    background-color: #fff;
    transition: all 0.3s linear;
    box-shadow: 0px 1px 3px rgb(16 24 40 / 10%), 0px 1px 2px rgb(16 24 40 / 6%);
}

.btn-comment:hover {
    background-color: #000000;
    color: #ffffff;
}

.singleArticle .header-inner .header-share-social>.social-icons-cover {
    opacity: 0;
    margin-left: -5px;
    background-color: #ffff;
    position: relative;
    z-index: 1;
    width: 30px;
    visibility: visible;
    transition: all 0.3s linear;
    display: block;
}

.singleArticle .header-inner .header-share-social>.social-icons-cover li {
    margin: 0 0 5px;
}

.singleArticle .header-inner .show-share .social-icons-cover {
    opacity: 1;
    visibility: visible;
    display: block;
}

body:has(.singleArticle) {
    overflow: visible;
}

.ringLoader {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    margin: 14px auto;
    top: -3px;
    left: 0;
    right: 0;
    animation: rotate 1s linear infinite;
}

.ringLoader:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 2px solid var(--primary);
    animation: prixClipFix 1s linear infinite;
    top: 0;
}

.ringLoaderUpdateBtn {
    top: -5px !important;
}

.article-min-container p,
.page-content-inner .page-content p {
    margin-top: 0.75rem;
    margin-bottom: 20px;
    line-height: 27px;
    font-size: 16px;
}

.article-min-container strong,
.page-content-inner .page-content strong {
    line-height: 27px;
    font-size: 16px;
}

.article-min-container h1,
.page-content-inner .page-content h1 {
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 700;
}

.article-min-container h2,
.page-content-inner .page-content h2 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
}

.article-min-container h3,
.page-content-inner .page-content h3 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 700;
}

.article-min-container .button-element.align-center,
.page-content-inner .page-content .button-element.align-center {
    text-align: center;
}

.article-min-container .button-element.align-left,
.page-content-inner .page-content .button-element.align-left {
    text-align: left;
}

.article-min-container .button-element.align-right,
.page-content-inner .page-content .button-element.align-right {
    text-align: right;
}

.article-min-container .button-element.width-auto button,
.page-content-inner .page-content .button-element.width-auto button {
    width: auto;
}

.article-min-container .button-element button,
.article-min-container .button-element a,
.page-content-inner .page-content .button-element button,
.page-content-inner .page-content .button-element a {
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
    border-radius: 6px;
    color: var(--primary);
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    padding: 5px 10px;
    transition: all .3s linear;
}

.article-min-container ul ul,
.article-min-container ul ol,
.article-min-container ul li,
.article-min-container ol ul,
.article-min-container ol ol,
.article-min-container ol li,
.page-content-inner .page-content ul ul,
.page-content-inner .page-content ul ol,
.page-content-inner .page-content ul li,
.page-content-inner .page-content ol ul,
.page-content-inner .page-content ol ol,
.page-content-inner .page-content ol li {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}

.article-min-container [data-type='button'],
.page-content-inner .page-content [data-type='button'] {
    margin-bottom: 20px;
}

.article-min-container ul,
.article-min-container li,
.page-content-inner .page-content ul,
.page-content-inner .page-content li {
    list-style-type: unset;
}

.article-min-container ul li,
.page-content-inner .page-content ul li {
    margin-top: 0.20rem;
    margin-bottom: 0.25rem;
}

.article-min-container ul li p,
.article-min-container ol li p,
.page-content-inner .page-content ul li p,
.page-content-inner .page-content ol li p {
    margin-bottom: 0.25rem;
    margin-top: 0;
}

.article-min-container ul,
.article-min-container ol,
.page-content-inner .page-content ul,
.page-content-inner .page-content ol {
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
    padding-inline-start: 20px;
}

.article-min-container>ul[data-type='taskList'],
.page-content-inner .page-content>ul[data-type='taskList'] {
    padding-inline-start: 0 !important;
}

.article-min-container>*:first-child:first-child,
.article-min-container [data-type='column']>*:first-child,
.page-content-inner .page-content>*:first-child:first-child,
.page-content-inner .page-content [data-type='column']>*:first-child {
    margin-top: 0px;
}

.article-min-container [data-type='column']>*:last-child,
.page-content-inner .page-content [data-type='column']>*:last-child {
    margin-bottom: 0px;
}

.article-min-container ul[data-type='taskList'] li[data-checked='true'],
.page-content-inner .page-content ul[data-type='taskList'] li[data-checked='true'] {
    text-decoration-line: line-through;
}

.article-min-container ul[data-type='taskList'] li,
.page-content-inner .page-content ul[data-type='taskList'] li {
    display: flex;
    /* margin-bottom: 0.25rem; */
}

.article-min-container ul[data-type='taskList'] li div p,
.page-content-inner .page-content ul[data-type='taskList'] li div p {
    margin-left: 4px;
}

.article-min-container input[type="checkbox"],
.page-content-inner .page-content input[type="checkbox"] {
    font-size: 0;
    width: 18px;
    height: 18px;
    border: 1px solid transparent;
    -o-appearance: none;
    appearance: none;
    position: relative;
    padding: 0 0 0 0;
    margin: 0;
    margin-right: 4px !important;
    cursor: pointer;
    margin-top: 3px !important;

}

.article-min-container input[type="checkbox"]:before,
.page-content-inner .page-content input[type="checkbox"]:before {
    content: "";
    font-size: 15px;
    border: 1px solid var(--white);
    display: block;
    width: 15px;
    background: none;
    height: 15px;
    border-radius: 4px;
    min-width: 16px;
    min-height: 16px;
    background-size: 60%;
    background-position: 4px center;
    background-repeat: no-repeat;
    accent-color: #e6f2ff;
}

.article-min-container input[type="checkbox"]:checked:before,
.page-content-inner .page-content input[type="checkbox"]:checked:before {
    position: absolute;
    left: 0;
    top: 0;
    margin-right: 0;
    background-image: var(--checkbox) !important;
    border: 1px solid var(--white);
    background-size: 60% !important;
    background-position: 4px center !important;
    background-repeat: no-repeat !important;
    accent-color: #e6f2ff;
    border-radius: 4px;
    min-width: 16px;
    min-height: 16px;
    display: block;
    width: 15px;
    background: none;
    height: 15px;
    content: "";
    font-size: 15px;
}

.article-min-container [data-type='bookmarkBlock'] .textBlock {
    background-color: var(--bg-color) !important;
}

.article-min-container [data-type='bookmarkBlock'] p,
.page-content-inner .page-content [data-type='bookmarkBlock'] p {
    margin-top: 0;
}

.article-min-container [data-type='bookmarkBlock'] .linkBox,
.page-content-inner .page-content [data-type='bookmarkBlock'] .linkBox {
    display: flex;
    box-shadow: none;
    border: 0;
    align-items: center;
    border: 1px solid #8080805c;
    border-radius: 0;
    padding: 15px;
}

.article-min-container [data-type='bookmarkBlock'].link-content,
.page-content-inner .page-content [data-type='bookmarkBlock'].link-content {
    margin-bottom: 20px;
}

.article-min-container [data-type='bookmarkBlock'].link-content a,
.page-content-inner .page-content [data-type='bookmarkBlock'].link-content a {
    text-decoration: none;
}

.article-min-container [data-type='bookmarkBlock'].link-content img,
.page-content-inner .page-content [data-type='bookmarkBlock'].link-content img {
    width: 100%;
    height: 90px;
    object-fit: cover;
    border-radius: 0;
}

.article-min-container [data-type='bookmarkBlock'] .textBlock.linkBox .linkImageSection,
.page-content-inner .page-content [data-type='bookmarkBlock'] .textBlock.linkBox .linkImageSection {
    display: flex;
    align-items: center;
    width: 90px !important;
    height: auto;
}

.article-min-container [data-type='bookmarkBlock'] .linkBox .linkData,
.page-content-inner .page-content [data-type='bookmarkBlock'] .linkBox .linkData {
    width: calc(100% - 90px);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
    padding-left: 15px;
}

.article-min-container [data-type='bookmarkBlock'].link-content p.linkDataTitle,
.page-content-inner .page-content [data-type='bookmarkBlock'].link-content p.linkDataTitle {
    color: var(--secondary);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0;
}

.article-min-container [data-type='bookmarkBlock'].link-content .linkDataDiscripation,
.page-content-inner .page-content [data-type='bookmarkBlock'].link-content .linkDataDiscripation {
    display: none;
}

.article-min-container [data-type='bookmarkBlock'].link-content .siteData,
.page-content-inner .page-content [data-type='bookmarkBlock'].link-content .siteData {
    display: flex;
    align-items: center;
    width: 100%;
}

.article-min-container [data-type='bookmarkBlock'].link-content .siteData.siteUrl,
.page-content-inner .page-content [data-type='bookmarkBlock'].link-content .siteData.siteUrl {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.article-min-container [data-type='bookmarkBlock'] .linkData .siteData:nth-child(3),
.page-content-inner .page-content [data-type='bookmarkBlock'] .linkData .siteData:nth-child(3) {
    margin-top: -10px;
}

.article-min-container [data-type='bookmarkBlock'].link-content .siteData img,
.page-content-inner .page-content [data-type='bookmarkBlock'].link-content .siteData img {
    margin-right: 6px;
    border-radius: 0;
    object-fit: cover;
    width: 18px;
    height: 18px; 
    max-width: 18px;
}

.article-min-container [data-type='bookmarkBlock'].link-content .siteData p,
.page-content-inner .page-content [data-type='bookmarkBlock'].link-content .siteData p {
    margin: 0;
    font-size: 14px;
    color: var(--lightfont);
    font-weight: 500;
}

.article-min-container [data-type='blockquoteFigure'],
.page-content-inner .page-content [data-type='blockquoteFigure'] {
    margin-bottom: 20px;
    color: rgb(0 0 0 / 1);
    margin-left: 0;
}

.article-min-container [data-type='blockquoteFigure']>div,
.page-content-inner .page-content [data-type='blockquoteFigure']>div {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 4px;
    border: 4px solid var(--secondary);
    border-bottom: 0;
    border-right: 0;
    border-top: 0;
}

.article-min-container>blockquote blockquote,
.article-min-container [data-type='blockquoteFigure'] blockquote,
.page-content-inner .page-content>blockquote blockquote,
.page-content-inner .page-content [data-type='blockquoteFigure'] blockquote {
    margin: 0px;
}

.product-figure {
    margin: 0 !important;
}

.article-min-container [data-type='blockquoteFigure'] p,
.page-content-inner .page-content [data-type='blockquoteFigure'] p {
    font-weight: 500;
    font-size: 18px;
    color: var(--ghost);
    margin: 10px 0 0;
}

.article-min-container [data-type='blockquoteFigure'] figcaption,
.page-content-inner .page-content [data-type='blockquoteFigure'] figcaption {
    margin-top: 0.8rem;
    overflow: hidden;
    font-size: 16px;
    color: rgb(115 115 115 / 1);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.article-min-container [data-type='blockquoteFigure'] figcaption span,
.page-content-inner .page-content [data-type='blockquoteFigure'] figcaption span {
    font-size: 16px !important;
    color: rgb(115 115 115 / 1) !important;
}

.article-min-container code *,
.page-content-inner .page-content code * {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    line-height: 24px;
}

.article-min-container code,
.page-content-inner .page-content code {
    color: var(--darkGreen) !important;
    background-color: var(--white);
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    line-height: 24px;
    font-size: 1rem;
}

.article-min-container pre.code-block,
.page-content-inner .page-content pre.code-block {
    caret-color: white;
    color: #f6f6f6;
    background: #282c34;
    border-radius: 0.375rem;
    margin-bottom: 20px;
    padding: 1rem;
    border: 1px solid var(--iconboder);
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    line-height: 24px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.article-min-container pre.code-block *::selection,
.page-content-inner .page-content pre.code-block *::selection {
    background-color: rgba(255, 255, 255, 0.2);
    color: unset !important;
}

.article-min-container p a,
.page-content-inner .page-content p a {
    color: #3b82f6 !important;
}

.article-min-container pre.code-block code,
.page-content-inner .page-content pre.code-block code {
    color: #f6f6f6 !important;
    background: #282c34;
    padding: 0;
    box-shadow: none;
}

.article-min-container pre.code-block code *,
.article-min-container pre.code-block code *::selection,
.page-content-inner .page-content pre.code-block code *,
.page-content-inner .page-content pre.code-block code *::selection {
    color: #f6f6f6;
}

.article-min-container pre.code-block code .hljs-comment,
.article-min-container pre.code-block code .hljs-quote,
.article-min-container pre.code-block code .hljs-comment::selection,
.article-min-container pre.code-block code .hljs-quote::selection,
.page-content-inner .page-content pre.code-block code .hljs-comment,
.page-content-inner .page-content pre.code-block code .hljs-quote,
.page-content-inner .page-content pre.code-block code .hljs-comment::selection,
.page-content-inner .page-content pre.code-block code .hljs-quote::selection {
    color: #a7a7a7;
    font-style: italic;
}

.article-min-container pre.code-block code .hljs-doctag,
.article-min-container pre.code-block code .hljs-keyword,
.article-min-container pre.code-block code .hljs-formula,
.article-min-container pre.code-block code .hljs-doctag::selection,
.article-min-container pre.code-block code .hljs-keyword::selection,
.article-min-container pre.code-block code .hljs-formula::selection,
.page-content-inner .page-content pre.code-block code .hljs-doctag,
.page-content-inner .page-content pre.code-block code .hljs-keyword,
.page-content-inner .page-content pre.code-block code .hljs-formula,
.page-content-inner .page-content pre.code-block code .hljs-doctag::selection,
.page-content-inner .page-content pre.code-block code .hljs-keyword::selection,
.page-content-inner .page-content pre.code-block code .hljs-formula::selection {
    color: #c678dd;
}

.article-min-container pre.code-block code .hljs-section,
.article-min-container pre.code-block code .hljs-name,
.article-min-container pre.code-block code .hljs-selector-tag,
.article-min-container pre.code-block code .hljs-deletion,
.article-min-container pre.code-block code .hljs-subst,
.article-min-container pre.code-block code .hljs-section::selection,
.article-min-container pre.code-block code .hljs-name::selection,
.article-min-container pre.code-block code .hljs-selector-tag::selection,
.article-min-container pre.code-block code .hljs-deletion::selection,
.article-min-container pre.code-block code .hljs-subst::selection,
.page-content-inner .page-content pre.code-block code .hljs-section,
.page-content-inner .page-content pre.code-block code .hljs-name,
.page-content-inner .page-content pre.code-block code .hljs-selector-tag,
.page-content-inner .page-content pre.code-block code .hljs-deletion,
.page-content-inner .page-content pre.code-block code .hljs-subst,
.page-content-inner .page-content pre.code-block code .hljs-section::selection,
.page-content-inner .page-content pre.code-block code .hljs-name::selection,
.page-content-inner .page-content pre.code-block code .hljs-selector-tag::selection,
.page-content-inner .page-content pre.code-block code .hljs-deletion::selection,
.page-content-inner .page-content pre.code-block code .hljs-subst::selection {
    color: #e06c75;
}

.article-min-container pre.code-block code .hljs-literal,
.article-min-container pre.code-block code .hljs-literal::selection,
.page-content-inner .page-content pre.code-block code .hljs-literal,
.page-content-inner .page-content pre.code-block code .hljs-literal::selection {
    color: #56b6c2;
}

.article-min-container pre.code-block code .hljs-string,
.article-min-container pre.code-block code .hljs-regexp,
.article-min-container pre.code-block code .hljs-addition,
.article-min-container pre.code-block code .hljs-attribute,
.article-min-container pre.code-block code .hljs-meta-string,
.article-min-container pre.code-block code .hljs-string::selection,
.article-min-container pre.code-block code .hljs-regexp::selection,
.article-min-container pre.code-block code .hljs-addition::selection,
.article-min-container pre.code-block code .hljs-attribute::selection,
.article-min-container pre.code-block code .hljs-meta-string::selection,
.page-content-inner .page-content pre.code-block code .hljs-string,
.page-content-inner .page-content pre.code-block code .hljs-regexp,
.page-content-inner .page-content pre.code-block code .hljs-addition,
.page-content-inner .page-content pre.code-block code .hljs-attribute,
.page-content-inner .page-content pre.code-block code .hljs-meta-string,
.page-content-inner .page-content pre.code-block code .hljs-string::selection,
.page-content-inner .page-content pre.code-block code .hljs-regexp::selection,
.page-content-inner .page-content pre.code-block code .hljs-addition::selection,
.page-content-inner .page-content pre.code-block code .hljs-attribute::selection,
.page-content-inner .page-content pre.code-block code .hljs-meta-string::selection {
    color: #98c379;
}

.article-min-container pre.code-block code .hljs-built_in,
.article-min-container pre.code-block code .hljs-class .hljs-title,
.article-min-container pre.code-block code .hljs-built_in::selection,
.article-min-container pre.code-block code .hljs-class .hljs-title::selection,
.page-content-inner .page-content pre.code-block code .hljs-built_in,
.page-content-inner .page-content pre.code-block code .hljs-class .hljs-title,
.page-content-inner .page-content pre.code-block code .hljs-built_in::selection,
.page-content-inner .page-content pre.code-block code .hljs-class .hljs-title::selection {
    color: #e6c07b;
}

.article-min-container pre.code-block code .hljs-attr,
.article-min-container pre.code-block code .hljs-variable,
.article-min-container pre.code-block code .hljs-template-variable,
.article-min-container pre.code-block code .hljs-type,
.article-min-container pre.code-block code .hljs-selector-class,
.article-min-container pre.code-block code .hljs-selector-attr,
.article-min-container pre.code-block code .hljs-selector-pseudo,
.article-min-container pre.code-block code .hljs-number,
.article-min-container pre.code-block code .hljs-attr::selection,
.article-min-container pre.code-block code .hljs-variable::selection,
.article-min-container pre.code-block code .hljs-template-variable::selection,
.article-min-container pre.code-block code .hljs-type::selection,
.article-min-container pre.code-block code .hljs-selector-class::selection,
.article-min-container pre.code-block code .hljs-selector-attr::selection,
.article-min-container pre.code-block code .hljs-selector-pseudo::selection,
.article-min-container pre.code-block code .hljs-number::selection,
.page-content-inner .page-content pre.code-block code .hljs-attr,
.page-content-inner .page-content pre.code-block code .hljs-variable,
.page-content-inner .page-content pre.code-block code .hljs-template-variable,
.page-content-inner .page-content pre.code-block code .hljs-type,
.page-content-inner .page-content pre.code-block code .hljs-selector-class,
.page-content-inner .page-content pre.code-block code .hljs-selector-attr,
.page-content-inner .page-content pre.code-block code .hljs-selector-pseudo,
.page-content-inner .page-content pre.code-block code .hljs-number,
.page-content-inner .page-content pre.code-block code .hljs-attr::selection,
.page-content-inner .page-content pre.code-block code .hljs-variable::selection,
.page-content-inner .page-content pre.code-block code .hljs-template-variable::selection,
.page-content-inner .page-content pre.code-block code .hljs-type::selection,
.page-content-inner .page-content pre.code-block code .hljs-selector-class::selection,
.page-content-inner .page-content pre.code-block code .hljs-selector-attr::selection,
.page-content-inner .page-content pre.code-block code .hljs-selector-pseudo::selection,
.page-content-inner .page-content pre.code-block code .hljs-number::selection {
    color: #d19a66;
}

.article-min-container pre.code-block code .hljs-symbol,
.article-min-container pre.code-block code .hljs-bullet,
.article-min-container pre.code-block code .hljs-link,
.article-min-container pre.code-block code .hljs-meta,
.article-min-container pre.code-block code .hljs-selector-id,
.article-min-container pre.code-block code .hljs-title,
.article-min-container pre.code-block code .hljs-symbol::selection,
.article-min-container pre.code-block code .hljs-bullet::selection,
.article-min-container pre.code-block code .hljs-link::selection,
.article-min-container pre.code-block code .hljs-meta::selection,
.article-min-container pre.code-block code .hljs-selector-id::selection,
.article-min-container pre.code-block code .hljs-title::selection,
.page-content-inner .page-content pre.code-block code .hljs-symbol,
.page-content-inner .page-content pre.code-block code .hljs-bullet,
.page-content-inner .page-content pre.code-block code .hljs-link,
.page-content-inner .page-content pre.code-block code .hljs-meta,
.page-content-inner .page-content pre.code-block code .hljs-selector-id,
.page-content-inner .page-content pre.code-block code .hljs-title,
.page-content-inner .page-content pre.code-block code .hljs-symbol::selection,
.page-content-inner .page-content pre.code-block code .hljs-bullet::selection,
.page-content-inner .page-content pre.code-block code .hljs-link::selection,
.page-content-inner .page-content pre.code-block code .hljs-meta::selection,
.page-content-inner .page-content pre.code-block code .hljs-selector-id::selection,
.page-content-inner .page-content pre.code-block code .hljs-title::selection {
    color: #61aeee;
}

.article-min-container pre.code-block code .hljs-emphasis,
.page-content-inner .page-content pre.code-block code .hljs-emphasis {
    font-style: italic;
}

.article-min-container pre.code-block code .hljs-strong,
.page-content-inner .page-content pre.code-block code .hljs-strong {
    font-weight: bold;
}

.article-min-container pre.code-block code .hljs-link,
.page-content-inner .page-content pre.code-block code .hljs-link {
    text-decoration: underline;
}

.article-min-container table code,
.page-content-inner .page-content table code {
    caret-color: white;
    color: var(--primary) !important;
    background-color: var(--secondary);
    border-radius: 0.125rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    line-height: 24px;
}

.article-min-container table,
.page-content-inner .page-content table {
    box-sizing: border-box;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    border-radius: 0.25rem;
    border-color: var(--secondary);
    margin-bottom: 20px;
}

.article-min-container table td,
.article-min-container table th,
.page-content-inner .page-content table td,
.page-content-inner .page-content table th {
    position: relative;
    min-width: 100px;
    padding: 0.5rem;
    text-align: left;
    vertical-align: top;
    border: 1px solid var(--table-border);
    word-break: break-word;
    overflow-wrap: break-word;
}

.article-min-container table::-webkit-scrollbar-thumb {
    border-radius: 6px !important;
    padding: 5px !important;
    background-color: #eaecf0 !important;
}

.article-min-container table::-webkit-scrollbar {
    height: 5px !important;
    width: 6px !important;
}

.article-min-container table td p,
.article-min-container table th p,
.page-content-inner .page-content table td p,
.page-content-inner .page-content table th p {
    margin: 0px;
}

.article-min-container table ul,
.article-min-container table ol,
.page-content-inner .page-content table ul,
.page-content-inner .page-content table ol {
    padding-inline-start: 20px;
    margin: 0 !important;
}

.article-min-container table ul[data-type="taskList"],
.page-content-inner .page-content table ul[data-type="taskList"] {
    padding-inline-start: 0 !important;
}

.article-min-container [data-type='columns'].layout-sidebar-left,
.page-content-inner .page-content [data-type='columns'].layout-sidebar-left {
    display: grid;
    gap: 1rem;
    grid-template-columns: 40fr 60fr;
    margin-bottom: 0.8rem;
}

.article-min-container [data-type='columns'] [data-type='column'],
.page-content-inner .page-content [data-type='columns'] [data-type='column'] {
    overflow: auto;
    padding: 0.25rem;
    border-radius: 0.25rem;
}

.article-min-container [data-type='columns'].layout-sidebar-right,
.page-content-inner .page-content [data-type='columns'].layout-sidebar-right {
    grid-template-columns: 60fr 40fr;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    display: grid;
    gap: 1rem;
}

.article-min-container [data-type='columns'].layout-two-column,
.page-content-inner .page-content [data-type='columns'].layout-two-column {
    grid-template-columns: 1fr 1fr;
    margin: 0 0 20px;
    display: grid;
    gap: 1rem;
}

.article-min-container [data-type='columns'] .image-preview,
.page-content-inner .page-content [data-type='columns'] .image-preview {
    margin: 0;
}

.article-min-container .TableOfContentsWrapper,
.page-content-inner .page-content .TableOfContentsWrapper {
    margin-bottom: 20px;
}

.article-min-container .TableOfContentsWrapper .TableOfContentsTitle,
.page-content-inner .page-content .TableOfContentsWrapper .TableOfContentsTitle {
    margin-bottom: 0.3rem;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--lightfont);
}

.article-min-container .TableOfContentsWrapper .TableOfContentsCover,
.page-content-inner .page-content .TableOfContentsWrapper .TableOfContentsCover {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    overflow-x: hidden;
}

.article-min-container .TableOfContentsWrapper .TableOfContentsItem,
.page-content-inner .page-content .TableOfContentsWrapper .TableOfContentsItem {
    display: block;
    font-weight: 500;
    color: var(--lightfont);
    padding: 0.25rem;
    border-radius: 0.25rem;
    font-size: 16px;
    transition: all 0.3s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: none;
}

.article-min-container .TableOfContentsWrapper .TableOfContentsItem:hover,
.page-content-inner .page-content .TableOfContentsWrapper .TableOfContentsItem:hover {
    opacity: 0.7;
}

.article-min-container .video-wrapper.youtube-video,
.article-min-container .video-wrapper.vimeo-video,
.page-content-inner .page-content .video-wrapper.youtube-video,
.page-content-inner .page-content .video-wrapper.vimeo-video {
    background-color: var(--darkGreen);
    margin-bottom: 20px;
}

.article-min-container .video-wrapper.youtube-video .player-wrapper,
.article-min-container .video-wrapper.vimeo-video .player-wrapper,
.page-content-inner .page-content .video-wrapper.youtube-video .player-wrapper,
.page-content-inner .page-content .video-wrapper.vimeo-video .player-wrapper {
    width: 100%;
    margin-top: -5px;
    line-height: 0;
    height: 380px;
    display: block;
    margin-bottom: 0;
}

.page-content-inner .page-content.page-data .video-wrapper.youtube-video .player-wrapper {
    height: 223px !important;
}

.page-content-inner .page-content.page-data .video-wrapper.vimeo-video .player-wrapper {
    height: 222px !important;
}

.article-min-container .video-wrapper.youtube-video .video-caption,
.article-min-container .video-wrapper.vimeo-video .video-caption,
.page-content-inner .page-content .video-wrapper.youtube-video .video-caption,
.page-content-inner .page-content .video-wrapper.vimeo-video .video-caption {
    min-height: 24px;
    text-align: center;
    margin-bottom: 0 !important;
    outline: none;
    border: none;
    box-shadow: none;
    margin-top: 0;
    font-size: 14px;
    background-color: var(--darkGreen);
}

.article-min-container .video-wrapper.vimeo-video .react-player,
.page-content-inner .page-content .video-wrapper.vimeo-video .react-player {
    width: 100%;
    height: 100%;
}

.article-min-container .video-wrapper .video-title,
.page-content-inner .page-content .video-wrapper .video-title {
    padding: 5px 0;
}

.article-min-container h1,
.article-min-container h2,
.article-min-container h3,
.page-content-inner .page-content h1,
.page-content-inner .page-content h2,
.page-content-inner .page-content h3 {
    margin-bottom: 20px !important;
    color: var(--white)
}

.article-min-container h1,
.page-content-inner .page-content h1 {
    margin-bottom: 1rem;
}

.article-min-container h1:first-child,
.article-min-container h2:first-child,
.article-min-container h3:first-child,
.page-content-inner .page-content h1:first-child,
.page-content-inner .page-content h2:first-child,
.page-content-inner .page-content h3:first-child {
    margin-top: 0 !important;
}

.article-min-container ul,
.article-min-container ol,
.page-content-inner .page-content ul,
.page-content-inner .page-content ol {
    margin-top: 0;
    margin-bottom: 1.3rem;
}

.article-min-container [data-type="taskList"] li label,
.page-content-inner .page-content [data-type="taskList"] li label {
    margin-bottom: 2px;
}

.article-min-container [data-type="taskList"] li p,
.page-content-inner .page-content [data-type="taskList"] li p {
    margin-top: 0;
}

.article-min-container ul li p span,
.article-min-container ol li p span,
.article-min-container p span,
.page-content-inner .page-content ul li p span,
.page-content-inner .page-content ol li p span,
.page-content-inner .page-content p span {
    color: var(--lightfont);
    line-height: 27px;
}

.article-min-container .spotify-embed-wrapper,
.page-content-inner .page-content .spotify-embed-wrapper {
    margin-bottom: 20px;
}

.article-min-container .twitter-tweet.twitter-tweet-rendered,
.page-content-inner .page-content .twitter-tweet.twitter-tweet-rendered {
    margin-bottom: 20px !important;
    margin: auto;
}

.article-min-container .twitter-tweet.twitter-tweet-rendered iframe {
    max-width: 100%;
}

.article-min-container .spotify-embed-wrapper[data-spotify-type="track"] iframe,
.page-content-inner .page-content .spotify-embed-wrapper[data-spotify-type="track"] iframe {
    max-height: 232px;
}

.article-min-container .spotify-embed-wrapper[data-spotify-type="episode"] iframe,
.page-content-inner .page-content .spotify-embed-wrapper[data-spotify-type="episode"] iframe {
    max-height: 352px;
}

.article-min-container div[data-type="other"],
.article-min-container .soundcloud-embed-wrapper,
.page-content-inner .page-content div[data-type="other"],
.page-content-inner .page-content .soundcloud-embed-wrapper {
    position: relative;
    margin-bottom: 20px;
    text-align: center;
}

.article-min-container div[data-type="other"]>*,
.page-content-inner .page-content div[data-type="other"]>* {
    max-width: 100%;
}

.article-min-container [data-type='horizontalRule'],
.page-content-inner .page-content [data-type='horizontalRule'] {
    transition-property: all;
    transition-duration: 100ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    animation-duration: 100ms;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    margin-bottom: 20px;
}

.article-min-container [data-type='horizontalRule'] hr,
.page-content-inner .page-content [data-type='horizontalRule'] hr {
    border-width: 0px;
    border-top-width: 1px;
    border-color: var(--white);
    /* background-color: rgb(0 0 0 / 0.8);  */
    margin: revert;
}

.article-min-container .video-preview video,
.page-content-inner .page-content .video-preview video {
    width: 100% !important;
}

.article-min-container [data-type="imageBlock"] .image-width-default img,
.page-content-inner .page-content [data-type="imageBlock"] .image-width-default img {
    max-width: 100%;
    width: 100% !important;
}

.article-min-container [data-type="logoSliderBlock"] .swiper-slider {
    z-index: 50;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    text-align: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: flex;
    position: relative;
    overflow: hidden;
}

.page-content-inner [data-type="logoSliderBlock"] .swiper-slider-container {
    overflow: unset !important;
}

[data-type="logoSliderBlock"] .swiper-slider-container {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    width: 100%;
    display: flex;
}

[data-type="logoSliderBlock"] .swiper-slider-list {
    animation: logoSliderScroll 15s linear infinite;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    will-change: transform;
    transform-style: preserve-3d;
    animation-duration: 66.8s;
    flex: none;
}

[data-type="logoSliderBlock"] .swiper-items {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    display: flex;
}

[data-type="logoSliderBlock"] .swiper-slide-item {
    height: 2.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

[data-type="logoSliderBlock"] .swiper-slide-item img {
    vertical-align: middle;
    max-width: 100%;
    display: inline-block;
    height: 100%;
    border: 0;
}

.article-min-container [data-type="logoSliderBlock"],
.page-content-inner .page-content [data-type="logoSliderBlock"] {
    margin-bottom: 30px;
}

.article-min-container [data-type="imageBlock"] .image-width-full,
.article-min-container [data-type="VideoBlock"] .image-width-wide,
.article-min-container [data-type="logoSliderBlock"][data-layout="full"],
.article-min-container [data-type="galleryBlock"][data-layout="full"],
.page-content-inner .page-content [data-type="imageBlock"] .image-width-full,
.page-content-inner .page-content [data-type="VideoBlock"] .image-width-wide,
.page-content-inner .page-content [data-type="logoSliderBlock"][data-layout="full"],
.page-content-inner .page-content [data-type="galleryBlock"][data-layout="full"] {
    max-width: 1200px;
    transform: translateX(-50%);
    left: 50%;
    position: relative;
    width: 100vw;
    padding: 0 20px;
}

.article-min-container .image-preview img,
.page-content-inner .page-content .image-preview img {
    width: 100% !important;
    height: auto;
    object-fit: cover;
}

.article-min-container .image-preview,
.article-min-container .video-preview,
.page-content-inner .page-content .image-preview,
.page-content-inner .page-content .video-preview {
    margin-bottom: 24px;
}

.article-min-container .image-preview .image-caption,
.article-min-container .video-preview .video-caption,
.article-min-container .gallery-block-view .video-caption,
.page-content-inner .page-content .image-preview .image-caption,
.page-content-inner .page-content .video-preview .video-caption,
.page-content-inner .page-content .gallery-block-view .video-caption {
    min-height: 24px;
    text-align: left;
    margin: 0 !important;
    line-height: 27px;
    font-size: 14px;
    padding: 5px 0;
    padding-top: 0;
    color: var(--lightfont);
}

.article-min-container .gallery-block-view .video-caption,
.page-content-inner .page-content .gallery-block-view .video-caption {
    padding-top: 5px;
}

.page-content-inner .page-content .gallery-block-view {
    margin: 10px 0;
}

.article-min-container .product-block,
.page-content-inner .page-content .product-block {
    margin-bottom: 30px;
}

.article-min-container .product-block:first-of-type,
.page-content-inner .page-content .product-block:first-of-type {
    padding-top: 14px;
}

.article-min-container .product-block .product-title,
.page-content-inner .page-content .product-block .product-title {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
    margin-bottom: 5px !important;
}

.article-min-container .product-block .product-title-wrapper,
.page-content-inner .page-content .product-block .product-title-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 7px;
}

.article-min-container .product-block .product-description,
.page-content-inner .page-content .product-block .product-description {
    color: var(--ghost);
    margin: 0;
    font-weight: 500;
}

.article-min-container .product-block .product-button,
.page-content-inner .page-content .product-block .product-button {
    background-color: var(--white);
    border: 1px solid var(--white);
    border-radius: 6px;
    color: var(--primary);
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    padding: 5px 10px;
    transition: all .3s linear;
    width: 100%;
    margin-top: 10px;
    text-align: center;
}

.article-min-container .product-block .rating,
.page-content-inner .page-content .product-block .rating {
    display: flex;
    flex-direction: row;
    margin: 0;
    gap: 3px;
}

.article-min-container .product-block .rating .rate.highlighted svg,
.page-content-inner .page-content .product-block .rating .rate.highlighted svg {
    fill: #F2AE40;
}

.article-min-container .product-block .rating .rate,
.page-content-inner .page-content .product-block .rating .rate {
    font-size: 35px;
    cursor: auto !important;
}

.article-min-container .publications-wrapper .publication-list.grid.four-column,
.page-content-inner .page-content .publications-wrapper .publication-list.grid.four-column {
    grid-template-columns: repeat(4, minmax(0px, 1fr));
}

.article-min-container .publications-wrapper .publication-list.grid.three-column,
.page-content-inner .page-content .publications-wrapper .publication-list.grid.three-column {
    grid-template-columns: repeat(3, minmax(0px, 1fr));
}

.article-min-container .publications-wrapper .publication-list.grid.two-column,
.page-content-inner .page-content .publications-wrapper .publication-list.grid.two-column {
    grid-template-columns: repeat(2, minmax(0px, 1fr));
}

.article-min-container .publications-wrapper .publication-list.grid.one-column,
.page-content-inner .page-content .publications-wrapper .publication-list.grid.one-column {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
}

.article-min-container .publication-list.grid,
.page-content-inner .page-content .publication-list.grid {
    display: grid;
    align-items: start;
    column-gap: 20px;
    row-gap: 20px;
}

.article-min-container .publication-list,
.page-content-inner .page-content .publication-list {
    /* border: 1px solid #573CFF;  */
    border-radius: 6px;
    /* padding: 14px 0; */
}

.article-min-container .publications-wrapper,
.page-content-inner .page-content .publications-wrapper {
    margin-bottom: 20px;
}

.article-min-container .publications-wrapper .publication-list.grid .publication-item,
.page-content-inner .page-content .publications-wrapper .publication-list.grid .publication-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 0;
}

.article-min-container .publications-wrapper .publication-list.grid .publication-item .publication-image,
.page-content-inner .page-content .publications-wrapper .publication-list.grid .publication-item .publication-image {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    line-height: 0;
    overflow: hidden;
}

.article-min-container .publications-wrapper .publication-image:hover img,
.page-content-inner .page-content .publications-wrapper .publication-image:hover img {
    transform: scale(1.05, 1.05);
}

.article-min-container .publication-list.grid .publication-item .publication-image img,
.article-min-container .publication-list.list .publication-item .publication-image img,
.page-content-inner .page-content .publication-list.grid .publication-item .publication-image img,
.page-content-inner .page-content .publication-list.list .publication-item .publication-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    max-width: 100%;
    max-height: 100%;
    transform: scale(1, 1);
    transition-property: transform, filter, opacity;
    transition-timing-function: cubic-bezier(0.57, 0.21, 0.69, 1);
    transition-duration: 0.3s;
}

.article-min-container .publications-wrapper .publication-list.grid .publication-data,
.page-content-inner .page-content .publications-wrapper .publication-list.grid .publication-data {
    margin-top: 12px;
}

.article-min-container .publications-wrapper .publication-data .publication-title,
.article-min-container .publications-wrapper .publication-data .publication-title a,
.page-content-inner .page-content .publications-wrapper .publication-data .publication-title,
.page-content-inner .page-content .publications-wrapper .publication-data .publication-title a {
    margin-bottom: 0 !important;
    line-height: 22px;
    font-size: 16px;
    font-weight: 600;
    color: var(--white);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.article-min-container .publication-list.grid .publication-data .publication-date,
.page-content-inner .page-content .publication-list.grid .publication-data .publication-date {
    font-size: 14px;
    padding: 5px 0;
    color: var(--lightfont);
}

.article-min-container .publications-wrapper .publication-data .publication-description,
.page-content-inner .page-content .publications-wrapper .publication-data .publication-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 22px;
    color: var(--lightfont);
}

.article-min-container .publication-list.grid .publication-item .publication-image a,
.article-min-container .publication-list.list .publication-item .publication-image a,
.page-content-inner .page-content .publication-list.grid .publication-item .publication-image a,
.page-content-inner .page-content .publication-list.list .publication-item .publication-image a {
    display: block;
}

.article-min-container .publication-list.grid .publication-item .publication-image a:hover img,
.article-min-container .publication-list.list .publication-item .publication-image a:hover img,
.page-content-inner .page-content .publication-list.grid .publication-item .publication-image a:hover img,
.page-content-inner .page-content .publication-list.list .publication-item .publication-image a:hover img {
    transform: scale(1.05, 1.05);
}

.article-min-container .publications-wrapper .publication-list.list,
.page-content-inner .page-content .publications-wrapper .publication-list.list {
    display: block;
}

.article-min-container .publication-list.list .publication-item:first-child,
.page-content-inner .page-content .publication-list.list .publication-item:first-child {
    margin-top: 0 !important;
}

.article-min-container .publication-list.list .publication-item,
.page-content-inner .page-content .publication-list.list .publication-item {
    display: flex;
    align-items: flex-start;
    margin-top: 30px;
}

.article-min-container .publications-wrapper .publication-list.list .publication-image,
.page-content-inner .page-content .publications-wrapper .publication-list.list .publication-image {
    width: 25%;
    border-radius: 6px;
    overflow: hidden;
    line-height: 0;
}

.article-min-container .publication-list.list .publication-data,
.page-content-inner .page-content .publication-list.list .publication-data {
    padding-left: 15px;
    width: calc(100% - 25%);
    font-size: 14px;
}

.article-min-container .gallery-block-view .image-row,
.page-content-inner .page-content .gallery-block-view .image-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}

.article-min-container .gallery-block-view .image-row .gallery-image-wrapper img,
.page-content-inner .page-content .gallery-block-view .image-row .gallery-image-wrapper img {
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    object-fit: cover;
}

.article-min-container .gallery-block-view,
.page-content-inner .page-content .gallery-block-view {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.article-min-container .fileblock-wrapper,
.page-content-inner .page-content .fileblock-wrapper {
    margin-bottom: 20px;
}

.article-min-container .fileblock-wrapper .uploadFileBlock,
.page-content-inner .page-content .fileblock-wrapper .uploadFileBlock {
    display: flex;
    align-items: center;
    gap: 20px;
    min-height: unset;
    max-width: unset;
    background: transparent;
    border: none;
    box-shadow: none;
    cursor: unset;
    justify-content: unset;
}

.article-min-container .fileblock-wrapper .file-detail,
.page-content-inner .page-content .fileblock-wrapper .file-detail {
    width: 100%;
}

.article-min-container .fileblock-wrapper .file-detail .file-name,
.page-content-inner .page-content .fileblock-wrapper .file-detail .file-name {
    color: var(--secondary);
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 27px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.article-min-container .fileblock-wrapper .file-caption,
.page-content-inner .page-content .fileblock-wrapper .file-caption {
    width: calc(100% - 100px);
    font-size: 14px;
    color: var(--lightfont);
    margin-top: 0;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 22px;
    margin-bottom: 0;
}

.article-min-container .fileblock-wrapper .newPagefileUploadText,
.page-content-inner .page-content .fileblock-wrapper .newPagefileUploadText {
    margin: 0;
    font-size: 14px;
}

.article-min-container .fileblock-wrapper .newPagefileUploadText span,
.page-content-inner .page-content .fileblock-wrapper .newPagefileUploadText span {
    color: #777777 !important;
    margin-left: 5px;
    font-size: 14px;
}

.article-min-container .fileblock-wrapper .uploadFileBlock .file-icon svg,
.page-content-inner .page-content .fileblock-wrapper .uploadFileBlock .file-icon svg {
    height: 40px;
}

.article-min-container .fileblock-wrapper .uploadFileBlock .file-icon .icons-img,
.page-content-inner .page-content .fileblock-wrapper .uploadFileBlock .file-icon .icons-img {
    max-width: 40px;
    margin-left: 0.4rem;
}

.article-min-container .fileblock-wrapper .uploadFileBlock .file-icons,
.page-content-inner .page-content .fileblock-wrapper .uploadFileBlock .file-icons {
    max-width: calc(40px + 0.4rem);
}

.article-min-container .swiper-slide img,
.page-content-inner .page-content .swiper-slide img {
    max-width: 100%;
}

.article-min-container sup,
.article-min-container sub,
.page-content-inner .page-content sup,
.page-content-inner .page-content sub {
    font-size: smaller;
}

.article-min-container [data-type="galleryBlock"] .gallery-preview,
.page-content-inner .page-content [data-type="galleryBlock"] .gallery-preview {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.article-min-container [data-type="galleryBlock"] .gallery-preview .gallery-row,
.page-content-inner .page-content [data-type="galleryBlock"] .gallery-preview .gallery-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}

.article-min-container [data-type="galleryBlock"] .gallery-preview img,
.page-content-inner .page-content [data-type="galleryBlock"] .gallery-preview img {
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    object-fit: cover;
}

.article-min-container [data-type="columns"] div ul,
.page-content-inner .page-content [data-type="columns"] div ul {
    margin: 0 !important;
}

.hero-content .page-content-inner {
    width: 100%;
}

.article-min-container .fileblock-wrapper .file-caption {
    margin-bottom: 0;
}

.publicationSocial .socialIcon li {
    border: 1px solid var(--iconboder);
    line-height: 0;
    border-radius: 20px;
    padding: 9px;
    cursor: pointer;
}

.publicationSocial .socialIcon li:hover {
    background-color: var(--primary);
}

.publicationSocial .Copylink .socialIcon svg {
    height: 16px;
    width: 16px;
}

.publicationSocial .socialIcon svg {
    height: 20px;
    width: 20px;
}

.publicationSocial .socialIcon .text-tooltip {
    position: absolute;
    font-size: 11px;
    background: var(--primary);
    color: var(--ghost);
    padding: 12px 6px;
    border-radius: 6px;
    font-weight: 500;
    margin-top: 75px;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border: 1px solid var(--iconboder);
}
.preview-loader{
    min-height: 100vh !important;
}
.publicationSocial .socialIcon .text-tooltip:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 17px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--iconboder) transparent;
}

.article-min-container .twitterBlock {
    background-color: #fff;
}
.article-min-container .twitterBlock table {
    margin: 0;
}
.article-min-container [data-type="twitter"] .twitterBlock .userName,
.page-content-inner [data-type="twitter"] .twitterBlock .userName {
    display: block;
    font-size: 16px;
    color: #1c2022;
    font-weight: 700;
    margin-bottom: 0;
    line-height: unset;
}
.article-min-container [data-type="twitter"] .twitterBlock .userNamewithLink,  
.page-content-inner [data-type="twitter"] .twitterBlock .userNamewithLink {  
    display: block;
    color: #697882;
    font-size: 16px;
    margin-bottom: 0;
    line-height: unset;
}
.article-min-container [data-type="twitter"] .retweetCount,
.page-content-inner [data-type="twitter"] .retweetCount {
    margin-left: 5px;
}
.article-min-container [data-type="twitter"] .likeCount,
.page-content-inner [data-type="twitter"] .likeCount,
.article-min-container [data-type="twitter"] .retweetCount,
.page-content-inner [data-type="twitter"] .retweetCount {
    color: #697882;
    font-size: 14px;
}
.article-min-container [data-type="twitter"] .likeCount span,
.page-content-inner [data-type="twitter"] .likeCount span,
.article-min-container [data-type="twitter"] .retweetCount span,
.page-content-inner [data-type="twitter"] .retweetCount span {
    color: #1C2022;
    font-weight: bold;
}
.article-min-container [data-type="twitter"] .displayTime,
.page-content-inner [data-type="twitter"] .displayTime {
    padding-top: 10px;
}
.article-min-container .page-content .twitterBlock table td,
.page-content-inner .page-content .twitterBlock table td {
    padding: 0;
}
.article-min-container .page-content .twitterBlock table,
.page-content-inner .page-content .twitterBlock table {
    margin-bottom: 0;
}

.socialIcon {
    display: flex;
    list-style: none;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    pointer-events: none;
}

.socialIcon .Copylink {
    display: flex;
    align-items: center;
    gap: 5px;
}

.copyLinkText {
    font-size: 14px;
    font-weight: 600;
    color: var(--lightfont);
}

@media (min-width:1400px) {
    .singleArticle .heroImage {
        position: relative;
        height: 85dvh;
    }
}

@media (max-width:1400px) {
    .publicationTitle {
        font-size: 36px;
        line-height: 40px;
    }

    .singleArticle .heroContent {
        padding: 80px 30px 30px;
        justify-content: center;
    }

    .singleArticle .pageContentInner .pageContent {
        height: auto;
    }

    .header-actions {
        right: 30px;
    }
}

@media (max-width:1201px) {
    .home .hero-image,.hero-image {
        height: 100vh;
    }
}

@media (max-width:1200px) {
    #hero-and-body {
        margin-left: 0;
        width: 100%;
        margin-top: 0;
    }

    .hero-wrap {
        flex-direction: column-reverse;
    }

    .hero-wrap .hero-col {
        width: 100%;
        padding-top: 0;
    }

    .hero-image {
        height: 60dvh;
        padding-top: 0;
        position: relative;
        width: 100%;
        line-height: 0;
    }

    .hero-image img {
        position: relative;
    }

    .overlay {
        background: none !important;
    }

    .publication-title {
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 5px;
    }

    .articleContainer {
        max-width: 100%;
        flex-wrap: wrap;
    }

    .singleArticle .heroCaption {
        padding: 10px 30px 0;
    }

    .articleContainer .articleCard,
    .articleContainer .articleSidebar {
        margin: 0 auto;
        max-width: 760px;
    }

    .articleContainer .articleSidebar:before {
        display: none;
    }

    .singleArticle .heroContent {
        height: auto;
        padding-bottom: 20px;
        padding-top: 10px;
    }

    .content {
        margin-bottom: 15px;
    }

    .article-gapping {
        padding: 0 20px;
    }

    .articleDetail {
        padding-top: 0px;
        padding-bottom: 30px;
    }

    .singleArticle .header-inner .header-share-social>.social-icons-cover {
        margin: 0;
        position: fixed;
        width: 100%;
        left: 0;
        top: 50px;
        display: flex;
        justify-content: flex-start;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s linear;
        padding: 35px 25px 10px;
    }

    .singleArticle .header-inner .header-share-social.show-share>.social-icons-cover {
        opacity: 1;
        visibility: visible;
    }

    .singleArticle .header-inner .header-share-social>.social-icons-cover li {
        margin: 0 10px 5px 0;
    }

    .caption {
        margin: auto;
        margin-top: 10px;
        width: unset;
    }

    .heroCol .arrowImg {
        display: none;
    }

    .articleContainer .articleSidebar {
        max-width: 100%;
        position: relative;
        width: 100%;
    }

    .articleContainer .articleSidebar,
    .articleContainer .articleCard,
    .caption {
        padding: 0 30px;
    }

    .articleContent .heroCaption {
        margin-top: 5px;
    }

    .articleContent .heroCaption p {
        margin-bottom: 0;
    }

    .singleArticle .publication-name-date {
        padding: 0;
    }
}

@media (max-width: 992px) {

    .article-min-container [data-type="imageBlock"] .image-width-full img,
    .page-content-inner .page-content [data-type="imageBlock"] .image-width-full img {
        max-width: 1000px
    }
}

@media (max-width:767px) {
    .desktop {
        display: none !important;
    }

    .mobile {
        display: block !important;
    }
    .preview-newsletter__header{
        flex-direction: column;
        gap: 8px 0;
        padding: 20px;
    }
    .article-min-container [data-type="imageBlock"] .image-width-full img,
    .page-content-inner .page-content [data-type="imageBlock"] .image-width-full img {
        max-width: 700px;
    }
    .article-min-container [data-type='bookmarkBlock'].link-content img, .page-content-inner .page-content [data-type='bookmarkBlock'].link-content img{
        height: auto;
    }
    .article-min-container [data-type='bookmarkBlock'] .textBlock.linkBox .linkImageSection,
    .page-content-inner .page-content [data-type='bookmarkBlock'] .textBlock.linkBox .linkImageSection {
        width: 100% !important;
    }
    .article-min-container [data-type='bookmarkBlock'] .linkBox .linkData, .page-content-inner .page-content [data-type='bookmarkBlock'] .linkBox .linkData{
        width: 100%;
        padding-left: 0;
        padding-top: 15px;
    }
    .article-min-container [data-type='bookmarkBlock'] .linkBox ,
    .page-content-inner .page-content [data-type='bookmarkBlock'] .linkBox {
       flex-direction: column;
    }

    .article-min-container .publications-wrapper .publication-list.grid.three-column,
    .article-min-container .publications-wrapper .publication-list.grid.four-column,
    .page-content-inner .page-content .publications-wrapper .publication-list.grid.three-column,
    .page-content-inner .page-content .publications-wrapper .publication-list.grid.four-column {
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        gap: 20px;
    }

    .article-min-container .video-wrapper.vimeo-video .player-wrapper,
    .article-min-container .video-wrapper.youtube-video .player-wrapper {
        padding-top: 56%;
        position: relative;
        height: auto;
    }

    .article-min-container .video-wrapper.vimeo-video .player-wrapper iframe,
    .article-min-container .video-wrapper.youtube-video .player-wrapper iframe {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }

    #hero-and-body {
        margin-top: 0;
    }

    .singleArticle .heroContent {
        padding: 20px;
    }

    .singleArticle .heroCaption {
        padding: 10px 20px 0;
    }

    .singleArticle .publication-social p {
        margin-bottom: 0;
    }

    .publication-title {
        font-size: 30px;
    }

    .publicationContent {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        align-items: flex-start;
        gap: 15px;
    }

    .content {
        margin-bottom: 15px;
    }

    .articleContainer .articleSidebar,
    .articleContainer .articleCard,
    .caption {
        padding: 0 20px;
    }
    .singleArticle .heroWrap {
        margin-top: 89px;
    }
    .copyLinkText {
        display: block;
    }


}

@media (max-width:420px) {

    .publication-content .publication-paragraph.text-white {
        font-size: 14px;
        font-weight: 400;
        color: #CECFD2;
        line-height: 17px;
        text-align: start;
    }

    .publication-content .publication-title.text-white {
        font-size: 35px;
    }

    .article-min-container [data-type="imageBlock"] .image-width-full img,
    .page-content-inner .page-content [data-type="imageBlock"] .image-width-full img {
        max-width: 400px;
    }

    .article-min-container .publications-wrapper .publication-list.grid.three-column,
    .article-min-container .publications-wrapper .publication-list.grid.four-column,
    .article-min-container .publications-wrapper .publication-list.grid.two-column,
    .page-content-inner .page-content .publications-wrapper .publication-list.grid.three-column,
    .page-content-inner .page-content .publications-wrapper .publication-list.grid.four-column,
    .page-content-inner .page-content .publications-wrapper .publication-list.grid.two-column {
        grid-template-columns: repeat(1, minmax(0px, 1fr));
    }

    .article-min-container .publications-wrapper .publication-list.list.three-column .publication-item,
    .page-data .publications-wrapper .publication-list.list.three-column .publication-item {
        flex-direction: row;
    }

    .article-min-container .publications-wrapper .publication-list.list .publication-image,
    .page-content-inner .page-content .publications-wrapper .publication-list.list .publication-image {
        width: 30%;
    }

    .article-min-container .publications-wrapper .publication-list.list .publication-data,
    .page-data .publications-wrapper .publication-list.list .publication-data {
        padding-left: 10px;
        padding-top: 0;
        width: 70%;
    }
    .publicationContent {
        padding-left: 0;
    }
    
    .copyLinkText {
        display: none;
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes prixClipFix {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }

    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }

    50% {
        clip-path: polygon(50% 50%,
                0 0,
                100% 0,
                100% 100%,
                100% 100%,
                100% 100%);
    }

    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }

    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
}