.main-wrapper .logotype {
    display: flex;
    align-items: center;
}

.main-wrapper .logotype .logo-icon {
    max-width: 22px;
}
.main-wrapper .logotype .logo-text {
    max-width: 80px;
}

.main-wrapper .logotype span {
    color: #000;
    font-size: 22px;
    font-weight: 700;
    overflow: hidden;
    transition: width .7s;
    display: inline-block;
    margin-left: 5px;
}

.main-wrapper .sidebar .menuWrapper {
    display: block;
}

.main-wrapper .menuWrapper {
    padding-top: 0;
    padding-bottom: 0;
}

.menuWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
}

.main-wrapper .logo-hamburger-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 9px 9px 26px;
    border-bottom: 1px solid #eaecf0;
    min-height: 67px;
}

.main-wrapper .hamburger-menu:hover {
    background-color: #f2f2f2;
}

.main-wrapper .hamburger-menu {
    background-color: transparent;
    width: 48px;
    height: 48px;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: all 0.3s linear;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-wrapper .hamburger-menu span {
    display: block;
    height: 1.5pt;
    background-color: #667085;
    border-radius: 32px;
    margin: 0;
    position: relative;
    width: 18px;
}
.main-wrapper .hamburger-menu span:after,
.main-wrapper .hamburger-menu span:before {
    background: #667085;
    content: "";
    display: block;
    height: 1.5pt;
    left: 50%;
    margin-left: -9px;
    position: absolute;
    top: 10%;
    transform: rotate(0deg);
    transition: all 0.4s cubic-bezier(0.6, 0, 0.2, 1);
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.6, 0, 0.2, 1);
    width: 18px;
    border-radius: 32px;
}

.main-wrapper .hamburger-menu span:before {
    margin-top: -6px;
    top: 0;
}

.main-wrapper .hamburger-menu span:after {
    margin-top: 6px;
    top: 0;
}

.menu-wrapperBox {
    height: calc(100vh - 134px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    left: 2px;
    padding-top: 8px;
    padding-bottom: 8px
}

.menu-wrapperBox::-webkit-scrollbar-thumb {
    border-radius: 6px;
    padding: 5px;
    background-color: #EAECF0;
}

/* Handle on hover */
.menu-wrapperBox::-webkit-scrollbar-thumb:hover {
    background-color: #f2f2f2;
}

.menu-wrapperBox::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #f9fafc;
}

.menu-wrapperBox::-webkit-scrollbar {
    height: 5px;
    background-color: #f9fafc;
    width: 6px;
}

.main-wrapper .sidebar,
.main-wrapper .sidebar .menuList,
.main-wrapper .sidebar .menuWrapper {
    display: block;
}

.menuList {
    display: flex;
    align-items: center;
    padding-inline-start: 0;
    line-height: 2em;
}

.main-wrapper .sidebar nav .menuList .menuData > li {
    margin: 0 0 1px;
}

.main-wrapper .sidebar .menuList .menuListItem {
    width: calc(100% - 30px);
    display: flex;
    align-items: center;
    padding: 10px;
    color: #344054;
    font-size: 14px;
    font-weight: 500;
    gap: 12px;
    border-radius: 6px;
    line-height: 24px;
    transition: 1s;
    margin: 0 auto;
}

.main-wrapper .sidebar nav .menuList .menuListItem.menuItemActive,
.main-wrapper .sidebar nav .menuList .menuListItem.menuItemActive span {
    color: #101828;
    font-weight: 600;
}

.menuItemActive {
    background: #f9f9f9;
}

.main-wrapper .sidebar nav .menuList>li:hover .menuListItem {
    background-color: #f2f2f2;
}

.sidebar .menuList .menuListItem {
    white-space: nowrap;
}

.main-wrapper .sidebar .menuList .menuListItem > img {
    margin-right: 0;
    opacity: 0.7;
    width: 20px;
    position: relative;
    left: 2px;
}

.main-wrapper .sidebar .menuList .menuListItem.menuItemActive > img {
    opacity: 1;
}

.menuListItem > img {
    transition: 1.2s;
}

.main-wrapper .sidebar .menuList .menuListItem span {
    width: 80px;
    transition: width 0.7s;
}

.main-wrapper .sidebar .menuList .menuListItem span {
    color: #344054;
    font-size: 14px;
    font-weight: 500;
}

.main-wrapper .user-action {
    border-top: 1px solid #eaecf0;
    padding: 0;
    width: calc(100% - 24px);
    min-height: 67px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin: 0 auto;
}

.main-wrapper .user-action .menuRight {
    width: 100%;
    align-items: center;
    padding: 0 8px;
}

.menuRight {
    display: flex;
}

.main-wrapper .user-action .userProfile {
    width: 100%;
    justify-content: center;
}

.main-wrapper .user-action .userProfile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
}

.main-wrapper .user-action .userProfile .avatarVisible {
    margin-left: 0;
    align-items: center;
    width: 100%;
}

.avatarVisible {
    display: flex;
    align-items: center;
    margin-left: 15px;
}

.main-wrapper .sidebar-user {
    line-height: 1;
}

.main-wrapper .sidebar-user .avatar {
    padding-right: 0;
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50px;
}

.avatarVisible .avatar {
    margin-right: 0;
    padding-right: 15px;
}

.avatarVisible img {
    cursor: pointer;
}

.main-wrapper .user-action .userProfile .avatarVisible .sidebar-user-content {
    margin-left: 12px;
    width: 199px;
    max-width: 100%;
    height: 36px;
    transition: width 0.7s;
    overflow: hidden;
}

.main-wrapper .user-action .userProfile .avatarVisible .sidebar-user-content {
    margin-left: 12px;
    width: 199px;
    max-width: 100%;
    height: 36px;
    transition: width 0.7s;
    overflow: hidden;
}

.sidebar-user-name {
    color: #344054;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 199px;
}

.sidebar-user-mail {
    color: #667085;
    font-size: 14px;
    line-height: 16px;
    word-break: break-word;
}

.sidebar-logout {
    width: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.hide-menu .main-wrapper .user-action .userProfile .avatarVisible {
    align-items: center;
}

.hide-menu .sidebar-user-content {
    margin-left: 15px;
}

.hide-menu .main-wrapper .addNew {
    margin: 24px 20px 10px;
}

@media screen and (min-width: 1025px) {
    
    .main-wrapper {
        display: flex;
    }

    .main-wrapper .sidebar {
        width: 312px;
        border-right: 1px solid #eaecf0;
        min-height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        transition: all 1000ms cubic-bezier(0.74, 0.215, 0.125, 1.02);
        transition-delay: 0.06s;
        transition-duration: 1s;
        transition-timing-function: cubic-bezier(0.74, 0.215, 0.125, 1.02);
        background-color: #f9fafc;
        z-index: 9;
        overflow: hidden;
    }

    .main-wrapper .main-content {
        left: 312px;
        position: relative;
        transition: left 599ms cubic-bezier(.74,.215,.125,1.02),right 599ms cubic-bezier(.74,.215,.125,1.02)!important;
        width: calc(100% - 312px);
    }

    .hide-menu .logo-hamburger-menu .logotype {
        font-size: 0;
    }
    .hover-menu .logo-hamburger-menu .logotype span{
        width: 100px;
        transition: width .7s;
    }

    .hide-menu .logo-hamburger-menu .logotype span {
        transition: width .7s;
        width: 0;
    }

    .hide-menu .main-content {
        left: 81px;
        width: calc(100% - 81px);
    }

    .hover-menu .menuWrapper .logotype span {
        transition: width .7s;
        width: 100px;
    }

    .hide-menu .sidebar {
        background-color: #f9f9f9;
        width: 81px;
    }

    .hide-menu .hamburger-menu {
        display: none;
    }

    .hide-menu .main-wrapper .sidebar nav .menuList .menuListItem span {
        overflow: hidden;
        width: 0;
    }

    .hide-menu .user-action .sidebar-logout {
        display: none;
    }

    .hover-menu.sidebar {
        transition-delay: 0s;
        width: 312px;
        z-index: 99;
    }

    .hover-menu .sidebar-text-box .sidebar-social-list,
    .hover-menu .user-action .sidebar-logout {
        display: flex;
    }

    .hover-menu .hamburger-menu {
        display: flex;
    }

    .hide-menu .main-wrapper .hover-menu.sidebar nav .menuList .menuListItem span {
        width: 80px;
    }

    .hover-menu .sidebar-text-box h6, .hover-menu .sidebar-text-box p, .hover-menu .sidebar-user-content {
        display: block;
    }

    .main-wrapper .logo-hamburger-menu.mobile-view {
        display: none;
    }
}
@media screen and (max-width: 1024px) {
    .sidebar {
        position: fixed;
        width: 100%;
        z-index: 6;
        top: 0;
        background: #fff;
    }
    .main-wrapper:has(.uploadMediaPopup) .sidebar {
        z-index: 0;
    }
     
    body.hide-menu {
        height: 100%;
        overflow: hidden;
        position: fixed;
        width: 100%;
    }
    .menu-wrapperBox {
        height: calc(100dvh - 130px);
        left: 0;
        width: 100%;
    }
    .main-wrapper .logo-hamburger-menu.mobile-view {
        padding: 9px 12px;
    }
    .main-wrapper .logo-hamburger-menu .logotype {
        padding-left: 10px;
    }
    .main-wrapper .hamburger-menu {
        height: 35px;
        width: 35px;
    }
    .hide-menu .main-wrapper .hamburger-menu span:before {
        margin-top: 0;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    .hide-menu .main-wrapper .hamburger-menu span:after {
        margin-top: 0;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
    }
    .hide-menu .main-wrapper .hamburger-menu span {
        background: transparent!important;
        transition-delay: .1s;
        transition-duration: .2s;
    }
    .hide-menu .main-wrapper .main-content {
        left: calc(100% - 60px);
    }
    #myNav {
        background-color: #f9fafc;
        border-right: 1px solid #eaecf0;
        height: 100%;
        left: 0;
        position: fixed;
        right: 0;
        top: 67px;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        transition: all 799ms cubic-bezier(.74,.215,.125,1.02);
        transition-delay: .06s;
        transition-duration: .7s;
        transition-timing-function: cubic-bezier(.74,.215,.125,1.02);
        width: calc(100% - 60px);
        z-index: 99999;
    }   
    .hide-menu #myNav {
        box-shadow: 0 8px 8px -4px rgba(16,24,40,.03), 0 20px 24px -4px rgba(16,24,40,.08);
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    .main-wrapper .logo-hamburger-menu.desktop-view {
        display: none;
    }
}