.socialContent {
    width: 100%;
}
.addToMenu {
    align-items: center;
    background: #fff;
    border: 1px solid #d0d5dd;
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(16,24,40,.05);
    color: #344054;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    justify-content: center;
    line-height: 20px;
    margin-bottom: 20px;
    margin-left: 18px;
    padding: 8px 10px;
}
.addToMenu p {
    font-weight: inherit;
}
.stepCheckbox_block label {
    font-size: 14px;
    color: #667085;
    cursor: pointer;
}
.navEditableVisible>div img {
    margin-right: 12px;
}
.navLinkRow.inputFieldsIcon img {
    max-width: 15px;
}
.addToMenu img {
    margin-right: 6px;
}
.navigationBlocks .addToMenu img {
    margin-right: 0;
    margin-left: 6px;
    max-width: 14px;
}
.addToMenu .addMenuText {
    color: #344054;
}
.nestable-item.is-dragging:before {
    background: #f9f9f9 !important;
    border: 1px dashed #d0d5dd !important;
    width: 380px;
}
.navItem {
    background: #fff;
    border: 1px solid #d0d5dd;
    border-radius: 6px;
    box-shadow: 0 1px 3px #0000001a;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 10px;
    padding: 11.5px 14px;
    width: 380px;
}
.navEditableVisible {
    display: flex;
    justify-content: space-between;
    -webkit-user-select: none;
    user-select: none;
    align-items: center;
}
.navEditableVisible > div {
    display: flex;
}
.socialBlock {
    display: flex;
}
.socialBlock .socialLeft {
    max-width: 500px;
    width: 100%;
}
.socialRight {
    width: 100%;
    padding-left: 30px;
    max-width: 560px;
}
.socialMenuBlockTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
}
.socialMenuBlockSubtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    margin-top: 0;
}
.socialBlock .addToMenu {
    margin-top: 20px;
    margin-bottom: 20px;
}
.socialItemBlock .dragIcon {
    margin-right: 10px;
    cursor: grab;
}
.navEditableVisible .socialIcon {
    width: 18px;
    margin-right: 6px;
}
.socialIconFix {
    position: relative;
    top: 2px!important;
}
.navEditableVisible p {
    line-height: 14px;
    position: relative;
    top: 0;
}
.customLinkRow {
    margin-bottom: 15px;
    padding-left: 18px;
    padding-right: 18px;
    position: relative;
}
.navItem .customLinkRow {
    margin-bottom: 5px;
    margin-top: 15px;
    padding-left: 0;
    padding-right: 0;
}
.customLinkRow p {
    color: #475467;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 10px;
}
.customLinkURL img {    
    position: absolute;
    left: 0;
    top: 14px;
    margin-left: 15px;
}
.navItem .navLinkRow {
    position: relative;
    margin-bottom: 10px;
}
.navItem .navLinkRow,
.deleteRow{
align-items: center;
    display: flex;
}
.deleteRow {
    margin-top: 20px;
}
.deleteRow img {
    margin-right: 10px;
}
.deleteRow p {
    margin-bottom: 0;
}
.select img {
    bottom: 5px;
    margin-right: 6px;
    margin-left: 0;
    position: relative;
    width: 22px;
}
.select.socialLinkSelect {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    background-image: url("../../images/select_down.svg") !important;
    background-position-x: calc(100% - 13px)!important;
    background-position-y: 52%!important;
    background-repeat: no-repeat!important;
    background-size: 12px!important;
    border: 1px solid #d0d5dd;
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(16,24,40,.05);
    color: #101828;
    font-size: 14px;
    height: 45px;
    outline: none;
    position: relative;
    width: 100%;
    padding: 9px 11px 14px 13px;
}
.selectMain {
    font-size: 16px;
    color: #101828;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
}
.selectMain img {
    margin-right: 9px;
    width: 15px;
    bottom: 5px;
    position: relative;
}
.socialLinkSelect .selectMainairbnb {
    padding-top: 3px;
}
.socialLinkSelect .selectMain p {
    color: #475467!important;
    margin-bottom: 12px;
}
.selectListOption {
    left: 0;
    position: absolute;
    top: 50px;
    width: 100%;
    background: #fff;
    border: 1px solid #eaecf0;
    border-radius: 6px;
    box-shadow: 0 12px 16px -4px rgb(16 24 40/8%), 0 4px 6px -2px rgb(16 24 40/3%);
    max-height: 195px;
    overflow: hidden auto;
    z-index: 999;
}
.pubTypeSelect .selectListOption{
    max-height: unset;
}
.selectOption {
    display: flex;
    background: #fff;
    box-shadow: 0 12px 16px -4px rgb(16 24 40/8%), 0 4px 6px -2px rgb(16 24 40/3%);
    color: #111827;
    cursor: pointer;
    padding-left: 12px;
    padding-right: 20px;
    padding-top: 10px;
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.selectListOption p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #101828;
}
.selectOption:hover {
    background: #f9f9f9;
}
.socialSelectOptions::-webkit-scrollbar {
  width: 5px;
  background: #d0d5dd;
}
.socialSelectOptions::-webkit-scrollbar-thumb {
  background: #475467;
}
.settingsMenuList {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
}
.settingsListWrap {
    display: flex;
    margin-bottom: 30px;
    flex-wrap: wrap;
}
.navLeftList {
    max-width: 380px;
    min-width: 380px;
}
.navigationRight {
    margin-left: 35px;
    padding-top: 0;
}
.settingsMenuBlockTitle {
    color: #344054;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    padding: 0 0 15px;
}
.pagesTitle {
    cursor: pointer;
    padding: 1rem 1.5rem;
    position: relative;
}
.titleBottom {
    padding-bottom: 0;
}
.navigationLeft {
    background: #fff;
    border: 1px solid #d0d5dd;
    border-radius: 6px;
    min-width: 280px;
}
.navigationBlocks {
    border-bottom: 1px solid #eaecf0;
    padding-bottom: 0;
    padding-top: 0;
    width: 100%;
}
.linkAdd {
    border: none!important;
}
.inputLink {
    position: absolute;
    left: 29px;
    top: 36px;
}
.UserDetails .userMenuWrapper .inputLink {
    width: 15px;
    top: 13px;
}
.userMenuWrapper .inputFieldsIcon  .inputFields {
    padding-left: 36px;
}
.navigationBlocks>div {
    overflow: hidden;
}
.navigationTop {
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 19px;
}
.settingsMenuPages {
    padding-right: 24px;
    padding-left: 24px;
    overflow: hidden;
    padding-bottom: 15px;
}
.settingsMenuList .pageSearchInput {
    width: 100%;
    margin-top: 0;
    position: relative;
}
.pageSearchInput input {
    padding-left: 40px;
}
.settingsMenuList #pages_search {
    position: relative;
    right: 0;
}
.navigationTop #pages_search {
    width: 100%;  
}
.settingsMenuItem {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    margin-bottom: 10px;
}
.settingsMenuItem input {
    width: auto;
}
.UserDetails .profilePictureItems {
    margin-bottom: 15px;
}
.fileUploadCover_Img {
    display: block;
    margin: 0 auto;
}
.uploadFileBlock, .uploadFileTextBlock {
    align-items: center;
    background: #fff;
    display: flex;
}
.newPageImageUploadText,
.uploadFileBlock .newPageImageUploadText {
    color: #425466;
    font-size: 14px;
    line-height: 24px;
    padding-top: 10px;
    /* pointer-events: none; */
    text-align: center;
    -webkit-user-select: none;
    user-select: none;
}
.newPageImageUploadText span {
    color: #1c1c1c;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
.newpage_CoverImg {
    border-radius: 6px;
    cursor: pointer;
    height: 100%;
    min-height: 215px;
    max-width: 100%;
    object-fit: cover;
    width: auto;
}
#newpageImageUpload:has(.newpage_CoverImg) {
    border: 0;
    box-shadow: none;
}
.generalBlockItems .newpage_CoverImg {
    height: 100%;
    width: 100%;
}
.pagesTitle:before {
    background-image: url("../../images/icondropdown.svg");
    background-position: 100%;
    background-repeat: no-repeat;
    content: "";
    display: block;
    height: 18px;
    left: auto;
    position: absolute;
    right: 20px;
    top: 20px;
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    transition: .3s;
    width: 12px;
}
.pagesTitle.active:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
.settingsMenuItem label,
.settingsMenuItem p {
    color: #344054;
    cursor: pointer;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
}
.settingsMenuBlockSubtitle {
    color: #475467;
    font-size: 14px;
    line-height: 20px;
}
.navRightList {
    margin-top: 15px;
}
.navRightList .remove {
    margin-top: 20px;
}
.remove-navitem {
    background: transparent;
    border: 0;
    color: #475467;
    padding: 0 3px;
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    cursor: pointer;
}
.remove-navitem svg {
    margin-right: 10px;
}
.downRotated {
    rotate: -180deg!important;
}
.socialContent button.addToMenu {
    margin-left: 0;
    margin-bottom: 0;
}
/* Start Subscription Deatail */
.subscription-data{
    display: flex;
    gap:36px;
}
.subscription-item:first-child{
    width: 30%;
}
.subscription-item:last-child{
    width: 70%;
}
.table-heading {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #0A2540;
    margin: 0;
}
.subscription-content{
    display: flex;
    flex-direction: column;
    margin-top: 11px;
}
.table-sub-title{
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color:#344054;
    margin-bottom: 6px;
}
.subscription-text {
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    margin: 10px 0;
}
.subscription-activity {
    border: 1px solid #EAECF0;
    border-radius: 6px;
    margin-bottom: 27px;
}
.subscription-activity-title, .subscription-activity-detail{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
}
.subscription-activity-title h4,
.subscription-activity-title p, 
.subscription-activity-detail h5,
.subscription-activity-detail p{
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color:#425466;
}
.subscription-activity-title h4 {
    margin: 0;
}
.subscription-activity-detail{
    border-top:1px solid #EAECF0;
}
.subscription-activity-detail h5 {
    color:#344054;
    margin: 0;
}
.subscription-activity-detail p{
    color:#344054;
    text-align: end;
}
/* End Subscription Deatail */


/* Start Subscription Table */
.subscription-table-item .table-date {
    font-size: 14px;
    color: #425466;
}
.table-head.subscription-head>div:first-child,
.table-item.subscription-table-item>div:first-child{
    width: 20%;
    min-width: auto;
}
.table-head.subscription-head>div:nth-child(2),
.table-item.subscription-table-item>div:nth-child(2){
    width: 23%;
}
.table-item.subscription-table-item>div:nth-child(2){
    padding-left: 15px;
}
.table-head.subscription-head>div:nth-child(3),
.table-item.subscription-table-item>div:nth-child(3){
    width: 15%;
    padding-left: 0;
}
.table-head.subscription-head>div:nth-child(4),
.table-head.subscription-head>div:nth-child(5){
    padding-left: 5px;
}
.table-head.subscription-head>div:nth-child(5),
.table-item.subscription-table-item>div:nth-child(5){
    width: 12%;
}
.table-head.subscription-head>div:nth-child(5) {
    padding-left: 10px;
}
.table-head.subscription-head>div:nth-child(6),
.table-item.subscription-table-item>div:nth-child(6){
    width: 15%;
    text-align: end;
}
.subscription-table-item .table-download{
    padding-right: 24px;
}
.table-head.subscription-head .table-title,
.table-check h4 {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color:#475467;  
}
.table-check h4{
    margin: 0;
}
.table-amount h5,
.table-plan h5{
    font-size: 14px;
    line-height: 20px;
    color:#425466;
    margin: 0;
}
.plan-status {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #027A48;
    border: 1px solid #027A48;
    border-radius: 16px;
    padding: 2px 6px;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
}
.plan-status svg {
    position: relative;
    top: 2px;
    margin-right: 4px;
}
.Unpaid .plan-status {
    border-color: #B93815;
    color: #B93815;
}
.Unpaid .plan-status svg path {
    stroke: #B93815;
}
.Unpaid .plan-status .unpaid-icon {
    display: inline-block;
    top: -1px;
    vertical-align: middle;
}
.paid .plan-status .paid-icon,
.Unpaid .plan-status  .paid-icon  {
    display: none;
}
.Paid .unpaid-icon {
    display: none;
    top: 0;
}
.subscription-item .table-data{
    margin-top: 22px;
}
.download-link {
    font-size: 14px;
    font-weight: 500;
}
.profile-link {
    background-color: #344054;
    border-color: #344054;
    color: #fff;
    border-radius: 6px;
}
.UserDetails .add-new-member > button {
    min-width: 155px;
}
.UserDetails .categoriesPage .add-new-member > button,
.pagesNew .add-new-member > button {
    min-width: unset;
}
.mediaFeatures p span {
    color: #0A2540;
    font-weight: 500;
}
.userMenuWrapper {
    opacity: 0;
    height: 0;
    padding-bottom: 0;
    overflow: hidden;
    transition: opacity 0.3s, height 0.3s, padding-bottom 0.3s;
}
  
.userMenuWrapper.visible {
    opacity: 1;
    height: auto;
    padding-bottom: 15px;
}

.userMenuWrapper.hidden {
    opacity: 0;
    height: 0;
    padding-bottom: 0;
}
  

@media (max-width: 1400px) {

    .subscription-data {
        display: block;
    }
    .subscription-item:first-child,
    .subscription-item:last-child {
        width: 100%;
    }
    .subscription-item:last-child {
        padding-top: 25px;
    }
    .subscription-item .table-data {
        margin-top: 0;
    }
    .subscription-activity {
        margin-bottom: 15px;
    }

}

@media (max-width: 1200px) {

    .settingsMenuList .settingsListWrap {
        margin-bottom: 15px;
    }
    .settingsListWrap .navLeftList {
        margin-bottom: 15px;
        max-width: 100%;
        min-width: auto;
    }
    .settingsMenuList .settingsListWrap {
        display: block;
    }
    .settingsListWrap .navigationRight {
        margin-left: 0;
        padding-top: 10px;
    }
    .navLeftList .navigationLeft {
        max-width: 100%;
        min-width: auto;
    }
    .navigationLeft,
    .navigationRight {
        max-width: 100%;
        min-width: 100%;
    }
    .navigationRight .navItem {
        width: 100%;
    }
    .settingsMenuList .pageSearchInput {
        max-width: 100%;
    }
    .UserDetails .pageSearchTop .pageSearchInput {
        max-width: calc(50% - 15px);
        margin-bottom: 0;
        width: calc(50% - 15px);
        display: inline-block;
    }
    .UserDetails .pageSearchTop .newsletter-dropdown {
        padding-right: 0;
        padding-left: 15px;
        width: 50%;
    }
    
}


@media (max-width: 767px) {

    .subscription-data {
        flex-direction: column;
    }
    .subscription-item:first-child,
    .subscription-item:last-child,
    .navItem,
    .UserDetails .pageSearchTop .newsletter-dropdown {
        width: 100%;
    }
    .settingsMenuList .settingsListWrap {
        margin-bottom: 10px;
    }
    .UserDetails .pageSearchTop .newsletter-dropdown {
        padding-top: 0;
        padding-left: 0;
        margin-bottom: 15px;
    }
    .UserDetails .pageSearchTop .pageSearchInput,
    .socialContent {
        max-width: 100%;
    }
    .UserDetails .pageSearchTop .pageSearchInput {
        width: 100%;
    }
    .socialBlock {
        flex-direction: column;
    }
    .socialRight {
        width: 100%;
        padding-left: 0;
    }
    .socialBlock .socialLeft {
        max-width: unset;
        width: 100%;
    }
    .socialBlock:has(.nestable-item-1) .socialRight {
        margin-top: 20px;
    }
}