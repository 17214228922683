.rangeInput {
    width: 100%;
}

.controls p {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
}

.controls {
    margin-top: 20px;
}

.replaceImage {
    display: flex;
    padding: 10px;
    gap: 8px;
    background: #007aff;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    color: #ffffff;
    border: none;
    cursor: pointer;
    margin-right: 8px;
    margin-top: 15px;
}

.controlsButton {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
}

.controlsCancel {
    padding: 10px;
    gap: 8px;
    background: #f2f2f7;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #344054;
    opacity: 0.7;
    border: none;
    cursor: pointer;
}

.imageEditor canvas {
    background: transparent;
    border-radius: 6px;
    min-height: 100;
    position: relative;
    z-index: 999;
    cursor: pointer;
    object-fit: cover;
}

.profile-img-model .imageEditor canvas {
    min-height: 0px !important;
}

@media (max-width: 1024px) {
    .controls {
        width: 100%;
    }
    .fileUploadCover_Img {
        padding-top: 0px;
    }
    .imageEditor canvas {
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 768px) {
    .profile-img-model .imageEditor canvas {
        min-height: 0px;
        height: 0px;
    }

}
@media (max-width: 490px) {
    .imageEditor canvas {
        width: 100% !important;
        height: 175px !important;
        min-height: unset !important;
    }
    .fileUploadCover_Img {
        padding-top: 50px;
    }
    .newpageImageUpload {
        min-height: 175px;
        height: 215px;
    }
    .featuredImageModal .newpageImageUpload {
        min-height: 175px;
        height: auto;
    }
    .fileUploadCover_Img {
        padding-top: 30px;
    }
}

@media (max-width: 390px) {
    .imageEditor canvas {
        width: 100% !important;
        height: 175px !important;
        min-height: unset !important;
    }
}