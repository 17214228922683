.main-footer {
    display: block;
    padding: 21px 36px;
    margin: 0 auto;
    border-top: 1px solid #eaecf0;
    position: fixed;
    bottom: 0;
    z-index: 11;
    background: #fff;
    width: calc(100% - 312px);
}
.main-footer .container {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    align-items: center;
}
.footerContent{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.footerLeft {
    display: flex;
    align-items: center;
    color: #667085;
}
.footerLeft p,
.main-footer a {
    font-size: 14px;
    font-weight: 500;
    color: #667085;
    line-height: 24px;
}
.main-footer ul {
    align-items: center;
    display: flex;
    margin: 0;
    padding: 0;
}
.main-footer li {
    margin-left: 35px;
}
@media screen and (max-width: 1400px) {
    .main-footer {
        padding: 21px 25px;
    }
}

@media screen and (max-width: 1024px) {
    .main-footer {
        width: 100%;
    }
}
@media screen and (max-width: 767px) {

    .main-footer {
        padding: 20px 22px;
    }
    .main-footer .container {
        display: block;
        padding-left: 0;
        padding-right: 0;
    }
    .main-footer li {
        margin-left: 0;
        margin-right: 10px;
        margin-top: 10px;
    }
    .footerLeft,
    .main-footer ul {
        justify-content: center;
    }

}