.table-data {
    border: 1px solid #EAECF0;
    border-radius: 12px;
    width: 100%;
}
.table-head {
    display: flex;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    padding-left: 24px;
    padding-right: 24px;
}
.table-head>div:nth-child(1) {
    min-width: 400px;
}
.table-head div {
    color: #425466;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    align-items: center;
    display: flex;
    cursor: pointer;
    user-select: none;
}
.table-data div input {
    align-items: center;
    display: flex;
    min-height: 20px;
    min-width: 20px;
}
.table-head>div:nth-child(3) {
    padding-left: 10px;
}
.table-item {
    align-items: center;
    display: flex;
    border-bottom: 0;
    border-top: 1px solid #eaecf0;
    position: relative;
}
.table-item>div:nth-child(1) {
    display: flex;
    align-items: center;
    font-weight: 500;
    min-width: 430px;
    padding-left: 24px;
}
.table-head>div:nth-child(1),
.table-item>div:nth-child(1) {
    width: 55%;
}
.table-head>div:nth-child(2),
.table-item>div:nth-child(2) {
    width: 25%;
}
.table-item>div {
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 16px;
    padding-top: 16px;
    color: #425466;
}
.table-edit,
.table-link,
.user-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #0A2540;
    text-decoration: none;
}
.table-link {
    display: flex;
    align-items: center;
}
.user-name {
    display: block;
    text-transform: capitalize;
}
.table-link p {
    font-weight: 500;
    color: inherit;
}
.table-link span {
    margin-left: 2px;
}
.support-title p {
    text-transform: capitalize;
    display: inline;
}
.user-status img, .teamTable img {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    line-height: 20px;
}
.media-picture img {
    border-radius: 4px;
    margin-right: 10px;
}
.user-status,
.table-item .user-status a.user-link {
    display: flex;
    align-items: center;
    gap:12px;
}
.table-item .user-status a {
    color: #0A2540;
    text-transform: capitalize;
}
.table-item .user-status a:hover {
    color: #101828;
}
.user-name a {
    color: inherit;
    font-weight: inherit;
}
.user-mail {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #425466;
}
.up-arrow img {
    transform: rotate(180deg);
}
.user-content {
    display: flex;
    align-items: center;
    gap: 12px;
}
.subscription-plan,
.table-action {
    display: flex;
    justify-content: flex-end;
    padding-right: 24px;
    position: relative;
}
.table-head>div:last-child {
    display: flex;
    justify-content: flex-end;
}
.table-data-dropdown a {
    font-size:14px;
    align-items: center;
    color: #111827;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 20px;
    text-decoration: none;
}
.table-data-dropdown a:hover {
    background: #f9f9f9;
    color: #101828;
}
.table-data-dropdown a img {
    margin-right: 10px;
}
.table-data-dropdown li {
    list-style: none;
}
.table-data-dropdown ul {
    padding: 0;
}
.table-data-dropdown {
    padding: 0 !important;
    background: #fff;
    border: 1px solid #eaecf0;
    border-radius: 6px;
    box-shadow: 0 12px 16px -4px rgba(16,24,40,.08),0 4px 6px -2px rgba(16,24,40,.03);
    min-width: 160px;
    position: absolute;
    right: auto;
    top: 50px;
    z-index: 999;
}
.table-data-dropdown .table-edit-action {
    padding: 0;
}
.table-check img {
    margin-right:3px;
}
.table-data-wrapper {
    margin-top: 25px;
}
.plan-btn {
    color: #425466;
    border: 1.5px solid #425466;
    text-decoration: none;
    transition: all 0.3s linear;
    text-transform: capitalize;
    /* padding: 2px 8px; */
    min-width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    mix-blend-mode: multiply;
    border-radius: 6px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
}
.plan-btn:empty {
    display: none;
}
.pro-btn {
    color:#2B59FF;
    border: 1px solid #2B59FF;
}
.support .plan-btn,
.profile-btn.support {
    border-color: #067647;
    color: #067647;
}
.assistant .plan-btn,
.profile-btn.assistant {
    border-color: #0086C9;
    color: #0086C9;
}
.admin .plan-btn,
.profile-btn.admin {
    border-color: #1570EF;
    color: #1570EF;
} 
.table-item.user-item>div {
    padding-bottom: 16px;
    padding-top: 16px;
}
.table-head.table-four-column>div:first-child {
    min-width: 400px;
}
.table-head.table-four-column>div:nth-child(2) {
    padding-left: 5px;
}
.table-item.user-item>div:first-child,
.table-head.table-four-column>div:first-child {
    width: 60%;
} 
.table-head.table-four-column>div:nth-child(3), 
.table-head.table-four-column>div:nth-child(4) {
    padding-left: 12px;
}
.table-item.user-item .subscription-plan {
    padding-right: 28px;
}
.table-pagination-wrapper .table-data:has(.table-pagination) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
}
.table-pagination-wrapper .table-pagination {
    padding: 16px 27px;
    border: 1px solid #EAECF0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}  
.table-pagination-wrapper:has(.table-pagination) .table-data {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.pagination-prev .pagination-item,
.pagination-next .pagination-item {
    display: flex;
    align-items: center;    
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 8px 14px;
    color: #425466;
    border: 1px solid #EAECF0;
    border-radius: 8px;
    text-decoration: none;
    cursor: pointer;
}
.table-pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100% !important;
    padding-right: 24px;
    padding: 20px;
    border-top: 1px solid rgba(0, 0, 0, .12);
}
.pagination-prev .pagination-item img,
.pagination-next .pagination-item img {
    padding-right: 8px;
}
.pagination-next .pagination-item img {
    transform: rotate(180deg);
}
.pagination-number .pagination-item {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #425466;
    text-decoration: none;
    width: 50px;
    height: 40px;
    padding: 10px 16px;
    cursor: pointer;
    text-align: center;
}
.pagination-number .pagination-item.current,
.pagination-number .pagination-item:hover {
    color: #1D2939;
    background-color: #FBFBFB;
    border-radius: 6px;
}
.table-five-column .table-head>div:nth-child(1),
.table-five-column .table-item>div:nth-child(1) {
    width: 50%;
}
.table-five-column .subscription-plan {
    justify-content: flex-start;
}
.table-five-column .table-head>div:nth-child(4) {
    padding-left: 10px;
}
.table-five-column .table-item>div:nth-child(5) {
    text-align: right;
    padding-right: 24px;
}
.table-action img {
    cursor: pointer;
}
.table-data .table-head img {
    margin-left: 5px;
}
.actions-buttons .deleteIcon {
    width: auto;
    margin-top: 5px;
}
.table-data-dropdown li span {
    align-items: center;
    color: #111827;
    display: flex;
    justify-content: flex-start;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    cursor: pointer;
}
.table-data-dropdown li span img {
    margin-right: 10px;
}
.table-data-dropdown li span:hover {
    background: #f9f9f9;
    color: #101828;
}
.table-role span {
    display: inline-block;
    border-width: 1.5px;
    margin-left: 2px;
}
.table-role.Admin > .plan-btn {
    border-color: #1570EF;
    color: #1570EF;
}
.table-role.Assistant > .plan-btn {
    border-color: #026AA2;
    color: #026AA2;
}
.table-role.Support > .plan-btn {
    border-color: #067647;
    color: #067647;
}
.table-status .user-status {
    gap: 0;
}
.pagesNew .add-new-member > button {
    min-width: unset;
}
.table-six-column .table-head>div:nth-child(1), 
.table-six-column .table-item>div:nth-child(1) {
    width: 35%;
}
.table-status .status-btn {
    font-size: 12px;
    border: 1px solid #ccc;
    padding: 4px 10px;
    border-radius: 32px;
    margin-left: 7px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 1.1;
    font-weight: 500;
}
.table-status.Published .status-btn,
.table-status.Send .status-btn {
    border-color: #067647;
    color: #067647;
    background-color: #ECFDF3;
}
.table-status.Draft .status-btn {
    border-color: #EAECF0;
    background-color: #F9FAFB;
}
.table-seven-column .table-head > div:nth-child(3),
.table-six-column .table-head>div:nth-child(3),
.table-six-column .table-item>div:nth-child(3)  {
    padding-left: 0;
}
.table-six-column .table-head>div:nth-child(4),
.table-five-column .table-head>div:nth-child(3),
.table-seven-column .table-item > div:nth-child(3),
.table-seven-column .table-head > div:nth-child(5) {
    padding-left: 5px;
}
.table-six-column .table-head>div:nth-child(5),
.table-seven-column .table-head > div:nth-child(6) {
    padding-left: 15px;
}
.table-title input[type=checkbox]:before {
    margin-right: 0;
}
.table-seven-column .table-head > div:first-child,
.table-seven-column .table-item > div:first-child {
    width: 30%;
}
.table-seven-column .table-status.Published .status-btn {
    margin-left: 10px;
}
.table-item.table-four-column > div:nth-child(4),
.table-five-column .table-head > div:nth-child(5),
.table-five-column .table-item > div:nth-child(5),
.table-six-column .table-head > div:nth-child(5),
.table-six-column .table-item > div:nth-child(5),
.table-six-column .table-head > div:nth-child(6),
.table-six-column .table-item > div:nth-child(6),
.table-seven-column .table-head > div:nth-child(2),
.table-seven-column .table-item > div:nth-child(2),
.table-seven-column .table-head > div:nth-child(3),
.table-seven-column .table-item > div:nth-child(3),
.table-seven-column .table-head > div:nth-child(6),
.table-seven-column .table-item > div:nth-child(6),
.table-seven-column .table-head > div:nth-child(7),
.table-seven-column .table-item > div:nth-child(7) {
    width: 10%;
}
.table-head > div:nth-child(3),
.table-head > div:nth-child(4),
.table-item > div:nth-child(3),
.table-item > div:nth-child(4),
.table-head.table-four-column > div:nth-child(4),
.table-item.user-item > div:nth-child(2),
.table-head.table-four-column > div:nth-child(2),
.table-head.table-four-column > div:nth-child(3),
.table-item.user-item > div:nth-child(3),
.table-five-column .table-head > div:nth-child(2),
.table-five-column .table-item > div:nth-child(2),
.table-six-column .table-head > div:nth-child(2), 
.table-six-column .table-item > div:nth-child(2),
.table-six-column .table-head > div:nth-child(4),
.table-six-column .table-item > div:nth-child(4),
.table-six-column .table-head > div:nth-child(3),
.table-six-column .table-item > div:nth-child(3),
.table-seven-column .table-head > div:nth-child(4),
.table-seven-column .table-item > div:nth-child(4),
.table-seven-column .table-head > div:nth-child(5),
.table-seven-column .table-item > div:nth-child(5) {
    width: 15%;
}
.pageUser + .table-data-wrapper .table-five-column .table-item > div:nth-child(2) {
    padding-left: 3px;
}
.tableDataSubscribeStatus{
    width: 30% !important;
}
.tableDataSubscribePlan{
    width: 27% !important;
    padding-left: 0 !important;
}
.tableDataSubscribeDate{
    width: 13% !important;
}
.tableDataSubscribeAction{
    margin-left: 20px !important;
}
.tableDataSubscribeStatus  .status-btn{
    margin-left: 0 !important;
}
.table-subscriber{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.table-subscriber .table-status{
    padding-left:24px !important;
}
.table-subscriber .tableDataSubscribeDate {
    padding-right:24px !important;
}
@media (max-width: 1200px) {
    
    .table-data-wrapper {
        overflow-x: scroll;
        width: 100%;
        margin-top: 20px;
    }
    .table-data {
        width: 145vw;
        min-width: 1150px;
    }
    .table-six-column .table-item>div:nth-child(1) {
        min-width: 425px;
    }
    .table-six-column .table-item>div:nth-child(1) {
        min-width: 420px;
    }
    .table-seven-column  .table-item>div:first-child {
        min-width: 415px;
    }
    .table-pagination-wrapper .table-pagination {
        width: 145vw;
        min-width: 1150px;
    }

}