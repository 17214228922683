.profile-tabs {
    /* border-bottom: 1px solid #EAECF0; */
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.profile-tabs .profile-tab-item{
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #667085;
    display: inline-block;
    padding-bottom: 7px;
    margin-right:20px;
    text-decoration: none;
    position: relative;
    margin-bottom: 4px;
}
.profile-tab-item:after {
    content: "";
    background-color: #1c1c1c;
    bottom: 0px;
    height: 2px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    transition: all .2s linear;
    width: 0;
}
.profile-tab-item:hover,
.profile-tabs .profile-tab-item.active{
    color:#1c1c1c;
}
.profile-tabs .profile-tab-item:hover:after,
.profile-tabs .profile-tab-item.active:after {
    width: 100%;
}
.profile-tab-item:last-child{
    margin-right:0;
}
.profile-main p{
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #425466;
}
.profile-navigation{
    display: none;
}
.profile-tab-menu{
    display: flex;
    flex-direction: column;
}
.profile-tab-select {
    left: 0;
    position: absolute;
    top: 50px;
    width: 100%;
}
.profile-tab-link{
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #425466;
    padding: 10px 12px;
    display: inline-block;
    text-decoration: none;
    background: #fff;
    box-shadow: 0 12px 16px -4px rgb(16 24 40/8%), 0 4px 6px -2px rgb(16 24 40/3%);
}
.profile-tab-link:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.profile-tab-link:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
.generalContent {
    max-width: 640px;
}

.profile-tabs {
    overflow-x: auto;
    margin-bottom: 20px;
}


.profile-tabs .profile-tab-item {
    white-space: nowrap;
}
.profile-tabs::-webkit-scrollbar-track{
    border-radius: 8px;
    background-color: #f8fafc;
}
.profile-tabs::-webkit-scrollbar{
    height: 6px;
    background-color: #fff;
}
.profile-tabs::-webkit-scrollbar-thumb{
    border-radius: 8px;
    padding: 6px;
    background-color: #eaecf0;
}



@media (max-width: 767px) {

    .profile-section {
        margin-bottom: 20px;
    }
    .profile-tabs .profile-tab-item:not(:last-child) {
        margin-right: 10px;
    }

}
