body:has(.home) {
    overflow: hidden auto;
  }
  
  .home .heroContent {
    padding: 60px 40px 80px 70px;
  }
  
  .articlesDetail {
    padding: 10px 175px 40px;
    margin-top: -92px;
  }
  
  .isotopeItem {
    padding: 10px 0 20px;
  }
  
  .blogData {
    margin-top: 12px;
  }
  
  .blogItem * {
    color: var(--white);
  }
  
  h2.blogIitle {
    margin: 0 !important;
  }
  
  h2.blogIitle a,
  h2.blogIitle span {
    color: var(--secondary);
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
  }
  
  .blogItem .blogDate,
  .blogItem .blogDescription {
    font-size: 14px;
  }
  
  .blogItem .blogDescription {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    /* Limit to 3 lines */
    line-clamp: 3;
    text-overflow: ellipsis;
    line-height: 22px;
  }
  
  h2.blogIitle.two-line {
    margin-top: 10px;
  }
  
  p.blogDate {
    padding: 10px 0;
  }
  
  .section {
    position: relative;
  }
  
  .blogImage {
    line-height: 0;
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;
  }
  
  .blogImage img {
    width: 100%;
    max-width: 100%;
    height: 300px;
    object-fit: cover;
    transform: scale(1, 1);
    transition-property: transform, filter, opacity;
    transition-timing-function: cubic-bezier(0.57, 0.21, 0.69, 1);
    transition-duration: 0.3s;
  }
  
  .blogImage:hover img {
    transform: scale(1.05, 1.05);
  }
  
  .infiniteScroll {
    display: block !important;
    overflow: unset !important;
  }
  
  .home-col-left {
    width: 40%;
    background-color: var(--bg-color);
  }
  
  .publication-bg-color {
    background-color: var(--bg-color);
  }
  
  .home-col-right {
    width: 60%;
  }
  
  .hero-position {
    font-size: 22px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 10px;
  }
  
  .hero-paragraph {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  
  .header-social .social-icons-cover a {
    margin: 0 auto;
  }
  
  .social-icons-cover {
    margin-top: 0;
    display: flex;
    list-style: none;
  }
  
  .social-icons-cover li {
    margin-right: 7px;
  }
  
  .social-icons-cover a {
    border-radius: 50%;
    color: #ffffff;
    display: flex;
    font-size: 15px;
    height: 30px;
    justify-content: center;
    line-height: 40px;
    width: 30px;
    align-items: center;
  }
  
  .social-icons-cover a:hover {
    opacity: 0.8;
  }
  
  .social-icons-cover li.social-linkedin a {
    background-color: #0466b8;
  }
  
  .social-icons-cover li.social-twitter a {
    background-color: #00a1f9;
  }
  
  .social-icons-cover li.social-facebook a {
    background-color: #0074fb;
  }
  
  .publication-social .social-icons-cover {
    margin-top: 0;
  }
  
  .homepreview .heroContent {
    display: flex;
    flex-direction: column;
  }
  
  .hero-title {
    color: var(--white);
    font-size: 44px;
    font-weight: 600;
    line-height: 72px;
  }
  
  @media (max-width:1800px) {
    .hero-position {
      font-size: 21px;
    }
  }
  
  @media (max-width: 1600px) {
  
    /*
        .home .heroContent {
            padding: 120px 60px 60px 120px;
        }
        */
    .blogImage img {
      height: 300px;
    }
  
    .articlesDetail {
      margin-top: -75px;
    }
  }
  
  
  @media (max-width:1401px) {
  
    .home .heroContent {
      padding: 30px;
      height: 100%;
    }
  
  }
  
  @media (min-width: 1201px) {
    .homepreview .hero-content {
      margin-left: 120px;
      height: 100vh;
    }
  }
  
  @media (max-width: 1200px) {
    .home .heroContent {
      padding: 30px 30px 60px;
    }
  
    .articlesDetail {
      padding: 50px 20px;
    }
  
    .blogImage img {
      height: 250px;
    }
  
    .articlesDetail {
      margin-top: -75px;
      padding-bottom: 10px;
    }
  
    .hero-title {
      font-size: 50px;
      line-height: 35px;
      margin-bottom: 10px;
    }
  
    .hero-position {
      font-size: 20px;
      line-height: 24px;
      margin-top: 5px;
    }
  
    .social-subscribe-btn .social-icons-cover a {
      font-size: 14px;
      height: 28px;
      line-height: 30px;
      width: 28px;
    }
  
    .social-subscribe-btn .social-icons-cover li {
      margin-right: 5px;
    }
  }
  
  @media (max-width: 767px) {
    .isotopeItem {
      padding-left: 0;
      padding-right: 0;
    }
  
    .blogImage img {
      height: 300px;
    }
  
    .home .heroContent {
      padding: 20px 20px 50px;
    }
  }
  
  @media (max-width: 575px) {
    .blogImage img {
      height: 250px;
    }
  }