.about-section .hero-content {
    /* background-color: var(--bg-color); */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    margin-left: 120px;
    padding: 16px 120px 100px 80px;
}

.about-section .hero-wrap {
    margin-top: 50px;
}
body:has(.preview-loader) .menuWrapper {
    display: none;
}
.hero-col-left {
    width: 50%;
    background-color: var(--bg-color);
}

.hero-col-right {
    width: 50%;
}

.hero-content.alignment-center {
    justify-content: center;
}

.hero-content p {
    margin-bottom: 14px;
    color: var(--lightfont);
}

.hero-content .publication-paragraph {
    font-size: 16px;
    line-height: 27px;
    font-weight: 400;
    margin-bottom: 15px;
}

.hero-content .publication-social {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.hero-content .publication-name-date {
    margin-bottom: 15px;
}

.fp-scroll-mac .hero-content {
    background-color: transparent;
}

.hero-content .page-content-inner {
    width: 100%;
}

.about-section .hero-title {
    color: var(--white);
    font-size: 44px;
    font-weight: 600;
    line-height: 72px;
    margin-bottom: 14px;
    margin-top: 0;
}
.about-section .hero-image{
    height: calc(100vh - 50px);
}
.bookmark-block .linkBox{
    background-color: transparent;
}
.preview-buttons .preview-update-btn {
    min-height: unset !important;
    border-radius: 4px;
    margin-right: 0 !important;
    width: auto;
}

@media (max-width: 1401px) {
    .about-section .hero-content {
        margin-left: 0;
        padding: 36px 30px 30px;
        height: 85dvh;
        margin-left: 0px;
    }
}

@media (max-width: 1200px) {
    .about-section .overlay {
        background: none !important;
    }

    .about-section .hero-wrap .hero-col {
        margin-top: 0;
    }
}

@media (min-width: 1201px) {
    .fp-scroll-mac .hero-content {
        background-color: transparent;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .about-section .hero-content {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        height: calc(100vh - 84px);
        padding: 40px 95px 80px 70px;

    }

    .hero-content.error-content {
        min-height: calc(60dvh - 60px);
    }

    .hero-content .hero-paragraph {
        margin-bottom: 15px;
    }

    .page-content::-webkit-scrollbar-track {
        background: var(--bg-color); 
        padding-right: 5px;
    }
    .page-content::-webkit-scrollbar,
    .page-content::-webkit-scrollbar-thumb {
        width: 20px;
        border-radius: 20px;
        background-clip: padding-box;
        border: 8px solid transparent;
    }

    .page-content::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 0 20px var(--secondary);
    }

    .page-content-inner .page-content {
        height: calc(100dvh - 250px);
        max-height: unset;
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        overflow: auto;
        padding-bottom: 0;
        margin-right: -50px;
        padding-right: 48px;
        transition: color .5s ease
    }
}

@media (max-width: 1200px) {
    .about-section .hero-title {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 10px;
    }
    .hero-content .page-content-inner {
        max-width: 900px;
        width: 100%;
        margin: 0 auto;
    }
    .error-content .hero-title {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 10px;
    }

    .about-section .hero-content {
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: nowrap;
        align-items: flex-start;
        padding: 30px;
        height: auto;
    }

    .about-section .hero-content .hero-caption {
        margin-bottom: 15px;
        margin-top: -15px;
    }

    .about-section .hero-content .hero-caption {
        margin-bottom: 15px;
        margin-top: -15px;
    }
}

@media (max-width: 767px) {
    .about-section .hero-content {
        height: auto;
    }

    .hero-content {
        padding: 20px;
    }

    .hero-content.error-content p {
        margin-bottom: 15px;
    }
}