.preview-header .logo img {
    left: 15px;
    top: -15px;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    margin-right: 14px;
}

.preview-header .logo>a {
    display: flex;
    align-items: center;
}

.preview-header .logo>a span {
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    line-height: 52px;
    /* margin-left: 5px; */
    transition: all .5s linear;
    display: block;
}

.preview-header {
    height: auto;
    position: fixed;
    transition: all .2s linear;
    width: 100%;
    z-index: 1501;
    margin-top: 20px;
    top: 0;
}

.preview-header .menu {
    border-radius: 30px;
    height: 100%;
    display: flex;
    gap: 35px;
    margin: 0 auto;
}

.preview-header .header-inner {
    background: transparent;
    margin-top: 57px;
    pointer-events: none;
}

.preview-header .menu {
    background-color: var(--headerbg);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
    padding: 2px 5px;
    position: relative;
    width: max-content;
}

.preview-header .menu .menu-hamburger {
    position: relative;
    z-index: 10;
    transition: all 0.4s ease;
    margin-top: 30px;
    width: 80px;
    background-color: transparent;
    border: none;
    padding: 0;
    display: none;
}

.menu-toggle .hamburger,
.menu-toggle .hamburger::after,
.menu-toggle .hamburger::before {
    content: "";
    display: block;
    width: 26px;
    height: 1.5pt;
    background: var(--white);
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 50%;
    margin-left: -13px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 0.4s cubic-bezier(0.6, 0, 0.2, 1);
    -moz-transition: all 0.4s cubic-bezier(0.6, 0, 0.2, 1);
    -o-transition: all 0.4s cubic-bezier(0.6, 0, 0.2, 1);
    transition: all 0.4s cubic-bezier(0.6, 0, 0.2, 1);
    -webkit-transition-timing-function: cubic-bezier(0.6, 0, 0.2, 1);
    -moz-transition-timing-function: cubic-bezier(0.6, 0, 0.2, 1);
    -o-transition-timing-function: cubic-bezier(0.6, 0, 0.2, 1);
    transition-timing-function: cubic-bezier(0.6, 0, 0.2, 1);
    transition-delay: 0s;
}

.menu-toggle .hamburger::after {
    margin-top: 7px;
    top: 0;
}

.menu-toggle span.hamburger::before {
    margin-top: -7px;
    top: 0;
}

.show-menu .menu-toggle .hamburger::after {
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
    margin-top: 0px;
}

.show-menu .menu-toggle .hamburger::before {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    margin-top: 0px;
}

.show-menu .menu-toggle span.hamburger {
    background: transparent !important;
    transition-delay: 0.1s;
    transition-duration: 0.2s;
}

nav.menu-inner ul {
    list-style: none;
}

nav.menu-inner ul ul {
    overflow: visible;
}

.menu-item-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
}

span.menu-arrow {
    font-size: 24px;
    margin-left: 2px;
    position: relative;
    top: 2px;
}

.level-2 .menu-item-wrapper {
    align-items: center;
}

.menu-arrow svg {
    width: 18px;
}

span.menu-arrow svg path {
    stroke: var(--white);
}

.light span.menu-arrow svg path {
    stroke: var(--white-light);
}

.menu-arrow:empty {
    display: none;
}

.level-2 li .menu-item-wrapper span.menu-arrow svg {
    width: 20px;
    height: 20px;
}

ul.level-2,
ul.level-3 {
    padding-left: 15px;
}

.level-2 .menu-items:not(:last-child),
.level-3 .menu-items:not(:last-child) {
    margin-bottom: 8px;
}

.level-3 .menu-items:first-child {
    margin-top: 8px;
}

.level-2 .menu-items {
    animation: fadein 0.5s ease-in-out;
}

.menu-toggle {
    width: 30px;
    height: 30px;
    max-width: 100%;
    position: relative;
    left: calc(50% - 15px);
    display: block;
    cursor: pointer;
    overflow: hidden;
    z-index: 10;
    transition: height 0.3s ease, transform 0.4s ease;
}

.preview-header .menu button.active-menu~.logo>a>span {
    color: var(--black);
}

.nav-wrapp {
    display: flex;
    gap: 14px;
}

.header-actions .search-line {
    width: 6px;
    height: 1.2pt;
    background: #0A0D14;
    display: inline-block;
    transform: rotate(45deg);
    position: absolute;
    top: 13pt;
    left: 8.5pt;
    transition: all 0.2s ease-in 0s;
}

.header-actions .search-circle {
    width: 16px;
    height: 16px;
    border: 1.5pt solid #0A0D14;
    display: inline-block;
    position: absolute;
    top: 3px;
    left: -1px;
    border-radius: 50%;
    transition: top 0.3s ease-in 0s, left 0.3s ease-in 0s,
        border-radius 0.3s ease-in 0s, border-width 0.3s ease-in 0s,
        width 0.3s ease-in 0s, height 0.3s ease-in 0s,
        background-color 0.1s ease-in 0s;
    background-color: var(--white);
}

.header-actions>a {
    display: inline-block;
    width: 17px;
    height: 20px;
    transition: all 0.2s ease;
    text-align: center;
    font-size: 22px;
    right: 10px;
    position: relative;
    cursor: pointer;
    top: -2px;
}

.header-actions>a:hover {
    opacity: 0.5;
}

.header-actions {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.header-actions-overlay .search-close:hover:after,
.header-actions-overlay .search-close:hover:before {
    background-color: var(--secondary);
}

.header-actions-overlay .search-close {
    position: relative;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    visibility: visible;
    opacity: 1;
    transition: all 0.4s ease;
    margin-right: 5px;
}

.header-actions-overlay .search-close::after,
.header-actions-overlay .search-close::before {
    content: "";
    position: absolute;
    width: 17px;
    height: 1.5pt;
    left: 0;
    top: 10px;
    background-color: #525866;
}

.header-actions-overlay .search-close::after,
.header-actions-overlay .search-close::before {
    content: "";
    position: absolute;
    width: 17px;
    height: 1.5pt;
    left: 0;
    top: 10px;
    background-color: #525866;
}

.header-actions-overlay .search-close::before {
    transform: rotate(135deg);
    margin-top: 0;
}

.header-actions-overlay .search-close::after {
    transform: rotate(-135deg);
    margin-top: 0;
}

.header-actions .inpoutOpen {
    cursor: pointer;
    margin-left: 5px;
}

.header-actions .inpoutOpen {
    height: 43px;
    width: 20px;
    color: #CECFD2;
}

.search-transition {
    transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

.search-input-closed {
    opacity: 0;
    max-height: 0;
    visibility: hidden;
}

.header-top {
    margin-left: 0;
    position: relative;
}

.btn-common {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background-color: var(--white);
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s linear;
    border-radius: 10px;
    display: inline-block;
    color: var(--primary);
    appearance: none;
    -webkit-appearance: none;
    line-height: 1.2;
    border-radius: 30px;
    min-height: 46px;
}

.preview-header .menu a {
    padding: 0;
}

.header-inner {
    margin-top: 67px;
}
.menu-inner > ul > li:first-child {
    margin-top: 0;
}
.menu-inner > ul > li {
    margin: 8px 0;
    display: block;
    padding: 0;
    position: relative;
    left: 0px;
    padding-left: calc(80px + 40px);
    transform: translateX(-50px);
    transition: all 0.5s ease;
    transition-delay: 0s;
    opacity: 0;
    visibility: hidden;
}
.menu-inner ul li a {
    color: var(--white);
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    height: auto;
    letter-spacing: 0;
    line-height: 16px;
    position: relative;
    transition: all .3s ease;
    vertical-align: bottom;
    width: auto;
}
.nav-wrapp nav{
    background: transparent;
}

@media (max-width: 1401px) {
    .preview-header {
        padding: 5px 30px;
    }

    .header-actions {
        right: 30px;
    }
}

@media (min-width: 1201px) {
    .preview-header .header-inner .menu-inner {
        display: inline-block;
        vertical-align: top;
        margin: 0;
    }

    .search-mobile {
        top: 14px;
    }

    .search-mobile {
        font-size: 20px;
        position: relative;
        right: 0;
        z-index: 999;
        display: inline-block;
        top: 0%;
        margin: 0;
    }

    .search-mobile {
        top: 14px;
        margin-left: 10px;
        margin-right: -10px;
    }

    .header-actions .search-icon {
        display: block;
    }

    .search-icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin: auto;
    }

    .search-line {
        width: 6px;
        height: 1.5pt;
        background: var(--white);
        display: inline-block;
        transform: rotate(45deg);
        position: absolute;
        top: 13pt;
        left: 8pt;
        transition: all 0.2s ease-in 0s;
    }

    .search-line {
        width: 6px;
        height: 1.5pt;
        background: var(--white);
        display: inline-block;
        transform: rotate(45deg);
        position: absolute;
        top: 13pt;
        left: 8pt;
        transition: all 0.2s ease-in 0s;
    }

    .search-circle {
        width: 15px;
        height: 15px;
        border: 1.5pt solid var(--white);
        display: inline-block;
        position: absolute;
        top: 3px;
        left: -1px;
        border-radius: 50%;
        transition: top 0.3s ease-in 0s, left 0.3s ease-in 0s, border-radius 0.3s ease-in 0s, border-width 0.3s ease-in 0s, width 0.3s ease-in 0s, height 0.3s ease-in 0s, background-color 0.1s ease-in 0s;
        background-color: transparent;
    }

    .header-actions .header-actions-overlay {
        display: block;
    }

    .header-actions-overlay .search-close {
        opacity: 0;
    }

    .header-actions .search-icon {
        display: block;
    }

    .header-actions .searchform {
        position: relative;
        margin-right: 7px;
    }

    .header-actions .searchform .searchformicon {
        position: absolute;
        left: 14px;
        top: 50%;
        transform: translateY(-50%);
        color: #CECFD2;
    }

    .header-actions .searchform .search-field {
        font-size: 16px;
        line-height: 56px;
        height: auto;
        border: none;
        background: none;
        padding: 0px;
        margin: 0;
        color: var(--white);
        max-width: 100%;
        text-transform: none;
    }

    .header-actions .searchform #searchsubmit {
        position: absolute;
        left: 9px;
        top: 9px;
        font-size: 0;
        width: 20px;
        height: 20px;
        background-color: transparent;
        border-color: transparent;
        display: none;
        /* background-image: url(./assets/images/search-lg.svg);
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer; */
    }

    .header-actions .subscribe-popup .btn-common {
        background: var(--white);
        color: var(--primary);
        border-radius: 12px;
        /* margin-left: 10px; */
        padding: 10px 20px;
        font-weight: 500;
        border-radius: 30px;
    }
    nav.menu-inner > ul {
        display: flex;
        list-style-type: none;
        gap: 5px;
        margin: 0;
        padding: 0;
    }
    nav.menu-inner > ul > li:not(:last-child) {
        margin-right: 13px;
    }
    nav.menu-inner > ul > li {
        padding: 0;
        margin: 0;
        opacity: 1;
        visibility: visible;
        transform: none;
    }
    nav.menu-inner > ul > li {
        padding-top: 14px;
        padding-bottom: 12px;
    }
}

@media (max-width: 1200px) {
    .preview-header .menu {
        width: unset;
    }

    .nav-wrapp {
        display: block;
    }

    .preview-header {
        margin-top: 0;
        top: 0;
    }

    header.preview-header {
        height: 50px;
        padding: 0;
        margin-top: 20px;
        width: 100%;
        max-width: 700px;
        left: 50%;
        transform: translateX(-50%);
    }

    .preview-header .logo {
        left: unset !important;
    }

    .preview-header .logo {
        position: absolute;
        top: 0;
        left: 100px;
        width: auto;
        z-index: 1502;
        padding: 7px 0;
        transition: all 0.3s linear;
    }

    .preview-header .logo {
        left: 30px;
    }

    .preview-header .logo>a span {
        line-height: 1.5;
        padding-left: 0px;
    }

    .preview-header .logo>a span {
        line-height: 1.5;
        padding-left: 0px;
    }

    .preview-header .header-inner>.menu .menu-inner {
        transition: max-height 0.4s cubic-bezier(0.85, 0.11, 0.25, 1);
        overflow-y: hidden;
    }

    .preview-header .header-inner,
    .preview-header .menu {
        max-width: 700px;
        background-color: var(--primary);
        display: block;
        height: inherit;
    }

    .preview-header .header-inner {
        background-color: transparent !important;
    }

    .preview-header .header-inner>.menu .show-menu .menu-inner {
        max-height: 400px;
        z-index: 1;
        width: 100%;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        left: 50%;
        transform: translateX(-50%);
        padding: 0px;
        margin: 48px auto 0px !important;
        max-width: 700px;
        margin-top: 30px !important;
    }

    .preview-header .header-inner>.menu .menu-inner {
        box-shadow: none;
        height: auto;
        margin-top: 30px !important;
        padding: 0;
        position: fixed;
        right: auto;
        top: -1px;
        max-height: 0;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
    }

    .search-mobile {
        font-size: 20px;
        position: relative;
        right: 0;
        z-index: 999;
        display: inline-block;
        top: 11px;
        left: calc(100% - 60px);
        margin: 0;
    }

    .mobile-view {
        display: block;
    }

    .mobile-view>span {
        display: block;
        width: 100%;
        height: 2px;
        background: var(--black);
        position: absolute;
        top: 0;
        margin-top: 0;
        left: 0;
        margin-left: 0;
        transform: rotate(0deg);
        transition: all 0.4s cubic-bezier(0.6, 0, 0.2, 1);
        transition-timing-function: cubic-bezier(0.6, 0, 0.2, 1);
        transition-delay: 0s;
        border-radius: 32px;
    }

    .mobile-view>span:nth-child(2) {
        margin-top: 4px;
    }

    .mobile-view>span:nth-child(3) {
        margin-top: 8px;
    }

    .mobile-view>span:nth-child(4) {
        margin-top: 12px;
    }

    .search-icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin: auto;
    }

    .search-line {
        width: 6px;
        height: 1.5pt;
        background: var(--white);
        display: inline-block;
        transform: rotate(45deg);
        position: absolute;
        top: 13pt;
        left: 8pt;
        transition: all 0.2s ease-in 0s;
    }

    .search-circle {
        width: 15px;
        height: 15px;
        border: 1.5pt solid var(--white);
        display: inline-block;
        position: absolute;
        top: 3px;
        left: -1px;
        border-radius: 50%;
        transition: top 0.3s ease-in 0s, left 0.3s ease-in 0s,
            border-radius 0.3s ease-in 0s, border-width 0.3s ease-in 0s,
            width 0.3s ease-in 0s, height 0.3s ease-in 0s,
            background-color 0.1s ease-in 0s;
        background-color: transparent;
    }

    .preview-header .menu .menu-hamburger {
        margin-right: 8px;
        margin-top: 0;
        position: absolute;
        right: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: auto;
        display: block;
    }

    .header-actions>.searchform {
        display: none;
    }

    .header-actions-overlay {
        position: fixed;
        left: 0;
        width: 100%;
        z-index: 9;
        top: 0px;
        height: auto;
        min-height: 50px;
        transition: none;
        visibility: hidden;
        opacity: 0;
        transition: all 0.2s ease-out 0s;
        padding-top: 0;
        width: 100%;
        border-radius: 16px;
        max-width: 700px;
        margin: 0 auto;
        left: 50%;
        transform: translateX(-50%);
    }

    .header-actions>a {
        display: none;
    }

    .header-actions-overlay .search-close {
        width: 20px;
        height: 20px;
        top: 21px;
        right: 65px;
        visibility: hidden;
        opacity: 0;
    }

    .header-actions-overlay .search-close::after,
    .header-actions-overlay .search-close::before {
        width: 22px;
        height: 2px;
        left: 0;
        top: 6px;
        border-radius: 32px;
    }

    .header-top {
        display: none;
    }
}

@media (max-width: 767px) {
    header.preview-header {
        max-width: 100%;
        width: calc(100% - 40px);
    }

    .preview-header .logo img {
        margin-right: 9px;
    }

    .preview-header .header-inner>.menu .menu-inner {
        margin-top: 50px;
    }

    .search-mobile {
        left: calc(100% - 70px);
    }

    .preview-header .menu-hamburger {
        margin-right: 20px;
    }

    .preview-header .header-inner {
        margin-top: 112px;
    }
}

@media (max-width: 420px) {
    .preview-header .logo img {
        margin-right: 9px;
    }
    .preview-header .header-inner {
        margin-top: 132px;
    }
}