@keyframes logoSliderScroll {
  from {
    transform: translateX(0);
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translateX(-100%);
    transform: translate3d(-100%, 0, 0);
  }
}
.newpageContent:has(.tiptop-editor) .newpageLeft {
  z-index: 9;
}

.tiptop-editor:has(.uploadMediaPopup) [data-tippy-root] {
  display: none !important;
}
.tiptop-editor [data-tippy-root] {
  z-index: 3 !important;
}
.tiptop-editor *,
.tiptop-editor ::before,
.tiptop-editor ::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}
.tiptop-editor .ProseMirror {
  outline: none !important;
  border: none !important;
  min-height: 100%;
  z-index: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 740px;
  width: 100%;
  padding: 15px 20px;
  caret-color: #000;
  outline-width: 0;
  font-size: 16px;
  padding-top: 0;
  margin-top: 25px;
  font-weight: 400;
}


.ProseMirror .is-empty::before {
  pointer-events: none;
  float: left;
  height: 0px;
  width: 100%;
  color: rgb(0 0 0 / 0.4);
}

ProseMirror.ProseMirror-focused>p.is-empty::before,
ProseMirror.ProseMirror-focused>p.has-focus.is-empty::before {
    content: 'Type  /  to browse options';
}

.ProseMirror.ProseMirror-focused>[data-type='columns']>[data-type='column']>p.is-empty.has-focus::before {
    content: 'Type  /  to browse options';
}
.ProseMirror > .is-editor-empty::before {
  content: attr(data-placeholder);
}
.ProseMirror > .is-editor-empty[data-placeholder='']::before,
.ProseMirror > .is-editor-empty:not([data-placeholder])::before {
  content: 'Type / to browse options';
}
.ProseMirror > [data-type="blockquoteFigure"].is-editor-empty::before {
  content: '';
}
.ProseMirror blockquote .is-empty:first-child:last-child::before {
    content: 'Enter a quote...';
}
.ProseMirror blockquote+figcaption.is-empty::before {
    content: 'Author';
}

.ProseMirror [data-placeholder][data-suggestion]::before,
.ProseMirror [data-placeholder][data-suggestion] *::before {
    content: none !important;
}

.ProseMirror .node-aiWriter.has-focus [data-node-view-wrapper]>*,
.ProseMirror .node-aiImage.has-focus [data-node-view-wrapper]>*,
.ProseMirror .node-tableOfContentsNode.has-focus [data-node-view-wrapper]>* {
    border-radius: 0.5rem;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 0 0 2px rgb(0 0 0 / 0.3);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.ProseMirror [contenteditable="false"] {
    white-space: normal;
}

.ProseMirror p {
  margin-top: 0.75rem;
  margin-bottom: 20px;
  line-height: 27px;
  font-size: 16px;    
  font-weight: 400;
}

.ProseMirror p:first-child {
    margin-top: 0px;
}

.ProseMirror a {
    color: #3b82f6;
}

.ProseMirror a.link {
    font-weight: 500;
    color: rgb(59 130 246 / 1);
}

.ProseMirror>ul,
.ProseMirror>ol {
    margin-top: 0;
    margin-bottom: 1rem;
}

.ProseMirror ul ul,
.ProseMirror ul ol,
.ProseMirror ul li,
.ProseMirror ol ul,
.ProseMirror ol ol,
.ProseMirror ol li {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.ProseMirror ul p,
.ProseMirror ol p {
    margin-top: 0px;
    margin-bottom: 0.25rem;
}

.ProseMirror [contenteditable="false"] {
    white-space: normal;
}

.ProseMirror ul[data-type='taskList'] {
    list-style-type: none;
    padding: 0px;
}

.ProseMirror ul[data-type='taskList'] li {
    display: flex;
}

.ProseMirror ul[data-type='taskList'] li[data-checked='true'] div p {
    text-decoration-line: line-through;
}

.ProseMirror ul[data-type='taskList'] li>label {
  margin-top: 5px;
  margin-right: 4px;
  flex: 1 1 auto;
  flex-shrink: 0;
  flex-grow: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}


.ProseMirror ul[data-type='taskList'] li>div {
    flex: 1 1 auto;
}

.ProseMirror ul[data-type='taskList'] p {
    margin: 0px;
}
.ProseMirror ul[data-type='taskList'] input{
    font-style: unset;
    font-weight: unset;
    font-size: unset;
    line-height: unset;
    color: unset;
    padding: unset;
    gap: unset;
    background: unset;
    box-shadow: unset;
    border-radius: unset;
    outline: unset;
    border: unset;
    width: unset;
}
.ProseMirror ul[data-type='taskList'] input{
  font-size: 0;
  width: 18px;
  height: 18px;
  border: 1px solid transparent;
  -o-appearance: none;
  appearance: none;
  position: relative;
  padding: 0 0 0 0;
  margin: 0;
}
.ProseMirror ul[data-type='taskList'] input:before {
  content: "";
  border: 1px solid black;
  display: block;
  width: 15px;
  background: none;
  height: 15px;
  margin-right: 7px;
  border-radius: 4px;
  min-width: 16px;
  min-height: 16px;
  margin-right: 15px;
  background-size: 60%;
  background-position: 4px center;
  background-repeat: no-repeat;
  accent-color: #e6f2ff;
}
.ProseMirror ul[data-type='taskList'] input[type="checkbox"]:checked:before {
  background-image: url('../../images/black_checkbox.svg') !important;
  border: 1px solid black;
}
.ProseMirror b {
    font-weight: bolder;
}
.ProseMirror strong{
  font-weight: 700;
}

.ProseMirror code,
.ProseMirror kbd,
.ProseMirror samp,
.ProseMirror pre {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em;
}

.ProseMirror h1 {
    font-size: 1.875rem;
    line-height: 2.25rem
}

.ProseMirror h2 {
    font-size: 1.5rem;
    line-height: 2rem
}

.ProseMirror h3 {
    font-size: 1.25rem;
    line-height: 1.75rem
}

.ProseMirror h4 {
    font-size: 1.125rem;
    line-height: 1.75rem
}

.ProseMirror h5 {
    font-size: 1rem;
    line-height: 1.5rem
}

.ProseMirror h6 {
    font-size: 0.875rem;
    line-height: 1.25rem
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6,
.ProseMirror h1 a.link,
.ProseMirror h2 a.link,
.ProseMirror h3 a.link,
.ProseMirror h4 a.link,
.ProseMirror h5 a.link,
.ProseMirror h6 a.link {
    font-weight: 700
}

.ProseMirror h1:first-child,
.ProseMirror h2:first-child,
.ProseMirror h3:first-child,
.ProseMirror h4:first-child,
.ProseMirror h5:first-child,
.ProseMirror h6:first-child {
    margin-top: 0px
}

.ProseMirror h1:last-child,
.ProseMirror h2:last-child,
.ProseMirror h3:last-child,
.ProseMirror h4:last-child,
.ProseMirror h5:last-child,
.ProseMirror h6:last-child {
    margin-bottom: 0px
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3 {
  margin-bottom: 20px;
  margin-top: 0;
  color: #000;
}

.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
    margin-top: 2rem
}

.ProseMirror>blockquote blockquote,
.ProseMirror [data-type='blockquoteFigure'] blockquote {
    margin: 0px;
}
.ProseMirror [data-type='blockquoteFigure'] blockquote p {
  font-weight: 500;
  font-size: 18px;
  color: #344054;
  margin: 10px 0 0;
}

.ProseMirror figure[data-type='blockquoteFigure'] {
    margin: 0 0 20px 0;
    color: rgb(0 0 0 / 1);
}

.ProseMirror [data-type='blockquoteFigure']>div {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left-width: 4px;
    border-left-color: #000;
    --tw-bg-opacity: 0.8;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 4px;
}

.ProseMirror [data-type='blockquoteFigure'] figcaption {
  margin-top: 1rem;
  overflow: hidden;
  font-size: 16px;
  color: rgb(115 115 115 / 1);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.ProseMirror [data-type='blockquoteFigure'] figcaption span {
  font-size: 16px !important;
  color: rgb(115 115 115 / 1) !important;
}

.ProseMirror blockquote .is-empty:not(.is-editor-empty):first-child:last-child::before {
    content: 'Enter a quote...';
}

.ProseMirror blockquote+figcaption.is-empty:not(.is-editor-empty)::before {
    content: 'Author';
}

.ProseMirror code {
    caret-color: white;
    color: white;
    background-color: #171717;

    border-radius: 0.125rem;

    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);

    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

    line-height: 24px;
}

.ProseMirror code * {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    line-height: 24px;
}
.ProseMirror code::selection {
    background-color: rgba(255, 255, 255, 0.3);
}
.ProseMirror pre {
  caret-color: white;
  color: #f6f6f6;
  background: #282c34;
  border-radius: 0.375rem;
  margin: 0 0 20px 0;
  padding: 1rem;
  border: 1px solid black;
}
.ProseMirror pre *::selection {
    background-color: rgba(255, 255, 255, 0.2);
}
.ProseMirror pre code {
  color: #f6f6f6;
  background: #282c34;
  padding: 0;
  box-shadow: none;
}
.ProseMirror pre code .hljs-comment,
.ProseMirror pre code .hljs-quote {
    color: #a7a7a7;
    font-style: italic;
}
.ProseMirror pre code .hljs-doctag,
.ProseMirror pre code .hljs-keyword,
.ProseMirror pre code .hljs-formula {
    color: #c678dd;
}
.ProseMirror pre code .hljs-section,
.ProseMirror pre code .hljs-name,
.ProseMirror pre code .hljs-selector-tag,
.ProseMirror pre code .hljs-deletion,
.ProseMirror pre code .hljs-subst {
    color: #e06c75;
}
.ProseMirror pre code .hljs-literal {
    color: #56b6c2;
}
.ProseMirror pre code .hljs-string,
.ProseMirror pre code .hljs-regexp,
.ProseMirror pre code .hljs-addition,
.ProseMirror pre code .hljs-attribute,
.ProseMirror pre code .hljs-meta-string {
    color: #98c379;
}
.ProseMirror pre code .hljs-built_in,
.ProseMirror pre code .hljs-class .hljs-title {
    color: #e6c07b;
}
.ProseMirror pre code .hljs-attr,
.ProseMirror pre code .hljs-variable,
.ProseMirror pre code .hljs-template-variable,
.ProseMirror pre code .hljs-type,
.ProseMirror pre code .hljs-selector-class,
.ProseMirror pre code .hljs-selector-attr,
.ProseMirror pre code .hljs-selector-pseudo,
.ProseMirror pre code .hljs-number {
    color: #d19a66;
}
.ProseMirror pre code .hljs-symbol,
.ProseMirror pre code .hljs-bullet,
.ProseMirror pre code .hljs-link,
.ProseMirror pre code .hljs-meta,
.ProseMirror pre code .hljs-selector-id,
.ProseMirror pre code .hljs-title {
    color: #61aeee;
}
.ProseMirror pre code .hljs-emphasis {
    font-style: italic;
}
.ProseMirror pre code .hljs-strong {
    font-weight: bold;
}
.ProseMirror pre code .hljs-link {
    text-decoration: underline;
}

.ProseMirror .tableWrapper {
    margin-top: 0;
    margin-bottom: 20px;
}

.ProseMirror table {
    box-sizing: border-box;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    border-radius: 0.25rem;
    border-color: rgba(0, 0, 0, 0.1);
}
.ProseMirror table::-webkit-scrollbar,
.ProseMirror table::-webkit-scrollbar-track,
.ProseMirror table::-webkit-scrollbar-thumb {
  cursor: pointer;
} 
.tiptop-editor .tableWrapper:has(.ProseMirror-widget) table {
  padding: 10px 0 0 10px;
}
.ProseMirror table td,
.ProseMirror table th {
    position: relative;
    min-width: 150px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    text-align: left;
    vertical-align: top;
}

.ProseMirror table td:first-of-type:not(a),
.ProseMirror table th:first-of-type:not(a) {
    margin-top: 0px;
}

.ProseMirror table td p,
.ProseMirror table th p {
    margin: 0px;
}

.ProseMirror table td p+p,
.ProseMirror table th p+p {
    margin-top: 0.75rem;
}

.ProseMirror table th {
    font-weight: 700;
}

.ProseMirror table .column-resize-handle {
    pointer-events: none;
    position: absolute;
    bottom: -2px;
    right: -0.25rem;
    top: 0px;
    display: flex;
    width: 0.5rem;
}

.ProseMirror table .column-resize-handle::before {
    margin-left: 0.5rem;
    height: 100%;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.2);
    content: '';
}

.ProseMirror table .selectedCell {
    border-style: double;
    border-color: rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.05);
}

.ProseMirror table .grip-column,
.ProseMirror table .grip-row {
    position: absolute;
    z-index: 10;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.05);
}

.ProseMirror table .grip-column {
    left: 0px;
    top: -0.75rem;
    margin-left: -1px;
    height: 0.75rem;
    width: calc(100% + 1px);
    border-left-width: 1px;
    border-color: rgba(0, 0, 0, 0.2);
}

.ProseMirror table .grip-column:hover::before,
.ProseMirror table .grip-column.selected::before {
    content: '';
    width: 0.625rem;
}

.ProseMirror table .grip-column:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.ProseMirror table .grip-column:hover::before {
    border-bottom-width: 2px;
    border-style: dotted;
    border-color: rgba(0, 0, 0, 0.6);
}

.ProseMirror table .grip-column.first {
    border-top-left-radius: 0.125rem;
    border-color: transparent;
}

.ProseMirror table .grip-column.last {
    border-top-right-radius: 0.125rem;
}

.ProseMirror table .grip-column.selected {
    border-color: rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.ProseMirror table .grip-column.selected::before {
    border-bottom-width: 2px;
    border-style: dotted;
}

.ProseMirror table .grip-row {
    left: -0.75rem;
    top: 0px;
    margin-top: -1px;
    height: calc(100% + 1px);
    width: 0.75rem;
    border-top-width: 1px;
    border-color: rgba(0, 0, 0, 0.2);
}

.ProseMirror table .grip-row:hover::before,
.ProseMirror table .grip-row.selected::before {
    height: 0.625rem;
    content: '';
}

.ProseMirror table .grip-row:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.ProseMirror table .grip-row:hover::before {
    border-left-width: 2px;
    border-style: dotted;
    border-color: rgba(0, 0, 0, 0.6);
}

.ProseMirror table .grip-row.first {
    border-top-left-radius: 0.125rem;
    border-color: transparent;
}

.ProseMirror table .grip-row.last {
    border-bottom-left-radius: 0.125rem;
}

.ProseMirror table .grip-row.selected {
    border-color: rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.ProseMirror table .grip-row.selected::before {
    border-left-width: 2px;
    border-style: dotted;
}

.ProseMirror [data-type='horizontalRule'] {
  margin-bottom: 20px;
  cursor: pointer;
  /* padding-top: 1rem;
  padding-bottom: 1rem; */
  transition-property: all;
  transition-duration: 100ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-duration: 100ms;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.ProseMirror [data-type='horizontalRule'].ProseMirror-selectednode {
    background-color: rgb(0 0 0 / 0.05);
}

.ProseMirror [data-type='horizontalRule'] hr {
    border-width: 0px;
    border-top-width: 1px;
    border-color: rgb(0 0 0 / 0.2);
    background-color: rgb(0 0 0 / 0.8);
}

.ProseMirror [data-type='horizontalRule'].ProseMirror-selectednode hr {
    border-top-color: rgb(0 0 0 / 0.3);
}

.ProseMirror>*:first-child:first-child,
.ProseMirror [data-type='column']>*:first-child {
    margin-top: 0px;
}

.ProseMirror>*:first-child:last-child,
.ProseMirror [data-type='column']>*:last-child {
    margin-bottom: 0px;
}
.ProseMirror [data-type='column'] {
    border-radius: 0.25rem;
    border-width: 2px;
    border-style: dotted;
    border-color: transparent;
    padding: 0.25rem;
    transition: border 160ms cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

.ProseMirror [data-type='column']:hover {
    border-color: rgb(245 245 245 / 1);
}

.ProseMirror [data-type='column']:has(.is-active),
.ProseMirror [data-type='column'].has-focus {
    border-color: rgb(245 245 245 / 1);
}

.ProseMirror [data-type='columns'] {
  margin-bottom: 20px;
  display: grid;
    gap: 1rem;
}

.ProseMirror [data-type='columns'].layout-sidebar-left {
    grid-template-columns: 40fr 60fr;
}

.ProseMirror [data-type='columns'].layout-sidebar-right {
    grid-template-columns: 60fr 40fr;
}

.ProseMirror [data-type='columns'].layout-two-column {
    grid-template-columns: 1fr 1fr;
}
.ProseMirror [data-type='column'] {
    overflow: auto;
}
[data-type="columns"] [data-type="column"]>* {
    padding: 5px 0;
}
[data-type="columns"] [data-type="column"] p.is-empty:before {
    content: 'Type  /  to browse options';
}
[data-type="columns"] p,
[data-type="columns"] [data-type="imageBlock"] .image-block {
  margin-top: 0;
}
.tiptop-editor ul,.tiptop-editor li{
  list-style-type:unset;
}
.tiptop-editor ul, .tiptop-editor ol{
  padding-inline-start: 20px;
}
.tiptop-editor ul[data-type="taskList"]{
  padding-inline-start: 0px !important;
}
ul[data-type="taskList"]::before,
.tiptop-editor ol::before, 
.tiptop-editor ul::before {
  content: '' !important;
  pointer-events: unset !important ;
  float: unset !important;
  height: unset !important;
  width: unset !important;
  color: unset !important;
}
.layout-two-column::before ,
.layout-sidebar-left::before,
.layout-sidebar-right::before {
  pointer-events: none !important;
  float: unset !important;
  height: unset !important;
  width: unset !important;
  color: unset !important;
  content: unset !important;
}
::-webkit-scrollbar {
  height: 0.25rem;
  width: 0.25rem;
  background-color: rgb(115 115 115 / 0.2);
}

::-webkit-scrollbar-thumb {
  border-radius: 9999px;
  background-color: rgb(115 115 115 / 0.5);
}
.tiptop-editor  .bubble-menu {
  color: #000; 
  display: inline-flex;
  height: 100%; 
  line-height: 1; 
  gap: 0.125rem; 
  flex-direction: row;
  padding: 0.25rem; 
  align-items: center;
  background-color: #fff;
  border-radius: 0.5rem; 
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e7eb; 
}
.tiptop-editor  .bubble-menu .divider {
  background-color: #e5e7eb;
  height: 100%;
  min-height: 1.5rem;
  width: 1px;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.tiptop-editor  .bubble-menu button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  height: 2rem;
  min-width: 2rem;
  padding: 0 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 0.375rem;
  background-color: transparent;
  border: 1px solid transparent;
  color: #6b7280;
  white-space: nowrap;
  cursor: pointer;
}
.tiptop-editor  .bubble-menu .bubble-menu-item {
  display: flex; 
  align-items: center; 
  justify-content: center; 
  border: 1px solid transparent; 
  font-size: 0.875rem; 
  font-weight: 600; 
  border-radius: 0.375rem;
  opacity: 1; 
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.1); 
  color: #1f2937; 
  height: 2rem; 
  gap: 0.25rem; 
  min-width: 2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: auto; 
}
.tiptop-editor .bubble-menu button:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #374151;
}
.tiptop-editor .toolbar-button>div {
  line-height: 0;
} 
.tiptop-editor .toggle-menu {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: .7rem;
  box-shadow: 3px 2px 5px 2px hsla(0, 0%, 47%, .239);
  display: inline-grid;
  padding: 1rem .5rem;
  position: absolute;
  top: 40px;
}
.tiptop-editor .bubble-toggle-menu {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.5rem 0.5rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e7eb;
  position: absolute;
  top: 40px;
  min-width: 9rem;
}
.tiptop-editor .bubble-toggle-menu .font-label {
  font-size: 0.65rem;
  font-weight: 600;
  margin: 0.25rem 0;
  text-transform: uppercase;
  color: #6b7280;
  padding: 0 0.375rem;
}
.tiptop-editor .bubble-toggle-menu button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #6b7280;
  text-align: left;
  background-color: transparent;
  width: 100%;
  border: 0;
  border-radius: 0.375rem;
  cursor: pointer;
}
.tiptop-editor  .bubble-toggle-menu button.is-active,
.tiptop-editor .bubble-toggle-menu button:hover {
  background-color: #f3f4f6;
  color: #1f2937;
}

.tiptop-editor .bubble-toggle-menu.link-box {
  position: relative;
  top: 0;
  padding: 0.5rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e7eb;
}
.tiptop-editor .bubble-toggle-menu.link-box form {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.tiptop-editor  .bubble-toggle-menu.link-box .url-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #f3f4f6;
  cursor: text;
}
.tiptop-editor .bubble-toggle-menu.link-box input {
  flex: 1;
  background-color: transparent;
  outline: none;
  min-width: 12rem;
  color: #000;
  font-size: 0.875rem;
  border: 0;
  padding: 0;
}
.tiptop-editor .bubble-toggle-menu.link-box .setlinkBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 0.375rem;
  padding: 0.25rem 0.5rem;
  border: 1px solid #000;
  color: #fff;
  background-color: #000;
  white-space: nowrap;
  height: 34px;
}
.tiptop-editor .bubble-toggle-menu.link-box .setlinkBtn:disabled {
  opacity: 0.5;
}
.tiptop-editor .bubble-toggle-menu .toggle-button-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  color: #6b7280;
  margin-top: 10px;
}
.tiptop-editor .bubble-toggle-menu .toggle-button {
  display: inline-flex;
  align-items: center;
  border-radius: 9999px;
  border: 1px solid transparent;
  background-color: #e5e7eb;
  cursor: pointer;
  transition: background-color 0.2s ease;
  height: 1.25rem;
  width: 2.25rem;
  padding: 0 0.125rem;
}
.tiptop-editor .bubble-toggle-menu .toggle-button:hover {
  background-color: #d1d5db;
}
.tiptop-editor .bubble-toggle-menu .toggle-button.checked {
  background-color: #000;
}
.tiptop-editor .bubble-toggle-menu .toggle-button.checked:hover {
  background-color: #374151;
}
.tiptop-editor .bubble-toggle-menu .toggle-button span {
  display: block;
  border-radius: 9999px;
  pointer-events: none;
  background-color: #fff;
  height: 1rem;
  width: 1rem;
  transform: translateX(-0.5rem);
  transition: transform 0.2s ease;
}
.tiptop-editor .bubble-toggle-menu .toggle-button.checked span {
  transform: translateX(0.5rem);
}
.tiptop-editor .bubble-toggle-menu.button-box {
  padding: 1rem;
  min-width: 18rem;
}
.tiptop-editor .bubble-toggle-menu.button-box .alignment-buttons {
  padding: 0.25rem;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  margin: 0;
  gap: 6px;
  background-color:#EAECF0;
  border-radius: 6px;
}
.tiptop-editor .bubble-toggle-menu.button-box .alignment-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.tiptop-editor .bubble-toggle-menu.button-box .box-title {
  margin: 0;
}
.tiptop-editor .bubble-toggle-menu.button-box .alignment-buttons button {
  width: auto;
}
.tiptop-editor .bubble-toggle-menu.button-box label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
}
.tiptop-editor .bubble-toggle-menu.button-box input {
  flex: 1 1;
  background-color: #f3f4f6;
  outline: none;
  min-width: 12rem;
  color: #101828;
  font-size: 14px;
  border: 0;
  height: 45px;
  border-radius: 6px;
  padding: 10px 14px;
  margin-top: 4px;
  width: 100%;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  line-height: 24px;
}
.tiptop-editor .bubble-toggle-menu.button-box #publication-count {
  background: #fff;
  margin-bottom: 5px;
}
.button-box .submit-button {
  text-align: right;
  margin-top: 12px;
}
.tiptop-editor .button-box .submit-button button{
  width:auto;
}
.tiptop-editor .bubble-toggle-menu.button-box .url-block{
  position: relative;
}
.tiptop-editor .bubble-toggle-menu.button-box .url-block::after{
  content: "";
  background: url('../../images/link-icon.svg');
  background-size: 100%;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 10px;
  top: 37px;
}
.tiptop-editor .url-block .text-box .button-url{
  padding-left: 32px;
}
.tiptop-editor  .link-panel {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e7eb;
}
.tiptop-editor .link-panel a {
  font-size: 0.875rem;
  text-decoration: underline;
}
.tiptop-editor .link-panel .divider {
  background-color: #e5e7eb;
  height: 100%;
  min-height: 1.5rem;
  width: 1px;
  margin: 0 0.25rem;
}
.tiptop-editor .link-panel button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 0.375rem;
  opacity: 1;
  white-space: nowrap;
  background-color: transparent;
  color: #6b7280;
  height: 2rem;
  gap: 0.25rem;
  min-width: 2rem;
  padding: 0 0.5rem;
  width: auto;
  cursor: pointer;
}
.tiptop-editor .link-panel button.active {
  background-color: rgba(0, 0, 0, 0.1);
  color: #1f2937;
}
.tiptop-editor .toolbar-button.active, 
.tiptop-editor .bubble-toggle-menu button:hover {
  background-color: #ffffff;
  color: #1f2937;
}
.tiptop-editor .link-panel button:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #374151;
}
.tiptop-editor .toolbar-button > div {
  line-height: 0;
}
.tiptop-editor .content-type-menu {
  position: absolute;
  left: 0;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 1rem 0.5rem;
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.tiptop-editor .content-type-menu .content-type-menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  padding: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #6b7280;
  text-align: left;
  background-color: transparent;
  width: 100%;
  border-radius: 0.375rem;
}
.tiptop-editor .content-type-menu .content-type-menu-item div {
  line-height: 0;
}
.tiptop-editor .content-type-menu .content-type-menu-item.active,
.tiptop-editor .content-type-menu .content-type-menu-item:hover {
  background-color: #f3f4f6;
  color: #111827;
}
.tiptop-editor .content-type-menu .content-type-menu-title {
  font-size: 0.65rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  margin-top: 0.5rem;
  text-transform: uppercase;
  color: #6b7280;
  padding: 0 0.375rem;
}
.tiptop-editor .content-type-menu .content-type-menu-title:first-child,.tiptop-editor table tr td ol {
  margin-top: 0;
}

.tiptop-editor .ai-menu {
  position: absolute;
  left: 0;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 1rem 0.5rem;
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.tiptop-editor .ai-menu .ai-menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  padding: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #6b7280;
  text-align: left;
  background-color: transparent;
  width: 100%;
  border-radius: 0.375rem;
}
.tiptop-editor .ai-menu .ai-menu-item div {
  line-height: 0;
}
.tiptop-editor .ai-menu .ai-menu-item:hover {
  background-color: #f3f4f6;
  color: #111827;
}

.tiptop-editor .colorpicker-wrapper {
  position: absolute;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e7eb;
  padding: 0.25rem;
  margin-top: 0.5rem;
  min-width: 16.3rem;
}
.tiptop-editor .colorpicker-cover {
  display: flex;
  flex-direction: column;
  gap: 0.5rem; /* 2 x 0.25rem (8px) */
}
.colorpicker-cover .react-colorful {
  width: auto;
}
.tiptop-editor .colorpicker-input {
  width: 100%;
  padding: 0.5rem; /* 2 x 0.25rem (8px) */
  color: #000;
  background-color: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem; /* 4px */
}
.tiptop-editor .colorpicker-input:focus {
  outline: 1px solid #d1d5db;
}
.tiptop-editor .colorpicker-buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.25rem; /* 1 x 0.25rem (4px) */
}
.tiptop-editor .colorpicker-buttons .colorpicker-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem; /* 6px */
  border-radius: 0.375rem; /* 6px */
}
.tiptop-editor .colorpicker-buttons .colorpicker-button:hover,
.tiptop-editor .colorpicker-buttons .colorpicker-button.active {
  background-color: #f5f5f5; 
}
.tiptop-editor .colorpicker-buttons .colorpicker-button > div {
  width: 1rem; /* 4px */
  height: 1rem; /* 4px */
  border-radius: 0.25rem; /* 4px */
  background-color: #f1f5f9; /* slate-100 */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); /* shadow-sm */
  outline-offset: 0.125rem; /* 2px */
  outline-color: currentColor; /* ring-current */
}
.tiptop-editor .colorpicker-buttons .colorpicker-button > div:hover
.tiptop-editor .colorpicker-buttons .colorpicker-button > div.active {
  outline-width: 1px;
}

.tiptop-editor .moreoptions-wrapper {
  position: absolute;
  right: -40%;
  bottom: -90%;
  background-color: #ffffff;
  border-radius: 0.5rem; /* 8px */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e7eb; /* neutral-200 */
  padding: 0.25rem; /* 4px */
  margin-top: 0.5rem; /* 8px */
  display: flex;
  flex-direction: row;
}

.tiptop-editor .tablerow-menu {
  color: #000000; /* text-black */
  display: inline-flex;
  height: 100%;
  line-height: 1;
  gap: 0.125rem; /* 0.5 */
  flex-direction: column;
  padding: 0.5rem; /* 2 */
  background-color: #ffffff; /* bg-white */
  border-radius: 0.5rem; /* rounded-lg */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); /* shadow-sm */
  border: 1px solid #e5e7eb; /* border-neutral-200 */
}
.tiptop-editor .tablerow-menu .tablerow-menu-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #71717a;
  text-align: left;
  background-color: transparent;
  width: 100%;
  border-radius: 0.375rem;
}
.tiptop-editor .tablerow-menu .tablerow-menu-item:hover {
  background-color: #f5f5f5;
  color: #1f2937;
}
.tiptop-editor .node-tableOfContentsNode.ProseMirror-selectednode .TableOfContentsWrapper {  
  border-radius: 0.5rem;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 0 0 2px rgb(0 0 0 / 0.3);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.tiptop-editor .TableOfContentsWrapper {
  padding: 0.5rem;
  margin: 0;
  border-radius: 0.5rem;
}
.tiptop-editor .TableOfContentsTitle {
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #6b7280; /* Neutral 500 */
  font-size: 14px;
}
.tiptop-editor .TableOfContentsCover {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow-x: hidden;
}
.tiptop-editor .TableOfContentsCover .TableOfContentsItem {
  display: block;
  font-weight: 500;
  color: #6b7280; /* neutral-500 */
  padding: 0.25rem;
  border-radius: 0.25rem;
  font-size: 16px; /* text-sm */
  transition: all 0.3s;
  /* width: 100%; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
}
.tiptop-editor .TableOfContentsCover .TableOfContentsItem:hover {
  color: #1f2937; /* neutral-800 */
  /* background-color: rgba(0, 0, 0, 0.05); */
}
.tiptop-editor .TableOfContentsWrapper .TableOfContentsNoData {
  font-size: 0.875rem; /* text-sm */
  color: #6b7280; /* neutral-500 */
}

.tiptop-editor .DragHandleItems {
  display: flex;
  align-items: center;
  gap: 0.125rem;
  background-color: transparent;
}
.DragHandleItems.d-none {
  display: none;
}
.DragHandleItems .DragHandleItem {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  font-size: 0.875rem; /* equivalent to text-sm */
  font-weight: 600; /* equivalent to font-semibold */
  border-radius: 0.375rem; /* equivalent to rounded-md */
  opacity: 1;
  white-space: nowrap;
  background-color: transparent;
  color: #737373; /* equivalent to text-neutral-500 */
  height: 2rem;
  gap: 0.30rem; /* equivalent to gap-1 */
  width: 2rem;
  cursor: pointer;
}
.DragHandleItems .DragHandleItem:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #3f3f46;
}
.DragHandleItems .DragHandleItem svg,
.DragHandleSubItems .DragHandleSubItem svg,
.menulist-wrapp .menu-label svg {
  width: 1rem;
  height: 1rem;
}
.DragHandleItems .DragHandleItem svg{
  width: 2rem;
}

.DragHandleSubItems {
  position: fixed;
  left: 0px;
  top: 0px;
  transform: translate(36px, 40px);
  min-width: max-content;
  --radix-popper-transform-origin: 0% 0px;
  z-index: auto;
  --radix-popper-available-width: 1346px;
  --radix-popper-available-height: 383px;
  --radix-popper-anchor-width: 34px;
  --radix-popper-anchor-height: 32px;
}
.DragHandleSubItems .DragHandleSubItemsWrapp {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05);
  padding: 0.5rem;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 0.5rem;
  min-width: 12rem;
  display: flex;
  flex-direction: column;
}
.DragHandleSubItems .DragHandleSubItem {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
  cursor: pointer;
  text-transform: none;

  display: flex;
  align-items: center;
  gap: 0.5rem; 
  padding: 0.375rem; 
  font-size: 0.875rem;
  font-weight: 500;
  color: #737373; 
  text-align: left; 
  background-color: transparent;
  border-radius: 0.25rem; 
}
.DragHandleSubItems .DragHandleSubItem.dangerItem {
  color: #ef4444;
  background-color: rgba(239, 68, 68, 0.1); 
}
.DragHandleSubItems .DragHandleSubItem:hover {
  background-color: #f5f5f5; 
  color: #1f2937;
}

.DragHandleSubItems .DragHandleSubItem.dangerItem:hover {
  background-color: rgba(239, 68, 68, 0.2);
}
.DragHandleSubItems .sunItemSivider {
  background-color: #e5e5e5; 
  width: 100%; 
  min-width: 1.5rem;
  height: 1px; 
  margin-bottom: 0.4rem;
}

.menulist-container {
  color: #000000;
  max-height: min(80vh, 24rem);
  overflow: auto; 
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  padding: 0.5rem;
  background-color: #ffffff;
  border-radius: 0.5rem; 
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e5e5;
  min-width: 12rem;
}
.menulist-wrapp {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr)); 
  gap: 0.125rem;
  width: 100%;
}
.menulist-wrapp .menu-title {
  color: #6b7280;
  font-size: 0.65rem;
  grid-column: 1 / -1;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 1rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  user-select: none; 
  text-transform: uppercase;
  width: 100%;
}
.menulist-wrapp .menu-label {
  display: flex; 
  align-items: center;
  gap: 0.5rem; 
  padding: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500; 
  color: #0A0A0A; 
  text-align: left;
  background-color: transparent; 
  width: 100%; 
  border-radius: 0.25rem;
  cursor: pointer;
}
.menulist-wrapp .menu-label div {
  line-height: 0;
}
.menulist-wrapp .menu-label.active,
.menulist-wrapp .menu-label:hover {
  background-color:#f9f9f9;
  color: #1f2937; 
}

.ProseMirror [data-type='button'] {
  padding: 12px;
  border: 1px solid #ffffff;
  border-radius: 6px;
  cursor: default;
}
.ProseMirror [data-type='button'].ProseMirror-selectednode {
  border-color: #573CFF;
}
.button-element.align-left {
  text-align: left;
}
.button-element.align-center {
  text-align: center;
}
.button-element.align-right {
  text-align: right;
}
.tiptop-editor .button-element button,
.tiptop-editor .button-element a {
  background-color: #1c1c1c;
  border: 1px solid #1c1c1c;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  padding: 5px 10px;
  transition: all .3s linear;
}
.button-element.width-full button {
  width: 100%;
}
.button-element.width-auto button {
  width: auto;
}

/* book mark section */
.tiptop-editor .bookmark-node-wrapper,
.vimeo-node-wrapper,
.youtube-node-wrapper,
.other-node-wrapper,
.twitter-node-wrapper,
.spotify-node-wrapper,
.soundcloud-node-wrapper,
.publications-wrapper {
  position: relative;
  margin-top: 0;
}
.twitter-node-wrapper .none,
.spotify-node-wrapper .none,
.soundcloud-node-wrapper .none,
.other-node-wrapper .none {
    display: none;
}
.tiptop-editor .bookmark-node-wrapper label,
.twitter-node-wrapper label,
.spotify-node-wrapper label,
.soundcloud-node-wrapper label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0a2540;
  margin-bottom: 5px !important;
}
.tiptop-editor .bookmark-node-wrapper .urlInputLeft, 
.vimeo-node-wrapper .urlInput,
.tiptop-editor .youtube-node-wrapper .urlInput,
.tiptop-editor .other-node-wrapper .urlInput,
.twitter-node-wrapper .urlInputLeft,
.tiptop-editor .spotify-node-wrapper .urlInputLeft,
.tiptop-editor .soundcloud-node-wrapper .urlInputLeft {
  padding: 8px 12px;
  gap: 8px;
  height: 45px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #573CFF;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  outline: none;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 40px;
  font-size: 14px;
  color: #000;
}
.tiptop-editor .other-urlInput .urlInput.embedCode{
  padding: 10px 10px 10px 40px;
  width: 100%;
  min-height: 120px;
  resize: vertical;
  font-size: 16px;
  line-height: 26px;
}
.embed-code iframe {
  max-width: 100%;
}
.tiptop-editor .bookmark-node-wrapper svg,
.youtube-node-wrapper svg,
.vimeo-node-wrapper svg,
.other-node-wrapper svg,
.twitter-node-wrapper svg,
.spotify-node-wrapper svg,
.soundcloud-node-wrapper svg {
  position: absolute;
  top: 0.8rem;
  left: 14px;
  width: 20px;
}
.other-node-wrapper svg{
  top: 0.99rem !important;
}
.twitter-node-wrapper svg,
.spotify-node-wrapper svg ,
.soundcloud-node-wrapper svg {
  top: 1.4rem !important;
}
.tiptop-editor .bookmark-node-wrapper svg{
  top: 1.5rem;
}
.tiptop-editor .bookmark-node-wrapper .linkbutton-loader , 
.tiptop-editor .youtube-node-wrapper .linkbutton-loader,
.vimeo-node-wrapper .linkbutton-loader,
.tiptop-editor .other-node-wrapper .linkbutton-loader,
.tiptop-editor .twitter-node-wrapper .linkbutton-loader ,
.tiptop-editor .spotify-node-wrapper .linkbutton-loader,
.tiptop-editor .soundcloud-node-wrapper .linkbutton-loader {
  min-width: 100%;
  padding: 8px 12px;
  gap: 8px;
  height: 45px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 10px;
} 
.tiptop-editor .bookmark-node-wrapper .ring-loader,
.tiptop-editor .youtube-node-wrapper .ring-loader,
.vimeo-node-wrapper .ring-loader,
.tiptop-editor .other-node-wrapper .ring-loader,
.twitter-node-wrapper .ring-loader,
.tiptop-editor .spotify-node-wrapper .ring-loader,
.tiptop-editor .soundcloud-node-wrapper .ring-loader,
.publications-wrapper .ring-loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  margin: 7px auto;
  top: 6px;
  left: 0;
  right: 0;
  animation: rotate 1s linear infinite;
}
.publications-wrapper .ring-loader{
  top: 2px !important;
}
.tiptop-editor .bookmark-node-wrapper .ring-loader:before,
.tiptop-editor .youtube-node-wrapper .ring-loader:before,
.vimeo-node-wrapper .ring-loader:before,
.tiptop-editor .other-node-wrapper .ring-loader:before,
.twitter-node-wrapper .ring-loader:before,
.tiptop-editor .spotify-node-wrapper .ring-loader:before,
.tiptop-editor .soundcloud-node-wrapper .ring-loader:before,
.publications-wrapper .ring-loader:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 3px solid black;
  animation: prixClipFix 2s linear infinite;
  top: 0;
}
#widget .privacyPolicy {
  display: none !important;
}
.tiptop-editor .twitter-node-wrapper .twitter-embed,.tiptop-editor .node-urlPaste .twitter-node-wrapper.twitter-block .twitter-tweet{
  width: 100%;
  max-width: 550px;
  margin: 20px auto !important;
}
.twitter-node-wrapper .twitter-embed .twitter-tweet-rendered{
  margin-top: 1.5rem !important;
}
.spotifyEmbed iframe{
  border-radius: 0.5rem !important;
}
.spotifyEmbed[data-spotify-type="track"] iframe {
  max-height: 232px;
}
.spotifyEmbed[data-spotify-type="episode"] iframe {
  max-height: 352px;
}
.tiptop-editor .bookmark-node-wrapper .ring-loader.dark:before,
.youtube-node-wrapper .ring-loader.dark:before,
.vimeo-node-wrapper .ring-loader.dark:before,
.other-node-wrapper .ring-loader.dark:before,
.publications-wrapper .ring-loader.dark:before {
  border: 3px solid #868c98;
}
.video-section{
  margin: 20px 0;
}
.tiptop-editor .linkBox{
  display: flex;
  box-shadow: none;
  align-items: center;
  border: 1px solid #8080805c;
  border-radius: 0;
  padding: 15px;
}
.tiptop-editor .node-bookmarkBlock .link-content{
  margin: 15px 0;
}
.tiptop-editor .link-content a {
  text-decoration: none;
}
.tiptop-editor .link-content img {
  width: 100%;
  height: 90px;
  object-fit: cover;
  border-radius: 0;
}
.tiptop-editor .textBlock.linkBox .linkImageSection {
  display: flex;
  align-items: center;
  width: 90px !important;
  height: auto;
}
.tiptop-editor .linkBox .linkData {
  width: calc(100% - 90px);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  padding-left: 15px;
}
.tiptop-editor .link-content p.linkDataTitle {
  color: #101828;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 0;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.tiptop-editor .link-content .linkDataDiscripation {
  display: none;
}
.tiptop-editor .link-content .siteData {
  display: flex;
  align-items: center;
  width: 100%;
}
.tiptop-editor .link-content .siteData.siteUrl {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tiptop-editor .linkData .siteData:nth-child(3) {
  margin-top: -10px;
}
.tiptop-editor .link-content .siteData img {
  margin-right: 6px;
  border-radius: 0;
  object-fit: unset;
  width: 18px;
  height: 18px;  
}
.tiptop-editor .link-content .siteData p {
  margin: 0;
  font-size: 14px;
  color: #475467;
}

.tiptop-editor .error-message {
  font-size: 14px;
  color: #d92d20;
  display: block;
  margin-top: 0px  !important;
}
.video-wrapper .error-message {
  margin: 5px !important;
}

.tiptop-editor .video-wrapper .video-caption,
.gallery-block .video-caption {
  min-height: 24px;
  text-align: left;
  margin-bottom: 0 !important;
  border: none;
  box-shadow: none;
  outline: none;
  font-size: 14px;
}
.tiptop-editor .video-wrapper .video-caption:empty:before {
  pointer-events: none;
  float: left;
  height: 0px;
  width: 100%;
  color: rgb(0 0 0 / 0.4);
  content: 'Write a caption for the video (optional)';
  line-height: 26px;
}
.tiptop-editor .file-block .file-detail .file-caption:empty:before {
  pointer-events: none;
  float: left;
  height: 0px;
  width: 100%;
  color: rgb(0 0 0 / 0.4);
  content: 'Write a caption for the file (optional)';
  line-height: 26px;
}
.youtube-video, 
.vimeo-video{
  font-size: 14px;
  /* border: 1px solid #573CFF;
  border-radius: 6px; */
  background-color: #fff;
  color: #101828;
  margin-bottom: 20px;
}
.toolbar-button .menu-icon{
  margin-top: 2px;
}
.toolbar-button .font-size-picker{
  font-weight: 600;
  color: #6b7280;
}
.player-wrapper{
  display: none;
}
.tiptop-editor .image-preview .player-wrapper.player-ready {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  line-height: 0;
  overflow: hidden;
  height: 350px;
  display: block;
  margin-bottom: 0;
  border-radius: 0;
}
.tiptop-editor .node-vimeoBlock .player-wrapper.player-ready,
.tiptop-editor .video-wrapper.youtube-video .player-wrapper.player-ready {
  margin: 0 !important;
}
.tiptop-editor [data-type='video'] .video-wrapper .player-wrapper.player-ready {
  height: auto;
  min-height: 300px;
}
.tiptop-editor .video-title{
  padding: 5px 0;
  outline: none;
}
.node-youtubeBlock .video-wrapper.youtube-video .video-title{
  padding-top: 0;
}
.toolbar-button .content-icon{
  margin-top: 3px;
}

.tiptop-editor .video-wrapper .skeleton-video-wrapper {
  position: relative;
  padding-top: 56.25%;
}
.tiptop-editor .video-wrapper .skeleton-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #ececee;
  border-radius: 6px 6px 0 0;
  animation: loading 1.5s infinite;
}
.tiptop-editor .node-vimeoBlock  .video-wrapper.vimeo-video .video-title{
  margin-top: -6px !important;
}
.tiptop-editor .node-vimeoBlock  .video-wrapper.vimeo-video .player-wrapper.player-ready{
  margin-top: -1px;
}


/* publication */
.tiptop-editor .publication-list.list .publication-item {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}
.tiptop-editor .publication-list.list .publication-item:first-child{
  margin-top: 0 !important;
}
.tiptop-editor .publication-list.list .publication-data {
  padding-left: 15px;
  width: calc(100% - 25%);
  font-size: 14px;
}
.tiptop-editor .publications-wrapper .publication-title.two-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.ProseMirror .publications-wrapper a {
  pointer-events: none;
  cursor: pointer;
  color: #000 ;
  text-decoration: none;
  font-weight: 700;
}
.tiptop-editor .publications-wrapper .publication-description.four-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 22px;
}
.tiptop-editor .publications-wrapper .publication-title{
  margin-bottom: 0;
  line-height: 22px;
  font-size: 18px;
  font-weight: 400;
  color: #101828;
}
.tiptop-editor .publications-wrapper .publication-list.list .publication-image{
  width: 25%;
  border-radius: 6px;
  line-height: 0;
}
.tiptop-editor .publications-wrapper .publication-image img{
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.alignment-box .menu-title.layout-title{
  margin-bottom: 0;
}
.tiptop-editor .publications-wrapper .publication-list.list {
  display: block;
}
.tiptop-editor .node-publications .publications-wrapper,
.tiptop-editor .node-product .product-node-wrapper {
  margin-left: -14px;
  margin-right: -14px;
}
.tiptop-editor .node-publications .publication-list {
  /*border: 1px solid #573CFF;*/
  border-radius: 6px;
  padding: 14px;
}
.publication-image img {
  width: 100%;
  height: auto;
}
.tiptop-editor .node-publications .publication-list.grid {
  display: grid;
  align-items: start;
  column-gap: 12px;
  row-gap: 25px;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
}
.publication-list .publication-date {
  font-size: 16px;
  padding: 10px 0 0;
  color: #101828;
}
.tiptop-editor .categories-box .categories-button{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d0d5dd;
  background: #fff;
  padding: 10px 14px;
  border-radius: 6px;
  margin: 5px 0;
  cursor: pointer;
  height: 46px;
  font-weight: 400;
  color: #101828;
}
.tiptop-editor .bubble-toggle-menu.button-box .categories-box .select-categories{
  font-size: 14px;
  color: #101828;
  line-height: 24px;
}
.tiptop-editor .publications-wrapper .categories-box .menu-title,
.layout-box .menu-title{
  font-size: 14px;
  line-height: 20px;
  color: #0A0A0A;
}
.tiptop-editor .page-not-found .NoResults {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  border-bottom-left-radius: 12px;
  min-height: 300px;
  align-items: center;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  border: 0;
}
.tiptop-editor .NoResults .noResultsBody {
  max-width: 350px;
}
.tiptop-editor .NoResults .noResultsBody > svg {
  width: 170px;
  margin-top: 12px;
            
}
.tiptop-editor .page-not-found .noResTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #101828;
  margin-top: 20px;
  margin-bottom: 8px;
}
.tiptop-editor .page-not-found .noResDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #475467;
  margin-bottom: 30px;
}

.viemo-urlInput {
  margin-top: calc(1.5rem - 10px);
}

.image-node-wrapper .mediaGallery .ulpoadImageBlock {
  padding-left: 36px;
  padding-right: 36px;
}

.tiptop-editor .image-node-wrapper .textBlock {
  margin-top: 0;
  margin-bottom: 20px;
  position: relative;
  top: 0;
}
.tiptop-editor .image-node-wrapper .newpageLeftRow input {
  background: #fff;
  border: none;
  border-radius: 6px;
  box-shadow: 0 1px 2px #1018280d;
  color: #667085;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  gap: 8px;
  line-height: 24px;
  outline: none;
  padding: 14px 14px 12px;
  width: 100%;
}
.tiptop-editor .image-node-wrapper .uploadFileBlock {
  margin: 0 auto;
  width: 100%;
  min-height: 250px;
  padding: 50px 10px;
  box-shadow: 0 1px 2px #1018280d;
  cursor: pointer;
  justify-content: center;
  position: relative;
  z-index: 0;
  border: 1px solid #573cff;
  border-radius: 6px;
}
.image-node-wrapper .fileUploadBox .fileUploadCover_Img {
  align-items: center;
  background: #f2f4f7;
  border: 6px solid #f9fafb;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 0 auto 5px;
  padding: 5px !important;
  width: 40px;
}
.tiptop-editor .image-node-wrapper .newPageImageUploadText, .uploadFileBlock .newPageImageUploadText {
  color: #475467;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  user-select: none;
  margin-bottom: 0;
  padding-top: 0;
  text-align: center;
}
.tiptop-editor .image-node-wrapper .newPageImageUploadText span {
  color: #573cff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.image-node-wrapper .image-size-text{
  font-size: 14px;
}
.tiptop-editor .progress-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tiptop-editor .popupBlock .progress-wrap {
  margin: 0 auto;
}
.tiptop-editor .progress {
  position: relative;
  height: 7px;
  width: 100%;
  border: 1px solid rgb(246, 246, 250);
  border-radius: 15px;
  top: 0;
  left: 0;
}
.tiptop-editor .popupBlock .progress {
  width: 70%;
}
.tiptop-editor .progress .color {
  position: absolute;
  background-color: #573CFF;
  width: 0px;
  height: 6px;
  border-radius: 15px;
  animation: progress 4s infinite linear;
}
.image-node-wrapper .uploadRunning {
  width: 100%;
  min-height: 250px;
  padding: 100px;
  box-shadow: 0 1px 2px #1018280d;
  position: relative;
  z-index: 0;
  border: 1px solid #573cff;
  border-radius: 6px;
}
.image-block .video-caption,
.node-youtubeBlock .video-wrapper .video-caption {
  min-height: 24px;
  text-align: left;
  margin-bottom: 0 !important;
  outline: none;
}
.image-block .video-caption:before,
.node-youtubeBlock .video-wrapper .video-caption::before {
  outline: none;
}
.image-block .video-caption:empty:before {
  pointer-events: none;
  float: left;
  height: 0px;
  width: 100%;
  color: rgb(0 0 0 / 0.4);
  content: 'Write a caption for the image (optional)';
  font-size: 14px;
  outline: none;
  line-height: 26px;
}
.tiptop-editor .node-VideoBlock .video-wrapper .video-caption:empty:before {
  pointer-events: none;
  float: left;
  height: 0px;
  width: 100%;
  color: rgb(0 0 0 / 0.4);
  content: 'Write a caption for the video (optional)';
  font-size: 14px;
  outline: none;
  line-height: 26px;
}
.tiptop-editor .image-block {
  /* border: 1px solid #573cff; */
  border-radius: 0.5rem;
  margin-bottom: 20px;
}
.tiptop-editor .image-preview-cover {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.tiptop-editor .image-preview-cover .image-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}
.tiptop-editor .image-block .image-preview.image-width-full,
.tiptop-editor .video-wrapper .image-preview.image-width-wide ,
.tiptop-editor .logoslider-wapper.image-width-full{
  width: calc(100vw - 312px - 375px - 36px - 40px - 40px);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1200px;
}
.tiptop-editor .image-block .image-preview img{
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  object-fit: cover;
}
.tiptop-editor .image-bubble-menu{
  padding: 0.5rem !important;
  top: -9px !important;
  min-width: 5rem !important;
  transform: translateX(-50%);
}
.tiptop-editor  .image-bubble-menu.page-bubble-menu {
  min-width: 3rem !important;
}
.tiptop-editor .image-bubble-menu button {
  padding: 0 !important;
  display: block;
  width: unset;
  line-height: 0;
}
.tiptop-editor .image-bubble-menu .image-bubble-block {
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
}
.toggle-button-image{
  padding: 9px !important;
}
.image-node-wrapper .errorMessages{
  text-align: center;
  margin-top: 10px;
}
/* .image-block {
  padding: 10px;
} */

.node-fileBlock.ProseMirror-selectednode .fileblock-wrapper {
  border: 1px solid #573cff;
  border-radius: 6px;
}
.tiptop-editor .node-fileBlock .fileblock-wrapper {
  margin-left: -10px;
  margin-right: -10px;
}
.tiptop-editor .file-block .file-detail .filedescription .file-caption{
  outline: none;
  width: calc(100% - 75px);
  font-size: 15px;
  color: rgb(55 65 81);
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0;
}
.file-caption:empty:before{
  content: 'Enter a description'
}
.tiptop-editor .file-block .file-detail .file-caption.file-title{
  color: #000;
  font-weight: 600;
  font-size: 16px;
  outline: none;
  margin-bottom: 0;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.tiptop-editor .fileblock-wrapper .image-block.file-block .uploadFileBlock {
  display: flex;
  align-items: center;
  gap: 20px;
  min-height: unset;
  max-width: unset;
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: unset;
  justify-content: unset;
}
.tiptop-editor .file-block .file-detail .filedescription,
.tiptop-editor .file-block .uploadFileBlock .file-detail {
  width: 100%;
}
.uploadFileBlock .newPagefileUploadText {
  margin-top: 10px;
  margin-bottom: 0;
}
.tiptop-editor .fileblock-wrapper .file-block {
  padding: 10px;
  margin: 0;
}
.tiptop-editor .fileblock-wrapper .file-block .featureImgMiddleContent svg {
  height: 40px;
}
.tiptop-editor .fileblock-wrapper .file-block .featureImgMiddleContent .icon-img {
  max-width: 40px;
  margin-left: 0.4rem;
}
.tiptop-editor .fileblock-wrapper .file-block .featureImgMiddleContent .file-icon {
  max-width: calc(40px + 0.4rem);
}
.tiptop-editor .newPageImageUploadText span {
  color: #573cff;
}
.tiptop-editor .uploadFileBlock .newPageImageUploadText.fileupload {
  display: block;
}
.tiptop-editor .uploadFileBlock .uploaded-file .newPagefileUploadText {
  font-size: 14px;
}
.tiptop-editor .file-block .uploaded-file .newPagefileUploadText span {
  color: #777;
}
.bubble-toggle-menu.file-bubble-menu{
  min-width: 2.4rem !important;
  top: -9px !important; 
  display: block;
  padding: 2px 0 !important;
}
.file-bubble-menu .file-bubble .full-display-button{
  padding: 5px !important;
  margin-left: 2px;
}
.tiptop-editor .publications-wrapper .publication-list.grid .publication-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  
}
.tiptop-editor .publications-wrapper .publication-list.grid .publication-item .publication-image {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  line-height: 0;
  position: relative;
  overflow: hidden;
  height: 220px;
}
.tiptop-editor .publications-wrapper .publication-list.list .publication-item .publication-image .publicationTypeWrapper {
  display: none;
}
.tiptop-editor .publications-wrapper .publication-list.grid .publication-item .publication-image .publicationTypeWrapper {
  background-color: #fff;
  color: #101828;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 15px;
  line-height: 1.2;
  padding: 6px 12px 12px 6px;
  border-bottom-right-radius: 20px;
}
.tiptop-editor .publications-wrapper .publication-list.grid .publication-item .publication-image .publicationTypeWrapper .corner-top-left {
  width: 18px;
  height: 18px;
  overflow: hidden;
}
.tiptop-editor .publications-wrapper .publication-list.grid .publication-item .publication-image .publicationTypeWrapper .corner-top-left.top {
  position: absolute;
  right: -18px;
  top: 0;
}
.tiptop-editor .publications-wrapper .publication-list.grid .publication-item .publication-image .publicationTypeWrapper .corner-top-left.bottom {
  position: absolute;
  left: 0;
  bottom: -18px;
}
.tiptop-editor .publications-wrapper .publication-list.grid .publication-data{
  margin-top: 12px;
}
.publication-list.grid .publication-item .publication-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden; 
}
.bubble-toggle-menu.button-box .publication-count .zoom-range,
.bubble-toggle-menu.button-box .publication-column .zoom-range {
  width: 100%;
}
.bubble-toggle-menu.button-box .publication-count input.zoom-range,
.bubble-toggle-menu.button-box .publication-column input.zoom-range {
  height: 4px;
  background-color: #0A2540;
  border-radius: 6px;
  appearance: none;
  padding: 0;
  margin-bottom: 10px;
}
.bubble-toggle-menu.button-box .publication-count input.zoom-range::-webkit-slider-thumb,
.bubble-toggle-menu.button-box .publication-column input.zoom-range::-webkit-slider-thumb {
  height: 15px;
  width: 15px;
  background-color: #0A2540;
  border-radius: 50%;
  appearance: none;
  z-index: 99;
  cursor: grab;
}
.bubble-toggle-menu.button-box .publication-count input.zoom-range.slider-cursor::-webkit-slider-thumb,
.bubble-toggle-menu.button-box .publication-column input.zoom-range.slider-cursor::-webkit-slider-thumb {
  cursor: grabbing;
}
.bubble-toggle-menu.button-box .publication-count input.zoom-range::-moz-range-thumb,
.bubble-toggle-menu.button-box .publication-column input.zoom-range::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #344054;
  border-radius: 50%;
  appearance: none;
  z-index: 99;
}
.bubble-toggle-menu.button-box .publication-count input.zoom-range::-ms-thumb,
.bubble-toggle-menu.button-box .publication-column input.zoom-range::-ms-thumb {
  height: 15px;
  width: 15px;
  background-color: #344054;
  border-radius: 50%;
  appearance: none;
  z-index: 99;
}
.tiptop-editor .bubble-toggle-menu.button-box .publication-count .count-title,
.tiptop-editor .bubble-toggle-menu.button-box .publication-column .column-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tiptop-editor .bubble-toggle-menu.button-box .publication-count .count-title label,
.tiptop-editor .bubble-toggle-menu.button-box .publication-column .column-title label {
  margin-bottom: 0;
  color: #0A0A0A;
}
.tiptop-editor .publications-wrapper .publication-list.grid.one-column {
  grid-template-columns: repeat(1, minmax(0px, 1fr))
}
.tiptop-editor .publications-wrapper .publication-list.grid.two-column {
  grid-template-columns: repeat(2, minmax(0px, 1fr))
}
.tiptop-editor .publications-wrapper .publication-list.grid.three-column {
  grid-template-columns: repeat(3, minmax(0px, 1fr))
}
.tiptop-editor .publications-wrapper .publication-list.grid.four-column {
  grid-template-columns: repeat(4, minmax(0px, 1fr))
}
.tiptop-editor .bubble-toggle-menu .content-type-menu.categories-menu {
  width: calc(100% - 2rem);
    margin-left: 1rem;
    top: 83px;
    max-height: 170px;
    overflow-y: scroll;
    box-shadow: none;
    height: auto;
    padding: 0;
    border-radius: 6px;
}
.categories-menu .categories {
  font-size: 14px;
  color: #101828;
  padding-left: 6px;
  cursor: pointer;
  margin: 0;
  font-weight: 400;
}
.categories-box .categories {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  margin: 10px 0;
}
.categories-box .selected-categorie {
  display: flex;
  gap: 2px;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  align-items: center;
  font-size: 13px;
  padding: 2px 6px;
  padding-right: 0 !important;
  background-color: #fff;
}
.categories-box .selected-categorie p {
  margin: 0 !important;
  font-weight: 400;
}
.categories-box .selected-categorie svg {
  cursor: pointer;
}
.categories-menu .selectedcat:hover {
  background: #f9f9f9;
  color: #101828;
}
.tiptop-editor .bubble-toggle-menu .categories-menu .selectedcat {
  display: flex;
  justify-content: flex-start;
  padding: 10px 16px 10px 10px;
  color: #111827;
  cursor: pointer;
  align-items: center;
}
.tiptop-editor .bubble-toggle-menu .categories-menu .selectedcat p{
  margin: 0;
  line-height: 24px;
}
.content-type-menu.categories-menu input[type="checkbox"]:before{
  height: 18px;
  width: 18px;
}
.tiptop-editor .bubble-toggle-menu.button-box .content-type-menu.categories-menu .selectedcat input {
  height: 18px;
  min-width: unset;
  width: 20px;
  flex: unset;
  margin-left: 4px;
  margin-right: 0;
  cursor: pointer;
  background: transparent;
  appearance: unset;
  border: none;
  padding: 0;
  box-shadow: none;
  margin-top: 0;
  margin-bottom: 0;
  min-height: unset;
}
.column-bubble-menu {
  padding-top: 9px !important;
  min-width: 7rem !important;
  top: -40px !important;
}
.AiWriter-node-wrapper {
  border: 1px solid #573cff;
  border-radius: 0.5rem;
}
.AiWriter-node-wrapper .aitext-generator {
  color: rgba(0, 0, 0, 0.8); 
  font-size: 0.75rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-left: 0.375rem;
}
.tiptop-editor .bubble-toggle-menu.button-box .publication-submit-button{
  text-align: right;
}
.product .publication-submit-button {
  margin-top: 10px;
}
.bubble-toggle-menu.button-box .publication-submit-button button {
  width: auto;
}
/* .video-wrapper.video-preview .image-preview{
  border: 1px solid #573cff;
  border-radius: 0.5rem;
  margin: 20px 0;
  overflow: hidden;
} */
.tiptop-editor .node-VideoBlock.ProseMirror-selectednode .player-wrapper.player-ready{
  border-radius: 6px;
}
.tiptop-editor .uploadFileBlock .videoMiddleContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tiptop-editor .video-wrapper .react-player {
  margin-top: -5px;
}
.upload-thumbnail {
  position: relative;
  background: rgba(250,250,251,1);
  width: 100%;
  height: 150px;
  margin-bottom: 15px;
  border-radius: 6px;
  cursor: pointer;
}
.button-box .upload-thumbnail .uploadBtn svg{
  position: absolute;
  left: 112px;
  top: 69px;
  padding-top: 3px;
}
.image-bubble-menu-position{
  top: -5px !important;
}
.loop-box p{
  margin: 0 !important;
}
.loop-box .toggle-button span{
  transform: translateX(0) !important;
}
.loop-box .toggle-button.checked span{
  transform: translateX(0.9rem) !important;
}
.tiptop-editor .image-row .gallery-image{
  position: relative;
}
.gallery-image:hover .delete-item-block,.gallery-image:hover .layout-wrapper{
  display: block;
}
.layout-wrapper {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000033;
}
.tiptop-editor .delete-item-block {
  position: absolute;
  top: 10px;
  display: none;
  right: 10px;
  z-index: 9;
}
.tiptop-editor .delete-item-block  .delete-item{
  display: flex;
  flex-direction: row-reverse;
}
.tiptop-editor .delete-item-block .delete-item .delete-button {
  pointer-events: auto;
  position: relative;
  display: flex;
  height: 1.8rem; 
  /* width: 1.8rem;  */
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem; 
  background-color: rgba(255, 255, 255, 0.9); 
  color: #1F2937; 
  transition: all 0.2s; 
}
.tiptop-editor .delete-item-block .delete-item .delete-button svg {
  stroke: #6b7280;
  margin-left: 1px;
}
.tiptop-editor .delete-item-block .delete-item .delete-button:hover svg {
  stroke: #0A0A0A;
}
.tiptop-editor .gallery-bubble{
  min-width: 0 !important;
}

.tiptop-editor .product-node-wrapper .image-node-wrapper .textBlock {
  margin-top: 0;
}
.tiptop-editor .product-node-wrapper .product-button,
.tiptop-editor .product-node-wrapper .product-content {
  margin-top: 10px;
} 
.tiptop-editor .product-node-wrapper  .product-content .product-title-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.tiptop-editor .product-content .product-title,
.tiptop-editor .product-content .product-description {
  cursor: text;
}
.tiptop-editor .product-content .product-title-wrapper:has(.rating) .product-title {
  max-width: calc(100% - 110px);
  font-weight: 600;
}
.tiptop-editor .product-content .product-title-wrapper .rating {
  display: flex;
  flex-direction: row;
  margin: 0;
  gap: 3px;
}
.tiptop-editor .product-content .product-title-wrapper .rating .highlighted svg {
  fill: #F2AE40;
}
.tiptop-editor .product-node-wrapper .product-title {
  margin-bottom: 5px;
  max-width: 100%;
}
.tiptop-editor .product-node-wrapper .product-title:empty:before{
  content: 'Product title' !important;
  color: #7777;
}
.tiptop-editor .product-title:focus-visible{
  outline: 0 !important;
  border: 0 !important;
}
.tiptop-editor .product-description{
  margin: 0 !important;
  cursor: auto;
}
.tiptop-editor .product-description:empty:before{
  content: 'Description' !important;
  color: #7777;
}
.tiptop-editor .product-description:focus-visible{
  outline: 0 !important;
  border: 0 !important;
}
.node-product .product-node-wrapper{
  border: 1px solid #573cff;
  border-radius: 0.5rem;
  padding: 14px;
}
.tiptop-editor .product-node-wrapper .image-block{
  padding: 0 !important;
  margin: 0 !important;
}
.tiptop-editor .product-node-wrapper .image-preview{
  border: 0 !important;
  border-radius: 0.5rem !important;
  margin: 0;
  line-height: 0;
}
.tiptop-editor .product .toggle-button span{
  transform: translateX(0) !important;
}
.tiptop-editor .product .toggle-button.checked span {
  transform: translateX(0.9rem) !important;
}
.tiptop-editor .product .toggle-button-label{
  justify-content: space-between !important;
}
.tiptop-editor .product {
  min-width: 18rem !important;
  padding: 15px !important;
}
.tiptop-editor .product .button-element button {
  background-color: #1c1c1c !important;
  border: 1px solid #1c1c1c;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  padding: 5px 10px !important;
  transition: all .3s linear;
}
.tiptop-editor .product-button button {
  width: 100% !important;
}
.tiptop-editor .product-node-wrapper .rating .rate {
  font-size: 24px;
}
.tiptop-editor .product-bubble-block .button-wrapp:has(.rating) .rating-label {
  margin-bottom: 3px;
}
.tiptop-editor .product-bubble-block .button-wrapp .rating .highlighted svg {
  fill: #F2AE40;
}
.tiptop-editor .product-bubble-block .button-wrapp .rating {    
  text-align: right;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 3px;
  margin-top: 10px;
}

.tiptop-editor .AiWriter-wrapper {
  padding: 0.5rem; 
  width: 100%; 
  background-color: white; 
  border-radius: 6px; 
  border: 1px solid #E5E7EB;
  margin-top: 0;
  margin-bottom: 20px;
}
.tiptop-editor.AiWriter-wrapper .aitext-generator {
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
}
.tiptop-editor .AiWriter-wrapper .aitext-generator label {
  color: rgba(0, 0, 0, 0.8); 
  font-size: 0.75rem; 
  font-weight: 500; 
  margin-bottom: 0.5rem; 
  margin-left: 0.375rem;
}
.tiptop-editor .node-aiWriter.ProseMirror-selectednode .AiWriter-wrapper{
  overflow: unset;
}
.tiptop-editor .AiWriter-wrapper .prompt-textarea {
  background-color: rgba(0, 0, 0, 0.05); 
  border: 0; 
  border-radius: 6px; 
  caret-color: black; 
  display: block; 
  color: black; 
  font-size: 0.875rem; 
  font-weight: 500; 
  height: 4.5rem; 
  padding: 0.25rem 0.5rem; 
  width: 100%; 
  margin-bottom: 0.5rem; 
  transition: background-color 0.2s, outline-color 0.2s;
  max-width: 100%;
  min-height: 100px;
  resize: vertical;
  margin-top: 0.5rem;
}
.tiptop-editor .AiWriter-wrapper .prompt-textarea:focus,
.tiptop-editor .AiWriter-wrapper .prompt-textarea:active {
  background-color: transparent; 
  outline: 1px solid black; 
}
.tiptop-editor .AiWriter-wrapper .generator-menu {
  display: flex;
  justify-content: space-between; 
  width: auto; 
  gap: 0.25rem;
}
.tiptop-editor .AiWriter-wrapper .generator-menulist{
  display: flex; 
  align-items: center; 
  justify-content: center; 
  border: 1px solid transparent;
  gap: 0.5rem; 
  font-size: 0.875rem; 
  font-weight: 600; 
  border-radius: 0.375rem;
  opacity: 1; 
  white-space: nowrap; 
  background-color: #F9FAFB;
  color: #111827; 
  padding: 0.5rem 0.55rem; 
  transition: background-color 0.2s; 
  height: 38px;
  position: relative;
  cursor: pointer;
}
.tiptop-editor .AiWriter-wrapper .generator-menulist:hover {
    background-color: #E5E7EB;
}
.tiptop-editor .AiWriter-wrapper .generator-menulist:active {
  background-color: #EDE9FE;
}
.tiptop-editor .generator-menulist .menuitem{
  padding: 0.5rem; 
  min-width: 12rem; 
  background-color: white; 
  border-radius: 0.5rem; 
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #E5E7EB;
  position: absolute;
  top: 41px;
  left: -4px;
  z-index: 1;
}
.tiptop-editor .AiWriter-wrapper .menuitem .categories {
  display: flex; 
  align-items: center;
  gap: 0.5rem; 
  padding: 0.375rem; 
  font-size: 0.875rem; 
  font-weight: 500; 
  color: #6B7280; 
  text-align: left; 
  background-color: transparent; 
  width: 100%; 
  border-radius: 0.375rem; 
  transition: background-color 0.2s, color 0.2s; 
}
.tiptop-editor .AiWriter-wrapper .menuitem .categories:hover {
  background-color: #F3F4F6; 
  color: #1F2937;
}
.tiptop-editor .AiWriter-wrapper .generate-button {
  display: flex; 
  justify-content: space-between;
  width: auto; 
  gap: 0.25rem;
}
.tiptop-editor .AiWriter-wrapper .generate-button .toolbar-button {
  display: flex;
  align-items: center;
  justify-content: center; 
  border: 1px solid black; 
  gap: 0.5rem; 
  font-size: 0.875rem;
  font-weight: 600; 
  border-radius: 0.375rem; 
  opacity: 1; 
  white-space: nowrap; 
  color: white; 
  background-color: black; 
  padding: 0.5rem 0.55rem; 
  transition: background-color 0.2s;
  cursor: pointer;
}
.tiptop-editor .AiWriter-wrapper .generate-button .toolbar-button:hover {
  background-color: #1F2937;
}
.tiptop-editor .AiWriter-wrapper .generate-button .toolbar-button:active {
  background-color: #1E3A8A; 
}
.tiptop-editor .AiWriter-wrapper .generate-button .toolbar-button:disabled {
  opacity: 0.5; 
  pointer-events: none; 
}
.tiptop-editor .AiWriter-wrapper  .generate-button .toolbar-button.button-discard {
  display: flex; 
  align-items: center; 
  justify-content: center; 
  border: 1px solid transparent;
  gap: 0.5rem;
  font-size: 0.875rem; 
  font-weight: 600; 
  border-radius: 0.375rem; 
  opacity: 1; 
  white-space: nowrap;
  background-color: transparent; 
  color: #F87171; 
  padding: 0.5rem 0.55rem; 
  transition: background-color 0.2s, color 0.2s; 
  cursor: pointer;
}
.tiptop-editor .AiWriter-wrapper .generate-button .toolbar-button.button-discard:hover {
  background-color: rgba(248, 113, 113, 0.1);
  color: #F87171;
}
.tiptop-editor .AiWriter-wrapper .generate-button .toolbar-button.button-discard:active {
  background-color: rgba(0, 0, 0, 0.1);
  color: #1F2937;
}
.tiptop-editor .AiWriter-wrapper .generate-button .toolbar-button.button-discard:disabled {
  opacity: 0.5; 
  pointer-events: none; 
}
.tiptop-editor .AiWriter-wrapper .generate-button .toolbar-button.button-insert {
  display: flex; 
  align-items: center; 
  justify-content: center; 
  border: 1px solid transparent; 
  gap: 0.5rem; 
  font-size: 0.875rem; 
  font-weight: 600; 
  border-radius: 0.375rem; 
  opacity: 1; 
  white-space: nowrap; 
  background-color: transparent; 
  color: #6B7280; 
  padding: 0.5rem 0.55rem;
  transition: background-color 0.2s, color 0.2s;
  cursor: pointer;
}
.tiptop-editor .AiWriter-wrapper .generate-button .toolbar-button.button-insert:hover {
  background-color: rgba(0, 0, 0, 0.05); 
  color: #374151; 
}
.tiptop-editor .AiWriter-wrapper .generate-button .toolbar-button.button-insert:active {
  background-color: rgba(0, 0, 0, 0.1); 
  color: #1F2937; 
}
.tiptop-editor .AiWriter-wrapper .generate-button .toolbar-button.button-insert:disabled {
  opacity: 0.5;
  pointer-events: none; 
}
.tiptop-editor .AiWriter-wrapper .preview-title{
  color: rgba(0, 0, 0, 0.8); 
  font-size: 0.75rem; 
  font-weight: 500; 
  margin-bottom: 0.5rem; 
  margin-left: 0.375rem; 
}
.tiptop-editor .AiWriter-wrapper .aitext-preview{
  background-color: white; 
  border-left: 4px solid #e5e7eb; 
  color: black; 
  font-size: 1rem; 
  max-height: 14rem;
  margin-bottom: 1rem; 
  margin-left: 0.625rem; 
  overflow-y: auto;
  padding-left: 1rem;
  position: relative;
}
.tiptop-editor .AiWriter-wrapper .button .generate-button.insertbtn{
  display: block;
}
.tiptop-editor .AiWriter-wrapper .button .generate-button .mobile-button{
  display: none;
}
.tiptop-editor .generator-menu .ai-button {
  display: flex;
  align-items: center;
  gap: 8px;
}
.tiptop-editor .AiWriter-wrapper .dropdown .downarrow{
  position: absolute;
  top: 12px;
}
.tiptop-editor .logoslider-wapper{
  margin-bottom: 20px;
}
.tiptop-editor .logoslider-wapper .swiper-wrapper {
  align-items: center;
}
.tiptop-editor .logoslider-wapper .swiper-slide {
  margin: 20px auto;
}
.tiptop-editor .logoslider-wapper .swiper-slide img {
  max-width: 100%;
}
.tiptop-editor .bubble-toggle-menu .categories-button .menu-icon{
  height: 24px !important;
}
.tiptop-editor .node-VideoBlock .video-wrapper{
  margin-bottom: 20px;
}
.tiptop-editor .node-VideoBlock.ProseMirror-selectednode .video-wrapper.video-preview,
.node-galleryBlock .image-block,
.node-imageBlock .image-block .image-preview {
  border: 0;
  overflow: unset;
}
.tiptop-editor .ProseMirror-selectednode .video-wrapper .image-preview.image-width-wide,
.tiptop-editor .ProseMirror-selectednode .video-wrapper .image-preview.image-width-default{
  border: 1px solid #573cff;
  border-radius: 0.5rem;
}
.node-fileBlock .fileblock-wrapper,
.node-publications .publications-wrapper,
.node-product .product-node-wrapper,
.node-galleryBlock .image-preview,
.node-VideoBlock .video-wrapper.video-preview,
.node-logoSliderBlock .logoslider-wapper,
.node-imageBlock .image-block .image-preview {
  border: 1px solid transparent;
}
/* .node-vimeoBlock .vimeo-video,.gallery-block .image-preview, .image-block .image-preview {
  overflow: hidden;
} */
.node-aiWriter.ProseMirror-selectednode .AiWriter-wrapper,
.node-publications.ProseMirror-selectednode .publications-wrapper,
.node-product.ProseMirror-selectednode .product-node-wrapper,
.node-vimeoBlock.ProseMirror-selectednode .vimeo-video,
.tiptop-editor .node-galleryBlock.ProseMirror-selectednode .image-preview,
.node-VideoBlock.ProseMirror-selectednode .video-wrapper.video-preview,
.node-imageBlock.ProseMirror-selectednode .image-block .image-preview,
.tiptop-editor .node-logoSliderBlock.ProseMirror-selectednode .logoslider-wapper{
  border: 1px solid #573cff;
  border-radius: 0.5rem;
  overflow: hidden;
}
.node-VideoBlock .video-preview,
.node-imageBlock.ProseMirror-selectednode .image-preview,
.gallery-block .gallery-preview{
  border: none;
  border-radius: 0;
}
.tiptop-editor .image-block .image-preview.image-width-default .image-title{
  margin-top: -5px;
}
.tiptop-editor .image-block .video-title.image-title p{
  font-size: 14px;
}
.tiptop-editor .video-wrapper.video-preview .player-wrapper.player-ready{
  margin-top: 0;
}
.tiptop-editor .video-wrapper.video-preview .video-title{
  margin-top: -5px;
}
.ProseMirror [data-type='columns'] [data-position='left']:hover,
.ProseMirror [data-type='columns'] [data-position='right']:hover,
.ProseMirror [data-type='columns'] [data-position='left'].has-focus,
.ProseMirror [data-type='columns'] [data-position='right'].has-focus {
  border: 1px solid #573cff;
}
.ProseMirror [data-type='columns'] [data-position='left'],
.ProseMirror [data-type='columns'] [data-position='right'] {
  border: 1px solid transparent; 
}
.gallery-block .video-caption:empty:before{
  content:'Write a caption for the Gallery (Optional)'
}
.gallery-block {
  position: relative;
}
.galley-layout-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000033;
}
.image-node-wrapper .dragging{
  opacity: 0.6;
}
.node-VideoBlock.ProseMirror-selectednode .image-preview,
.node-imageBlock.ProseMirror-selectednode .image-preview,
.node-galleryBlock.ProseMirror-selectednode .image-preview{
  border: none;
  border-radius: 0;
}
.node-image .tabTitle, 
.node-video .tabTitle,
.node-gallery .tabTitle,
.node-product .tabTitle,
.node-file .tabTitle,
.node-logoSlider .tabTitle
{
  list-style-type: none !important;
  padding-inline-start: 0 !important;
  padding: 0 24px !important;
}
.node-image .tabTitle li,
.node-video .tabTitle li,
.node-gallery .tabTitle li,
.node-product .tabTitle li,
.node-file  .tabTitle li,
.node-logoSlider  .tabTitle li
{
  margin-bottom: 0  !important;
  margin-top: 0  !important;
}
.node-image .popupTitle , 
.node-video .popupTitle,
.node-gallery .popupTitle ,
.node-product .popupTitle ,
.node-file  .popupTitle,
.node-logoSlider  .popupTitle
{
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #101828;
  margin-bottom: 10px;
}
.node-image .pageSearchInput input,
.node-video .pageSearchInput input,
.node-gallery .pageSearchInput input,
.node-product .pageSearchInput input,
.node-file  .pageSearchInput input,
.node-logoSlider  .pageSearchInput input
 {
  width: calc(100% - 5px);
  height: 40px !important;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  padding-left: 40px;
  outline: none;
  right: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #667085;
}
.node-image .dropdown-wrapp ul,
.node-video .dropdown-wrapp ul,
.node-gallery .dropdown-wrapp ul ,
.node-product .dropdown-wrapp ul ,
.node-file  .dropdown-wrapp ul,
.node-logoSlider  .dropdown-wrapp ul 
{
  border: none;
  padding: 10px;
}
.node-image .dropdown,
.node-video .dropdown,
.node-gallery .dropdown ,
.node-product .dropdown, 
.node-file  .dropdown ,
.node-logoSlider  .dropdown 
{
  background: white;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 6px;
  position: absolute;
  right: 0;
  top: 50px;
  min-width: 170px;
  z-index: 999999;
}
.node-file  .dropdown.countryDropdown {
  min-width: 160px;
}
.node-image .dropdown.addDropdown,
.node-video .dropdown.addDropdown,
.node-gallery .dropdown.addDropdown,
.node-product .dropdown.addDropdown,
.node-file  .dropdown.addDropdown,
.node-logoSlider  .dropdown.addDropdown
 {
  right: auto;
}
.node-image .dropdown.dateDropdown {
  width: max-content;
}
.node-image .dropdown-wrapp ul li:not(:last-child),
.node-video .dropdown-wrapp ul li:not(:last-child),
.node-gallery .dropdown-wrapp ul li:not(:last-child) ,
.node-product .dropdown-wrapp ul li:not(:last-child) ,
.node-file  .dropdown-wrapp ul li:not(:last-child) ,
.node-logoSlider  .dropdown-wrapp ul li:not(:last-child) 
{
  margin-bottom: 10px;
}

.node-image .dropdown-wrapp ul li,
.node-video .dropdown-wrapp ul li,
.node-gallery .dropdown-wrapp ul li ,
.node-product .dropdown-wrapp ul li ,
.node-file  .dropdown-wrapp ul li ,
.node-logoSlider  .dropdown-wrapp ul li 
{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.node-image .media-grid-layout .list-search input[type="checkbox"],
.node-video .media-grid-layout .list-search input[type="checkbox"],
.node-gallery .media-grid-layout .list-search input[type="checkbox"],
.node-product .media-grid-layout .list-search input[type="checkbox"],
.node-file  .media-grid-layout .list-search input[type="checkbox"],
.node-logoSlider  .media-grid-layout .list-search input[type="checkbox"]
 {
  font-size: 0;
  width: 18px;
  height: 18px;
  border: 1px solid transparent;
  -o-appearance: none;
  appearance: none;
  position: relative;
  padding: 0 0 0 0;
  margin: 0;
  margin-right: 9px !important;
  right: 0;
  top: 0;
}
.node-image .media-grid-layout input[type="checkbox"]:not(:checked)::before,
.node-video .media-grid-layout input[type="checkbox"]:not(:checked)::before,
.node-gallery .media-grid-layout input[type="checkbox"]:not(:checked)::before,
.node-product .media-grid-layout input[type="checkbox"]:not(:checked)::before,
.node-file  .media-grid-layout input[type="checkbox"]:not(:checked)::before,
.node-logoSlider  .media-grid-layout input[type="checkbox"]:not(:checked)::before
 {
  background-color: #fff;
}
.node-image .media-grid-layout input[type="checkbox"]::before,
.node-video .media-grid-layout input[type="checkbox"]::before,
.node-gallery .media-grid-layout input[type="checkbox"]::before,
.node-product .media-grid-layout input[type="checkbox"]::before,
.node-file  .media-grid-layout input[type="checkbox"]::before,
.node-logoSlider  .media-grid-layout input[type="checkbox"]::before
 {
  margin-right: 0;
}
.node-image .list-search input[type="checkbox"]:before,
.node-video .list-search input[type="checkbox"]:before ,
.node-gallery .list-search input[type="checkbox"]:before ,
.node-product .list-search input[type="checkbox"]:before ,
.node-file  .list-search input[type="checkbox"]:before ,
.node-logoSlider  .list-search input[type="checkbox"]:before 
{
  position: absolute;
  left: 0;
  top: -1px;
  margin-right: 0;
}
.node-image input[type="checkbox"]:before,
.node-video input[type="checkbox"]:before,
.node-gallery input[type="checkbox"]:before,
.node-product input[type="checkbox"]:before,
.node-file  input[type="checkbox"]:before,
.node-logoSlider  input[type="checkbox"]:before
 {
  content: "";
  font-size: 15px;
  border: 1px solid #d0d5dd;
  display: block;
  width: 15px;
  background: none;
  height: 15px;
  margin-right: 7px;
  border-radius: 4px;
  min-width: 18px;
  min-height: 18px;
  margin-right: 15px;
  background-size: 60%;
  background-position: 4px center;
  background-repeat: no-repeat;
  accent-color: #e6f2ff;
}
.node-image .dropdown-wrapp ul li span,
.node-video .dropdown-wrapp ul li span,
.node-gallery .dropdown-wrapp ul li span,
.node-product .dropdown-wrapp ul li span,
.node-file  .dropdown-wrapp ul li span,
.node-logoSlider  .dropdown-wrapp ul li span
 {
  margin-left: 0;
  color: #101828;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.node-image input[type="checkbox"]:checked:before,
.node-video input[type="checkbox"]:checked:before ,
.node-gallery input[type="checkbox"]:checked:before ,
.node-product input[type="checkbox"]:checked:before ,
.node-file  input[type="checkbox"]:checked:before ,
.node-logoSlider  input[type="checkbox"]:checked:before 
{
  background-image: url('../../images/checkbox.svg');
  border: 1px solid #2b59ff;
  background-color: #fff;
}
.node-image .gridImg input,
.node-video .gridImg input,
.node-gallery .gridImg input,
.node-product .gridImg input,
.node-file  .gridImg input,
.node-logoSlider  .gridImg input
{
  position: absolute !important;
  top: 10px;
  right: 10px;
  appearance: none;
  width: auto !important;
  background: unset;
  height: 0;
  padding: 0;
}

.node-image .file-wrapper .filename,
.node-video .file-wrapper .filename,
.node-gallery .file-wrapper .filename,
.node-product .file-wrapper .filename,
.node-file  .file-wrapper .filename,
.node-logoSlider  .file-wrapper .filename
{
  margin-top: 0 !important;
  line-height: unset !important;
}
.node-image .file-wrapper .formUrlImg,
.node-video .file-wrapper .formUrlImg,
.node-gallery .file-wrapper .formUrlImg,
.node-product .file-wrapper .formUrlImg,
.node-file  .file-wrapper .formUrlImg,
.node-logoSlider  .file-wrapper .formUrlImg
{
  margin-top: 14px;
}
.node-image .file-wrapper.show-wrapper,
.node-video .file-wrapper.show-wrapper,
.node-gallery .file-wrapper.show-wrapper,
.node-product .file-wrapper.show-wrapper,
.node-file .file-wrapper.show-wrapper,
.node-logoSlider .file-wrapper.show-wrapper
{
  z-index: 0 !important;
}
.node-image .featuredImageModal input.zoom-range,
.node-video .featuredImageModal input.zoom-range,
.node-gallery .featuredImageModal input.zoom-range,
.node-product .featuredImageModal input.zoom-range,
.node-file  .featuredImageModal input.zoom-range,
.node-logoSlider  .featuredImageModal input.zoom-range
{
  padding: 0 !important;
}
.node-image .ring-loader:before,
.node-video .ring-loader:before,
.node-gallery .ring-loader:before,
.node-product .ring-loader:before,
.node-file .ring-loader:before,
.node-logoSlider .ring-loader:before
{
  border: 3px solid #fff !important;
}
.product-node-wrapper .gallery-image .image-preview{
  position: relative;
}
.publications-wrapper .publication-image {
  overflow: hidden;
}
.publications-wrapper .publication-image img {
  width: 100%;
  max-width: 100%;
  height: 300px;
  object-fit: cover;
  transform: scale(1, 1);
  transition-property: transform, filter, opacity;
  transition-timing-function: cubic-bezier(0.57, 0.21, 0.69, 1);
  transition-duration: 0.3s;
}
.publications-wrapper .publication-image:hover img {
  transform: scale(1.05, 1.05);
}
.tiptop-editor .node-bookmarkBlock,.tiptop-editor .node-tableOfContentsNode,.tiptop-editor .node-spotifyBlock,.tiptop-editor .node-soundCloudBlock,.tiptop-editor .node-other,.tiptop-editor .node-product{
  margin-bottom: 20px;
}
.tiptop-editor .node-fileBlock,.tiptop-editor .node-publications{
  margin-bottom: 10px;
}
.dashboard.new-publication:has(.popupContainer ),
.dashboard.new-page:has(.popupContainer ),
.dashboard.new-newsletter:has(.popupContainer ) {
  position: relative;
  z-index: 15;
}
/* .main-wrapper:has(.uploadMediaPopup) .sidebar {
  z-index: 7;
} */
.tiptop-editor .main-wrapper .main-content {
  z-index: 10;
}
.swiper-slider {
  z-index: 50;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  text-align: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.swiper-slider-container {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  width: 100%;
  display: flex;
}

.swiper-slider-list {
  animation: logoSliderScroll 15s linear infinite;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  will-change: transform;
  transform-style: preserve-3d;
  animation-duration: 66.8s;
  flex: none;
}

.swiper-items {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  display: flex;
}

.swiper-slide-item {
  height: 4.5rem;
}

.swiper-slide-item img {
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
  height: 100%;
  border: 0;
}
.twitterBlock td, .twitterBlock th{
  border: 0 !important
}
.twitterBlock tr td table tr td{
  padding: 0;
}

.tiptop-editor .twitterBlock .userSubPanel .userName {
  display: block;
  font-size: 16px;
  color: #1c2022;
  font-weight: 700;
  margin-bottom: 0 ;
  line-height: unset;
}
.tiptop-editor .twitterBlock .userSubPanel .userNamewithLink {
  display: block;
  color: #697882;
  font-size: 16px;
  margin-bottom: 0;
  line-height: unset;
  margin-top: 0;
}
.tiptop-editor .twitterBlock .retweetCount {
  margin-left: 5px
}
.tiptop-editor .twitterBlock .likeCount , 
.tiptop-editor .twitterBlock .retweetCount{
  color: #697882;
  font-size: 14px
}
.tiptop-editor .twitterBlock .likeCount span, 
.tiptop-editor .twitterBlock .retweetCount span {
  color: #1C2022;
  font-weight: bold;
}
.field-info {
  font-weight: 400;
}

@media screen and (max-width: 1450px) {
  .tiptop-editor .publications-wrapper .publication-list.grid.three-column,.tiptop-editor .publications-wrapper .publication-list.grid.four-column {
    grid-template-columns: repeat(2, minmax(0px, 1fr))
  }
  .tiptop-editor .publications-wrapper .publication-list.grid .publication-item .publication-image {
    height: 160px;
  }
}
@media screen and (max-width: 1260px) {
  .new-publication .publication-Regular.newpageContent .newpageLeft, .new-page .newpageLeft, .new-newsletter .newpageLeft{
    padding-right: 80px;
    padding-left: 80px;
  }
  .tiptop-editor .video-wrapper .image-preview.image-width-wide,
  .tiptop-editor .image-block .image-preview.image-width-full,
  .tiptop-editor .logoslider-wapper.image-width-full {
    width: calc(100vw - 312px - 36px - 36px - 40px);
  }
  .tiptop-editor .publication-list.list .publication-item{
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
  }
  .tiptop-editor .publications-wrapper .publication-list.list .publication-image{
    width: 40%;
  }
  .tiptop-editor .publications-wrapper .publication-image img{
    width: 100%;
  }
  .tiptop-editor .publication-list.list .publication-data{
    width: 60%;
    padding-left: 15px;
    padding-top: 0;
  }
  .dashboard.new-publication:has(.popupContainer ) .newpageRight,
  .dashboard.new-page:has(.popupContainer ) .newpageRight, 
  .dashboard.new-newsletter:has(.popupContainer ) .newpageRight  {
    visibility: hidden;
  }
  .tiptop-editor .publications-wrapper .publication-list.grid .publication-item .publication-image {
    height: 220px;
  }
}

@media screen and (max-width: 1240px) {
  .tiptop-editor .video-wrapper .image-preview.image-width-wide,
  .tiptop-editor .image-block .image-preview.image-width-full,
  .tiptop-editor .logoslider-wapper.image-width-full {
    width: calc(100vw - 312px - 36px - 40px);
  }
}

@media screen and (max-width: 1024px) {
  .tiptop-editor .video-wrapper .image-preview.image-width-wide,
  .tiptop-editor .image-block .image-preview.image-width-full,
  .tiptop-editor .logoslider-wapper.image-width-full {
    width: calc(90vw - 36px);
  }
  .tiptop-editor .publications-wrapper .publication-list.grid.three-column {
    grid-template-columns: repeat(3, minmax(0px, 1fr))
  }

  .tiptop-editor .publications-wrapper .publication-list.grid.four-column {
    grid-template-columns: repeat(4, minmax(0px, 1fr))
  }
  .tiptop-editor .publications-wrapper .publication-list.list .publication-image{
    width: 30%;
  }
  .tiptop-editor .publications-wrapper .publication-image img{
    width: 100%;
  }
  .tiptop-editor .publication-list.list .publication-data{
    width: 70%;
    padding-left: 15px;
    padding-top: 0;
  }
  .new-publication .publication-Regular.newpageContent .newpageLeft, .new-page .newpageContent .newpageLeft,
  .new-newsletter .newpageContent .newpageLeft {
    padding-right: 45px;
    padding-left: 45px;
  }
  .tiptop-editor .DragHandleItems {
    flex-wrap: wrap;
  }
  .DragHandleItems .DragHandleItem {
    width: 100%;
  }
  .tiptop-editor [data-tippy-root] {
    z-index: 5 !important;
  }
  .dashboard.new-publication:has(.popupContainer ),
  .dashboard.new-page:has(.popupContainer ),
  .dashboard.new-newsletter:has(.popupContainer ) {
    position: relative;
    z-index: 20;
  }
  .main-wrapper .main-content {
    z-index: 0;
  }
  .board:has(.popupContainer) .sidebar {
    z-index: 0;
  }
  
}

@media (max-width: 767px) {
  .tiptop-editor .publications-wrapper .publication-list.grid{
    grid-template-columns: repeat(2, minmax(0px, 1fr));
    gap: 20px;
  }
  .tiptop-editor .linkBox {
    flex-direction: column;
  }
  .tiptop-editor .textBlock.linkBox .linkImageSection {
    width: 100% !important;
  }
  .tiptop-editor .linkBox .linkData {
    width: 100%;
    padding-left: 0;
    padding-top: 15px;
  }
  .tiptop-editor .link-content img{
    height: auto;
  }
  .tiptop-editor .video-wrapper .image-preview.image-width-wide,
  .tiptop-editor .image-block .image-preview.image-width-full,
  .tiptop-editor .logoslider-wapper.image-width-full {
    width: calc(100vw - 36px);
  }
  .tiptop-editor .publications-wrapper .publication-list.grid.three-column,
  .tiptop-editor .publications-wrapper .publication-list.grid.four-column {
    grid-template-columns: repeat(2, minmax(0px, 1fr))
  }
  .tiptop-editor [data-type='video'] .video-wrapper .player-wrapper.player-ready{
    min-height: auto;
  }
  .new-publication .publication-Regular.newpageContent .newpageLeft, .new-page .newpageContent .newpageLeft, .new-newsletter .newpageContent .newpageLeft {
    margin-left: 22px;
    margin-right: 22px !important;
  }
  .newpageContent .newpageLeft{
    width: calc(100% - 44px);
  }
}
@media (max-width: 590px) {
  .tiptop-editor .publications-wrapper .publication-list.grid .publication-item .publication-image {
    height: 160px;
  }
}
@media (max-width: 430px) {
  .tiptop-editor .tiptap.ProseMirror {
    padding-bottom: 10px;
  }
  .publication-list.list .publication-item{
    flex-direction: row;
    margin-top: 20px;
  }
  .publication-list.list .publication-data{
    width: 100%;
    padding-left: 0;
  }
  .publication-list.list .publication-date{
    font-size: 14px;
  }
  .tiptop-editor .publications-wrapper .publication-list.list .publication-image{
    width: 30%;
  }
  .tiptop-editor .publication-list.list .publication-data{
    width: 70%;
    padding-left: 10px;
  }
  .publication-list.list .publication-image,
  .publication-list.grid .publication-image{
    width: 100%;
  }
  .publication-list.list .publication-image img,
  .publication-list.grid .publication-image img{ 
    width: 100%;
  }
  .tiptop-editor .publications-wrapper .tiptop-editor .publication-list.grid{
    grid-template-columns: repeat(1, minmax(0px, 1fr));
  }
  .tiptop-editor .AiWriter-wrapper .generator-menulist,
  .tiptop-editor .AiWriter-wrapper .generate-button .toolbar-button {
    padding: 0.5rem 0.60rem;
    gap: 0.2rem;
  }
  .tiptop-editor .AiWriter-wrapper .generate-button .toolbar-button{
    width: 100%;
  }
  .tiptop-editor .generator-menulist .menuitem {
    width: 100%;
    height: 11rem;
    overflow-y: scroll;
    margin-left: 4px;
  }
  .tiptop-editor .generator-menu .ai-button,
  .tiptop-editor .AiWriter-wrapper .generator-menu {
    flex-direction: column;
  }
  .AiWriter-wrapper .generator-menu{
    gap: 0.5rem;
  }
  .tiptop-editor .AiWriter-wrapper .generate-button {
    width: 100%;
  }
  .tiptop-editor .AiWriter-wrapper .button .generate-button.insertbtn{
    display: none;
  }
  .tiptop-editor .AiWriter-wrapper .button .generate-button .mobile-button{
    display: block;
  }
  .tiptop-editor .publications-wrapper .publication-list.grid.three-column,.tiptop-editor .publications-wrapper .publication-list.grid.four-column,.tiptop-editor .publications-wrapper .publication-list.grid.two-column {
    grid-template-columns: repeat(1, minmax(0px, 1fr))
  }
}


@keyframes rotate {
  100% {
      transform: rotate(360deg);
  }
}
@keyframes prixClipFix {
  0% {
      clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
      clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          100% 100%,
          100% 100%
      );
  }

  75% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  100% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

@keyframes loading {
  0% {
      opacity: 0.6;
  }

  50% {
      opacity: 1;
  }

  100% {
      opacity: 0.6;
  }
}
@keyframes progress {
  0% {
      width: 0%;
  }

  25% {
      width: 50%;
  }

  50% {
      width: 75%;
  }

  75% {
      width: 85%;
  }

  100% {
      width: 100%;
  }
};
